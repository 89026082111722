/**
 * This module contains a comprehensive hard-reload function, as described here:
 *
 * https://www.wednesday.is/writing-tutorials/react-to-the-react-app-how-to-hard-reload-your-react-web-app-using-error-boundary
 */
const reloadApp = async () => {
  // Unregister service walkers.
  const registrations = await window.navigator.serviceWorker.getRegistrations()
  registrations.forEach(registration => {
    registration.unregister()
  })

  // Clear the cache.
  const keyList = await caches.keys()
  await Promise.all(keyList.map(key => caches.delete(key)))

  // Finally, do the actual reload.
  window.location.reload()
}

export { reloadApp }
