/**
 * ErrorBoundary is what you might expect: an error boundary component as described in
 * https://reactjs.org/docs/error-boundaries.html
 */
import React, { Component } from 'react'

import withStyles from '@mui/styles/withStyles'

import { errorComponent } from '../error-types/library'
import { trackError } from '../../tracking/segment'

const styles = theme => ({}) // No-op; currently just a placeholder.

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error) {
    if (error) {
      trackError(error, error.url, error.status)
    }

    return { error }
  }

  render() {
    const { children } = this.props
    const { error } = this.state

    const ErrorComponent = error ? errorComponent(error) : null
    return ErrorComponent ? <ErrorComponent error={error} /> : children
  }
}

export default withStyles(styles, { withTheme: true })(ErrorBoundary)
