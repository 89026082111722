import React, { useEffect, useRef } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import { THEME_COLOR_GROUP_NEUTRAL, THEME_PRIMARY_PURPLE } from '../../app/support/theme'

const INPUT_PATTERN = '[0-9]*'

const INPUT_HEIGHT_DEFAULT = 7
const INPUT_WIDTH_DEFAULT = 7.5

const useStyles = makeStyles(
  theme => ({
    digit: {
      ...theme.typography.h5,

      alignItems: 'center',
      aspectRatio: 0.5625, // We use aspect ratio rather than fixed height because width can vary by %.
      backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N10,
      borderColor: THEME_COLOR_GROUP_NEUTRAL.N30,
      borderRadius: theme.spacing(1),
      borderStyle: 'solid',
      borderWidth: theme.spacing(0.125),
      caretColor: theme.palette.text.primary,
      display: 'flex',
      fontWeight: theme.typography.fontWeightRegular,
      justifyContent: 'center',
      textAlign: 'center',

      transition: theme.transitions.create(['border-color', 'color'], {
        duration: theme.transitions.duration.standard
      }),

      width: `min(15%, ${theme.spacing(INPUT_WIDTH_DEFAULT)})`,

      '&:focus-visible': {
        outlineStyle: 'none'
      },

      [theme.breakpoints.up('brandSmaller')]: {
        aspectRatio: INPUT_WIDTH_DEFAULT / INPUT_HEIGHT_DEFAULT
      }
    },

    selected: {
      borderColor: THEME_PRIMARY_PURPLE.light
    },

    selectedWithAnimation: {
      animationDirection: 'alternate',
      animationDuration: '0.75s',
      animationIterationCount: 'infinite',
      animationName: '$blink',
      animationTimingFunction: 'steps(2, jump-none)'
    },

    '@keyframes blink': {
      from: {
        color: THEME_COLOR_GROUP_NEUTRAL.N70
      },

      to: {
        color: theme.palette.text.primary
      }
    },

    empty: {
      color: THEME_COLOR_GROUP_NEUTRAL.N70
    }
  }),
  {
    name: 'InputDigit'
  }
)

const InputDigit = props => {
  const { value, selected, animateSelection, onAutocomplete } = props

  const inputRef = useRef()

  const ignore = event => event.preventDefault()

  const handleAutocomplete = event => onAutocomplete(event.target.value)

  useEffect(() => {
    if (selected) {
      inputRef.current.focus()
    }
  }, [selected])

  const classes = useStyles(props)
  return (
    <input
      ref={inputRef}
      className={clsx(
        classes.digit,
        !value && classes.empty,
        selected && classes.selected,
        animateSelection && classes.selectedWithAnimation
      )}
      type="tel"
      maxLength="1"
      pattern={INPUT_PATTERN}
      onBeforeInput={ignore}
      onChange={handleAutocomplete}
      onCut={ignore}
      onPaste={ignore}
      value={value || ''}
    />
  )
}

InputDigit.propTypes = {
  selected: PropTypes.bool, // Whether this digit should look highlighted.
  value: PropTypes.string, // The individual digit to display.
  animateSelection: PropTypes.bool, // Whether the selection should be animated.
  onAutocomplete: PropTypes.func // Function to handle autocomplete
}

export default InputDigit
