import PropTypes from 'prop-types'

import { getRegionalCenterList } from '../typeUtilities'

/**
 * Because of the way care plan templates are routed, we need to reserve certain “identifiers.”
 */
const RESERVED_ID_NEW = 'new'

const GUIDE_ADMIN = 'guide-admin'

const DEFAULT_RETURN_PATH = `/${GUIDE_ADMIN}`
const DEFAULT_NEW_PATH = `/${GUIDE_ADMIN}/${RESERVED_ID_NEW}`

const GUIDE_STATUS_PUBLISHED = 'active'
const GUIDE_STATUS_ARCHIVED = 'archived'
const GUIDE_STATUS_DRAFT = 'draft'
const GUIDE_STATUS_DEPRECATED = 'deprecated'

const GUIDE_STATUSES = [GUIDE_STATUS_PUBLISHED, GUIDE_STATUS_ARCHIVED, GUIDE_STATUS_DRAFT, GUIDE_STATUS_DEPRECATED]

const GUIDE_SHAPE = {
  ages: PropTypes.arrayOf(PropTypes.string),
  category: PropTypes.string,
  createdAt: PropTypes.instanceOf(Date),
  curator: PropTypes.string,
  description: PropTypes.string,
  diagnoses: PropTypes.arrayOf(PropTypes.string),
  goalId: PropTypes.string,
  id: PropTypes.string,
  updatedAt: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  status: PropTypes.oneOf(GUIDE_STATUSES),
  tags: PropTypes.arrayOf(PropTypes.string),
  topics: PropTypes.arrayOf(PropTypes.number),
  type: PropTypes.string
}

const GUIDE_VARIABLE_REGIONAL_CENTER = 'regionalCenter'

const GUIDE_VARIANT_KEYS = [{ key: GUIDE_VARIABLE_REGIONAL_CENTER, label: 'Regional Center' }]

const GUIDE_VARIANT_KEY_MAP = Object.fromEntries(GUIDE_VARIANT_KEYS.map(({ key, label }) => [key, label]))

const GUIDE_VARIANT_VALUES = {
  [GUIDE_VARIABLE_REGIONAL_CENTER]: {
    optionsLoader: async () => await getRegionalCenterList()
  }
}

// Because some options need to be retrieved from the API, GUIDE_VARIANT_VALUES
// cannot predefine these options right away. This function can be used to initiate
// the load when needed.
//
// The function does not handle throws, so the try/catch clause is expected to be
// provided by the caller.
//
// Also, yes, we take the mutation approach here 🙈 The choice is made because loaded
// options need only be retrieved from the API once. Mutating will allow future uses
// of GUIDE_VARIANT_VALUES to no longer incur network requests.
//
// The callback function is provided to indicate when a particular set of options has
// finished loading, for instance in case a React component needs to re-render.
//
// To avoid redundant loads, the global `loadLock` variable keeps track of on-going
// loading operations.
let loadLock = {}

GUIDE_VARIANT_VALUES.loadOptions = optionsCompletionCallback => {
  Object.keys(GUIDE_VARIANT_VALUES).forEach(async key => {
    const variant = GUIDE_VARIANT_VALUES[key]
    const { options, optionsLoader } = variant
    if (optionsLoader && !options) {
      // Load the options once and only once.
      if (loadLock[key]) {
        // Someone else is already loading, so we queue up the callback.
        loadLock[key].push(optionsCompletionCallback)
      } else {
        // Otherwise, we’re the first one in, so we get started.
        loadLock[key] = [optionsCompletionCallback]

        const options = await optionsLoader()
        variant.options = options
        variant.optionsMap = Object.fromEntries(options.map(({ id, name }) => [id, name]))
        // ☝🏼 For easier id-to-name lookup.

        // Once the load finishes, additional callbacks may have accumulated so we call
        // them all.
        loadLock[key].forEach(callback => {
          if (callback) {
            callback(key)
          }
        })

        delete loadLock[key]
      }
    }
  })
}

export {
  RESERVED_ID_NEW,
  DEFAULT_RETURN_PATH,
  DEFAULT_NEW_PATH,
  GUIDE_STATUS_PUBLISHED,
  GUIDE_STATUS_DRAFT,
  GUIDE_SHAPE,
  GUIDE_VARIANT_KEYS,
  GUIDE_VARIANT_KEY_MAP,
  GUIDE_VARIANT_VALUES,
  GUIDE_ADMIN
}
