import React, { useContext, useState } from 'react'
import { Navigate } from 'react-router-dom'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import { useAppUser, useParentViewCapability } from '../../app/hooks'
import { paymentCollectFunnel } from '../../app/utilities'

import {
  THEME_COLOR_GROUP_GREEN,
  THEME_COLOR_GROUP_NEUTRAL,
  THEME_COLOR_GROUP_YELLOW,
  THEME_PRIMARY_GREEN,
  THEME_PRIMARY_YELLOW
} from '../../app/support/theme'

import { CAPABILITY_HAVE_MEMBERSHIP } from '../../arch/capabilityConstants'

import { THANK_YOU_QUOTES, SCHEDULED_CALL_QUOTES, CHECKPOINT_VERIFY_EMAIL, CHECKPOINT_PAYMENT_CC } from '../constants'
import { OnboardingStatus } from '../contexts'

import mobileImage from '../../assets/mobile-app-sample-video-chat.svg'
import vectorImageForQualified from '../../assets/stylized-circle.svg'
import vectorImageForUnqualified from '../../assets/hand-drawn-lines.svg'

import BrandButton from '../../subcomponents/brand/BrandButton'

import EventCard from './EventCard'
import HelpPrompt from './HelpPrompt'
import OnboardingContainer from './OnboardingContainer'

const useStyles = makeStyles(
  theme => ({
    root: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(5)
    },

    copy: {
      margin: 0
    },

    helpContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
      justifyContent: 'space-between',
      margin: theme.spacing(6.625, 0),

      [theme.breakpoints.up('brandSmaller')]: {
        flexDirection: 'row'
      }
    },

    helpPrompt: {},

    exploreButton: {
      padding: theme.spacing(1.625, 3, 1.5),
      width: '100%',

      [theme.breakpoints.up('brandSmaller')]: {
        width: 'auto'
      }
    },

    clientColor: {
      color: THEME_COLOR_GROUP_NEUTRAL.N500
    },

    vectorImage: {
      height: '100%',
      left: theme.spacing(3),
      maxHeight: theme.spacing(40.875),
      maxWidth: theme.spacing(40.75),
      position: 'absolute',
      top: theme.spacing(-5.25),
      width: '100%'
    },

    sidebarImage: {
      bottom: '30%',
      height: theme.spacing(4.5),
      position: 'absolute',
      width: theme.spacing(60.25)
    },

    accent: {
      backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N10
    },

    qualifiedQuote: {
      color: THEME_COLOR_GROUP_GREEN.G100
    },

    unqualifiedQuote: {
      color: THEME_COLOR_GROUP_YELLOW.Y100
    }
  }),
  {
    name: 'ScheduleConfirmationCard'
  }
)

const ScheduleConfirmationCard = props => {
  const appUser = useAppUser()
  const { household } = appUser ?? {}
  const { joinFunnel } = household ?? {}
  const isPaymentCollect = paymentCollectFunnel(joinFunnel)

  const { className } = props

  const { onboarding, setOnboarding } = useContext(OnboardingStatus)

  const { isParentCapable } = useParentViewCapability()

  const qualifiedForKickstart = isParentCapable(CAPABILITY_HAVE_MEMBERSHIP)

  const [error, setError] = useState()

  const text = qualifiedForKickstart ? SCHEDULED_CALL_QUOTES : THANK_YOU_QUOTES
  const showMobileImage = qualifiedForKickstart ? mobileImage : null
  const showVectorImage = qualifiedForKickstart ? vectorImageForQualified : vectorImageForUnqualified

  const updateOnboardingCheckpoint = () => {
    setOnboarding({
      ...onboarding,
      checkpoint: isPaymentCollect ? CHECKPOINT_PAYMENT_CC : CHECKPOINT_VERIFY_EMAIL
    })
  }

  const classes = useStyles(props)

  if (!appUser) {
    return <Navigate to="/login" />
  }

  return (
    <OnboardingContainer
      classes={{ img: classes.hero, accent: classes.accent }}
      theme={qualifiedForKickstart ? THEME_PRIMARY_GREEN.main : THEME_PRIMARY_YELLOW.main}
      sidebarProps={{
        classes: {
          quotesColor: qualifiedForKickstart ? classes.qualifiedQuote : classes.unqualifiedQuote,
          client: classes.clientColor,
          sidebarImage: !qualifiedForKickstart && classes.sidebarImage,
          vectorImage: classes.vectorImage,
          quoteImage: qualifiedForKickstart && classes.quotes
        },
        text: text,
        member: 'Undivided client',
        mobileImage: showMobileImage,
        vectorImage: showVectorImage,
        quoteVisible: true
      }}
      hideCheckpoints
      error={error}
      setError={setError}
    >
      <section className={clsx(classes.root, className)}>
        <h1 className={classes.copy}>Great, you’re scheduled!</h1>

        <EventCard onboardingDetails={onboarding} />
      </section>

      <section className={classes.helpContainer}>
        <BrandButton className={classes.exploreButton} variant="contained" onClick={updateOnboardingCheckpoint}>
          Next
        </BrandButton>

        <HelpPrompt className={classes.helpPrompt} />
      </section>
    </OnboardingContainer>
  )
}

ScheduleConfirmationCard.propTypes = {
  className: PropTypes.string
}

export default ScheduleConfirmationCard
