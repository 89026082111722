// FIXME Cannot do import { CHECKLIST_ITEM_PRIORITY_HIGH } from '../goal/constants'
//       because this causes an import cycle that breaks initialization.
//
//       goal/constants -> common -> tracking/segment
const CHECKLIST_ITEM_PRIORITY_HIGH = 'high'

// Have all track calls in one spot to compare with Aaron's
const segmentPageCall = (pageName, properties) => window.analytics.page(pageName, properties)
const segmentTrackCall = (event, properties) => window.analytics.track(event, properties)

// Abstract the name out in case implementation needs to change later.
const trackPage = segmentPageCall

// This convenience function enforces the object-action convention for event names.
const trackObjectActionEvent = ({ object, action, ...rest }) =>
  segmentTrackCall(`${object} ${action}`, { object, action, ...rest })

// For timeout- and token-related tracks, the event is meaningless if there isn’t a current user
// so these have an additional condition.
const trackIdleTimeout = user => {
  if (user) {
    trackObjectActionEvent({ object: 'Timeout', action: 'Triggered', user_email: user.email })
  }
}

const trackTokenRefresh = user => {
  if (user) {
    trackObjectActionEvent({ object: 'Token', action: 'Refreshed', user_email: user.email })
  }
}

const trackTokenRejection = user => {
  if (user) {
    trackObjectActionEvent({ object: 'Token', action: 'Rejected', user_email: user.email })
  }
}

const trackSignupEvent = (action, name, email, joinFunnel) =>
  trackObjectActionEvent({
    object: 'Signup',
    category: 'Create Account',
    page_name: 'Signup',
    joiner_email: email,
    joiner_name: name,
    join_funnel: joinFunnel,
    action
  })

const trackSignupStarted = (name, email, joinFunnel) => trackSignupEvent('Started', name, email, joinFunnel)

const trackSignupCompleted = (name, email, joinFunnel) => trackSignupEvent('Completed', name, email, joinFunnel)

const trackSkippingScheduleKickstart = ({ email, name }, location) =>
  trackObjectActionEvent({
    object: 'Scheduling',
    action: 'Skipped',
    user_email: email,
    user_name: name,
    location
  })

const trackOnboardingComplete = ({ email, name }) =>
  trackObjectActionEvent({
    object: 'Onboarding',
    category: 'Onboarding',
    action: 'Completed',
    user_email: email,
    user_name: name
  })

const trackEmailConfirmation = (location, joinFunnel) =>
  trackObjectActionEvent({
    object: 'Confirm Email',
    action: 'Verified',
    location,
    ...(joinFunnel ? { join_funnel: joinFunnel } : {})
  })

const trackInvalidOTPSubmitted = location =>
  trackObjectActionEvent({
    object: 'Invalid OTP',
    action: 'Submitted',
    location
  })

const trackViewedTask = (queueTaskId, queueTaskType) =>
  trackObjectActionEvent({
    object: 'Queue Task',
    category: 'Queue Task',
    action: 'Viewed',
    queueTaskId,
    queueTaskType
  })

const trackCompletedTask = (queueTaskId, queueTaskType) =>
  trackObjectActionEvent({
    object: 'Queue Task',
    category: 'Queue Task',
    action: 'Completed',
    queueTaskId,
    queueTaskType
  })

const trackCreatedTask = (queueTaskId, queueTaskType, queueTaskTitle) =>
  trackObjectActionEvent({
    object: 'Queue Task',
    category: 'Queue Task',
    action: 'Created',
    queueTaskId,
    queueTaskType,
    queueTaskTitle
  })

const trackEditedTask = (queueTaskId, queueTaskType, queueTaskTitle) =>
  trackObjectActionEvent({
    object: 'Queue Task',
    category: 'Queue Task',
    action: 'Edited',
    queueTaskId,
    queueTaskType,
    queueTaskTitle
  })

const trackOpenedChatFromTask = () =>
  trackObjectActionEvent({
    object: 'Queue Task',
    category: 'Queue Task',
    action: 'Opened Chat from'
  })

const trackViewedCompletedTasks = () =>
  trackObjectActionEvent({
    object: 'Queue Task',
    category: 'Queue Task',
    action: 'Viewed Completed'
  })

const trackViewedClientAccount = (householdId, householdName) =>
  trackObjectActionEvent({
    object: 'Client Account',
    category: 'Client Account',
    action: 'Viewed',
    household_id: householdId,
    household_name: householdName
  })

const trackViewedClientGoal = (goalId, goalName) =>
  trackObjectActionEvent({
    object: 'Client Goal',
    category: 'Client Goal',
    action: 'Viewed',
    goal_id: goalId,
    goal_name: goalName
  })

const trackUpdatedAccountColumns = () =>
  trackObjectActionEvent({
    object: 'Account Columns',
    category: 'Account Columns',
    action: 'Updated'
  })

const trackViewedAsParent = () =>
  trackObjectActionEvent({
    object: 'Parent View',
    category: 'Parent View',
    action: 'Used'
  })

const trackAuditEvent = (action, audits, auditId) => {
  const selectedAudit = audits.find(({ id }) => id === auditId)
  trackObjectActionEvent({
    object: 'Audit',
    action,
    audit_name: selectedAudit?.description ?? auditId,
    audit_id: auditId
  })
}

const trackAuditStarted = (audits, auditId) => trackAuditEvent('Started', audits, auditId)
const trackAuditCompleted = (audits, auditId) => trackAuditEvent('Completed', audits, auditId)
const trackAuditClosed = (audits, auditId) => trackAuditEvent('Exited', audits, auditId)

const trackChildShared = (clientId, clientName = null) =>
  trackObjectActionEvent({
    object: 'Child',
    category: 'Child',
    action: 'Shared',
    child_id: clientId,
    label: clientId,
    child_name: clientName
  })

const trackCurrentEventsViewed = () =>
  trackObjectActionEvent({ object: 'Current Events', category: 'Event', action: 'Viewed' })

const trackDocumentDownloaded = documentId =>
  trackObjectActionEvent({
    object: 'Document',
    category: 'Document',
    action: 'Downloaded',
    document_id: documentId,
    label: documentId
  })

const trackDocumentEdited = documentId =>
  trackObjectActionEvent({
    object: 'Document',
    category: 'Document',
    action: 'Edited',
    document_id: documentId,
    label: documentId
  })

const trackDocumentMarkedPrivate = documentId =>
  trackObjectActionEvent({
    object: 'Document',
    category: 'Document',
    action: 'Marked Private',
    docuemnt_id: documentId,
    label: documentId
  })

const trackDocumentUploaded = documentType =>
  trackObjectActionEvent({
    object: 'Single Document',
    category: 'Document',
    action: 'Uploaded',
    document_name: documentType
  })

const trackMultipleDocumentsUploaded = (documentTypes, numberOfDocs) =>
  trackObjectActionEvent({
    object: 'Multiple Documents',
    category: 'Document',
    action: 'Uploaded',
    document_names: documentTypes,
    number_of_docs: numberOfDocs
  })

const trackError = (error, url, status) => {
  const { name, message, stack } = error ?? {}

  // 401s and 403s are common and somewhat expected, so we skip those.
  // We avoid using status code constants to minimize coupling.
  if ([401, 403].includes(status)) {
    return
  }

  trackObjectActionEvent({
    object: 'Error',
    action: 'Tracked',
    name,
    message,
    stack,
    url,
    statusCode: status
  })
}

const trackEventAdded = type =>
  trackObjectActionEvent({
    object: 'Event',
    category: 'Event',
    action: 'Added',
    source: 'home_plan',
    type
  })

const trackEventDetailsViewed = eventId =>
  trackObjectActionEvent({
    object: 'Event Details',
    category: 'Event',
    action: 'Viewed',
    event_id: eventId,
    label: eventId
  })

const trackResourceFilterApplied = (name, value) =>
  trackObjectActionEvent({ object: 'Resource Filter', action: 'Applied', name, value })

const trackResourceSearchApplied = value =>
  trackObjectActionEvent({ object: 'Resource Search', action: 'Applied', value })

const trackResourceViewed = (articleId, title, type) =>
  trackObjectActionEvent({
    object: 'Resource',
    action: 'Viewed',
    articleId,
    title,
    type
  })

const trackGoalChatSent = (goalId, goalName) =>
  trackObjectActionEvent({
    object: 'Goal Chat',
    category: 'Goal Chat',
    action: 'Sent',
    goal_id: goalId,
    label: goalId,
    goal_name: goalName
  })

const GOAL_OBJECT_DESCRIPTOR = {
  active: 'Active Goals',
  future: 'Future Goals',
  achieved: 'Completed Goals',
  draft: 'Draft Goals'
}

const getGoalCategory = status => GOAL_OBJECT_DESCRIPTOR[status] ?? 'Uncategorized Goals'

const trackGoalsViewed = goalState => {
  const object = getGoalCategory(goalState)
  trackObjectActionEvent({
    object,
    category: 'Goal',
    action: 'Viewed'
  })
}

const trackGoalsReordered = (householdId, category) => {
  const object = `Goal ${category}`
  trackObjectActionEvent({
    object,
    category: 'Goal',
    action: 'Reordered',
    householdId
  })
}

const trackGoalChatViewed = (goalId, goalName) =>
  trackObjectActionEvent({
    object: 'Goal Chat',
    category: 'Goal Chat',
    action: 'Viewed',
    goal_id: goalId,
    label: goalId,
    goal_name: goalName
  })

const GOAL_LOCATION_CTA = 'Goal Card CTA'
const GOAL_LOCATION_WORKSPACE = 'Workspace'
const GOAL_LOCATION_MENU = 'Menu'

const trackGoalStatus = (action, goalId, location) =>
  trackObjectActionEvent({
    object: 'Goal',
    category: 'Goal',
    action,
    goal_id: goalId,
    location
  })

const trackGoalStarted = (goalId, location) => trackGoalStatus('Started', goalId, location)
const trackGoalCompleted = (goalId, location) => trackGoalStatus('Completed', goalId, location)

const trackGoalViewed = (goalId, goalName) =>
  trackObjectActionEvent({
    object: 'Goal',
    category: 'Goal',
    action: 'Viewed',
    goal_id: goalId,
    label: goalId,
    goal_title: goalName
  })

const trackGuideCompletionConfirmed = (goalId, guideName) =>
  trackObjectActionEvent({
    object: 'Guide',
    action: 'Completion Confirmed',
    goal_id: goalId,
    label: goalId,
    goal_title: guideName,
    category: 'Guide'
  })

const trackGuideViewed = guideId =>
  trackObjectActionEvent({
    object: 'Guide',
    category: 'Guide',
    action: 'Viewed',
    guide_id: guideId,
    label: guideId
  })

const trackNavigatorChatSent = () =>
  trackObjectActionEvent({ object: 'Navigator Chat', category: 'Navigator Chat', action: 'Sent' })

const trackNavigatorChatViewed = () =>
  trackObjectActionEvent({ object: 'Navigator Chat', category: 'Navigator Chat', action: 'Viewed' })

const trackNeedAdded = (client, objective) =>
  trackObjectActionEvent({
    object: 'Need',
    category: 'Need',
    action: 'Added',
    need_title: objective.title,
    need_category: objective.category,
    need_description: objective.description,
    need_id: objective.id,
    label: objective.id,
    child_id: client?.id,
    child_name: client?.name
  })

const trackNeedCategorySelected = category =>
  trackObjectActionEvent({
    object: 'Need Category',
    category: 'Onboarding',
    action: 'Selected',
    need_category_name: category
  })

const trackNeedEdited = (client, objective) =>
  trackObjectActionEvent({
    object: 'Need',
    category: 'Need',
    action: 'Edited',
    need_title: objective.title,
    need_category: objective.category,
    need_description: objective.description,
    need_id: objective.id,
    label: objective.id,
    child_id: client?.id,
    child_name: client?.name
  })

const trackOnboardingExploreTapped = () =>
  trackObjectActionEvent({
    object: 'Onboarding Explore',
    category: 'Onboarding',
    action: 'Tapped'
  })

const trackPastEventsViewed = () =>
  trackObjectActionEvent({ object: 'Past Events', category: 'Event', action: 'Viewed' })

const trackProfileBlockUpdated = name =>
  trackObjectActionEvent({ object: `Profile Block “${name}”`, action: 'Updated' })

const trackStepLinkClicked = () => trackObjectActionEvent({ object: 'Step Link', category: 'Step', action: 'Clicked' })

const trackStepMarkedComplete = stepId =>
  trackObjectActionEvent({
    object: 'Step',
    category: 'Step',
    action: 'Marked Complete',
    step_id: stepId,
    label: stepId
  })

const trackUserSignedIn = userId =>
  trackObjectActionEvent({
    object: 'User',
    category: 'User',
    action: 'Signed In',
    user_id: userId,
    label: userId
  })

const trackVisionAdded = clientId =>
  trackObjectActionEvent({
    object: 'Vision',
    category: 'Vision',
    action: 'Added',
    vision_id: clientId,
    label: clientId
  })

const trackVisionEdited = clientId =>
  trackObjectActionEvent({
    object: 'Vision',
    category: 'Vision',
    action: 'Edited',
    vision_id: clientId,
    label: clientId
  })

const trackVisionPhotoUploaded = clientId =>
  trackObjectActionEvent({
    object: 'Vision Photo',
    category: 'Vision',
    action: 'Uploaded',
    vision_id: clientId,
    label: clientId
  })

const trackVisionStarted = clientId =>
  trackObjectActionEvent({
    object: 'Vision',
    category: 'Vision',
    action: 'Started',
    child_id: clientId,
    label: clientId
  })

const trackVisionViewed = clientId =>
  trackObjectActionEvent({
    object: 'Vision',
    category: 'Vision',
    action: 'Viewed',
    child_id: clientId,
    label: clientId
  })

const trackPriorityAddedToTask = taskId =>
  trackObjectActionEvent({
    object: 'Tasks',
    category: 'Tasks',
    action: 'Priority Added',
    taskId
  })

const trackPriorityRemovedFromTask = taskId =>
  trackObjectActionEvent({
    object: 'Tasks',
    category: 'Tasks',
    action: 'Priority Removed',
    taskId
  })

const trackPriorityFilterSet = () =>
  trackObjectActionEvent({
    object: 'Priority',
    category: 'Tasks',
    action: 'Filter Set'
  })

const trackParentTaskAdded = (householdId, goalId, appLocation, checklistItem) => {
  trackObjectActionEvent({
    object: 'Task',
    category: 'Tasks',
    action: 'Added',
    householdId,
    goalId,
    appLocation
  })

  // When added with priority already set, we also track that.
  const { id, priority } = checklistItem
  if (priority === CHECKLIST_ITEM_PRIORITY_HIGH) {
    trackPriorityAddedToTask(id)
  }
}

const trackGuideTaskAdded = (householdId, goalId, guideId, stepId) =>
  trackObjectActionEvent({
    object: 'Guide Task',
    category: 'Tasks',
    action: 'Added',
    householdId,
    goalId,
    guideId,
    stepId
  })

const trackGuideTaskGroupAdded = (householdId, goalId, guideId, stepId) =>
  trackObjectActionEvent({
    object: 'Guide Task Group',
    category: 'Task Group',
    action: 'Added',
    householdId,
    goalId,
    guideId,
    stepId
  })

const trackGuideAllTasksAdded = (householdId, goalId, guideId) =>
  trackObjectActionEvent({
    object: 'Guide All Tasks',
    category: 'Tasks',
    action: 'Added',
    householdId,
    goalId,
    guideId
  })

const trackTaskGuideViewed = (householdId, goalId, guideId, stepId, appLocation) =>
  trackObjectActionEvent({
    object: 'Task Guide',
    category: 'Tasks',
    action: 'Viewed',
    householdId,
    goalId,
    guideId,
    stepId,
    appLocation
  })

const trackTaskMarkedComplete = (householdId, goalId, guideId, taskId, appLocation) =>
  trackObjectActionEvent({
    object: 'Task Marked',
    category: 'Tasks',
    action: 'Complete',
    householdId,
    goalId,
    guideId,
    taskId,
    appLocation
  })

const trackTaskMarkedIncomplete = (householdId, goalId, guideId, taskId, appLocation) =>
  trackObjectActionEvent({
    object: 'Task',
    category: 'Tasks',
    action: 'Marked Incomplete',
    householdId,
    goalId,
    guideId,
    taskId,
    appLocation
  })

const trackCompletedTasksExpanded = appLocation =>
  trackObjectActionEvent({
    object: 'Completed Tasks',
    category: 'Tasks',
    action: 'Expanded',
    appLocation
  })

const trackTaskGoalViewed = (householdId, goalId, guideId, stepId, appLocation) =>
  trackObjectActionEvent({
    object: 'Task Goal',
    category: 'Task Goal',
    action: 'Viewed',
    householdId,
    goalId,
    guideId,
    stepId,
    appLocation
  })

const trackAllTasksViewed = () =>
  trackObjectActionEvent({
    object: 'All Tasks',
    category: 'Tasks',
    action: 'Viewed'
  })

const trackEventLinkJoined = eventId =>
  trackObjectActionEvent({
    object: 'Event Link',
    category: 'Event Link',
    action: 'Joined',
    eventId
  })

const trackResourceBookmarked = bookmarkedLocation =>
  trackObjectActionEvent({
    object: 'Resource',
    category: 'Resource',
    action: 'Bookmarked',
    bookmarkedLocation
  })

const trackBinderActivation = location =>
  trackObjectActionEvent({
    object: 'Binder Activation',
    category: 'Parent Home',
    action: 'Started',
    location
  })

const trackResourcesActivation = location =>
  trackObjectActionEvent({
    object: 'Resources Activation',
    category: 'Parent Home',
    action: 'Started',
    location
  })

const trackKickstartUpgrade = (category, location) =>
  trackObjectActionEvent({
    object: 'Kickstart Upgrade',
    category,
    action: 'Started',
    location
  })

const trackKickstartUpgradeFromHome = kickstartLocation => trackKickstartUpgrade('Parent Home', kickstartLocation)

const trackBookmarkResources = bookmarkLocation =>
  trackObjectActionEvent({
    object: 'Bookmark Resources',
    category: 'Parent Home',
    action: 'Started',
    bookmarkLocation
  })

const trackResourceTopics = topicsLocation =>
  trackObjectActionEvent({
    object: 'Resource Topics',
    category: 'Parent Home',
    action: 'Started',
    topicsLocation
  })

const trackReferralLink = linkLocation =>
  trackObjectActionEvent({
    object: 'Referral Link',
    category: 'Parent Home',
    action: 'Copied',
    linkLocation
  })

const trackUpgradeStarted = (category, location, trialType, joinFunnel) =>
  trackObjectActionEvent({
    object: 'Upgrade',
    category,
    action: 'Started',
    location,
    ...(trialType ? { trialType } : {}),
    ...(joinFunnel ? { join_funnel: joinFunnel } : {})
  })

const trackUpgradeFromHome = (location, trialType) => trackUpgradeStarted('Parent Home', location, trialType)
const trackUpgradeFromMenu = (location, trialType) => trackUpgradeStarted('Navigation', location, trialType)
const trackUpgradeFromResources = (location, trialType) => trackUpgradeStarted('Resources', location, trialType)
const trackUpgradeFromGoal = (location, trialType) => trackUpgradeStarted('Goal', location, trialType)

const trackUpgradeFromAccountCreation = (location, joinFunnel) =>
  trackUpgradeStarted('Create Account', location, 'Platform', joinFunnel)

const trackUpgradeFromCta = (location, trialType) => trackUpgradeStarted('Reminders', location, trialType)

const trackPaymentCaptureViewed = location =>
  trackObjectActionEvent({ object: 'Payment', action: 'Capture viewed', location })
const trackPaymentCaptured = location => trackObjectActionEvent({ object: 'Payment', action: 'Captured', location })
const trackPaymentDismissed = () =>
  trackObjectActionEvent({ object: 'Payment', action: 'Dismissed', location: 'In-app' })

const trackTrialEvent = (action, category, { email, name }, trialType, joinFunnel) =>
  trackObjectActionEvent({
    object: 'Trial',
    action,
    user_email: email,
    user_name: name,
    category,
    ...(trialType ? { trialType } : {}),
    ...(joinFunnel ? { join_funnel: joinFunnel } : {})
  })

const trackTrialAdded = (category, userDetails, trialType, joinFunnel) =>
  trackTrialEvent('Added', category, userDetails, trialType, joinFunnel)

const trackTrialAddedFromInApp = (userDetails, trialType) => trackTrialAdded('In-app', userDetails, trialType)

const trackTrialAddedFromAccountCreation = (userDetails, trialType, joinFunnel) =>
  trackTrialAdded('Create Account', userDetails, trialType, joinFunnel)

const trackTrialSkipped = (category, userDetails, trialType, joinFunnel) =>
  trackTrialEvent('Skipped', category, userDetails, trialType, joinFunnel)

const trackTrialSkippedInApp = (userDetails, trialType) => trackTrialSkipped('In-app', userDetails, trialType)

const trackTrialSkippedFromAccountCreation = (userDetails, trialType, joinFunnel) =>
  trackTrialSkipped('Create Account', userDetails, trialType, joinFunnel)

// Track event for the completion of "Kickstart Upgrade"
const trackKickstartUpgradeCompleted = location =>
  trackObjectActionEvent({
    object: 'Kickstart Upgrade',
    action: 'Completed',
    location
  })

const trackChatBookmarked = messageId =>
  trackObjectActionEvent({
    object: 'Message',
    category: 'Chat',
    action: 'Bookmarked',
    messageId
  })

const trackShareStarted = () =>
  trackObjectActionEvent({
    object: 'Share Undivided',
    action: 'Started'
  })

const trackShareLinkCopied = linkLocation =>
  trackObjectActionEvent({
    object: 'Share Undivided Link',
    action: 'Copied',
    linkLocation
  })

const trackUntaggedDocumentFilterViewed = () =>
  trackObjectActionEvent({
    object: 'Untagged Document Filter',
    category: 'Documents',
    action: 'Viewed'
  })

const NEW_DOCUMENT_ID_PLACEHOLDER = '--new document--'

const trackDocumentLinkedToGoal = documentId =>
  trackObjectActionEvent({
    object: 'Document',
    action: 'Linked to Goal',
    document_id: documentId || NEW_DOCUMENT_ID_PLACEHOLDER
  })

const trackDocumentTagged = documentId =>
  trackObjectActionEvent({
    object: 'Document',
    action: 'Tagged',
    document_id: documentId || NEW_DOCUMENT_ID_PLACEHOLDER
  })

const trackConfirmEmailClicked = location =>
  trackObjectActionEvent({
    object: 'Confirm Email',
    action: 'Clicked',
    location
  })

const trackRoadmapCreated = location =>
  trackObjectActionEvent({
    object: 'Roadmap',
    action: 'Created',
    location
  })

const trackMembershipSelected = plan =>
  trackObjectActionEvent({
    object: 'Membership',
    action: 'Selected',
    plan
  })

const trackMembershipStarted = (category, location) =>
  trackObjectActionEvent({
    object: 'Membership',
    action: 'Started',
    location,
    category
  })

const trackMembershipStartedFromHome = location => trackMembershipStarted('Parent Home', location)
const trackMembershipStartedFromExpandedGoal = location => trackMembershipStarted('Goal Expanded', location)

const trackCustomizeRoadmapStarted = (resourceId, location) =>
  trackObjectActionEvent({
    object: 'Customize Roadmap',
    action: 'Started',
    location,
    resourceId
  })

export {
  trackPage,
  trackAuditStarted,
  trackAuditCompleted,
  trackAuditClosed,
  trackChildShared,
  trackCurrentEventsViewed,
  trackDocumentDownloaded,
  trackDocumentEdited,
  trackDocumentMarkedPrivate,
  trackDocumentUploaded,
  trackMultipleDocumentsUploaded,
  trackError,
  trackEventAdded,
  trackEventDetailsViewed,
  trackResourceFilterApplied,
  trackResourceSearchApplied,
  trackResourceViewed,
  trackGoalChatSent,
  trackGoalsViewed,
  trackGoalsReordered,
  trackGoalChatViewed,
  GOAL_LOCATION_CTA,
  GOAL_LOCATION_WORKSPACE,
  GOAL_LOCATION_MENU,
  trackGoalStarted,
  trackGoalCompleted,
  trackGoalViewed,
  trackGuideCompletionConfirmed,
  trackGuideViewed,
  trackIdleTimeout,
  trackNavigatorChatSent,
  trackNavigatorChatViewed,
  trackNeedAdded,
  trackNeedCategorySelected,
  trackNeedEdited,
  trackOnboardingExploreTapped,
  trackPastEventsViewed,
  trackProfileBlockUpdated,
  trackStepLinkClicked,
  trackStepMarkedComplete,
  trackTokenRefresh,
  trackTokenRejection,
  trackUserSignedIn,
  trackVisionAdded,
  trackVisionEdited,
  trackVisionPhotoUploaded,
  trackVisionStarted,
  trackVisionViewed,
  trackSkippingScheduleKickstart,
  trackOnboardingComplete,
  trackEmailConfirmation,
  trackSignupStarted,
  trackSignupCompleted,
  trackViewedTask,
  trackCompletedTask,
  trackCreatedTask,
  trackEditedTask,
  trackOpenedChatFromTask,
  trackViewedCompletedTasks,
  trackViewedClientAccount,
  trackViewedClientGoal,
  trackUpdatedAccountColumns,
  trackViewedAsParent,
  trackPriorityAddedToTask,
  trackPriorityRemovedFromTask,
  trackPriorityFilterSet,
  trackParentTaskAdded,
  trackGuideTaskAdded,
  trackGuideTaskGroupAdded,
  trackGuideAllTasksAdded,
  trackTaskGuideViewed,
  trackTaskGoalViewed,
  trackTaskMarkedComplete,
  trackTaskMarkedIncomplete,
  trackCompletedTasksExpanded,
  trackAllTasksViewed,
  trackEventLinkJoined,
  trackResourceBookmarked,
  trackBinderActivation,
  trackResourcesActivation,
  trackKickstartUpgradeFromHome,
  trackBookmarkResources,
  trackResourceTopics,
  trackReferralLink,
  trackShareStarted,
  trackShareLinkCopied,
  trackUpgradeFromHome,
  trackUpgradeFromMenu,
  trackUpgradeFromCta,
  trackKickstartUpgradeCompleted,
  trackChatBookmarked,
  trackUntaggedDocumentFilterViewed,
  trackDocumentLinkedToGoal,
  trackDocumentTagged,
  trackConfirmEmailClicked,
  trackRoadmapCreated,
  trackUpgradeFromResources,
  trackMembershipSelected,
  trackMembershipStarted,
  trackMembershipStartedFromExpandedGoal,
  trackMembershipStartedFromHome,
  trackUpgradeFromGoal,
  trackInvalidOTPSubmitted,
  trackCustomizeRoadmapStarted,
  trackUpgradeFromAccountCreation,
  trackPaymentCaptured,
  trackPaymentCaptureViewed,
  trackPaymentDismissed,
  trackTrialAddedFromAccountCreation,
  trackTrialAddedFromInApp,
  trackTrialSkippedInApp,
  trackTrialSkippedFromAccountCreation
}
