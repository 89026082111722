import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import { useDiagnosisTags } from '../commonHooks'

import CheckboxMultiselect from './CheckboxMultiselect'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%'
    },

    select: {},
    selectDisplay: {},
    selectLabel: {}
  }),
  {
    name: 'DiagnosisField'
  }
)

const DiagnosisField = props => {
  const { className, classes: classesProp, value: selectedValues, onChange, name, label, ...rest } = props
  const { diagnosisOptions } = useDiagnosisTags()

  const classes = useStyles(props)
  return (
    <CheckboxMultiselect
      classes={{
        root: clsx(classes.root, className),
        inputLabel: classes.selectLabel,
        select: classes.select,
        selectDisplay: classes.selectDisplay
      }}
      options={diagnosisOptions}
      label={label}
      name={name}
      value={selectedValues ?? []}
      onChange={onChange}
      shrink={selectedValues.length > 0}
      {...rest}
    />
  )
}

DiagnosisField.propTypes = {
  value: PropTypes.array, // Array of selected diagnosis values
  onChange: PropTypes.func, // Function to handle changes in the selection
  name: PropTypes.string, // Name attribute for the select element
  label: PropTypes.string // Label text for the input element

  // …all other properties are relayed to the underlying CheckboxMultiselect.
}

export default DiagnosisField
