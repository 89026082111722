import LogRocket from 'logrocket'
import { StreamChat } from 'stream-chat'

import { getMe } from './api'
import { BASE_API_REQUEST_HEADER, HTTP_LOCKED, HTTP_UNAUTHORIZED, METHOD_DELETE, METHOD_POST } from './arch/constants'
import { createdCheck, okCheck, throwResponseError, emitNativeError, defaultName, acceptedCheck } from './common'
import model from './model'
import { trackTokenRefresh, trackTokenRejection, trackUserSignedIn } from './tracking/segment'
import { invokeErrorPromise } from './app/utilities'
import { cachePostTypes, getCategoryTags, getDiagnosisTags } from './typeUtilities'
import { clearAllDrafts } from './chat/chatService'

const currentChatClient = StreamChat.getInstance(process.env.REACT_APP_STREAM_CHAT_API_KEY, {
  timeout: 10000
})

let currentUser = null
let currentToken = null
let forbiddenErrorPromise = null
let forbiddenErrorPromiseProvider = null
let lockedErrorPromise = null
let lockedErrorPromiseProvider = null
let tokenTimeout = null
let openVerifyOtpDialogCallback = null

let api = 'https://misconfigured-app.com/'

const setVerifyOtpRequestCallback = callback => (openVerifyOtpDialogCallback = callback)

const getPersistedToken = () => window.localStorage.getItem(api)
const setPersistedToken = token => window.localStorage.setItem(api, token)

const clearTokenTimeout = () => {
  if (tokenTimeout) {
    clearTimeout(tokenTimeout)
  }
}

const clearPersistedToken = () => {
  window.localStorage.removeItem(api)
  clearTokenTimeout()
}

const bootstrapUser = async token => {
  setCurrentToken(token)
  currentChatClient.disconnectUser()

  await Promise.all([cachePostTypes(), getCategoryTags(), getDiagnosisTags()])

  const user = await getMe()
  currentUser = model.user(user)

  const chatUser = user.chatToken
    ? await currentChatClient.connectUser(
        {
          id: user.username,
          name: user.name
        },
        user.chatToken
      )
    : null

  // A missing chat user may not fail here, but it will likely fail loudly later.
  currentUser.chatState = chatUser?.me
}

const apiHost = async host => {
  api = host
  const persistedToken = getPersistedToken()
  if (persistedToken) {
    try {
      await bootstrapUser(persistedToken)

      // Refresh the token immediately so that the user gets a fresh “clock.”
      await refreshToken()
    } catch (error) {
      // If the bootstrap didn’t work, then we act as if there is no token.
      currentToken = null
      clearPersistedToken()
    }
  }
}

const urlFor = resource => `${api}${resource}`

// These are somewhat arbitrary so are certainly subject to tuning, as needed.
const RETRY_COUNT = 4
const RETRY_MINIMUM = 1024
const RETRY_RANGE = 8192

const TOKEN_TIMEOUT_LENGTH = 20 * 60 * 1000 // Just under the underlying 30-minute timeout

const setForbiddenErrorPromiseProvider = callback => (forbiddenErrorPromiseProvider = callback)
const setLockedErrorPromiseProvider = callback => (lockedErrorPromiseProvider = callback)

const chatClient = () => currentChatClient
const user = () => currentUser

const navigatorChatChannel = householdId =>
  currentChatClient.channel('messaging', householdId, {
    name: 'Navigator Messenger'
  })

const goalChatChannel = goalId =>
  currentChatClient.channel('messaging', goalId, {
    name: 'Goal Discussion'
  })

// These are now legacy wrappers for the User methods in the model module; can eventually clean out once the code base
// has transitioned over.
const userRole = acceptableRoles => {
  if (!currentUser) {
    return false
  }

  return currentUser.role(acceptableRoles)
}

const userIsInternal = () => {
  if (!currentUser) {
    return false
  }

  return currentUser.isInternal()
}

const userIsParentOf = clientId => {
  if (!currentUser) {
    return false
  }

  return currentUser.isParentOf(clientId)
}

// Navigators are allowed to access non-children.
const userChild = clientId => {
  if (!currentUser) {
    return false
  }

  return currentUser.canPlanFor(clientId)
}

const tokenlessRequestErrorHandler = error => {
  if (!error.response) {
    return error
  } else {
    throwResponseError(error.response)
  }
}

const signup = ({ name, email, clientName, tier, navigator }) =>
  fetch(urlFor('signups'), {
    method: METHOD_POST,
    headers: BASE_API_REQUEST_HEADER,
    body: JSON.stringify({ name, email, clientName, tier, navigator })
  })
    .then(createdCheck, emitNativeError)
    .then(response => response.json())
    .catch(tokenlessRequestErrorHandler)

const getSignupPrefill = key =>
  fetch(urlFor(`signups/${key}/prefill`), {
    headers: BASE_API_REQUEST_HEADER
  })
    .then(okCheck, emitNativeError)
    .then(response => response.json())
    .catch(tokenlessRequestErrorHandler)

const confirmSignup = (key, confirmationData) =>
  fetch(urlFor(`signups/${key}`), {
    method: METHOD_POST,
    headers: BASE_API_REQUEST_HEADER,
    body: JSON.stringify(confirmationData)
  })
    .then(createdCheck, emitNativeError)
    .then(response => response.json())
    .catch(tokenlessRequestErrorHandler)

const confirmInvitation = (key, payload) =>
  fetch(urlFor(`invitations/${key}`), {
    method: METHOD_POST,
    headers: BASE_API_REQUEST_HEADER,
    body: JSON.stringify(payload)
  })
    .then(createdCheck, emitNativeError)
    .then(response => response.json())
    .catch(tokenlessRequestErrorHandler)

const inviteeKnown = key =>
  fetch(urlFor(`invitations/${key}/inviteeknown`))
    .then(okCheck, emitNativeError)
    .then(response => response.json())
    .catch(tokenlessRequestErrorHandler)

const requestPasswordReset = email =>
  fetch(urlFor('passwordresetters'), {
    method: METHOD_POST,
    headers: BASE_API_REQUEST_HEADER,
    body: JSON.stringify({ email })
  })
    .then(okCheck, emitNativeError)
    .then(response => response.json())
    .catch(tokenlessRequestErrorHandler)

const confirmPasswordReset = (key, password) =>
  fetch(urlFor(`passwordresetters/${key}`), {
    method: METHOD_POST,
    headers: BASE_API_REQUEST_HEADER,
    body: JSON.stringify({ password })
  })
    .then(okCheck, emitNativeError)
    .then(response => response.json())
    .catch(tokenlessRequestErrorHandler)

const setCurrentToken = token => {
  currentToken = token
  setPersistedToken(token)
  tokenTimeout = setTimeout(refreshToken, TOKEN_TIMEOUT_LENGTH)
}

const refreshToken = async () => {
  // It’s safe to clear the current refresh token timeout here, if any, in case there’s one lingering.
  // If there isn’t any, `clearTimeout` does not complain and does no harm, but if there _is_ one,
  // this ensures that it is cleared before another one is launched (via `setCurrentToken` below).
  clearTokenTimeout()

  const tokenResponse = await fullServiceFetch('tokens/refresh', { method: METHOD_POST })
  if (tokenResponse) {
    setCurrentToken(tokenResponse.token)
  }

  trackTokenRefresh(currentUser)
}

const identifyMe = navigator => {
  const { analytics } = window
  const { identify } = analytics ?? {}

  const currentUser = user()
  if (currentUser) {
    const {
      username: id,
      household,
      createdAt,
      email,
      nickname: firstName,
      familyName: lastName,
      name,
      roles,
      signupKey,
      status
    } = currentUser

    const identifyPayload = {
      appType: 'web',
      createdAt,
      email,
      firstName,
      household: household?.id,
      id,
      isParent: !currentUser.isInternal() && currentUser.isParent(),
      lastName,
      name,
      displayName: name || defaultName(firstName, lastName),
      username: email,
      roles,
      signupKey,
      status,
      qualified: household?.qualifiedForKickstart === 'qualified',
      tier: household?.tier
    }

    if (navigator) {
      identifyPayload.navigator = navigator.email
    }

    if (identify) {
      window.analytics.identify(id, identifyPayload)
    }

    const logRocketAppId = `${process.env.REACT_APP_LOGROCKET_APP_ID}`
    if (logRocketAppId !== 'disabled') {
      LogRocket.identify(id, identifyPayload)
    }
  } else {
    // As documented in https://segment.com/docs/connections/spec/identify/#anonymous-id
    if (identify) {
      identify({ appType: 'web' })
    }
  }
}

const identifyGroup = () => {
  const currentUser = user()
  if (currentUser) {
    const { household } = currentUser
    if (household) {
      window.analytics.group(household?.id, { name: household?.name })
    }
  }
}

const trackUserLogin = () => {
  // This one is for Segment.
  identifyMe()
  identifyGroup()

  const { username, emailConfirmedAt } = user() ?? {} // Should always be truthy, but just in case.
  if (username && emailConfirmedAt) {
    trackUserSignedIn(username)
  }
}

const trackUserAndClient = clientId => {
  // Nothing here, for now.
}

const trackNavigator = navigator => {
  identifyMe(navigator)
}

const login = async (username, password) => {
  const response = await fetch(urlFor('tokens'), {
    method: METHOD_POST,
    body: JSON.stringify({
      email: username,
      password: password
    }),
    headers: BASE_API_REQUEST_HEADER
  })

  const { status } = response

  const tokenResponse = await response.json()
  if (tokenResponse.token) {
    await bootstrapUser(tokenResponse.token)
    trackUserLogin()

    return { status }
  } else {
    return { ...tokenResponse, status }
  }
}

// 'direct' means that we accept a URL instead of a resource, we don’t need authorization,
// and we return the URL directly.
//
// HEAD failures are also propagated directly to the caller because these are very specific
// cases and it’s best not to generically pass them to the catch-all native error handler.
const directHead = url => fetch(url, { method: 'HEAD' }).then(okCheck)

const authorizedFetch = async (resource, settings) => {
  const requestOptions = retryCount => {
    const requiredHeaders = {
      Authorization: `Bearer ${currentToken}`,
      ...BASE_API_REQUEST_HEADER,
      ...(retryCount === undefined
        ? {}
        : {
            'X-Special-X-Retry-Count': RETRY_COUNT - retryCount + 1
          })
    }

    // Funky logic is meant to make sure that our necessary headers always take priority over headers that might have
    // been included in the settings.
    const { headers: settingsHeaders } = settings ?? {}
    return {
      ...settings,
      headers: {
        ...(settingsHeaders || {}),
        ...requiredHeaders
      }
    }
  }

  const authorizedResponse = async retryCount => {
    try {
      const response = await fetch(urlFor(resource), requestOptions(retryCount))

      if (response.status === HTTP_LOCKED) {
        const result = await invokeErrorPromise(lockedErrorPromise, lockedErrorPromiseProvider, authorizedResponse)

        if (result !== null) {
          return result
        }
      }

      if (response.status === HTTP_UNAUTHORIZED) {
        // Special exception case: an unauthorized DELETE /tokens is effectively a deleted token,
        // so we bounce right back without invoking a promise. We still return the erroneous
        // response for accuracy, but we assume that anyone who requested a DELETE /tokens
        // realizes that a forbidden error means that the caller is where they want to be anyway.
        if (settings?.method === METHOD_DELETE && resource === 'tokens') {
          return response
        }

        trackTokenRejection(currentUser)

        const result = await invokeErrorPromise(
          forbiddenErrorPromise,
          forbiddenErrorPromiseProvider,
          authorizedResponse
        )

        if (result !== null) {
          return result
        }
      }

      return response
    } catch (error) {
      // 5xx “responses” throw errors and do not actually produce a response, so we can only retry
      // and cannot tell the difference between 500, 502, 504, etc.
      if (retryCount !== 0) {
        // Thank you https://javascript.info/task/delay-promise
        await new Promise(resolve => setTimeout(resolve, RETRY_RANGE * Math.random() + RETRY_MINIMUM))
        return authorizedResponse(retryCount === undefined ? RETRY_COUNT : retryCount - 1)
      } else {
        // TODO Send a log message instead of (or in addition to?) throwing, so that these errors can
        //      stored for further triaging.
        throw error
      }
    }
  }

  return await authorizedResponse()
}

/**
 * checkedFetch “wraps” a fetch Promise around a function that presumably checks its status
 * code then returns the json() promise if the status code is OK.
 *
 * If the fetch Promise itself fails (e.g., CORS blockage), a native error handler is called.
 * The presumption is that failures such as this are rare and potentially transient, and the
 * native error handler displays a message without disrupting the application.
 *
 * However, this also means that application code which ultimately calls this function may get
 * resolved promises that return undefined. If this undefined result is not handled well, then
 * a second-order error will be thrown, and _that_ error will be outside the catch-all error
 * handler’s scope.
 *
 * In other words: make sure that API calls can elegantly handle undefined resolved values.
 *
 * @param {Promise} fetch
 * @param {function} responseCheck
 */
const checkedFetch = (fetch, responseCheck = okCheck) =>
  fetch.then(responseCheck, emitNativeError).then(response => response && response.json())

const fullServiceFetch = (resource, settings) => checkedFetch(authorizedFetch(resource, settings))

const get = resource => fullServiceFetch(resource)
const query = (resource, params) => fullServiceFetch(`${resource}?${params}`)
const del = resource => fullServiceFetch(resource, { method: METHOD_DELETE })

const patch = (resource, payload) =>
  fullServiceFetch(resource, {
    method: 'PATCH',
    body: JSON.stringify(payload)
  })

const post = (resource, payload) =>
  checkedFetch(
    authorizedFetch(resource, {
      method: METHOD_POST,
      body: payload ? JSON.stringify(payload) : null
    }),
    createdCheck
  )

const postSearch = (resource, payload) =>
  checkedFetch(
    authorizedFetch(resource, {
      method: METHOD_POST,
      body: payload ? JSON.stringify(payload) : null
    }),
    okCheck
  )

const postAccepted = (resource, payload) =>
  checkedFetch(
    authorizedFetch(resource, {
      method: METHOD_POST,
      body: payload ? JSON.stringify(payload) : null
    }),
    acceptedCheck
  )

const put = (resource, payload) =>
  fullServiceFetch(resource, {
    method: 'PUT',
    body: JSON.stringify(payload)
  })

const logout = async () => {
  let logoutResponse = {}

  try {
    logoutResponse = await del('tokens')

    // Clean up any saved chat draft messages from IndexedDB storage upon successful logout
    await clearAllDrafts()
  } catch (error) {
    // The only issue with a logout error is that we won’t know if we tried to delete a
    // proxy token. That’s OK, we’ll live…we’ll just make the app like a logout did indeed
    // happen. No matter what, the current token will expire within 30 minutes anyway.
  }

  // Make sure to clean house, then check if there is a replacement token.
  currentUser = null
  currentToken = null
  clearPersistedToken()
  currentChatClient.disconnectUser()
  identifyMe()

  if (logoutResponse.token) {
    await bootstrapUser(logoutResponse.token)
  }
}

const proxy = async username => {
  const tokenContainer = await postSearch('tokens/proxy', { for: username })
  const { token } = tokenContainer || {}
  if (token) {
    await bootstrapUser(token)
  }
}

export {
  apiHost,
  urlFor,
  chatClient,
  navigatorChatChannel,
  goalChatChannel,
  clearPersistedToken,
  directHead,
  authorizedFetch,
  setForbiddenErrorPromiseProvider,
  get,
  query,
  patch,
  post,
  postSearch,
  postAccepted,
  put,
  del,
  signup,
  getSignupPrefill,
  confirmSignup,
  confirmInvitation,
  inviteeKnown,
  requestPasswordReset,
  confirmPasswordReset,
  login,
  logout,
  identifyMe,
  trackUserLogin,
  trackUserAndClient,
  trackNavigator,
  user,
  userChild,
  userIsInternal,
  userIsParentOf,
  userRole,
  proxy,
  setVerifyOtpRequestCallback,
  setLockedErrorPromiseProvider,
  openVerifyOtpDialogCallback
}
