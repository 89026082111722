/**
 * GenericUnexpectedError is the component that renders for an error that doesn’t have a specific handler.
 */
import React from 'react'

import makeStyles from '@mui/styles/makeStyles'

import ErrorIcon from '@mui/icons-material/ErrorOutline'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(2, 4)
    },

    message: {
      maxWidth: theme.spacing(64)
    }
  }),
  {
    name: 'GenericUnexpectedError'
  }
)

const GenericUnexpectedError = props => {
  const { error } = props
  const classes = useStyles(props)

  return (
    <article className={classes.root}>
      <section className={classes.message}>
        <p>
          <ErrorIcon color="error" fontSize="large" titleAccess="Unexpected Error" />
        </p>

        <p>
          Sorry, but it appears that we have encountered an unexpected error. To minimize disruption of what you were
          doing, we’ve tried to isolate the problem to just this part of the window.
        </p>

        <p>
          Still, we recommend that you reload the page. If you keep seeing this message, please report the issue to
          Undivided as soon as possible, relaying the details provided below. Thank you!
        </p>

        <p>
          <code>{`${error}` /* Lazy string conversion, yeah. */}</code>
        </p>
      </section>
    </article>
  )
}

export default GenericUnexpectedError
