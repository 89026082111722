import React, { forwardRef } from 'react'

import SvgIcon from '@mui/material/SvgIcon'

const Tasks = forwardRef((props, ref) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props} ref={ref}>
    <path d="M11.352 0H4.648C1.736 0 0 1.736 0 4.648V11.352C0 14.264 1.736 16 4.648 16H11.352C14.264 16 16 14.264 16 11.352V4.648C16 1.736 14.264 0 11.352 0ZM6.376 10.32L4.576 12.12C4.456 12.24 4.304 12.296 4.152 12.296C4 12.296 3.84 12.24 3.728 12.12L3.128 11.52C2.888 11.288 2.888 10.904 3.128 10.672C3.36 10.44 3.736 10.44 3.976 10.672L4.152 10.848L5.528 9.472C5.76 9.24 6.136 9.24 6.376 9.472C6.608 9.704 6.608 10.088 6.376 10.32ZM6.376 4.72L4.576 6.52C4.456 6.64 4.304 6.696 4.152 6.696C4 6.696 3.84 6.64 3.728 6.52L3.128 5.92C2.888 5.688 2.888 5.304 3.128 5.072C3.36 4.84 3.736 4.84 3.976 5.072L4.152 5.248L5.528 3.872C5.76 3.64 6.136 3.64 6.376 3.872C6.608 4.104 6.608 4.488 6.376 4.72ZM12.448 11.696H8.248C7.92 11.696 7.648 11.424 7.648 11.096C7.648 10.768 7.92 10.496 8.248 10.496H12.448C12.784 10.496 13.048 10.768 13.048 11.096C13.048 11.424 12.784 11.696 12.448 11.696ZM12.448 6.096H8.248C7.92 6.096 7.648 5.824 7.648 5.496C7.648 5.168 7.92 4.896 8.248 4.896H12.448C12.784 4.896 13.048 5.168 13.048 5.496C13.048 5.824 12.784 6.096 12.448 6.096Z" />
  </SvgIcon>
))

export default Tasks
