import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useMatch, useNavigate, useSearchParams } from 'react-router-dom'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import { patchOnboarding } from '../../api'
import { THEME_COLOR_GROUP_NEUTRAL } from '../../app/support/theme'

import arrowGray from '../../assets/arrow.svg'
import arrowBlack from '../../assets/arrow-black.svg'

import { SIGNUP_CREATE_ACCOUNT, SIGNUP_STARTED } from '../../constants'
import { OnboardingStatus } from '../contexts'
import { useAppUser } from '../../app/hooks'

import BrandIconButton from '../../subcomponents/brand/BrandIconButton'

import {
  NO_CHECKPOINT,
  JOIN_SITES_ALL,
  CHECKPOINT_VERIFY_EMAIL,
  PROVIDER_SITES_ALL,
  ONBOARDING_MAP
} from '../constants'

const useStyles = makeStyles(
  theme => ({
    root: {},

    container: {
      height: theme.spacing(10),
      display: 'flex',
      alignItems: 'center'
    },

    rect: {
      width: theme.spacing(1.75),
      height: theme.spacing(1.75),
      borderRadius: theme.spacing(0.25),
      backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N30,
      margin: theme.spacing(0, 0.75)
    },

    active: {
      width: theme.spacing(3.25),
      height: theme.spacing(3.25),
      backgroundColor: ({ theme }) => theme
    },

    arrow: {},

    activeArrow: {
      color: THEME_COLOR_GROUP_NEUTRAL.N1000
    }
  }),
  {
    name: 'OnboardingHeader'
  }
)

const checkpointBefore = (checkpoint, sequence) => sequence?.length > 0 && sequence[sequence.indexOf(checkpoint) - 1]

const OnboardingHeader = props => {
  const { previous: previousProp, joinState } = props
  const navigate = useNavigate()
  const appUser = useAppUser()
  const [queryParams] = useSearchParams()
  const sourceFromPublicResource = queryParams.get('source') === 'public_resources'

  const { onboarding, setOnboarding, sequence } = useContext(OnboardingStatus)
  const { checkpoint } = onboarding ?? {}
  const [steps, setSteps] = useState([])
  const [currentCheckPoint, setCurrentCheckPoint] = useState(checkpoint ?? NO_CHECKPOINT)

  const onboardingPathIsJoin = JOIN_SITES_ALL.some(site => site.path === joinState?.path)
  const onboardingPathIsProvider = PROVIDER_SITES_ALL.some(site => site.path === joinState?.path)

  const onboardingPathIsCompleted = useMatch('/complete-onboarding')

  const currentOnboardingFlow = onboardingPathIsJoin
    ? JOIN_SITES_ALL.find(site => site.funnelId === joinState?.funnelId)
    : onboardingPathIsProvider
    ? PROVIDER_SITES_ALL.find(site => site.funnelId === joinState?.funnelId)
    : null

  const defaultSequence = useCallback(() => {
    const baseSequence = [SIGNUP_STARTED, SIGNUP_CREATE_ACCOUNT]
    const sequenceArray = joinState?.hasOwnProperty(`funnelId`)
      ? joinState.funnelId
        ? baseSequence
        : baseSequence.slice(1)
      : baseSequence
    return appUser ? [...sequenceArray, ...Object.keys(ONBOARDING_MAP)] : []
  }, [appUser, joinState])

  const previous = previousProp || checkpointBefore(currentCheckPoint, steps)

  const handleArrowClick = async () => {
    if (previous) {
      // TODO Error handling.
      if (previous === SIGNUP_STARTED) {
        setCurrentCheckPoint(SIGNUP_STARTED)
        navigate(`/${currentOnboardingFlow?.path ?? 'join'}`, joinState ? { state: joinState } : undefined)
      } else if (previous === SIGNUP_CREATE_ACCOUNT) {
        navigate('/login')
      } else {
        setOnboarding(await patchOnboarding({ checkpoint: previous }))
      }
    }
  }

  useEffect(() => {
    if (!appUser && currentCheckPoint === NO_CHECKPOINT) {
      if (onboardingPathIsJoin || onboardingPathIsProvider) {
        setCurrentCheckPoint(SIGNUP_STARTED)
      } else {
        setCurrentCheckPoint(SIGNUP_CREATE_ACCOUNT)
      }
    }
  }, [appUser, currentCheckPoint, onboardingPathIsJoin, onboardingPathIsProvider])

  useEffect(() => {
    setSteps(onboardingPathIsCompleted ? [] : sequence?.length > 0 ? sequence : !appUser ? defaultSequence : [])
  }, [appUser, defaultSequence, onboardingPathIsCompleted, sequence])

  const classes = useStyles(props)
  return (
    <section className={clsx(classes.root, classes.container)}>
      {steps?.length > 0 && (
        // The back arrow is meaningful if there is a current sequence.
        <BrandIconButton
          edge="start"
          onClick={handleArrowClick}
          disabled={!previous || sourceFromPublicResource || currentCheckPoint === CHECKPOINT_VERIFY_EMAIL}
        >
          <img
            className={clsx(classes.arrow, previous && classes.activeArrow)}
            src={
              previous && !sourceFromPublicResource && currentCheckPoint !== CHECKPOINT_VERIFY_EMAIL
                ? arrowBlack
                : arrowGray
            }
            alt="arrow"
          />
        </BrandIconButton>
      )}

      {steps.map(headerCheckpoint => (
        <section
          key={headerCheckpoint}
          className={clsx(classes.rect, currentCheckPoint === headerCheckpoint && classes.active)}
        />
      ))}
    </section>
  )
}

OnboardingHeader.propTypes = {
  previous: PropTypes.string, // The checkpoint to set if the user moves back, if different from the standard sequence.
  joinState: PropTypes.object // Special-case value to send to '/join' if we are still signing up.
}

export default OnboardingHeader
