/**
 * The top-level common module contains general-purpose functions that are used across a wide range
 * of use cases within this web app.
 */
import parseISO from 'date-fns/parseISO'
import formatDate from 'date-fns/format'
import memoize from 'lodash/memoize.js'

import { HTTP_OK, HTTP_CREATED, HTTP_ACCEPTED, HTTP_NO_CONTENT } from './arch/constants'
import { trackError } from './tracking/segment'

let nativeErrorHandler
const setNativeErrorHandler = callback => {
  nativeErrorHandler = callback
}

let versionUpdateHandler
const setVersionUpdateHandler = callback => {
  versionUpdateHandler = callback
}

const RUNNING_VERSION = process.env.REACT_APP_VERSION
const VERSION_INTERVAL = (3600 / 2) * 1000
const VERSION_REGEX = /"version" content="(\d+\.\d+\.\d+)"/

const versionCheck = async () => {
  const indexResponse = await fetch('/app/index.html')
  const indexContent = await indexResponse.text()
  const version = indexContent.match(VERSION_REGEX)[1]
  if (version !== RUNNING_VERSION && versionUpdateHandler) {
    versionUpdateHandler(version)
  }
}

setInterval(versionCheck, VERSION_INTERVAL)

const throwResponseError = response => {
  const error = new Error(response.statusText)
  error.response = response
  trackError(error, response?.url, response?.status)
  throw error
}

const emitNativeError = error => {
  trackError(error, error?.url, error?.status)

  if (nativeErrorHandler) {
    nativeErrorHandler(error)
  } else {
    window.alert(
      [
        'Sorry, but it appears that we have encountered an unexpected error',
        'with our servers. This type of error can range from something transient',
        'all the way to an undiscovered bug. To minimize disruption of what you were',
        'doing, the web app will not force a reload in case the error is transient.',
        'However, if things don’t seem quite right from this point, we recommend that',
        'you reload the page. If you keep seeing this message, please report the',
        'issue to Undivided as soon as possible. Thank you!'
      ].join(' ')
    )

    throw error
  }
}

const statusCheck = successStatuses => async response => {
  if (successStatuses.includes(response.status)) {
    return response
  } else {
    throwResponseError(response)
  }
}

const okCheck = statusCheck([HTTP_OK])
const createdCheck = statusCheck([HTTP_CREATED])
const acceptedCheck = statusCheck([HTTP_ACCEPTED])
const noContentCheck = statusCheck([HTTP_NO_CONTENT])
const upsertCheck = statusCheck([HTTP_OK, HTTP_CREATED])

/**
 * The possible custom functions are:
 * - `abortCheck`: Function to call to see whether to proceed with the `setState`—return `true` to abort
 * - `responseMessage`: Message to display for a given API response; two arguments are given: the original
 *   error response and the response’s JSON payload; the return value is expected to be the message string
 * - `reportError`: Function to call for additional error-reporting that is unrelated to component state
 */
const getPromiseErrorHandler = (component, baseErrorState, customFunctions) => error => {
  const { abortCheck, responseMessage, reportError } = customFunctions || {}

  const errorState = {
    ...(baseErrorState || {}),
    error: error.message
  }

  if (error.response && error.response.json) {
    error.response
      .json()
      .then(errorPayload => {
        if (abortCheck && abortCheck()) {
          return
        }

        const errorMessage = responseMessage ? responseMessage(error.response, errorPayload) : errorPayload.message
        if (component) {
          component.setState({
            ...errorState,
            error: errorMessage
          })
        }

        if (reportError) {
          reportError(errorMessage)
        }
      })
      .catch(() => {
        // On the rare but still possible chance that the JSON promise itself fails.
        if (component) {
          component.setState(errorState)
        }
      })
  } else {
    if (abortCheck && abortCheck()) {
      return
    }

    if (component) {
      component.setState(errorState)
    }

    if (reportError) {
      reportError(errorState.error)
    }
  }
}

/**
 * Pseudo-component.setState workalike for hooks. The `setters` object is expected to have one setter
 * function keyed by the property name that it sets.
 */
const setState = (setters, state) => Object.keys(state).forEach(key => setters[key](state[key]))

/**
 * Helper function for iterating through an array of setters and values.
 *
 * @param {array} setters - An array of [`useState` setter hook, value] pair arrays
 */
const setSetters = setters => setters.forEach(([set, value]) => set(value))

/**
 * Arguments are analogous to `getPromiseErrorHandler` but meant to work with hooks:
 *
 * @param {function} setError - The `useState` setter hook for the standard error message
 * @param {array} baseErrorSetters - An array of [`useState` setter hook, value] pair arrays for other state
 * @param {object} customFunctions - Same as in `getPromiseErrorHandler`
 *
 * Also, because of the async/await syntax plus the dependency checking of useEffect, sometimes it’s easier
 * to just outright call an error handler directly. That’s what `handleErrorWithStateHooks` does, with
 * `getHookPromiseErrorHandler` serving as a thin wrapper for that.
 */
const handleErrorWithStateHooks = (error, setError, baseErrorSetters, customFunctions) => {
  const { abortCheck, responseMessage, reportError } = customFunctions || {}

  const errorSetters = [...(baseErrorSetters || []), [setError, error.message]]

  if (error.response && error.response.json) {
    error.response
      .json()
      .then(errorPayload => {
        if (abortCheck && abortCheck()) {
          return
        }

        const errorMessage = responseMessage ? responseMessage(error.response, errorPayload) : errorPayload.message
        setError(errorMessage)
        if (baseErrorSetters) {
          setSetters(baseErrorSetters)
        }

        if (reportError) {
          reportError(errorMessage)
        }
      })
      .catch(() => {
        setSetters(errorSetters)
      })
    // ^^^^^ On the rare but still possible chance that the JSON promise itself fails.
  } else {
    if (abortCheck && abortCheck()) {
      return
    }

    setSetters(errorSetters)

    if (reportError) {
      reportError(error.message)
    }
  }
}

const getHookPromiseErrorHandler = (setError, baseErrorSetters, customFunctions) => error =>
  handleErrorWithStateHooks(error, setError, baseErrorSetters, customFunctions)

const modulePath = location => (location && location.pathname ? location.pathname.split('/')[1] : null)

const parseDateOrNull = dateString => {
  const date = parseISO(dateString)
  return isNaN(date) ? null : date
}

const MINIMUM_PASSWORD_LENGTH = 12

const passwordCheck = password => {
  if (!password) {
    return true
  }

  return password.length >= MINIMUM_PASSWORD_LENGTH
}

const confirmPasswordCheck = (password, confirmPassword) => {
  if (!confirmPassword) {
    return true
  }

  return password === confirmPassword
}

const profileBlockElementId = profileBlock => `profile-block-${profileBlock?.id ?? 'xxxxxxxxxx'}`
const requestTag = request => `request:${request.id}`
const sortKey = collectionTag => `sort:${collectionTag}`

/**
 * Convenience function for deriving the component specification ID of a plug-in view object.
 * Plug-in views have this structure to support Swift enums on the iOS app
 *
 * @param {object} view the plug-in view whose ID we want
 * @returns the ID
 */
const componentSpecId = view => Object.keys(view)[0]

/**
 * Convenience function for deriving the React component to use for a plug-in view,
 * plus the contents to send as props that component.
 *
 * @param {object} view the plug-in view whose contents and component we want
 * @param {object} specifications the map of id-to-specifications for the views
 * @param {function} propsGetter the function that drives props from the view (optional)
 *
 * @returns object with `id`, `Component`, and `props` properties
 */
const propsAndComponentFromSpec = (view, specifications, propsGetter) => {
  const id = componentSpecId(view)
  const propsContainer = view[id]
  const props = (propsGetter ? propsGetter(propsContainer) : propsContainer) ?? {}

  // Capturing `rest` allows the front-end-side specification objects to provide additional configuration
  // information that is not already included with the view. The `view` takes precedence though, which is
  // why the `props` spread happens last. And we omit the specification ID, which we already know anyway
  // from the incoming view object.
  const subviewSpecification = specifications[id]
  const { Component, id: specificationId, ...rest } = subviewSpecification ?? {}
  return { id, Component, props: { ...rest, ...props } }
}

const dateToString = memoize((dateStr, formatStr = 'PP') => formatDate(parseDateOrNull(dateStr), formatStr))

const defaultName = (nickname, familyName) =>
  nickname || familyName ? `${nickname ?? ''}${nickname && familyName ? ' ' : ''}${familyName ?? ''}` : ''

/**
 * Helper function to determine whether a value is “dirty”—that is, changed from a base value
 * due to user edits.
 *
 * Beyond checking for equality, the function also considers falsy values to be the same.
 * An optional `nullValue` argument allows the caller to also designate a non-falsy value
 * as equivalent to “none,” for cases where placeholders are used.
 *
 * This may run into trouble with zero and false one day, but we’ll cross that bridge when
 * we get there.
 *
 * @param {any} initialValue initial value of a property
 * @param {any} formValue current, being-edited value of a property
 * @param {any} noneValue (optional) a non-falsy value that should also be equated with “none”
 * @returns
 */
const dirtyValue = (initialValue, formValue, noneValue) =>
  initialValue !== formValue &&
  Boolean(initialValue || formValue) &&
  (!noneValue || ((initialValue || formValue !== noneValue) && (formValue || initialValue !== noneValue)))

/**
 * Very commonly-used function for `dirtyArray` comparisons (see below). It’s defined here
 * to avoid defining multiple copies of this in the code base (as small as it is).
 *
 * @param {object} the object to map to its `id` attribute
 * @returns the argument’s `id` attribute
 */
const idMap = ({ id }) => id

/**
 * This function determines whether two arrays have the same contents, generally for the purpose
 * of checking whether any updates are needed. As such, it isn’t exactly an “equality” function:
 *
 * - Falsy and empty arrays are all considered to be the same.
 *
 * - The function converts the arrays into Sets for comparison. Due to the use of Sets,
 *   duplicate items in an array are treated as the same item and item sequence does not
 *   factor into the comparison.
 *
 * - Optional “ID map” functions are accepted if it is sufficient to compare ID values.
 *   Otherwise, the Set comparisons use the array members directly.
 *
 * @param {array} initialArray the “base” array for comparison
 * @param {array} formArray the “updated” array to compare
 * @param {func(object)} initialIdMap mapper to convert base array members into ID values
 * @param {func(object)} formIdMap mapper to convert updated array members into ID values
 * @returns
 */
const dirtyArray = (initialArray, formArray, initialIdMap, formIdMap) => {
  // When one or both arrays is falsy, we have easy cases.
  if (!initialArray || !formArray) {
    // We consider falsy and empty arrays to be the same.
    return !(
      (!initialArray && !formArray) ||
      (!initialArray && formArray.length === 0) ||
      (!formArray && initialArray.length === 0)
    )
  }

  // Get rid of another easy case.
  if (initialArray.length !== formArray.length) {
    return true
  }

  // Based on https://stackoverflow.com/questions/31128855/comparing-ecma6-sets-for-equality
  //
  // We use sets to eliminate potential duplicates…
  const initialIds = new Set(initialIdMap ? initialArray.map(initialIdMap) : initialArray)
  const formIds = new Set(formIdMap ? formArray.map(formIdMap) : formArray)

  // …but we still need the array version so that we can use `some`.
  return initialIds.size !== formIds.size || [...initialIds].some(id => !formIds.has(id))
}

const dirtyArrayWithOrder = (initialArray, formArray) =>
  initialArray.length !== formArray.length || !initialArray.every((item, index) => item === formArray[index])

const emptyArray = array => !Array.isArray(array) || array.length === 0
const equalArrayWithOrder = (leftArray, rightArray) => !dirtyArrayWithOrder(leftArray, rightArray)

const emptyBool = bool => bool !== true && bool !== false

// Booleans distinguish the actual value false from other falsy values.
const dirtyBool = (initialBool, formBool) =>
  !(initialBool === formBool || (emptyBool(initialBool) && emptyBool(formBool)))

// Dates also need special handling because they may have the same timestamp while still
// being different objects (and thus !==). But we do still consider falsy Dates to be the
// same so we pass them through `dirtyValue` too.
const dirtyDate = (initialDate, formDate) => dirtyValue(initialDate?.getTime(), formDate?.getTime())

const householdLabel = (household, options = { stripAfterHyphen: true }) => {
  if (!household) {
    return '(n/a)'
  }

  const { name, id } = household

  const { stripAfterHyphen } = options

  if (!name) {
    return `Household <${id}>` // Fallback if somehow the name is missing.
  }

  if (!stripAfterHyphen) {
    return name
  }

  const lastSeparator = name.lastIndexOf('-')
  const displayName = lastSeparator === -1 ? name : name.substr(0, lastSeparator)
  return `${displayName || '(no name)'}`
}

/**
 * Given a name, this function returns a string with including initials derived from that name.
 * The derivation is a simple acronym—first letters of each space-separated word.
 *
 * @param {string} name
 * @returns “initials” for the name—essentially its acronym
 */
const initials = name => {
  // Falsy string edge case.
  if ((name ?? '').length === 0) {
    return ''
  }

  const allInitials = name.split(' ').map(word => word[0])
  return (allInitials.length === 1 ? allInitials[0] : `${allInitials[0]}${allInitials.pop()}`).toUpperCase()
}

/**
 * Simple helper that appends an 's' to a word if the given value isn’t 1.
 */
const simplePlural = (singular, count) => `${singular}${count === 1 ? '' : 's'}`

/**
 * Convenience function for building a map object from a list, given a property in the object(s)
 * to use as a key.
 *
 * @param {array} list the list of items from which to create the map
 * @param {string} key the property name in the items to use as the key
 * @returns the map object with the list items keyed accordingly
 */
const listToMap = (list, key = 'id') => Object.fromEntries(list.map(item => [item[key], item]))

/**
 * Convenience function for doing a sort based on object properties.
 *
 * @param {string} propertyName property to access from objects being sorted
 * @returns -1, 0, 1 depending on comparison of property values
 */
const propertyComparator = propertyName => (leftObject, rightObject) => {
  const leftProperty = leftObject[propertyName]
  const rightProperty = rightObject[propertyName]
  return leftProperty < rightProperty ? -1 : leftProperty > rightProperty ? 1 : 0
}

const getCookie = cookieName => {
  const name = cookieName + '='
  const cookies = document.cookie.split(';').map(cookie => cookie.trim())

  for (let cookie of cookies) {
    if (cookie.startsWith(name)) {
      const cookieValue = cookie.substring(name.length)
      return cookieValue || ''
    }
  }

  return ''
}

const scrollToId = id => {
  // TODO This code is very similar to many other `scrollIntoView` calls in the codebase, sometimes
  //      differing only by the query selector. May be worth consolidating sometime.
  const target = document.querySelector(`#${id}`)
  if (target) {
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
}

export {
  statusCheck,
  setState,
  setNativeErrorHandler,
  setVersionUpdateHandler,
  getPromiseErrorHandler,
  getHookPromiseErrorHandler,
  handleErrorWithStateHooks,
  okCheck,
  createdCheck,
  acceptedCheck,
  noContentCheck,
  upsertCheck,
  throwResponseError,
  emitNativeError,
  modulePath,
  parseDateOrNull,
  componentSpecId,
  propsAndComponentFromSpec,
  dateToString,
  defaultName,
  dirtyValue,
  dirtyArray,
  dirtyArrayWithOrder,
  emptyArray,
  equalArrayWithOrder,
  dirtyBool,
  dirtyDate,
  householdLabel,
  idMap,
  initials,
  listToMap,
  passwordCheck,
  confirmPasswordCheck,
  scrollToId,
  simplePlural,
  profileBlockElementId,
  propertyComparator,
  requestTag,
  sortKey,
  getCookie
}
