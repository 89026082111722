/**
 * HelpPrompt is an additional layer on top of HelpLink that contains a static text prompt.
 */
import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import HelpLink from './HelpLink'

const useStyles = makeStyles(
  theme => ({
    root: {
      ...theme.typography.body2,

      color: theme.palette.text.secondary,

      [theme.breakpoints.up('brandSmall')]: {
        ...theme.typography.body1
      }
    },

    helpLink: {
      ...theme.typography.body2,

      fontWeight: theme.typography.fontWeightMedium,
      textDecorationSkipInk: 'none',
      textUnderlinePosition: 'from-font',

      [theme.breakpoints.up('brandSmall')]: {
        ...theme.typography.body1,

        fontWeight: theme.typography.fontWeightMedium
      }
    }
  }),
  {
    name: 'HelpPrompt'
  }
)

const HelpPrompt = props => {
  const { className, prompt = 'Questions?' } = props

  const classes = useStyles(props)
  return (
    <section className={clsx(classes.root, className)}>
      {prompt} <HelpLink className={classes.helpLink} />
    </section>
  )
}

HelpPrompt.propTypes = {
  className: PropTypes.string,
  prompt: PropTypes.string // Defaults to “Questions?”
}

export default HelpPrompt
