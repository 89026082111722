import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import FoundationalAuditFields from '../../../goal/audit/FoundationalAuditFields'

const FIELD_SPACE = 2

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(2)
    },

    title: {
      margin: 0
    },

    loading: {
      opacity: 0.25
    },

    description: {
      ...theme.typography.body1,

      margin: 0
    },

    fieldContainer: {
      display: 'flex',
      gap: theme.spacing(FIELD_SPACE),
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: theme.spacing(2, 0, 0),
      transition: theme.transitions.create('opacity')
    }
  }),
  {
    name: 'ChildRoadmap'
  }
)

const ChildRoadmap = props => {
  const { loadInProgress, nickname, birthday, diagnosisTagIds, setNickname, setBirthday, setDiagnosisTagIds } = props

  const classes = useStyles(props)
  return (
    <section className={classes.root}>
      <h1 className={classes.title}>Start your child’s Roadmap</h1>

      <p className={classes.description}>
        Create your child’s comprehensive Roadmap before customizing it with individual goals.
      </p>

      <p className={classes.description}>
        By just providing a bit of information about your child to start, we’ll suggest a Roadmap of goals, sequenced by
        suggested age. A Roadmap is yours to keep!
      </p>

      <FoundationalAuditFields
        className={clsx(classes.fieldContainer, loadInProgress && classes.loading)}
        disabled={loadInProgress}
        nickname={nickname}
        setNickname={setNickname}
        birthday={birthday}
        setBirthday={setBirthday}
        diagnosisTagIds={diagnosisTagIds}
        setDiagnosisTagIds={setDiagnosisTagIds}
        showAge
      />
    </section>
  )
}

ChildRoadmap.propTypes = {
  loadInProgress: PropTypes.bool, // Whether child information is currently still loading.
  nickname: PropTypes.string, // Child's preferred name
  birthday: PropTypes.instanceOf(Date), // Child's date of birth
  diagnosisTagIds: PropTypes.arrayOf(PropTypes.number), // List of diagnosis tag IDs
  setNickname: PropTypes.func, // Function to handle nickname change
  setBirthday: PropTypes.func, // Function to handle birthday change
  setDiagnosisTagIds: PropTypes.func // Function to handle diagnosis tag IDs change
}

export default ChildRoadmap
