import React, { forwardRef } from 'react'

import IconButton from '@mui/material/IconButton'

const BrandIconButton = forwardRef((props, ref) => {
  const { children, ...rest } = props

  return (
    <IconButton ref={ref} size="large" {...rest}>
      {children}
    </IconButton>
  )
})

export default BrandIconButton
