/**
 * This module contains values/code that are internal to but shared among navigation-related code.
 */
import { generatePath, useLocation } from 'react-router-dom'

import { useCurrentHousehold, useCurrentSite } from '../../app/hooks'

const MENU_WIDTH = 26
const MENU_WIDTH_ICON_ONLY = 8 // Just enough to fit the lettermark.

const clientPrimarySortKey = client => client.createdAt // At this point it’s a string.
const clientSecondarySortKey = client => (client.familyName || '').toUpperCase()
const clientTertiarySortKey = client => (client.nickname || client.name || '').toUpperCase()
const compareKeys = (left, right) => (left < right ? -1 : left > right ? 1 : 0)

const clientSort = (leftClient, rightClient) => {
  const leftPrimary = clientPrimarySortKey(leftClient)
  const rightPrimary = clientPrimarySortKey(rightClient)
  const leftSecondary = clientSecondarySortKey(leftClient)
  const rightSecondary = clientSecondarySortKey(rightClient)
  const leftTertiary = clientTertiarySortKey(leftClient)
  const rightTertiary = clientTertiarySortKey(rightClient)
  const primaryCompare = compareKeys(leftPrimary, rightPrimary)
  const secondaryCompare = compareKeys(leftSecondary, rightSecondary)
  return primaryCompare === 0
    ? secondaryCompare === 0
      ? compareKeys(leftTertiary, rightTertiary)
      : secondaryCompare
    : primaryCompare
}

const clientList = clients => (clients ?? []).sort(clientSort)

/**
 * Convenience function for the common style properties of the sidebar menu indicator.
 *
 * @param {object} theme The Material UI theme that is in use
 * @returns a style object defining the side menu indicator look and feel
 */
const indicatorStyle = theme => ({
  borderRadius: theme.spacing(0, 2.5, 2.5, 0),
  bottom: 0,
  left: 0,
  right: 'auto',
  top: 0,
  width: theme.spacing(5.875),
  zIndex: -1 // Keep it behind the icon and label.
})

/**
 * Convenience function for the common style properties of an overlay sidebar menu indicator.
 *
 * @param {object} theme The Material UI theme that is in use
 * @returns a style object defining a side menu indicator meant for an overlay element
 */
const overlayIndicatorStyle = theme => ({
  ...indicatorStyle(theme),

  content: '""',
  position: 'absolute',
  transition: theme.transitions.create('background-color')
})

/**
 * Convenience function for the common style properties of a transient sidebar menu indicator.
 *
 * @param {object} theme The Material UI theme that is in use
 * @returns a style object defining a latent side menu indicator that appears on hover
 */
const transientIndicatorStyle = theme => ({
  '&:before': {
    ...overlayIndicatorStyle(theme),

    backgroundColor: 'transparent',
    opacity: 0.7 // For mimicking an MUI Tab.
  },

  '&:hover:before': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.text
  }
})

/**
 * This hook reads the current location and determines if it matches one of the given menu items.
 * The return value can then be used to affect how a menu item is rendered.
 *
 * @param {array} menuItems The menu items from which to determine a selected one.
 * @param {string} clientId The client ID to use, for menu items which have that as a path parameter
 * @returns
 */
const useSelectedMenuItem = (menuItems, clientId) => {
  const location = useLocation()
  const locationPathname = location.pathname.slice(1) // Strip the leading slash.

  const locationSite = useCurrentSite()
  const { owner } = locationSite || {}

  const household = useCurrentHousehold()
  const householdId = household?.id

  // The top priority match is an identical path.
  const exactMatchIndex = menuItems.findIndex(item => item.path === locationPathname)
  if (exactMatchIndex > -1) {
    return exactMatchIndex
  }

  // If not, we will take startsWith matches; and finally we check for a configured “owner” segment.
  const indirectMatchIndex = menuItems.findIndex(item => {
    const { path } = item
    const itemPath = generatePath(path, { householdId, clientId, '*': '' }) // Use empty wildcard if present in path.
    return locationPathname.startsWith(itemPath) || itemPath.startsWith(owner)
  })

  // Material UI Tabs takes false if there is to be no selection.
  return indirectMatchIndex > -1 ? indirectMatchIndex : false
}

export {
  MENU_WIDTH,
  MENU_WIDTH_ICON_ONLY,
  clientList,
  indicatorStyle,
  overlayIndicatorStyle,
  transientIndicatorStyle,
  useSelectedMenuItem
}
