/**
 * HelpLink is a standard component that lets the user ask for help during onboarding.
 */
import React from 'react'

import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import { HELP_CALL_LINK, HELP_LABEL } from '../constants'

import LinkButton from '../../subcomponents/LinkButton'

const useStyles = makeStyles(
  theme => ({
    root: {
      color: theme.palette.text.secondary,
      lineHeight: theme.spacing(1.75),

      '&:visited': {
        color: theme.palette.text.secondary
      }
    }
  }),
  {
    name: 'HelpLink'
  }
)

const HelpLink = props => {
  // We diverge a bit from the usual way that we do className because of the way LinkButton handles props.
  const { classes: classesProp, ...rest } = props

  const classes = useStyles(props)
  return (
    <LinkButton classes={classes} target="_blank" rel="noopener noreferrer" href={HELP_CALL_LINK} {...rest}>
      {HELP_LABEL}
    </LinkButton>
  )
}

HelpLink.propTypes = {
  className: PropTypes.string
  // …with other props being relayed to LinkButton.
}

export default HelpLink
