/**
 * Helper functions for mapping raw API objects to more convenient versions (e.g., converting from strings to other
 * data types). If there is a helper function for a particular Undivided model, then you should probably wrap any
 * received JSON object around that function before using it.
 */
import parse from 'date-fns/parse'
import isValid from 'date-fns/isValid'
import startOfDay from 'date-fns/startOfDay'

import appointmentIcon from './assets/appointment_post.svg'
import assessmentIcon from './assets/assessment_post.svg'
import behaviorIcon from './assets/behavioral.svg'
import billIcon from './assets/bill_post.svg'
import documentIcon from './assets/document.svg'
import eatIcon from './assets/eat.svg'
import educationIcon from './assets/objective-education.svg'
import eobIcon from './assets/eob_post.svg'
import infoBlockIcon from './assets/info-block.svg'
import legalIcon from './assets/legal_post.svg'
import medicalIcon from './assets/objective-medical.svg'
import paymentIcon from './assets/objective-insurance.svg'
import prescriptionIcon from './assets/prescription_post.svg'
import reportCardIcon from './assets/report_card.svg'
import sleepIcon from './assets/sleep.svg'
import testScoresIcon from './assets/test_scores_post.svg'

import {
  CAN_BE_NAVIGATOR,
  CAN_BE_SUPERUSER,
  INFO_BLOCK_ROOT,
  UNFINISHED_ROOT,
  CAN_BE_CERTIFIED,
  CAN_BE_ADMIN
} from './arch/constants'

import { CONTACT_TYPE_FAMILY } from './arch/contactConstants'
import { TASK_TYPE_NEW_MESSAGES } from './arch/taskConstants'

import {
  ASSET_SIZE_ORIGINAL,
  ASSET_SIZE_1X,
  ASSET_SIZE_2X,
  ASSET_SIZE_3X,
  DATE_FORMAT_ISO,
  FALLBACK_CHILD_NAME
} from './constants'

import { getBirthdayDate } from './domain/client'
import { clientList } from './navigation/support/common'
import { ATTACHMENT_TYPE_POST } from './subcomponents/attachment/constants'

const TYPE_TO_IMAGE = {
  [INFO_BLOCK_ROOT]: infoBlockIcon,
  [UNFINISHED_ROOT]: documentIcon,
  Concern: documentIcon,
  Question: documentIcon,
  'Milestone.Set': documentIcon,
  'Milestone.Achievement': documentIcon,
  'Goal.Set': documentIcon,
  'Goal.Progress': documentIcon,
  'Goal.Achievement': documentIcon,
  'Plan.Diet': eatIcon,
  'Plan.Sleep': sleepIcon,
  'Plan.Treatment': documentIcon,
  'Plan.Treatment.Change': documentIcon,
  'Plan.Medication': prescriptionIcon,
  'Plan.Medication.Change': prescriptionIcon,
  'Observation.Symptom': documentIcon,
  'Observation.Behavior': behaviorIcon,
  'Observation.DietChange': eatIcon,
  'Observation.SleepChange': sleepIcon,
  'Observation.Other': documentIcon,
  Appointment: appointmentIcon,
  Meeting: documentIcon,
  'Document.Medical.Continuity of Care Document (CCD)': documentIcon,
  'Document.Medical.Assessment': assessmentIcon,
  'Document.Medical.Lab Test Result': medicalIcon,
  'Document.Medical.Prescription': prescriptionIcon,
  'Document.Medical.Correspondence': documentIcon,
  'Document.Medical.Therapist Notes': documentIcon,
  'Document.Medical.Other': documentIcon,
  'Document.School.Individualized Education Plan (IEP)': educationIcon,
  'Document.School.Test Score': testScoresIcon,
  'Document.School.Report Card & Progress Reports': reportCardIcon,
  'Document.School.Correspondence': documentIcon,
  'Document.School.Therapist Notes': documentIcon,
  'Document.School.Other': documentIcon,
  'Document.Insurance & Bills.Bill': billIcon,
  'Document.Insurance & Bills.Explanation of Benefits (EOB)': eobIcon,
  'Document.Insurance & Bills.Correspondence': billIcon,
  'Document.Insurance & Bills.Other': billIcon,
  'Document.Legal.Guardianship': legalIcon,
  'Document.Legal.Power of Attorney': legalIcon,
  'Document.Legal.Authorization': legalIcon,
  'Document.Legal.Privacy Release': legalIcon,
  'Document.Legal.Other': legalIcon,

  // Late 2018 batch of types (will need syncing with iOS icons).
  'Document.Government Programs.In-Home Supportive Services (IHSS)': documentIcon,
  'Document.Government Programs.Indiv Family Service Plan (IFSP)': documentIcon,
  'Document.Government Programs.Indiv Program Plan (IPP)': documentIcon,
  'Document.Government Programs.Regional Center Authorization': documentIcon,
  'Document.Government Programs.Regional Center Correspondence': documentIcon,
  'Document.Insurance & Bills.Authorization': billIcon,
  'Document.Insurance & Bills.Insurance Card': paymentIcon,
  'Document.Insurance & Bills.Super Bill': eobIcon,
  'Document.Medical.Imaging': documentIcon,
  'Document.Medical.Intake Survey': documentIcon,
  'Document.Medical.Questionnaire': documentIcon,
  'Document.Private Services.ABA Report': documentIcon,
  'Document.Private Services.Therapist Assessment': documentIcon,
  'Document.Private Services.Therapist Notes': documentIcon,
  'Document.Private Services.Therapist Report': documentIcon,
  'Document.Reference.Contact Info': documentIcon,
  'Document.Reference.Parent Notes': documentIcon,
  'Document.Reference.Provider Handout': documentIcon,
  'Document.Reference.Research': documentIcon,
  'Document.Reference.Special X Reports': educationIcon,
  'Document.School.504 Plan': documentIcon,
  'Document.School.Assessment': documentIcon,
  'Document.School.Functional Behavior Assessment': behaviorIcon,
  'Document.School.IEP Modification': educationIcon,
  Observation: documentIcon,
  Note: documentIcon
}

const convertToDateIfNecessary = (source, dateProperty) => {
  const dateString = source[dateProperty]
  if (dateString && typeof dateString === 'string') {
    source[dateProperty] = new Date(dateString)

    // Preserve the original in case it is used for cases like a cursor.
    source[`${dateProperty}__string`] = dateString
  }
}

const assetDetail = assetDetail => {
  convertToDateIfNecessary(assetDetail, 'createdAt')
  return assetDetail
}

const post = post => {
  post.additionalProperties = post.additionalProperties ?? {}
  post.assetDetails = post.assetDetails?.map(assetDetail) ?? []
  post.image = TYPE_TO_IMAGE[post.type] ?? documentIcon
  post.types = post?.type?.split('.') ?? []
  convertToDateIfNecessary(post, 'createdAt')
  convertToDateIfNecessary(post, 'referenceTimestamp')
  convertToDateIfNecessary(post, 'lastUpdatedAt')
  return post
}

const event = event => {
  convertToDateIfNecessary(event, 'startTime')
  convertToDateIfNecessary(event, 'endTime')
  event.household = event.household || { id: event.householdId }

  // Attachments can either come from post objects or post ID strings. Either way, converge into attachment
  // objects in order to match the expectations of our attachment comopnents.
  const { postIds } = event
  event.posts = event.posts || (postIds ? postIds.map(postId => ({ id: postId })) : [])
  event.attachments = event.posts.map(post => ({ type: ATTACHMENT_TYPE_POST, post }))
  return event
}

const convertCommonTimestamps = source => {
  convertToDateIfNecessary(source, 'createdAt')
  convertToDateIfNecessary(source, 'updatedAt')
  return source
}

const contact = contact => {
  convertToDateIfNecessary(contact, 'createdAt')

  // The only other model conversion that we need is for friends and family contacts,
  // which have a birthdate within.
  const friendsAndFamily = contact.type[CONTACT_TYPE_FAMILY]
  if (friendsAndFamily) {
    const { birthdate } = friendsAndFamily
    friendsAndFamily.birthdate = getBirthdayDate(birthdate)
  }

  return contact
}

const goal = convertCommonTimestamps

const guide = guide => {
  convertCommonTimestamps(guide)

  // Guide variants from the API use “guideId” to hold the ID of their “root/source” guide.
  // Store these in a different attribute to match the web app code better.
  const { id, guideId, variantSourceId, variants } = guide
  if (guideId && !variantSourceId) {
    // Make the assignment only if a guideId is present and that variantSourceId hasn’t been
    // assigned yet.
    guide.variantSourceId = guideId
  }

  // “Parent” guides may have a `variants` array listing their variants. For uniformity with
  // the top-level model, we make sure these variant stubs also have `guideId` and
  // `variantSourceId` attributes.
  if (Array.isArray(variants)) {
    variants.forEach(variant => {
      variant.guideId = id
      variant.variantSourceId = id
    })
  }

  return guide
}

const household = household => {
  convertToDateIfNecessary(household, 'createdAt')
  convertToDateIfNecessary(household, 'kickstartDate')
  convertToDateIfNecessary(household, 'kickstartEndDate')
  convertToDateIfNecessary(household, 'navigatorHoldEnds')
  convertToDateIfNecessary(household, 'trialStartDate')
  convertToDateIfNecessary(household, 'trialEndDate')

  if (Array.isArray(household.clients)) {
    household.clients = clientList(household.clients)
  }

  return household
}

const step = step => {
  convertToDateIfNecessary(step, 'finishedAt')

  // Special cases:
  //
  // The Undivided API only accepts 'YYYY-MM-DD' for the goal date when POSTing or PATCHing steps but
  // returns a full ISO string, _with UTC time zone_, when GETting them. This effectively offsets the goal
  // date by the local time zone in relation to UTC when in reality the goal date will always be perceived
  // as local by the user. To get around this, we truncate everything after the date.
  if (step.goalDate) {
    const dateOnlyFormatLength = DATE_FORMAT_ISO.length

    let goalDateStr = step.goalDate
    goalDateStr = goalDateStr.length > dateOnlyFormatLength ? goalDateStr.substr(0, dateOnlyFormatLength) : goalDateStr

    const parsedDate = parse(goalDateStr, DATE_FORMAT_ISO, startOfDay(new Date()))
    step.goalDate = isValid(parsedDate) ? parsedDate : null
  }

  return step
}

const task = task => {
  convertCommonTimestamps(task)
  convertToDateIfNecessary(task, 'finishedAt')
  convertToDateIfNecessary(task, 'targetDate')

  // Port specifications date(s) if needed.
  const { type, specifications } = task
  if (type === TASK_TYPE_NEW_MESSAGES && specifications) {
    convertToDateIfNecessary(specifications, 'date')
  }

  return task
}

// Retrieves the resource link from local storage
const resourceLink = localStorage.getItem('resourceLink')

const STATUS_INTERNAL = 'INTERNAL'

class User {
  constructor(original) {
    Object.keys(original).forEach(property => {
      this[property] = original[property]
    })

    // Pass the household subobject through the same logic as a standard household object.
    if (this.household && typeof this.household === 'object') {
      this.household = household(this.household)
    }

    // Ensure that we always have a roles array at runtime.
    if (!this.roles) {
      this.roles = []
    }

    // Ensure that we always have a preferences object at runtime.
    if (!this.preferences) {
      this.preferences = {}
    }

    // Enforce consistent sorting of child lists.
    if (this.sharedClients) {
      this.sharedClients = clientList(this.sharedClients)
    }

    convertToDateIfNecessary(this, 'createdAt')
  }

  checkRole(acceptableRoles) {
    return this.roles.some(role => acceptableRoles.includes(role))
  }

  isParent() {
    return this.roles.length === 0
  }

  isCareTeamMember() {
    return this.roles.length === 0 && !this.household && this.sharedClients?.length > 0
  }

  role(acceptableRoles) {
    // No acceptable roles indicates that a parent user is expected.
    return (
      this.checkRole(CAN_BE_SUPERUSER) ||
      (acceptableRoles?.length > 0 ? this.checkRole(acceptableRoles) : this.isParent())
    )
  }

  isInternal() {
    return this.status === STATUS_INTERNAL
  }

  isParentOf(clientId) {
    if (!this.household?.clients) {
      return false
    }

    return this.household.clients.find(clientStub => clientStub.id === clientId) !== undefined
  }

  canPlanFor(clientId) {
    return this.isParentOf(clientId) || this.role(CAN_BE_NAVIGATOR)
  }

  goalRedirectLink(householdId, goalId, clientId) {
    // Where we go depends on the current user’s role and a goal ID is provided.
    const pathname = this.isParent()
      ? goalId
        ? `/roadmaps/${clientId}/goals/${goalId}`
        : `/roadmaps/${clientId ?? ''}`
      : householdId
      ? `/client-accounts/${householdId}${goalId && clientId && `/roadmaps/${clientId}/goals/${goalId}`}`
      : '/client-accounts' // Service users need a household ID; if absent, it just goes to the client account list.

    return { pathname }
  }

  messageLink(messageId, householdId, goalId, clientId) {
    // Where we go depends on the current user’s role and a goal ID is provided.
    const pathname = this.isParent()
      ? goalId
        ? `/messages/goals/${goalId}`
        : `/messages/navigator`
      : householdId
      ? `/client-accounts/${householdId}${goalId && clientId ? `/roadmaps/${clientId}/goals/${goalId}` : '/messages'}`
      : '/client-accounts' // Service users need a household ID; if absent, it just goes to the client account list.

    // The state supplies additional information for displaying the message.
    const state = goalId
      ? {
          highlightedGoalId: goalId,
          highlightedCommentId: messageId
        }
      : {
          chat: 'open',
          highlightedMessageId: messageId
        }

    return { pathname, state }
  }

  portalPath(household) {
    const portalBase = this.isParent()
      ? resourceLink ?? (household?.clients?.length > 0 ? 'feed' : household ? 'resources' : 'binder')
      : this.role(CAN_BE_SUPERUSER) // This has to be next because superusers will be true for everything else.
      ? 'client-accounts'
      : this.role(CAN_BE_NAVIGATOR)
      ? 'dashboard'
      : this.role(CAN_BE_CERTIFIED)
      ? 'tasks'
      : this.role(CAN_BE_ADMIN)
      ? 'tools'
      : 'not-found' // All other possibilities have been covered, so this indicates a data issue.

    return `/${portalBase}`
  }
}

const user = user => new User(user)

const assetSize = dimension => {
  // Heuristic: we return the size at double the dimension due to HiDPI displays.
  if (dimension <= 100) {
    return ASSET_SIZE_1X
  } else if (dimension <= 200) {
    return ASSET_SIZE_2X
  } else if (dimension <= 300) {
    return ASSET_SIZE_3X
  } else {
    return ASSET_SIZE_ORIGINAL
  }
}

const convertClientAccountDate = source => {
  convertToDateIfNecessary(source, 'kickstartDate')
  convertToDateIfNecessary(source, 'kickstartEndDate')
  convertToDateIfNecessary(source, 'last_boost')
  convertToDateIfNecessary(source, 'next_boost')
  source.child_dob = getBirthdayDate(source.child_dob)
  convertToDateIfNecessary(source, 'last_call')
  convertToDateIfNecessary(source, 'next_call')
  return source
}

const clientAccount = client => {
  convertClientAccountDate(client)

  return {
    householdId: client.household_id,
    goalId: client.goal_id,
    goalName: client.goal_name,
    goalType: client.goal_type,
    householdName: client.account_name,
    childName: client.child_name || FALLBACK_CHILD_NAME,
    childDOB: client.child_dob,
    roadmapCreated: client.roadmap_created,
    childAge: client.child_age,
    childId: client.child_id,
    level: client.membership_level,
    primaryEmail: client.primary_email,
    trialStart: client.trial_start,
    trialEnd: client.trial_end,
    lastBoost: client.last_boost,
    nextBoost: client.next_boost,
    nextCall: client.next_call,
    lastCall: client.last_call,
    nextIEP: client.next_iep,
    specificTo: client.child_name,
    kickstartDate: client.kickstartDate,
    kickstartEndDate: client.kickstartEndDate,
    harvestLink: client.harvest_link,
    callNotesLink: client.call_notes_link
  }
}

const model = {
  assetDetail,
  assetSize,
  contact,
  post,
  event,
  goal,
  guide,
  household,
  step,
  task,
  user,
  clientAccount
}

export default model
