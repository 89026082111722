/**
 * This module contains the various context objects that provide app-wide state. Components are expected to
 * reference these contexts as needed.
 */
import { createContext } from 'react'

export const ClientContext = createContext(null)
export const ParentContext = createContext(null)
export const UserContext = createContext(null)
export const HouseholdContext = createContext(null)
export const ResourceTopicsContext = createContext(null)
export const RemindersContext = createContext([])

export const LoginContext = createContext({ setAppUser: () => {}, setAppHousehold: () => {}, setAppClient: () => {} })
export const LogoutContext = createContext(() => {})

export const NotificationsContext = createContext({
  chatMessageRequest: '',
  requestChatMessage: chatMessageRequest => {},

  messagePreviews: [],
  addMessagePreviews: messagesWithChannel => {},
  removeMessagePreviewsForChannel: channelId => {},
  setMessagePreviews: messagePreviews => {},

  waitingForUnreadMessages: false,
  totalUnreadMessages: 0,
  setTotalUnreadMessages: totalUnreadMessages => {},

  totalUnreadTasks: 0,
  setTotalUnreadTasks: totalUnreadTasks => {}
})

export const MenubarContext = createContext({
  drawerResourceData: null, // If not null, specifies that the resource drawer should be displayed with the given data.
  setDrawerResourceData: () => {}, // Sets the resource data to display (`null` to close).

  menubarVisible: true,
  setMenubarVisible: () => {}
})

/**
 * SiteContext holds the current most closely-matched “site” (as defined in /navigation/map.js).
 * This may be useful for picking up metadata about the current site, like its gate, theme, etc.
 */
export const SiteContext = createContext(null)

export const SnackbarStackContext = createContext({
  //  openSnackbar argument ,
  // {
  //   message: {
  //     title: ''
  //   },

  //   snackbarTime in milliseconds (optional, has a default if not supplied)
  //   snackbarTime: 5000,

  //   state
  //   open: false,

  //   custom function ,if there is a button
  //   customAction: {
  //     label: '',
  //     onAction: () => {}
  //   }
  // },

  // set message and open to true to open the snackbar
  openSnackbar: snackbarOptions => {},

  // set state to close, when close the message should clear itself
  closeSnackbar: id => {}
})

export const DocumentUploadHandler = createContext({
  createAsset: () => {},
  deleteAsset: () => {},
  onDragOver: () => {},
  onDragDrop: () => {},
  onDragLeave: () => {},
  processDataTransfer: () => {},
  uploading: false,
  deleting: false,
  dragging: false,
  setDragging: () => {},
  fileInProgress: '',
  assetsToNames: {}, // Map from media IDs to filenames.
  media: [], // Media IDs that have finished uploading.
  setMedia: () => {}
})
