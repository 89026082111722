/**
 * The constants listed here are specific to contacts and being in the _arch_ module
 * these constants also have meaning on the server side.
 */
const CONTACT_TYPE_FAMILY = 'friendsAndFamily'
const CONTACT_TYPE_PROVIDER = 'providersAndTeam'

const ALL_CONTACT_TYPES = [CONTACT_TYPE_FAMILY, CONTACT_TYPE_PROVIDER]

export { CONTACT_TYPE_FAMILY, CONTACT_TYPE_PROVIDER, ALL_CONTACT_TYPES }
