/**
 * This “library” module contains code that can tell callers if a specific component is available
 * for a particular error.
 */
import ChunkLoadError from './ChunkLoadError'
import GenericUnexpectedError from './GenericUnexpectedError'

const errorComponent = error => {
  const errorString = `${error}` // TODO Yes this is lazy. But for now it feels safer than introspection on structure.

  // We structure the logic this way to accommodate different kinds of conditions for determining
  // whether we have a “match.”
  if (errorString.includes('ChunkLoadError')) {
    return ChunkLoadError
  }

  // If we get here, we go generic.
  return GenericUnexpectedError
}

export { errorComponent }
