import format from 'date-fns/format'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'

import { DATE_FORMAT } from '../../constants'

const age = date => (date && !isNaN(date) ? formatDistanceStrict(new Date(), date, { roundingMethod: 'floor' }) : 'n/a')

const birthdayDisplay = (birthday, dateFormat, defaultDisplay) =>
  birthday ? format(birthday, dateFormat || DATE_FORMAT) : defaultDisplay || 'n/a'

export { age, birthdayDisplay }
