import PropTypes from 'prop-types'

const ATTACHMENT_TYPE_POST = 'Post'
const ATTACHMENT_TYPES = [ATTACHMENT_TYPE_POST]

const ATTACHMENT_WIDTH = 28.75 // In spacing units.
const ATTACHMENT_HEIGHT = 19.75

// This serves as the “master copy” of an attachment object.
// Type-specific components will declare a subset of these.
const ATTACHMENT_SHAPE = {
  author_name: PropTypes.string,
  image_url: PropTypes.string,
  og_scrape_url: PropTypes.string,
  post: PropTypes.object, // TODO convert to shape
  text: PropTypes.string,
  thumb_url: PropTypes.string,
  title: PropTypes.string,
  title_link: PropTypes.string,
  type: PropTypes.string
}

export { ATTACHMENT_TYPES, ATTACHMENT_TYPE_POST, ATTACHMENT_WIDTH, ATTACHMENT_HEIGHT, ATTACHMENT_SHAPE }
