import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Navigate } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles'

import {
  useCurrentHousehold,
  useOTPVerification,
  useParentViewCapability,
  useVerificationEmailResend
} from '../app/hooks'
import { THEME_COLOR_GROUP_NEUTRAL, THEME_PRIMARY_NEUTRAL } from '../app/support/theme'
import { isValidOTP, notifyLoginStatusFromIframe } from '../app/utilities'
import { CAPABILITY_CONFIRM_EMAIL } from '../arch/capabilityConstants'
import { getHookPromiseErrorHandler } from '../common'
import { checkpointAfter } from '../domain/onboarding'

import { CHECKPOINT_EMPTY, VERIFY_EMAIL_QUOTES } from './constants'
import { OnboardingStatus } from './contexts'
import { identifyJoinerFunnel } from './utilities'

import BrandButton from '../subcomponents/brand/BrandButton'
import LinkButton from '../subcomponents/LinkButton'
import VerificationCodeInput from '../subcomponents/brand/VerificationCodeInput'

import HelpPrompt from './support/HelpPrompt'
import OnboardingContainer from './support/OnboardingContainer'

const useStyles = makeStyles(
  theme => ({
    root: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(5)
    },

    aside: {
      width: '33%'
    },

    changeCall: {
      ...theme.typography.body1,

      lineHeight: 22 / 16,
      margin: 0,

      '& em': {
        fontStyle: 'normal',
        fontWeight: theme.typography.fontWeightMedium
      },

      '& strong': {
        display: 'block',
        margin: theme.spacing(3, 0, 0)
      },

      [theme.breakpoints.up('brandSmall')]: {
        fontSize: theme.typography.pxToRem(20),
        lineHeight: 32 / 20
      }
    },

    actionContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
      justifyContent: 'space-between',
      margin: theme.spacing(6.625, 0),

      [theme.breakpoints.up('brandSmaller')]: {
        flexDirection: 'row'
      }
    },

    helpPrompt: {},

    clientColor: {
      color: THEME_COLOR_GROUP_NEUTRAL.N500
    },

    quote: {
      color: theme.palette.success.main
    },

    submitButton: {
      padding: theme.spacing(1.5, 3)
    },

    verificationCodeInput: {
      padding: theme.spacing(1, 0, 0)
    },

    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    },

    resendEmailButton: {
      textDecoration: 'none'
    }
  }),
  {
    name: 'EmailConfirmationReminder'
  }
)

const EmailConfirmationReminder = props => {
  const { onboarding, setOnboarding, sequence, additionalProperties } = useContext(OnboardingStatus)
  const { checkpoint } = onboarding ?? {}
  const nextCheckpoint = checkpointAfter(checkpoint, sequence)
  const concludeUponVerify = !nextCheckpoint || nextCheckpoint === CHECKPOINT_EMPTY

  const { referringObject } = useCurrentHousehold() ?? {}

  const { onClose } = additionalProperties

  const { isUserCapable } = useParentViewCapability()
  const alreadyVerified = !isUserCapable(CAPABILITY_CONFIRM_EMAIL)

  const [error, setError] = useState()
  const handleApiError = useMemo(() => getHookPromiseErrorHandler(setError), [])

  const { handleResendEmailVerification } = useVerificationEmailResend({ handleApiError })

  const { verificationState, updateOTPCode, verifyOTP } = useOTPVerification()
  const { otpCode, isVerified } = verificationState
  const emailVerified = alreadyVerified || isVerified

  const handleSubmit = () => verifyOTP({ location: 'Onboarding', joinFunnel: identifyJoinerFunnel(referringObject) })

  useEffect(() => {
    if (emailVerified && !concludeUponVerify) {
      setOnboarding({ checkpoint: nextCheckpoint })
    }
  }, [emailVerified, concludeUponVerify, setOnboarding, nextCheckpoint])

  const classes = useStyles(props)

  if (emailVerified && concludeUponVerify) {
    // Upon verification without a next checkpoint, we check for an onClose function,
    // and we prioritize calling that first if we find it. Otherwise, we signal our
    // iframe if we are in one, asking either to (a) go to the Roadmap if we find an
    // add-goal ID value available (meaning we were adding a goal) or (b) notify the
    // iframe owner that login succeeded.
    //
    // If not in an iframe, that message will have no effect and we will navigate to
    // the Home route.
    if (onClose) {
      onClose()
    } else {
      notifyLoginStatusFromIframe({ successfulLogin: true })

      return <Navigate to="/home" replace />
    }
  }

  return (
    <OnboardingContainer
      classes={{ aside: classes.aside }}
      theme={THEME_PRIMARY_NEUTRAL.background}
      title="Verify your email"
      sidebarProps={{
        classes: {
          client: classes.clientColor,
          quotesColor: classes.quote
        },
        text: VERIFY_EMAIL_QUOTES,
        member: 'Martha V.',
        quoteVisible: true
      }}
      hideCheckpoints
      error={error}
      setError={setError}
    >
      <section className={classes.root}>
        <p className={classes.changeCall}>
          We just sent you a 6-digit code, enter it below. If you haven’t received an email, please make sure to check
          your spam folder.
        </p>
      </section>

      <VerificationCodeInput
        className={classes.verificationCodeInput}
        onChangeOTP={updateOTPCode}
        verificationState={verificationState}
        hideResendEmail
      />

      <section className={classes.actionContainer}>
        <section className={classes.buttonContainer}>
          <BrandButton
            className={classes.submitButton}
            variant="contained"
            disabled={!isValidOTP(otpCode) || isVerified}
            onClick={handleSubmit}
          >
            Verify email
          </BrandButton>

          <LinkButton classes={{ text: classes.resendEmailButton }} onClick={handleResendEmailVerification}>
            Send email again
          </LinkButton>
        </section>

        <HelpPrompt className={classes.helpPrompt} />
      </section>
    </OnboardingContainer>
  )
}

export default EmailConfirmationReminder
