/**
 * CheckboxMultiselect is am MUI-TextField-like component that renders a FormControl
 * containing a multiselect element where selection is shown via checkboxes. In addition,
 * this component includes special `isNone` logic: if the given options have an item where
 * `isNone` is true, selecting that item will deselect all other items. and selecting any
 * item will automatically deselect any `isNone` items.
 *
 * TODO There are multiple components called `CheckboxMultiselect` in this codebase 😬
 *      and they are all slightly different from each other, but also have points of
 *      similarity. Would be nice to consolidate sometime 🫤
 */
import React, { useCallback, useEffect, useMemo } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

const useStyles = makeStyles(
  theme => ({
    root: {},
    inputLabel: {},
    select: {},
    selectDisplay: {},

    menu: {
      padding: 0
    },

    item: {
      padding: 0
    },

    checkboxLabel: {
      margin: 0,
      padding: theme.spacing(0.75, 2, 0.75, 0.625),
      transition: theme.transitions.create('color'),
      width: '100%'
    },

    discolor: {
      color: theme.palette.text.disabled
    },

    checkbox: {
      '&.Mui-checked': {
        color: theme.palette.text.primary
      }
    },

    text: {}
  }),
  {
    name: 'CheckboxMultiselect'
  }
)

/**
 * Convenience function for creating an event object based on another one.
 *
 * @param {object} event the original UI event
 * @param {string} name the name to use in the event
 * @param {any} value the value to use in the event
 * @returns the synthesized event
 */
const synthesizeEventWithValue = (event, name, value) => {
  const { target } = event
  return { ...event, target: { ...target, name, value } }
}

/**
 * Convenience function for extracting an object’s `isNone` attribute.
 *
 * @param {object} param0 an object whose `isNone` attribute we want.
 * @returns the argument’s isNone property
 */
const noneItem = ({ isNone }) => isNone

const CheckboxMultiselect = props => {
  const { className, classes: classesProp, options, label, name, value, onChange, onClose, shrink, ...rest } = props

  // We can skip the “none” logic if the options don’t have any such item.
  const noneItems = useMemo(() => options.filter(noneItem), [options])
  const includesNone = noneItems.length > 0

  // The “discolor” variables indicate whether a certain set of mutually-exclusive items needs
  // to be styled differently. Applicable only when `includesNone` is true, so we prefix by
  // that for short-circuit evaluation to skip it when this distinction is not needed.
  const noneSet = includesNone ? new Set(noneItems.map(({ value }) => value)) : undefined
  const noneIntersection = includesNone ? new Set(value).intersection(noneSet) : undefined
  const discolorNotNone = includesNone && noneIntersection.size > 0
  const discolorNone = includesNone && noneIntersection.size === 0

  const renderValue = useCallback(
    selected =>
      selected
        .map(value => options.find(option => option.value === value)?.label)
        .filter(label => Boolean(label))
        .sort() // By this time, we have an array of strings so no need for a sort function.
        .join(', '),

    [options]
  )

  const handleChangeWithNone = event => {
    const { checked, value: changedValue } = event.target

    const option = options.find(({ value }) => `${value}` === changedValue)
    const { value: optionValue, isNone } = option

    // Special handling for `isNone`: Checking this should uncheck everything else.
    if (includesNone && checked && isNone) {
      if (onChange) {
        onChange(
          synthesizeEventWithValue(
            event,
            name,

            // New value: only the `isNone`s.
            options.filter(noneItem).map(({ value }) => value)
          )
        )
        return
      }
    }

    if (value.includes(optionValue)) {
      if (!checked && onChange) {
        onChange(
          synthesizeEventWithValue(
            event,
            name,

            // New value: same as an uncheck.
            value.filter(selectedValue => selectedValue !== optionValue)
          )
        )
      }
    } else {
      if (checked && onChange) {
        onChange(
          synthesizeEventWithValue(
            event,
            name,

            // `isNone` is mutually exclusive from other items so new value does not include them.
            [...value, optionValue].filter(
              selectedValue => !options.find(({ value }) => value === selectedValue).isNone
            )
          )
        )
      }
    }
  }

  const handleClose = event => {
    if (onClose) {
      onClose(synthesizeEventWithValue(event, name, value))
    }
  }

  useEffect(() => {
    // The isNone items, if present, are mutually exclusive from other items.
    if (!includesNone) {
      return
    }

    if (value.find(noneItem) && value.length > 1) {
      onChange(value.filter(item => !item.isNone))
    }
  }, [includesNone, value, onChange])

  const classes = useStyles(props)
  return (
    <FormControl variant="filled" className={clsx(classes.root, className)}>
      <InputLabel className={classes.inputLabel} shrink={shrink}>
        {label}
      </InputLabel>

      <Select
        className={classes.select}
        MenuProps={{ classes: { list: classes.menu } }}
        SelectDisplayProps={{ className: classes.selectDisplay }}
        multiple
        size="small"
        label={label}
        name={name}
        value={value ?? []}
        renderValue={renderValue}
        // When there is no “none” item, the standard MUI select suffices.
        onChange={includesNone ? undefined : onChange}
        onClose={handleClose}
        {...rest}
      >
        {options.map(({ value: optionValue, label, isNone }) => (
          <MenuItem className={classes.item} key={optionValue} value={optionValue}>
            <FormControlLabel
              className={clsx(
                classes.checkboxLabel,
                ((discolorNone && isNone) || (discolorNotNone && !isNone)) && classes.discolor
              )}
              control={
                <Checkbox
                  className={classes.checkbox}
                  size="small"
                  value={optionValue}
                  checked={value?.includes(optionValue)}
                  onChange={includesNone ? handleChangeWithNone : undefined}
                />
              }
              label={label}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

CheckboxMultiselect.propTypes = {
  className: PropTypes.string,

  // The overall options from which to choose.
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any, // ID of the option.
      label: PropTypes.string, // Display string of the option.
      isNone: PropTypes.bool // Whether the option is a “none.”
    })
  ).isRequired,

  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  onClose: PropTypes.func, // Function to call if the select element closes.
  shrink: PropTypes.bool

  // …all other properties are relayed to the underlying Select component.
}

export default CheckboxMultiselect
