import { queryTopics } from '../api'

import {
  THEME_COLOR_GROUP_BLUE,
  THEME_COLOR_GROUP_GREEN,
  THEME_COLOR_GROUP_ORANGE,
  THEME_COLOR_GROUP_PURPLE,
  THEME_COLOR_GROUP_RED,
  THEME_COLOR_GROUP_YELLOW
} from '../app/support/theme'

import { PARAM_KEY_CURSOR, PARAM_KEY_LIMIT } from '../arch/constants'

/**
 * Per-topic properties that apply to the front end only—they are
 * keyed by ID, so it is fragile based on what’s in the database.
 */
const TOPIC_FRONT_END_PROPERTIES = {
  8: { backgroundColor: THEME_COLOR_GROUP_YELLOW.Y10, color: THEME_COLOR_GROUP_YELLOW.Y100 },
  20: { backgroundColor: '#fff1f8', color: '#f195dd' },
  39: { backgroundColor: THEME_COLOR_GROUP_GREEN.G10, color: THEME_COLOR_GROUP_GREEN.G100 },
  25: { backgroundColor: THEME_COLOR_GROUP_BLUE.B10, color: THEME_COLOR_GROUP_BLUE.B100 },
  41: { backgroundColor: THEME_COLOR_GROUP_RED.R10, color: THEME_COLOR_GROUP_RED.R100 },
  29: { backgroundColor: '#fcffdc', color: '#bbc736' },
  32: { backgroundColor: THEME_COLOR_GROUP_ORANGE.O10, color: THEME_COLOR_GROUP_ORANGE.O100 },
  1: { backgroundColor: THEME_COLOR_GROUP_PURPLE.P10, color: THEME_COLOR_GROUP_PURPLE.P100 },
  28: { backgroundColor: '#f7edff', color: '#d075f0' },
  33: { backgroundColor: '#eaf2ff', color: '#6fa3ff' }
}

/**
 * Wrapper comparator function that compares topics based on their names.
 *
 * @param {object} leftTopic
 * @param {object} rightTopic
 * @returns comparator value based on topic name
 */
const nameComparator = (leftTopic, rightTopic) => leftTopic.name.localeCompare(rightTopic.name)

/**
 * Helper function for connecting parent topics to their children (within the same array).
 *
 * @param {array} topics
 * @returns nothing—the topics array is modified in place
 */
const clusterTopics = topics => {
  topics.forEach(topic => {
    topic.children = topics.filter(possibleChild => possibleChild.parents.includes(topic.id)).sort(nameComparator)
  })
}

/**
 * Helper function for extracting only the topics in the given array with no parents.
 *
 * @param {array} topics
 * @returns array of topics that have no parents
 */
const getTopicRoots = topics => topics.filter(topic => topic.parents.length === 0)

/**
 * Consolidating helper function that implements a common combination of the other functions.
 *
 * @param {array} topics
 * @returns array of topics that have no parents, with children assigned and sorted by name
 */
const getTopicsTree = topics => {
  clusterTopics(topics)
  return getTopicRoots(topics).sort(nameComparator)
}

const TOPIC_LIMIT = 100

// Internal resource topics cache—these will change pretty infrequently so it’s OK to
// cache these throughout the life of a typical web app session.
let allResourceTopics

/**
 * Helper function for getting _all_ topics independent of pagination. Thrown errors are not
 * caught and propagated to the caller.
 */
const getAllResourceTopics = async topicsWith => {
  if (allResourceTopics && !topicsWith) {
    return allResourceTopics
  }

  const retrieveTopicsPage = async cursor => {
    const params = new URLSearchParams()
    params.set(PARAM_KEY_LIMIT, TOPIC_LIMIT)

    if (cursor) {
      params.set(PARAM_KEY_CURSOR, cursor)
    }
    if (['goalTemplates', 'guides', 'resources'].includes(topicsWith)) {
      params.set('topicsWith', topicsWith)
    }

    return await queryTopics(params)
  }

  let currentCursor = null
  const allTopics = []

  do {
    const topicsPage = await retrieveTopicsPage(currentCursor, topicsWith)

    // While waiting for the request, another thread might have already captured all resource topics.
    // If so, bail out.
    if (allResourceTopics && !topicsWith) {
      return allResourceTopics
    }

    const { cursor_next, topics } = topicsPage
    allTopics.push(...topics)
    currentCursor = cursor_next
  } while (currentCursor)

  if (!topicsWith) {
    allResourceTopics = allTopics
  }
  return allTopics
}

export { TOPIC_FRONT_END_PROPERTIES, clusterTopics, getTopicRoots, getTopicsTree, getAllResourceTopics, nameComparator }
