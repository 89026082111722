import compareAsc from 'date-fns/compareAsc'
import endOfDay from 'date-fns/endOfDay'
import formatDistance from 'date-fns/formatDistance'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import getDate from 'date-fns/getDate'
import getHours from 'date-fns/getHours'
import getMinutes from 'date-fns/getMinutes'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import isEqual from 'date-fns/isEqual'
import isToday from 'date-fns/isToday'
import isValid from 'date-fns/isValid'
import set from 'date-fns/set'
import startOfDay from 'date-fns/startOfDay'
import subMilliseconds from 'date-fns/subMilliseconds'

import format from 'date-fns-tz/format'

import { queryEvents } from '../api'
import { PARAM_KEY_CURSOR, PARAM_KEY_LIMIT } from '../arch/constants'
import model from '../model'

/**
 * Mapper that prepares
 * @param {object} event
 * @returns a version of the event which is suitable for display in event lists
 */
const eventStub = event => {
  const { household, posts } = event
  return model.event({
    ...event,

    // The event list expects stubs that use IDs instead of objects for the household and posts.
    householdId: household?.id,
    postIds: (posts || []).map(post => post.id)
  })
}

/**
 * Mapper that prepares an event model object for use as request payload.
 *
 * @param {object} event
 * @returns endpoint payload basis for that event
 */
const baseEventPayload = event => {
  const { type, name, allDay, startTime, endTime, location, video, attachments, notes } = event

  return {
    type,
    name,
    allDay,
    startTime: startTime?.toISOString(),
    endTime: endTime?.toISOString(),
    location,
    video,
    postIds: (attachments || []).map(attachment => attachment.post?.id).filter(postId => Boolean(postId)),
    notes
  }
}

/**
 * Mapper that prepares an event model object for use with `postEvent`.
 *
 * @param {object} event
 * @returns payload for `postEvent`
 */
const postEventPayload = event => {
  const { household } = event

  return {
    ...baseEventPayload(event),
    householdId: household?.id
  }
}

/**
 * Mapper that prepares an event model object for use with `patchEvent`.
 *
 * @param {object} event
 * @returns payload for `patchEvent`
 */
const patchEventPayload = event => {
  const { id } = event

  return {
    ...baseEventPayload(event),
    id
  }
}

const DAY_FORMAT = 'eee, MMM do'
const TIME_FORMAT = 'h:mm'
const DAY_TIME_FORMAT = `${DAY_FORMAT} ${TIME_FORMAT}`
const AM_PM_FORMAT = 'aaa' // As of date-fns 2.17.0.
const TIME_ZONE_FORMAT = 'zzz'
const TIME_AM_PM_FORMAT = `${TIME_FORMAT}${AM_PM_FORMAT}`
const DAY_TIME_AM_PM_FORMAT = `${DAY_FORMAT} ${TIME_AM_PM_FORMAT}`
const FULL_TIME_FORMAT = `${TIME_AM_PM_FORMAT} ${TIME_ZONE_FORMAT}`
const FULL_FORMAT = `${DAY_TIME_FORMAT}${AM_PM_FORMAT} ${TIME_ZONE_FORMAT}`

const EN_DASH = '–' // Yes, the en-dash.

/**
 * Takes an object with start/end datetimes and returns a boolean indicating if the dates are on
 * the same day.
 *
 * @param {eventSubset} event
 * @returns a boolean indicating if the event’s dates are on the same day
 */
const isSameDay = event => {
  // As a function for internal use only, we don’t need the same guards as the others.
  // We expect to fail loudly if bad values find their way here.
  const { startTime, endTime } = event

  const startDateString = format(startTime, DAY_FORMAT)
  const endDateString = format(endTime, DAY_FORMAT)
  return startDateString === endDateString
}

/**
 * Takes an object with an allDay boolean and start/end datetimes and returns a string reprseentation
 * of its date range.
 *
 * @param {eventSubset} event
 * @returns a string representing the date range
 */
const getDateRangeString = event => {
  const { startTime, endTime, allDay } = event || {}
  if (!isValid(startTime) || !isValid(endTime)) {
    return 'n/a'
  }

  const startDateString = format(startTime, DAY_FORMAT)
  const endDateString = format(endTime, DAY_FORMAT)
  const sameDay = isSameDay(event)
  if (allDay) {
    return sameDay ? startDateString : `${startDateString} ${EN_DASH} ${endDateString}`
  } else {
    if (isEqual(startTime, endTime)) {
      // Same bat time…same full bat format.
      return format(startTime, FULL_FORMAT)
    }

    if (sameDay) {
      const startAmPm = format(startTime, AM_PM_FORMAT)
      const endAmPm = format(endTime, AM_PM_FORMAT)
      const endFullString = format(endTime, FULL_TIME_FORMAT)
      return `${
        startAmPm === endAmPm ? `${format(startTime, DAY_TIME_FORMAT)}` : `${format(startTime, DAY_TIME_AM_PM_FORMAT)}`
      } ${EN_DASH} ${endFullString}`
    } else {
      return `${format(startTime, DAY_TIME_AM_PM_FORMAT)} ${EN_DASH} ${format(endTime, FULL_FORMAT)}`
    }
  }
}

/**
 * Takes an object with an allDay boolean and start/end datetimes and returns a string reprseentation
 * of just its date range if it spans within a single day.
 *
 * @param {eventSubset} event
 * @returns a string representing the date range
 */
const getTimeRangeString = event => {
  const { startTime, endTime } = event || {}
  if (!isValid(startTime) || !isValid(endTime)) {
    return 'n/a'
  }

  const sameDay = isSameDay(event)
  if (sameDay) {
    if (isEqual(startTime, endTime)) {
      return format(startTime, FULL_TIME_FORMAT)
    }

    const startAmPm = format(startTime, AM_PM_FORMAT)
    const endAmPm = format(endTime, AM_PM_FORMAT)
    const endFullString = format(endTime, FULL_TIME_FORMAT)
    return `${
      startAmPm === endAmPm ? `${format(startTime, TIME_FORMAT)}` : `${format(startTime, TIME_AM_PM_FORMAT)}`
    } ${EN_DASH} ${endFullString}`
  } else {
    return getDateRangeString(event)
  }
}

/**
 * Takes an object with an allDay boolean and start/end datetimes and returns a string reprseentation
 * of its date range, without the start date.
 *
 * @param {eventSubset} event
 * @returns a string representing the date range
 */
const getDateRangeStringWithoutStartDate = event => {
  // Implementation note: the logic flow here is very similar to `getDateString` but the final substitions
  // aren’t straightforward to parameterize. We can try to consolidate later.
  const { startTime, endTime, allDay } = event || {}
  if (!isValid(startTime) || !isValid(endTime)) {
    return 'n/a'
  }

  const endDateString = format(endTime, DAY_FORMAT)
  const sameDay = isSameDay(event)
  if (allDay) {
    return sameDay ? '' : `${EN_DASH} ${endDateString}`
  } else {
    if (isEqual(startTime, endTime)) {
      // Same bat time…same full bat format.
      return format(startTime, FULL_TIME_FORMAT)
    }

    if (sameDay) {
      const startAmPm = format(startTime, AM_PM_FORMAT)
      const endAmPm = format(endTime, AM_PM_FORMAT)
      const endFullString = format(endTime, FULL_TIME_FORMAT)
      return `${
        startAmPm === endAmPm ? `${format(startTime, TIME_FORMAT)}` : `${format(startTime, TIME_AM_PM_FORMAT)}`
      } ${EN_DASH} ${endFullString}`
    } else {
      return `${format(startTime, TIME_AM_PM_FORMAT)} ${EN_DASH} ${format(endTime, FULL_FORMAT)}`
    }
  }
}

/**
 * Takes an object with an allDay boolean and start/end datetimes and returns a string reprseentation
 * of its duration based on certain conventions.
 *
 * @param {eventSubset} event
 * @returns a string representing the duration
 */
const getDurationString = event => {
  const { startTime, endTime, allDay } = event || {}
  if (!isValid(startTime) || !isValid(endTime)) {
    return 'n/a'
  }

  return allDay
    ? formatDistanceStrict(startOfDay(startTime), endOfDay(endTime), { unit: 'day' })
    : formatDistance(startTime, endTime)
}

/**
 * Takes an array of objects with start datetimes and raturns an array of those objects grouped by
 * year, in the same order relative to each other in the original array.
 *
 * @param {eventSubset array} events
 * @returns array of objects with year and event array within
 */
const groupByYear = events => {
  const yearCache = {}
  return events.reduce((accumulator, currentEvent) => {
    const { startTime } = currentEvent
    if (typeof startTime === 'object' && isValid(startTime)) {
      const currentYear = getYear(startTime)
      const year = yearCache[currentYear]
      if (year) {
        year.events.push(currentEvent)
      } else {
        const newYearEntry = { year: currentYear, events: [currentEvent] }
        yearCache[currentYear] = newYearEntry
        accumulator.push(newYearEntry)
      }
    }

    return accumulator
  }, [])
}

/**
 * Takes an array of objects with start datetimes and raturns an array of those objects grouped by
 * month, in the same order relative to each other in the original array. A special group for “today”
 * is also allocated.
 *
 * Due to the one-off of today, this function also returns a `label` property for each entry for
 * easier rendering. `label` is either 'Today' or formatted as 'MMMM yyyy'.
 *
 * @param {eventSubset array} events
 * @returns array of objects with time period and event array within
 */
const groupByTodayAndMonths = events => {
  let todayEntry = null
  const monthCache = {}
  return events.reduce((accumulator, currentEvent) => {
    const { startTime } = currentEvent
    if (typeof startTime === 'object' && isValid(startTime)) {
      const today = isToday(startTime)
      const currentMonth = getMonth(startTime)
      const currentYear = getYear(startTime)
      if (today) {
        if (!todayEntry) {
          todayEntry = { month: currentMonth, year: currentYear, today: true, label: 'Today', events: [] }
          accumulator.push(todayEntry)
        }

        todayEntry.events.push(currentEvent)
      } else {
        const monthYearKey = `${currentYear}-${currentMonth}`
        const month = monthCache[monthYearKey]
        if (month) {
          month.events.push(currentEvent)
        } else {
          const newMonthEntry = {
            month: currentMonth,
            year: currentYear,
            label: format(startTime, 'MMMM yyyy'),
            events: [currentEvent]
          }

          monthCache[monthYearKey] = newMonthEntry
          accumulator.push(newMonthEntry)
        }
      }
    }

    return accumulator
  }, [])
}

/**
 * Takes just the date data of a given date object and applies it to another date object, preserving
 * the hours/minutes/seconds/milliseconds portion of that date.
 *
 * @param {Date} newDate
 * @param {Date} baseDateTime
 * @returns the resulting Date
 */
const applyDateToDateTime = (newDate, baseDateTime) => {
  if (!isValid(newDate) || !isValid(baseDateTime)) {
    return baseDateTime
  }

  return set(baseDateTime, { year: getYear(newDate), month: getMonth(newDate), date: getDate(newDate) })
}

/**
 * Takes just the time data of a given date object and applies it to another date object, preserving
 * the year/month/day portion of that date.
 *
 * Note that this truncates seconds and milliseconds as well—for the purposes of events, those should
 * be immaterial.
 *
 * @param {Date} newTime
 * @param {Date} baseDateTime
 * @returns the resulting Date
 */
const applyTimeToDateTime = (newTime, baseDateTime) => {
  if (!isValid(newTime) || !isValid(baseDateTime)) {
    return baseDateTime
  }

  return set(baseDateTime, { hours: getHours(newTime), minutes: getMinutes(newTime), seconds: 0, milliseconds: 0 })
}

/**
 * Returns whether or not a given string successfully parses as a URL.
 *
 * @param {possible URL} string
 * @returns whether the URL parsed successfully
 */
const isUrl = string => {
  try {
    new URL(string)
    return true
  } catch (error) {
    return false
  }
}

const DEFAULT_LIMIT = 20

/**
 * Standard event-loading function that interoperates with the `useLoadMore` hook.
 *
 * @param {URLSearchParams} params
 * @param {any} cursor
 * @param {number} limit
 * @returns data structure expected by `useLoadMore` when loading a page of data
 */
const loadEventsPage = async (params, cursor, limit = DEFAULT_LIMIT) => {
  params.set(PARAM_KEY_LIMIT, limit + 1)

  if (cursor) {
    params.set(PARAM_KEY_CURSOR, cursor)
  }

  const eventsPage = await queryEvents(params)
  const { cursor_next, events } = eventsPage
  const items = events.slice(0, limit)
  return {
    itemsPage: items,
    cursorNext: cursor_next && items[items.length - 1].sortKey
  }
}

/**
 * An event is considered upcoming if it starts today onward (even if it is now later in the day).
 *
 * @returns the standard cutoff date for inclusion in upcoming events
 */
const upcomingFromDate = () => new Date()

/**
 * An event is considered to be in the past if it started yesterday or before.
 *
 * @returns the standard cutoff date for inclusion in past events
 */
const pastToDate = () => subMilliseconds(upcomingFromDate(), 1)

/**
 * Custom sort comparator geared specifically for proper event cache behavior:
 * past events are sorted descending and upcoming events are sorted ascending,
 * based on the `endTime` property.
 *
 * @param {object} leftEvent any object with Date property `endTime`
 * @param {object} rightEvent any object with Date property `endTime`
 * @returns 1, 0, or -1 per the usual comparator expectation
 */
const compareEvents = (leftEvent, rightEvent) => {
  const cutoff = pastToDate()

  // Just in case the array has falsy values…
  const leftEnd = leftEvent?.endTime
  const rightEnd = rightEvent?.endTime

  const leftNowComparison = compareAsc(leftEnd, cutoff)
  const rightNowComparison = compareAsc(rightEnd, cutoff)
  const leftRightComparison = compareAsc(leftEnd, rightEnd)

  return leftNowComparison === rightNowComparison // Same side of now?
    ? leftNowComparison > 0 // If so, check if both before or both after
      ? leftRightComparison // Both after means ascending order.
      : -leftRightComparison // Both before means descending order.
    : leftRightComparison // If on different sides of now, then plain ascending order.
}

// See comment in hasFullEventsAccess below.
// const FULL_ACCESS_TIERS = [TIER_SELF, TIER_NAVIGATOR]

/**
 * Returns whether a given household has full access to events.
 *
 * @param {object} household
 * @returns boolean whether the given household has full access to events
 */
const hasFullEventsAccess = household => {
  // const { tier } = household || {}
  // return FULL_ACCESS_TIERS.includes(tier) // This way, falsy tiers are read-only.

  // Apparently this is true for all now—but keeping prior code above available for
  // now in case rules come up again.
  return true
}

export {
  eventStub,
  postEventPayload,
  patchEventPayload,
  isSameDay,
  getDateRangeString,
  getTimeRangeString,
  getDateRangeStringWithoutStartDate,
  getDurationString,
  groupByYear,
  groupByTodayAndMonths,
  applyDateToDateTime,
  applyTimeToDateTime,
  isUrl,
  loadEventsPage,
  upcomingFromDate,
  pastToDate,
  compareEvents,
  hasFullEventsAccess
}
