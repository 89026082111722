import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles'

import CircularProgress from '@mui/material/CircularProgress'

import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'

import { processSetupIntent } from '../api'
import { useAppUser } from '../app/hooks'
import { getHookPromiseErrorHandler } from '../common'
import {
  KICKSTART_CC_CAPTURE_LINEAR_ID,
  TRIAL_CC_CAPTURE_LINEAR_ID,
  KICKSTART_ONBOARD_CC_CAPTURE_LINEAR_ID,
  TRIAL_ONBOARD_CC_CAPTURE_LINEAR_ID
} from '../constants'
import { TRIAL_KICKSTART, TRIAL_PLATFORM, ONBOARD_KICKSTART, ONBOARD_PLATFORM } from '../onboarding/trial/constants'
import { trackPaymentCaptureViewed } from '../tracking/segment'

import BrandButton from '../subcomponents/brand/BrandButton'
import ErrorSnackbar from '../subcomponents/ErrorSnackbar'

import SetupIntentScaffold from './SetupIntentScaffold'

const useStyles = makeStyles(
  theme => ({
    progressContainer: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center'
    },

    progress: {
      color: theme.palette.common.white,
      margin: theme.spacing(-0.25, 0, 0)
    },

    cardForm: {
      display: 'flex',
      flexDirection: 'column'
    },

    submitButton: {
      borderRadius: theme.spacing(256),
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 20 / 18,
      margin: theme.spacing(5, 0, 0),
      padding: theme.spacing(2.375, 6)
    },

    content: {
      ...theme.typography.body1
    },

    title: {
      margin: 0
    },

    subtitle: {
      margin: theme.spacing(1, 0, 0)
    }
  }),
  {
    name: 'SetupPaymentIntent'
  }
)

const SetupForm = props => {
  const stripe = useStripe()
  const elements = useElements()
  const location = useLocation()

  const { state } = location ?? {}
  const { trialOption, onboardTrialOption } = state ?? {}

  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  const errorHandler = useMemo(() => getHookPromiseErrorHandler(setError), [])

  const linearIdMap = {
    [TRIAL_KICKSTART]: KICKSTART_CC_CAPTURE_LINEAR_ID,
    [TRIAL_PLATFORM]: TRIAL_CC_CAPTURE_LINEAR_ID,
    [ONBOARD_KICKSTART]: KICKSTART_ONBOARD_CC_CAPTURE_LINEAR_ID,
    [ONBOARD_PLATFORM]: TRIAL_ONBOARD_CC_CAPTURE_LINEAR_ID
  }

  const handleSubmit = async event => {
    setLoading(true)
    event.preventDefault()

    if (!stripe || !elements) {
      setLoading(false)
      return null
    }

    const queryParams = new URLSearchParams()

    if (trialOption) {
      queryParams.set('trialOption', trialOption)
    } else if (onboardTrialOption) {
      queryParams.set('onboardTrialOption', onboardTrialOption)
    }

    const linearId = linearIdMap[trialOption ?? onboardTrialOption]
    if (linearId) {
      queryParams.set('linearId', linearId)
    }

    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/app/pre-payment?${queryParams}`
      }
    })

    if (error) {
      errorHandler(error)
    }

    setLoading(false)
  }

  const classes = useStyles(props)

  return (
    <form className={classes.cardForm} onSubmit={handleSubmit}>
      <PaymentElement />

      <BrandButton
        classes={{ root: classes.submitButton }}
        disabled={!stripe || loading}
        endIcon={loading ? <CircularProgress className={classes.progress} size={16} /> : undefined}
        type="submit"
        variant="contained"
      >
        Submit
      </BrandButton>

      <ErrorSnackbar error={error} setError={setError} />
    </form>
  )
}

const SetupPaymentIntent = props => {
  const { location } = props

  const [intentData, setIntentData] = useState(null)
  const [loading, setLoading] = useState(false)

  const appUser = useAppUser()

  const { clientSecret } = intentData ?? {}

  const options = { clientSecret }

  useEffect(() => {
    let active = true

    if (appUser) {
      setLoading(true)
      const retrieveIntent = async () => {
        try {
          const { setupIntent } = await processSetupIntent()
          if (active) {
            setIntentData(setupIntent)
          }

          if (setupIntent.clientSecret) {
            trackPaymentCaptureViewed(location)
          }
        } catch (error) {
          setIntentData([])
        } finally {
          setLoading(false)
        }
      }
      retrieveIntent()
    }

    return () => {
      active = false
    }
  }, [appUser, location])

  const classes = useStyles(props)

  if (loading) {
    return (
      <section className={classes.progressContainer}>
        <CircularProgress />
      </section>
    )
  }

  return (
    <>
      <h1 className={classes.title}>Try now, pay later.</h1>

      <h3 className={classes.subtitle}>Only $9 a month after your free month, cancel anytime.</h3>

      <p className={classes.content}>
        Please provide your preferred payment method. Don’t worry—we’ll send you 2 email reminders before your
        subscription starts, and you’ll see remaining free trial days when logged in. Cancel anytime from the Accounts
        {' > '}
        Membership page.
      </p>

      {clientSecret ? (
        <SetupIntentScaffold options={options}>
          <SetupForm />
        </SetupIntentScaffold>
      ) : null}
    </>
  )
}

export default SetupPaymentIntent
