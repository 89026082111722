import PropTypes from 'prop-types'

import behavior from './assets/objective-behavior.svg'
import education from './assets/objective-education.svg'
import familySupport from './assets/objective-family-support.svg'
import financial from './assets/objective-financial.svg'
import general from './assets/objective-general.svg'
import social from './assets/objective-social.svg'
import insurance from './assets/objective-insurance.svg'
import medical from './assets/objective-medical.svg'

import {
  THEME_COLOR_GROUP_GREEN,
  THEME_COLOR_GROUP_NEUTRAL,
  THEME_COLOR_GROUP_PURPLE,
  THEME_COLOR_GROUP_YELLOW
} from './app/support/theme'

import { TIER_BASIC, TIER_FREE, TIER_NAVIGATOR, TIER_SELF, TIER_TRIAL } from './arch/constants'

const FULL_HOME_PATH = '/app/home'
// Explicitly includes ☝🏽 basename because this is used for direct window.location assignments.

const LABEL_ATTACH = 'Attach'
const LABEL_SAVE = 'Save'
const LABEL_EMAIL = 'Email'

const LAYOUT_PAGE = 'PAGE'
const LAYOUT_MODAL = 'MODAL'

const TAG_PROFILE = 'profile'
const TAG_PLAN = 'plan'

const SOURCE_PROPERTY = 'source'

const UNDIVIDED_EMAIL_SUFFIX = '@undivided.io'

const DATE_FORMAT = 'MMM d, yyyy'
const DATE_FORMAT_FULL = 'MMMM d, yyyy'
const DATE_FORMAT_ISO = 'yyyy-MM-dd'
const TIME_FORMAT = 'h:mm a'
const INPUT_DATE_FORMAT = 'MM/dd/yyyy'

const GET_DEBOUNCE_DELAY = 250
const PATCH_DEBOUNCE_DELAY = 500

// For design elements that show a placeholder upon deletion before actual removal…
const DELETION_PLACEHOLDER_DELAY = 1000
const DELETION_FADEOUT_DURATION = DELETION_PLACEHOLDER_DELAY // Make the fadeout just a tad quicker.

const DIAGNOSIS_SEVERITIES = [
  { display: '', value: '' },
  { display: 'Mild', value: 'Mild' },
  { display: 'Moderate', value: 'Moderate' },
  { display: 'Severe', value: 'Severe' },
  { display: 'Profound', value: 'Profound' }
]

const FALLBACK_CHILD_NAME = 'My Child'

const FAMILY_OBJECTIVE_TYPE_GENERAL = 'general'
const FAMILY_OBJECTIVE_TYPE_BEHAVIOR = 'behavioralHealth'
const FAMILY_OBJECTIVE_TYPE_EDUCATION = 'education'
const FAMILY_OBJECTIVE_TYPE_FAMILY_SUPPORT = 'familySupport'
const FAMILY_OBJECTIVE_TYPE_FINANCIAL = 'financialLegal'
const FAMILY_OBJECTIVE_TYPE_INSURANCE = 'insurance'
const FAMILY_OBJECTIVE_TYPE_MEDICAL = 'medical'
const FAMILY_OBJECTIVE_TYPE_SOCIAL = 'socialCommunity'

const FAMILY_OBJECTIVE_CATEGORIES = [
  {
    id: FAMILY_OBJECTIVE_TYPE_GENERAL,
    label: 'General',
    icon: general
  },

  {
    id: FAMILY_OBJECTIVE_TYPE_BEHAVIOR,
    label: 'Behavioral Health',
    icon: behavior
  },

  {
    id: FAMILY_OBJECTIVE_TYPE_EDUCATION,
    label: 'Education',
    icon: education
  },

  {
    id: FAMILY_OBJECTIVE_TYPE_FAMILY_SUPPORT,
    label: 'Family Support',
    icon: familySupport
  },

  {
    id: FAMILY_OBJECTIVE_TYPE_FINANCIAL,
    label: 'Financial/Legal',
    icon: financial
  },

  {
    id: FAMILY_OBJECTIVE_TYPE_INSURANCE,
    label: 'Insurance',
    icon: insurance
  },

  {
    id: FAMILY_OBJECTIVE_TYPE_MEDICAL,
    label: 'Medical',
    icon: medical
  },

  {
    id: FAMILY_OBJECTIVE_TYPE_SOCIAL,
    label: 'Social/Community',
    icon: social
  }
]

const FAMILY_OBJECTIVE_LOOKUP = FAMILY_OBJECTIVE_CATEGORIES.reduce((lookup, familyObjective) => {
  lookup[familyObjective.id] = familyObjective
  return lookup
}, {})

// TODO Temporary backward-compatibility tweaks for older stage data—should be removable shortly after deployment.
FAMILY_OBJECTIVE_LOOKUP['Financial/legal'] = FAMILY_OBJECTIVE_LOOKUP[FAMILY_OBJECTIVE_TYPE_FINANCIAL]
FAMILY_OBJECTIVE_LOOKUP['Social/community'] = FAMILY_OBJECTIVE_LOOKUP[FAMILY_OBJECTIVE_TYPE_SOCIAL]

const GENDERS = [
  { display: '', value: '' },
  { display: 'Female', value: 'Female' },
  { display: 'Male', value: 'Male' },
  { display: 'Non-binary', value: 'Non-binary' }
]

const GOAL_STATUS_ACTIVE = 'active'
const GOAL_STATUS_FUTURE = 'future'
const GOAL_STATUS_ACHIEVED = 'achieved'
const GOAL_STATUS_RESTART = 'Restart'
const GOAL_STATUS_ALREADY_COMPLETED = 'already completed'

// On the back end, draft is distinct from status, but the front end treats it as a status.
const GOAL_STATUS_DRAFT = 'draft'

const GOAL_STATUS_ALL = [GOAL_STATUS_ACTIVE, GOAL_STATUS_FUTURE, GOAL_STATUS_ACHIEVED, GOAL_STATUS_DRAFT]
const GOAL_STATUS_PUBLISHED = [GOAL_STATUS_FUTURE, GOAL_STATUS_ACTIVE, GOAL_STATUS_ACHIEVED]
const UNLOCKED_GOAL_STATUS = [GOAL_STATUS_DRAFT, GOAL_STATUS_ACTIVE, GOAL_STATUS_FUTURE]
const LOCKED_GOAL_STATUS_PUBLISHED = [GOAL_STATUS_FUTURE]
const LOCKED_GOAL_STATUS = [GOAL_STATUS_DRAFT, GOAL_STATUS_ACTIVE, ...LOCKED_GOAL_STATUS_PUBLISHED]

const GOAL_STATUS_MENU_OPTIONS = {
  [GOAL_STATUS_DRAFT]: [GOAL_STATUS_ACTIVE, GOAL_STATUS_FUTURE, GOAL_STATUS_ACHIEVED],
  [GOAL_STATUS_ACTIVE]: [GOAL_STATUS_FUTURE, GOAL_STATUS_ACHIEVED],
  [GOAL_STATUS_FUTURE]: [GOAL_STATUS_ACTIVE, GOAL_STATUS_ACHIEVED],
  [GOAL_STATUS_ACHIEVED]: [GOAL_STATUS_ACTIVE]
}

const GOAL_STATUSES_SNACKBAR_MESSAGE = {
  [GOAL_STATUS_ACTIVE]: 'Goal moved to In progress, and will now show on Home.',
  [GOAL_STATUS_FUTURE]: 'Goal paused, and removed from Home.',
  [GOAL_STATUS_ACHIEVED]: 'Goal marked complete.',
  [GOAL_STATUS_ALREADY_COMPLETED]:
    'Goal marked as already completed. It will be moved within the Previously completed section.'
}

const GOAL_STATUSES = {
  [GOAL_STATUS_ACTIVE]: {
    label: 'In Progress',
    backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N20,
    color: THEME_COLOR_GROUP_NEUTRAL.N500
  },

  [GOAL_STATUS_FUTURE]: {
    label: 'Pause',
    backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N20,
    color: THEME_COLOR_GROUP_NEUTRAL.N500
  },

  [GOAL_STATUS_ACHIEVED]: {
    label: 'Completed 🎉',
    backgroundColor: THEME_COLOR_GROUP_GREEN.G40,
    color: THEME_COLOR_GROUP_GREEN.G800,
    avatarColor: THEME_COLOR_GROUP_GREEN.G600
  },

  [GOAL_STATUS_DRAFT]: {
    label: 'Draft',
    backgroundColor: THEME_COLOR_GROUP_PURPLE.P40,
    color: THEME_COLOR_GROUP_NEUTRAL.N600
  }
}

const GOAL_MENU_STATUSES = {
  ...GOAL_STATUSES,
  [GOAL_STATUS_ACTIVE]: {
    ...GOAL_STATUSES[GOAL_STATUS_ACTIVE],
    label: 'Start'
  },

  [GOAL_STATUS_ACHIEVED]: {
    ...GOAL_STATUSES[GOAL_STATUS_ACHIEVED],
    label: 'Complete 🎉'
  }
}

const GOAL_STATUSES_COLOR = {
  ...GOAL_STATUSES,

  [GOAL_STATUS_ACTIVE]: {
    ...GOAL_STATUSES[GOAL_STATUS_ACTIVE],
    backgroundColor: THEME_COLOR_GROUP_YELLOW.Y10,
    color: THEME_COLOR_GROUP_YELLOW.Y600
  },

  [GOAL_STATUS_ACHIEVED]: {
    ...GOAL_STATUSES[GOAL_STATUS_ACHIEVED],
    backgroundColor: 'transparent',
    label: 'Complete'
  }
}

const GOAL_TYPE_STANDARD = 'standard'
const GOAL_TYPE_ADD_ON_SERVICE = 'addOnService'
const GOAL_TYPE_LEGACY = 'legacy'
const GOAL_TYPE_SUPER_GOAL = 'superGoal'
const GOAL_TYPE_FOUNDATIONAL = 'foundational'
const GOAL_TYPE_ANNUAL = 'annual'
const GOAL_TYPE_FIRST = 'first'

const HELP_LINK = 'https://lumpy-iodine-903.notion.site/3fa5c2fe0e7a4c619f4b0e252c516179'

const INVITATION_STATUS_PENDING = 'PENDING'
const INVITATION_STATUS_COMPLETE = 'COMPLETE'

const INVITATION_STATUS_LABELS = {
  [INVITATION_STATUS_PENDING]: 'Invited',
  [INVITATION_STATUS_COMPLETE]: 'Account Created'
}

const PHASES = [
  { display: '', value: '' },
  { display: 'I’m seeking a new diagnosis', value: 'newDx' },
  { display: 'We received a recent diagnosis(es)', value: 'recentDx' },
  { display: 'We’re managing pretty well', value: 'managing' },
  { display: 'We’re transitioning', value: 'transitioning' },
  { display: 'I need help, I’m overwhelmed', value: 'overwhelmed' },
  { display: 'I’m not sure', value: 'unsure' }
]

const DEFAULT_REQUEST_PRIORITY = 'normal'
const DEFAULT_REQUEST_SUBJECT = 'New Request'

const REQUEST_PRIORITIES = {
  normal: 'Next',
  need_asap: 'Top',
  if_we_have_time: 'Later',
  need_by_due_date: 'By Due Date'
}

const REQUEST_STATUS_CATEGORY_NEEDS_INPUT = 'needsInput'
const REQUEST_STATUS_CATEGORY_IN_PROGRESS = 'inProgress'
const REQUEST_STATUS_CATEGORY_REVIEW_AND_DELIVERY = 'reviewAndDelivery'
const REQUEST_STATUS_CATEGORY_NO_ACTIVITY = 'noActivity'

const REQUEST_STATUS_CATEGORIES = {
  [REQUEST_STATUS_CATEGORY_NEEDS_INPUT]: {
    categoryLabel: 'Reply Needed',
    color: '#e9ac0f'
  },

  [REQUEST_STATUS_CATEGORY_IN_PROGRESS]: {
    categoryLabel: 'In Progress',
    color: '#00b35a'
  },

  [REQUEST_STATUS_CATEGORY_REVIEW_AND_DELIVERY]: {
    categoryLabel: 'Review & Delivery',
    color: '#808080'
  },

  [REQUEST_STATUS_CATEGORY_NO_ACTIVITY]: {
    categoryLabel: 'Closed',
    color: '#b0b0b0'
  }
}

const REQUEST_STATUS_NEW = 'new'
const REQUEST_STATUS_OPEN = 'open'
const REQUEST_STATUS_PENDING = 'pending'
const REQUEST_STATUS_SOLVED = 'solved'
const REQUEST_STATUS_CLOSED = 'closed'

const REQUEST_STATUS_SEQUENCE = [
  REQUEST_STATUS_NEW,
  REQUEST_STATUS_PENDING,
  REQUEST_STATUS_OPEN,
  REQUEST_STATUS_SOLVED,
  REQUEST_STATUS_CLOSED
]

const REQUEST_STATUSES = {
  [REQUEST_STATUS_NEW]: {
    category: REQUEST_STATUS_CATEGORY_IN_PROGRESS,
    label: 'New'
  },

  [REQUEST_STATUS_OPEN]: {
    category: REQUEST_STATUS_CATEGORY_IN_PROGRESS,
    label: 'Open'
  },

  [REQUEST_STATUS_PENDING]: {
    category: REQUEST_STATUS_CATEGORY_NEEDS_INPUT,
    label: 'Pending'
  },

  [REQUEST_STATUS_SOLVED]: {
    category: REQUEST_STATUS_CATEGORY_REVIEW_AND_DELIVERY,
    label: 'Solved'
  },

  [REQUEST_STATUS_CLOSED]: {
    category: REQUEST_STATUS_CATEGORY_NO_ACTIVITY,
    label: 'Closed'
  }
}

const ASSET_SIZE_ORIGINAL = 'original'
const ASSET_SIZE_1X = '1x'
const ASSET_SIZE_2X = '2x'
const ASSET_SIZE_3X = '3x'
const ALL_ASSET_SIZES = [ASSET_SIZE_ORIGINAL, ASSET_SIZE_1X, ASSET_SIZE_2X, ASSET_SIZE_3X]

const CALENDAR_TYPE_SHARED = 'shared'
const CALENDAR_TYPE_PERSONAL = 'personal'

const SUPPORT_EMAIL_ADDRESS = 'support@undivided.io'

const SIGNUP_STARTED = 'getStarted'
const SIGNUP_CREATE_ACCOUNT = 'createAccount'

const TIERS = {
  [TIER_TRIAL]: {
    label: 'Trial',
    upgradable: true
  },

  [TIER_FREE]: {
    label: 'Free'
  },

  [TIER_BASIC]: {
    label: 'Standard'
  },

  [TIER_SELF]: {
    label: 'Platform',
    paid: true
  },

  [TIER_NAVIGATOR]: {
    label: 'Navigator',
    paid: true
  }
}

const ALL_TIERS = Object.keys(TIERS)

// Thank you https://stackoverflow.com/questions/48007326/what-is-the-correct-proptype-for-a-ref-in-react
const PROP_TYPE_REF = PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])

const TRIAL_CC_CAPTURE_LINEAR_ID = 'UND-2956'
const KICKSTART_CC_CAPTURE_LINEAR_ID = 'UND-3207'
const TRIAL_ONBOARD_CC_CAPTURE_LINEAR_ID = 'UND-3308'
const KICKSTART_ONBOARD_CC_CAPTURE_LINEAR_ID = 'UND-3309'

export {
  FULL_HOME_PATH,
  LABEL_ATTACH,
  LABEL_SAVE,
  LABEL_EMAIL,
  LAYOUT_PAGE,
  LAYOUT_MODAL,
  TAG_PROFILE,
  TAG_PLAN,
  SOURCE_PROPERTY,
  UNDIVIDED_EMAIL_SUFFIX,
  DATE_FORMAT,
  DATE_FORMAT_FULL,
  DATE_FORMAT_ISO,
  TIME_FORMAT,
  INPUT_DATE_FORMAT,
  GET_DEBOUNCE_DELAY,
  PATCH_DEBOUNCE_DELAY,
  DELETION_PLACEHOLDER_DELAY,
  DELETION_FADEOUT_DURATION,
  DIAGNOSIS_SEVERITIES,
  FALLBACK_CHILD_NAME,
  FAMILY_OBJECTIVE_TYPE_GENERAL,
  FAMILY_OBJECTIVE_TYPE_BEHAVIOR,
  FAMILY_OBJECTIVE_TYPE_EDUCATION,
  FAMILY_OBJECTIVE_TYPE_FAMILY_SUPPORT,
  FAMILY_OBJECTIVE_TYPE_FINANCIAL,
  FAMILY_OBJECTIVE_TYPE_INSURANCE,
  FAMILY_OBJECTIVE_TYPE_MEDICAL,
  FAMILY_OBJECTIVE_TYPE_SOCIAL,
  FAMILY_OBJECTIVE_CATEGORIES,
  FAMILY_OBJECTIVE_LOOKUP,
  GENDERS,
  GOAL_STATUS_ACTIVE,
  GOAL_STATUS_FUTURE,
  GOAL_STATUS_ACHIEVED,
  GOAL_STATUS_DRAFT,
  GOAL_STATUS_ALREADY_COMPLETED,
  GOAL_STATUS_ALL,
  GOAL_STATUS_PUBLISHED,
  GOAL_STATUSES,
  GOAL_STATUSES_COLOR,
  GOAL_STATUS_MENU_OPTIONS,
  GOAL_MENU_STATUSES,
  GOAL_TYPE_STANDARD,
  GOAL_TYPE_ADD_ON_SERVICE,
  GOAL_TYPE_LEGACY,
  GOAL_TYPE_SUPER_GOAL,
  GOAL_TYPE_FOUNDATIONAL,
  GOAL_TYPE_ANNUAL,
  GOAL_TYPE_FIRST,
  HELP_LINK,
  INVITATION_STATUS_PENDING,
  INVITATION_STATUS_COMPLETE,
  INVITATION_STATUS_LABELS,
  PHASES,
  PROP_TYPE_REF,
  DEFAULT_REQUEST_PRIORITY,
  DEFAULT_REQUEST_SUBJECT,
  REQUEST_STATUS_CATEGORY_NEEDS_INPUT,
  REQUEST_STATUS_CATEGORY_IN_PROGRESS,
  REQUEST_STATUS_CATEGORY_REVIEW_AND_DELIVERY,
  REQUEST_STATUS_CATEGORY_NO_ACTIVITY,
  REQUEST_STATUS_CATEGORIES,
  REQUEST_PRIORITIES,
  REQUEST_STATUS_SEQUENCE,
  REQUEST_STATUSES,
  CALENDAR_TYPE_PERSONAL,
  CALENDAR_TYPE_SHARED,
  ASSET_SIZE_ORIGINAL,
  ASSET_SIZE_1X,
  ASSET_SIZE_2X,
  ASSET_SIZE_3X,
  ALL_ASSET_SIZES,
  SUPPORT_EMAIL_ADDRESS,
  SIGNUP_STARTED,
  SIGNUP_CREATE_ACCOUNT,
  TIERS,
  ALL_TIERS,
  UNLOCKED_GOAL_STATUS,
  LOCKED_GOAL_STATUS,
  LOCKED_GOAL_STATUS_PUBLISHED,
  GOAL_STATUS_RESTART,
  GOAL_STATUSES_SNACKBAR_MESSAGE,
  TRIAL_CC_CAPTURE_LINEAR_ID,
  KICKSTART_CC_CAPTURE_LINEAR_ID,
  TRIAL_ONBOARD_CC_CAPTURE_LINEAR_ID,
  KICKSTART_ONBOARD_CC_CAPTURE_LINEAR_ID
}
