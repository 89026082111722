// These, of course, are beyond our architecture 🧐
const HTTP_OK = 200
const HTTP_CREATED = 201
const HTTP_ACCEPTED = 202
const HTTP_NO_CONTENT = 204
const HTTP_BAD_REQUEST = 400
const HTTP_UNAUTHORIZED = 401
const HTTP_FORBIDDEN = 403
const HTTP_NOT_FOUND = 404
const HTTP_CONFLICT = 409
const HTTP_TOO_MANY_REQUESTS = 429
const HTTP_LOCKED = 423

const OTP_LENGTH = 6
const OTP_RESEND_AFTER_MINUTES = 5

const BASE_API_REQUEST_HEADER = {
  'Content-Type': 'application/json'
}

// No GET nor PUT because the code doesn’t use those more than once.
const METHOD_POST = 'POST'
const METHOD_DELETE = 'DELETE'

// Parameter keys used by the API for pagination.
const PARAM_KEY_CURSOR = 'cursor'
const PARAM_KEY_PAGE = 'page'
const PARAM_KEY_LIMIT = 'limit'
const PARAM_KEY_OFFSET = 'offset'

const SORT_ASC = 'asc'
const SORT_DESC = 'desc'

const TYPE_ROOT = 'Document'
const INFO_BLOCK_ROOT = 'InfoBlock'
const UNFINISHED_ROOT = 'Draft'

const PRIVACY_PUBLIC = 'fullyVisible'
const PRIVACY_PRIVATE = 'visibleOnlyToMe'

const STATUS_DRAFT = 'DRAFT'
const STATUS_PUBLISHED = 'PUBLISHED'

const ROLE_CERTIFIED = 'CERTIFIED'
const ROLE_NAVIGATOR = 'NAVIGATOR'
const ROLE_ADMIN = 'ADMIN'
const ROLE_SUPERUSER = 'SUPERUSER'
const ROLE_DEMO = 'DEMO'

const CAN_BE_NAVIGATOR = [ROLE_NAVIGATOR]
const CAN_BE_ADMIN = [ROLE_ADMIN]
const CAN_BE_SUPERUSER = [ROLE_SUPERUSER]
const CAN_BE_CERTIFIED = [ROLE_CERTIFIED]
const CAN_PROVIDE_SERVICES = [...CAN_BE_NAVIGATOR, ...CAN_BE_CERTIFIED]
const IS_DEMO = [ROLE_DEMO]

const TIER_NAVIGATOR = 'navigator'
const TIER_SELF = 'platform'
const TIER_TRIAL = 'trial'
const TIER_FREE = 'free'
const TIER_BASIC = 'basic'

const SHARED_NAV_USERNAME = 'shared-nav'

export {
  HTTP_OK,
  HTTP_CREATED,
  HTTP_ACCEPTED,
  HTTP_NO_CONTENT,
  HTTP_BAD_REQUEST,
  HTTP_UNAUTHORIZED,
  HTTP_FORBIDDEN,
  HTTP_NOT_FOUND,
  HTTP_CONFLICT,
  HTTP_TOO_MANY_REQUESTS,
  HTTP_LOCKED,
  METHOD_POST,
  METHOD_DELETE,
  BASE_API_REQUEST_HEADER,
  PARAM_KEY_CURSOR,
  PARAM_KEY_PAGE,
  PARAM_KEY_LIMIT,
  PARAM_KEY_OFFSET,
  SORT_ASC,
  SORT_DESC,
  TYPE_ROOT,
  INFO_BLOCK_ROOT,
  UNFINISHED_ROOT,
  PRIVACY_PUBLIC,
  PRIVACY_PRIVATE,
  STATUS_DRAFT,
  STATUS_PUBLISHED,
  CAN_BE_NAVIGATOR,
  CAN_BE_ADMIN,
  CAN_BE_SUPERUSER,
  CAN_BE_CERTIFIED,
  CAN_PROVIDE_SERVICES,
  ROLE_CERTIFIED, // Exported because invitation confirmations need a direct check.
  ROLE_NAVIGATOR, // Exported because invitation needs to search by navigator.
  IS_DEMO,
  TIER_NAVIGATOR,
  TIER_SELF,
  TIER_TRIAL,
  TIER_FREE,
  TIER_BASIC,
  ROLE_ADMIN,
  ROLE_SUPERUSER,
  OTP_LENGTH,
  OTP_RESEND_AFTER_MINUTES,
  SHARED_NAV_USERNAME
}
