import React from 'react'

import SvgIcon from '@mui/material/SvgIcon'

const Calendar = props => (
  <SvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
    <path d="M11.9 1.27273H13.3636C13.5324 1.27273 13.6943 1.33977 13.8136 1.45911C13.933 1.57846 14 1.74032 14 1.90909V3.85H0V1.90909C0 1.74032 0.0670452 1.57846 0.186387 1.45911C0.305728 1.33977 0.46759 1.27273 0.636364 1.27273H2.1V1.05C2.1 0.470101 2.5701 0 3.15 0L3.16797 0.000150604L3.18182 0C3.21825 0 3.25436 0.00312428 3.28979 0.00922448C3.8036 0.0775789 4.2 0.517498 4.2 1.05V1.27273H9.8V1.05C9.8 0.517497 10.1964 0.0775772 10.7102 0.00922408C10.7456 0.00312415 10.7818 0 10.8182 0C10.8228 0 10.8274 5.02995e-05 10.832 0.000150596C10.838 5.03316e-05 10.844 0 10.85 0C11.4299 0 11.9 0.470101 11.9 1.05V1.27273ZM0.186387 13.8136C0.0670452 13.6943 0 13.5324 0 13.3636V5.25H14V13.3636C14 13.5324 13.933 13.6943 13.8136 13.8136C13.6943 13.933 13.5324 14 13.3636 14H0.636364C0.46759 14 0.305728 13.933 0.186387 13.8136Z" />
  </SvgIcon>
)

export default Calendar
