/**
 * The constants listed here are specific to capabilities and being in the _arch_ module
 * these constants also have meaning on the server side.
 */
// TODO As of now, capabilities are actually not implemented on the server side yet, so
//      some of these constants may change. We are implementing them as an abstraction
//      layer in the household object in order to ease the transition to true, server-
//      side capabilities one day.
const CAPABILITY_ACCESS_MEMBERSHIP = 'canAccessMembership'
const CAPABILITY_ADD_CHILD = 'canAddChild'
const CAPABILITY_ADD_NAVIGATOR = 'canAddNavigator'
const CAPABILITY_ADD_TO_BINDER = 'canAddToBinder'
const CAPABILITY_BECOME_MEMBER = 'canBecomeMember'
const CAPABILITY_HAVE_MEMBERSHIP = 'canHaveMembership'
const CAPABILITY_PLAN = 'plan'
const CAPABILITY_SAVE = 'canSave'
const CAPABILITY_CONFIRM_EMAIL = 'canConfirmEmail'
const CAPABILITY_SEND_MESSAGE = 'canSendMessage'
const CAPABILITY_VIEW_BINDER = 'canViewBinder'
const CAPABILITY_VIEW_GOAL = 'canViewGoal'
const CAPABILITY_VIEW_MESSAGES = 'canViewMessages'
const CAPABILITY_VIEW_ROADMAP = 'canViewRoadmap'
const CAPABILITY_RESTART_NAVIGATOR = 'canRestartNavigator'
const CAPABILITY_START_NAVIGATOR_TRIAL = 'canStartNavTrial'
const CAPABILITY_VIEW_PRIMARY_UI = 'canViewPrimaryUI'
const CAPABILITY_VIEW_CREATE_ROADMAP = 'canViewCreateRoadmap'
const CAPABILITY_REFER = 'canRefer'
const CAPABILITY_CANCEL_PENDING_SUBSCRIPTION = 'canCancelPendingSubscription'

export {
  CAPABILITY_ACCESS_MEMBERSHIP,
  CAPABILITY_ADD_CHILD,
  CAPABILITY_ADD_NAVIGATOR,
  CAPABILITY_ADD_TO_BINDER,
  CAPABILITY_BECOME_MEMBER,
  CAPABILITY_HAVE_MEMBERSHIP,
  CAPABILITY_PLAN,
  CAPABILITY_SAVE,
  CAPABILITY_SEND_MESSAGE,
  CAPABILITY_VIEW_BINDER,
  CAPABILITY_VIEW_GOAL,
  CAPABILITY_VIEW_MESSAGES,
  CAPABILITY_VIEW_ROADMAP,
  CAPABILITY_CONFIRM_EMAIL,
  CAPABILITY_RESTART_NAVIGATOR,
  CAPABILITY_START_NAVIGATOR_TRIAL,
  CAPABILITY_VIEW_PRIMARY_UI,
  CAPABILITY_VIEW_CREATE_ROADMAP,
  CAPABILITY_REFER,
  CAPABILITY_CANCEL_PENDING_SUBSCRIPTION
}
