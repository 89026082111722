/**
 * ScheduleCall is a component for scheduling a membership call via Calendly. Because its
 * design (as of this writing) puts it in a dialog/modal, although it somewhat resembles
 * OnboardingContainer there are still notable differences in style, so it does not reuse
 * styles/components from there. A little damp but what can ya do 🤷🏽‍♂️
 */
import React, { useEffect, useMemo, useState } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import CircularProgress from '@mui/material/CircularProgress'

import { postSchedulingRelay } from '../../api'
import { useAppHouseholdRefresh, useAppUserRefresh, useCurrentHousehold } from '../../app/hooks'
import { THEME_COLOR_GROUP_LINK, THEME_COLOR_GROUP_NEUTRAL } from '../../app/support/theme'
import { getHookPromiseErrorHandler } from '../../common'
import { userDialogStyles } from '../../commonStyles'

import ErrorSnackbar from '../../subcomponents/ErrorSnackbar'
import LinkButton from '../../subcomponents/LinkButton'

import CalendlyEmbed from './CalendlyEmbed'
import HelpPrompt from './HelpPrompt'

const useStyles = makeStyles(
  theme => ({
    ...userDialogStyles(theme),

    root: {
      display: 'flex',
      flexGrow: 1
    },

    main: {
      flexGrow: 1
    },

    copy: {
      margin: theme.spacing(0, 0, 1)
    },

    title: {
      margin: theme.spacing(0, 0, 2.5)
    },

    instructions: {
      ...theme.typography.body1,
      fontSize: theme.typography.pxToRem(20)
    },

    actions: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%'
    },

    skipMessage: {},

    skipButton: {
      ...theme.typography.body2
    },

    button: {
      padding: theme.spacing(1.625, 3, 1.5)
    },

    scheduleHeading: {
      marginBottom: 'unset',
      marginLeft: theme.spacing(3)
    },

    kickstartCtaContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
      justifyContent: 'space-between',
      paddingTop: theme.spacing(1),

      [theme.breakpoints.up('brandSmaller')]: {
        flexDirection: 'row'
      }
    },

    kickstartCta: {
      ...theme.typography.body2,

      color: THEME_COLOR_GROUP_NEUTRAL.N500,
      fontWeight: theme.typography.fontWeightMedium,

      '&:visited': {
        color: THEME_COLOR_GROUP_NEUTRAL.N500
      },

      '&:hover': {
        color: THEME_COLOR_GROUP_LINK.hover
      },

      [theme.breakpoints.up('brandSmall')]: {
        ...theme.typography.body1,

        fontWeight: theme.typography.fontWeightMedium
      }
    },

    progressContainer: {
      ...theme.typography.body1,

      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      fontWeight: theme.typography.fontWeightMedium,
      gap: theme.spacing(2),
      justifyContent: 'center',
      padding: theme.spacing(4),

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    },

    progress: {},
    progressMessage: {},

    helpPrompt: {},
    helpLink: {}
  }),
  { name: 'ScheduleCall' }
)

const SRC_CALENDLY = 'https://assets.calendly.com/assets/external/widget.js'

const EVENT_CALENDLY = 'calendly'
const EVENT_SCHEDULED = `${EVENT_CALENDLY}.event_scheduled`
const EVENT_VIEWED = `${EVENT_CALENDLY}.event_type_viewed`

const appendCalendlyScriptIfNeeded = () => {
  const head = document.querySelector('head')
  const existingCalendlyScript = head.querySelector(`script[src='${SRC_CALENDLY}']`)
  if (!existingCalendlyScript) {
    const script = document.createElement('script')
    script.setAttribute('src', SRC_CALENDLY)
    head.appendChild(script)
  }
}

const isCalendlyEvent = dataEvent => dataEvent && dataEvent.indexOf(EVENT_CALENDLY) === 0

const MODE_LOADING = 'loading'
const MODE_SCHEDULING = 'scheduling'
const MODE_CONFIRMING = 'confirming'
const MODE_SCHEDULED = 'scheduled'

const CALENDLY_EXCLUSION_MODES = [MODE_CONFIRMING, MODE_SCHEDULED]

// The kickstart event does not “land” in the back end predictably, so we impose a
// (yes, somewhat arbitrary and not a best practice at all) delay.
const RELAY_DELAY = 2000

const ScheduleCall = props => {
  const { onSchedule, onClose, CalendlyEmbedProps } = props

  const household = useCurrentHousehold()
  const { id } = household ?? {}

  const refreshAppUser = useAppUserRefresh()
  const refreshAppHousehold = useAppHouseholdRefresh(id)

  const [mode, setMode] = useState(MODE_LOADING)
  const [error, setError] = useState()

  const handleApiError = useMemo(() => getHookPromiseErrorHandler(setError), [])

  useEffect(() => {
    appendCalendlyScriptIfNeeded()

    const handleCalendlyEvent = async event => {
      const { data } = event
      const { event: dataEvent } = data ?? {}

      if (!isCalendlyEvent(dataEvent)) {
        return
      }

      if (dataEvent === EVENT_VIEWED) {
        setMode(MODE_SCHEDULING)
      } else if (dataEvent === EVENT_SCHEDULED) {
        setMode(MODE_CONFIRMING)

        try {
          const patch = await postSchedulingRelay({ q: data.payload.event.uri })
          if (patch) {
            // A successful schedule redoes a bunch of capabilities, so we need to refresh the user
            // and household after this.
            await Promise.all([refreshAppUser(), refreshAppHousehold()])

            // Wait a little longer before sending feedback because the kickstart event is not
            // available right away.
            setTimeout(() => {
              setMode(MODE_SCHEDULED)

              if (onSchedule) {
                onSchedule(event, patch)
              }
            }, RELAY_DELAY)
          } else {
            setError('Sorry an error occurred when scheduling this event.')
          }
        } catch (error) {
          handleApiError(error)
        }
      }
    }

    window.addEventListener('message', handleCalendlyEvent)

    return () => {
      window.removeEventListener('message', handleCalendlyEvent)
    }
  }, [handleApiError, onSchedule, refreshAppUser, refreshAppHousehold])

  const handleClick = event => {
    if (!event.currentTarget.href && onClose) {
      onClose(event)
    }
  }

  const classes = useStyles(props)
  return (
    <article className={classes.root}>
      <section className={classes.main}>
        <h1 className={classes.title}>Schedule a free Kickstart</h1>

        {mode !== MODE_SCHEDULED && (
          <p className={clsx(classes.copy, classes.instructions)}>
            Customize your Roadmap with the 1:1 support of a Navigator.
          </p>
        )}

        {!CALENDLY_EXCLUSION_MODES.includes(mode) && (
          <CalendlyEmbed onError={onClose} {...(CalendlyEmbedProps ?? {})} />
        )}

        {mode === MODE_CONFIRMING && (
          <section className={classes.progressContainer}>
            <CircularProgress className={classes.progress} />
            <span className={classes.progressMessage}>Confirming your kickstart…</span>
          </section>
        )}

        {mode === MODE_SCHEDULING && (
          <section className={classes.kickstartCtaContainer}>
            <LinkButton classes={{ root: classes.kickstartCta }} onClick={handleClick}>
              Not at this time
            </LinkButton>

            <HelpPrompt classes={{ root: classes.helpPrompt, helpLink: clsx(classes.helpPrompt, classes.helpLink) }} />
          </section>
        )}

        {mode !== MODE_SCHEDULING && (
          <section className={classes.actions}>
            {mode === MODE_LOADING && (
              <span className={classes.skipMessage}>
                If you find that you are unable to schedule due to an error or some other issue, please
                <LinkButton classes={{ label: classes.skipButton }} onClick={onClose}>
                  skip this step
                </LinkButton>
                and contact Undivided.
              </span>
            )}
          </section>
        )}
      </section>

      <ErrorSnackbar error={error} setError={setError} />
    </article>
  )
}

ScheduleCall.propTypes = {
  onSchedule: PropTypes.func, // The function to call when scheduling is successful.
  onClose: PropTypes.func, // The function to call when the user dismisses the scheduler.
  CalendlyEmbedProps: PropTypes.object // props to relay to Calendly embed
}

export default ScheduleCall
