/**
 * Ribbon is a static display with a particular ribbon-like background, hence the name.
 *
 * TODO This is not yet 100% generalizable—if `children` is anything other than simple text
 *      or if the CSS is customized, the ribbon effect will not adjust automatically.
 */
import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import { THEME_COLOR_GROUP_NEUTRAL } from '../app/support/theme'

const dimensionToBasis = dimension => dimension / 8 - 0.0625
const basisToMargin = basis => basis + 0.375
const marginToPosition = margin => -margin

// TODO Allow this to be customizable as a prop.
const DEFAULT_RIBBON_DIMENSION_MOBILE = 13
const DEFAULT_BASIS_MOBILE = dimensionToBasis(DEFAULT_RIBBON_DIMENSION_MOBILE)
const ROOT_MARGIN_MOBILE = basisToMargin(DEFAULT_BASIS_MOBILE)
const RIBBON_POSITION_MOBILE = marginToPosition(ROOT_MARGIN_MOBILE)

const DEFAULT_RIBBON_DIMENSION_DESKTOP = 14
const DEFAULT_BASIS_DESKTOP = dimensionToBasis(DEFAULT_RIBBON_DIMENSION_DESKTOP)
const ROOT_MARGIN_DESKTOP = basisToMargin(DEFAULT_BASIS_DESKTOP)
const RIBBON_POSITION_DESKTOP = marginToPosition(ROOT_MARGIN_DESKTOP)

const CONTENT_PADDING = 1.5
const DEFAULT_RIBBON_COLOR = THEME_COLOR_GROUP_NEUTRAL.N50

const commonRibbonStyles = (theme, props) => ({
  borderColor: props.ribbonColor,
  borderStyle: 'solid',
  borderWidth: theme.spacing(DEFAULT_BASIS_MOBILE),
  content: '""',
  height: theme.spacing(DEFAULT_BASIS_MOBILE * 2),
  position: 'absolute',

  [theme.breakpoints.up('sm')]: {
    borderWidth: theme.spacing(DEFAULT_BASIS_DESKTOP),
    height: theme.spacing(DEFAULT_BASIS_DESKTOP * 2)
  }
})

const useStyles = makeStyles(
  theme => ({
    root: props => ({
      ...theme.typography.body2,

      display: 'flex',
      fontWeight: theme.typography.fontWeightMedium,
      margin: theme.spacing(0, ROOT_MARGIN_MOBILE, 0, 0),
      position: 'relative',

      '&:after': {
        ...commonRibbonStyles(theme, props),

        borderRightColor: 'transparent',
        right: theme.spacing(RIBBON_POSITION_MOBILE)
      },

      [theme.breakpoints.up('sm')]: {
        // This needs to be restated because this block overwrites the original media query.
        ...theme.typography.body2[theme.breakpoints.up('sm')],

        margin: theme.spacing(0, ROOT_MARGIN_DESKTOP, 0, 0),

        '&:after': {
          right: theme.spacing(RIBBON_POSITION_DESKTOP)
        }
      }
    }),

    rootDouble: props => ({
      marginLeft: theme.spacing(ROOT_MARGIN_MOBILE),

      '&:before': {
        ...commonRibbonStyles(theme, props),

        borderLeftColor: 'transparent',
        left: theme.spacing(RIBBON_POSITION_MOBILE)
      },

      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(ROOT_MARGIN_DESKTOP),

        '&:before': {
          left: theme.spacing(RIBBON_POSITION_DESKTOP)
        }
      }
    }),

    content: props => ({
      backgroundColor: props.ribbonColor,
      padding: theme.spacing(0.25, CONTENT_PADDING, 0.125, 1)
    }),

    contentDouble: {
      // This override doesn’t appear to take without the dreaded !important, so here it is 🫤
      paddingLeft: `${theme.spacing(CONTENT_PADDING)} !important`
    }
  }),
  {
    name: 'Ribbon'
  }
)

const Ribbon = props => {
  const { className, children, double, ribbonColor = DEFAULT_RIBBON_COLOR } = props

  const classes = useStyles({ ...props, ribbonColor })
  return (
    <span className={clsx(classes.root, double && classes.rootDouble, className)}>
      <span className={clsx(classes.content, double && classes.contentDouble)}>{children}</span>
    </span>
  )
}

Ribbon.propTypes = {
  className: PropTypes.string,
  double: PropTypes.bool, // Whether the ribbon design should be applied to both ends.
  ribbonColor: PropTypes.string // Has to be a prop because it is used in multiple places.
}

export default Ribbon
