import { createContext } from 'react'

// For uniformity and coordination, top-level onboarding components read and write the current
// onboarding object.
const OnboardingStatus = createContext({
  onboarding: null,
  setOnboarding: onboarding => {},
  sequence: [],
  setSequence: sequence => {},

  // Open-ended object that onboarding components can check for specific/custom functionality.
  additionalProperties: {}
})

export { OnboardingStatus }
