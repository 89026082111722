import React from 'react'

import SvgIcon from '@mui/material/SvgIcon'

const Community = props => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path d="M16.0285 13.674C15.9691 13.773 15.8909 13.859 15.7986 13.9269C14.707 14.7593 13.4165 15.2782 12.0615 15.4295V17.2488H15.1816C15.4094 17.2488 15.6279 17.341 15.789 17.5052C15.9501 17.6694 16.0406 17.8922 16.0406 18.1244C16.0406 18.3566 15.9501 18.5793 15.789 18.7435C15.6279 18.9077 15.4094 19 15.1816 19H7.22438C6.99657 19 6.7781 18.9077 6.61701 18.7435C6.45593 18.5793 6.36543 18.3566 6.36543 18.1244C6.36543 17.8922 6.45593 17.6694 6.61701 17.5052C6.7781 17.341 6.99657 17.2488 7.22438 17.2488H10.3436V15.431C6.49943 14.9917 3.5 11.6593 3.5 7.62544C3.5002 6.35093 3.8046 5.09561 4.38693 3.96779C4.96926 2.83997 5.81202 1.87353 6.84252 1.15185C7.03026 1.02154 7.26098 0.972332 7.48422 1.015C7.70746 1.05766 7.90503 1.18873 8.03372 1.37952C8.1624 1.57031 8.21172 1.80529 8.17089 2.03305C8.13005 2.26081 8.00239 2.46282 7.81582 2.59487C7.16012 3.0538 6.60072 3.64136 6.17032 4.32321C5.73992 5.00505 5.44716 5.76751 5.30913 6.56603C5.17111 7.36454 5.1906 8.18309 5.36646 8.97384C5.54232 9.76459 5.87103 10.5117 6.33337 11.1714C6.79571 11.8312 7.38241 12.3904 8.05919 12.8164C8.73596 13.2423 9.48923 13.5265 10.275 13.6523C11.0607 13.778 11.8632 13.7429 12.6354 13.5489C13.4077 13.3549 14.1343 13.006 14.7728 12.5224C14.8631 12.4517 14.9663 12.4001 15.0764 12.3704C15.1865 12.3407 15.3012 12.3337 15.414 12.3497C15.5268 12.3656 15.6353 12.4043 15.7333 12.4634C15.8313 12.5225 15.9168 12.6009 15.9848 12.694C16.0528 12.7871 16.1019 12.8931 16.1294 13.0057C16.1569 13.1184 16.1621 13.2355 16.1448 13.3502C16.1274 13.4649 16.0879 13.575 16.0285 13.674Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.81831 10.568C6.2375 9.68169 5.92754 8.63975 5.92763 7.5739C5.92763 6.14483 6.48452 4.77429 7.47578 3.76378C8.46704 2.75328 9.81147 2.18558 11.2133 2.18558C12.2589 2.18548 13.281 2.50146 14.1504 3.09355C15.0197 3.68564 15.6974 4.52726 16.0975 5.51196C16.4977 6.49665 16.6024 7.58021 16.3984 8.62558C16.1945 9.67096 15.691 10.6312 14.9517 11.3849C14.2124 12.1385 13.2704 12.6518 12.245 12.8597C11.2195 13.0676 10.1566 12.9609 9.19066 12.5529C8.22471 12.145 7.39913 11.4542 6.81831 10.568ZM9.24503 5.5479C9.06503 5.62543 8.90178 5.73834 8.7646 5.8802C8.48941 6.16408 8.33508 6.5471 8.33508 6.9462C8.33508 7.34531 8.48941 7.72833 8.7646 8.01221L11.2099 10.5045L13.3165 8.35745L13.6537 8.01021C13.9327 7.72576 14.0895 7.33997 14.0895 6.9377C14.0895 6.53543 13.9327 6.14964 13.6537 5.86519C13.3747 5.58074 12.9962 5.42094 12.6016 5.42094C12.207 5.42094 11.8286 5.58074 11.5495 5.86519L11.2123 6.21243L10.8688 5.86519C10.7297 5.7253 10.5649 5.61473 10.3838 5.53977C10.2028 5.46482 10.009 5.42696 9.81363 5.42835C9.61824 5.42975 9.42502 5.47037 9.24503 5.5479Z"
    />
  </SvgIcon>
)

export default Community
