import React, { useState, useMemo, useEffect } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import CircularProgress from '@mui/material/CircularProgress'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

import { KEY_BACKSPACE } from '../../arch/keyConstants'
import { useVerificationEmailResend } from '../../app/hooks'
import { getHookPromiseErrorHandler } from '../../common'
import { OTP_LENGTH } from '../../arch/constants'

import LinkButton from '../LinkButton'
import ErrorSnackbar from '../ErrorSnackbar'

import InputDigit from './InputDigit'

const OTP_REG = /^[0-9]+$/

const useStyles = makeStyles(
  theme => ({
    root: {},

    otpContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      gap: theme.spacing(0.75),

      [theme.breakpoints.up('brandSmaller')]: {
        gap: theme.spacing(2)
      }
    },

    entry: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      position: 'relative'
    },

    invalidOTPMessage: {
      ...theme.typography.body2,

      color: theme.palette.error.dark
    },

    transient: {
      opacity: 1.0,
      transition: theme.transitions.create('opacity')
    },

    hidden: {
      opacity: 0.0 // We use opacity so it preserves its place in the layout.
    },

    otpInput: {
      borderRadius: theme.spacing(0.625)
    },

    invalidOTP: {
      borderColor: theme.palette.error.dark
    },

    // Absolute positioning + opacity: hidden = stable layout (UND-2753).
    statusContainer: {
      bottom: 0,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0
    },

    progressContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },

    verifiedContainer: {
      ...theme.typography.label1,

      alignItems: 'center',
      color: theme.palette.text.primary,
      display: 'flex',
      justifyContent: 'center'
    },

    checkIcon: {
      color: theme.palette.success.main
    },

    resendEmailButton: {
      justifyContent: 'flex-start',
      color: theme.palette.text.secondary
    }
  }),
  {
    name: 'VerificationCodeInput'
  }
)

const VerificationCodeInput = props => {
  const { className, onChangeOTP, verificationState, hideResendEmail } = props

  const [error, setError] = useState()

  const { otpCode, isVerified, isVerifying } = verificationState ?? {}

  const isOTPInvalid = isVerified === false
  const inTransientState = isVerified || isVerifying

  const handleApiError = useMemo(() => getHookPromiseErrorHandler(setError), [])

  const { handleResendEmailVerification } = useVerificationEmailResend({ handleApiError })

  const handleAutocomplete = value => onChangeOTP((otpCode + value).slice(0, OTP_LENGTH))

  useEffect(() => {
    const keyDownListener = event => {
      const { key } = event
      if ((key >= '0' && key <= '9') || key === KEY_BACKSPACE) {
        const newOTP =
          key === KEY_BACKSPACE
            ? otpCode.length > 0
              ? otpCode.slice(0, otpCode.length - 1)
              : otpCode
            : otpCode.length < OTP_LENGTH
            ? otpCode + key
            : otpCode

        onChangeOTP(newOTP)
      }
    }

    const isNumericOnly = text => OTP_REG.test(text)

    const pasteListener = event => {
      const pasteText = event.clipboardData.getData('text')
      if (isNumericOnly(pasteText)) {
        const newOTP = `${otpCode}${pasteText}`.slice(0, OTP_LENGTH)
        onChangeOTP(newOTP)
      }
    }

    window.addEventListener('keydown', keyDownListener)
    window.addEventListener('paste', pasteListener)

    return () => {
      window.removeEventListener('keydown', keyDownListener)
      window.removeEventListener('paste', pasteListener)
    }
  }, [onChangeOTP, otpCode])

  const classes = useStyles(props)
  return (
    <section className={clsx(classes.root, className)}>
      <section className={classes.entry}>
        <span
          className={clsx(
            classes.invalidOTPMessage,
            classes.transient,
            (!isOTPInvalid || inTransientState) && classes.hidden
          )}
        >
          Sorry, the code you entered was not a match. Please check the code and try again.
        </span>

        <section className={clsx(classes.otpContainer, classes.transient, inTransientState && classes.hidden)}>
          {[...Array(OTP_LENGTH).keys()].map(position => (
            <InputDigit
              classes={{
                digit: clsx(classes.otpInput, isOTPInvalid && classes.invalidOTP)
              }}
              key={position}
              value={otpCode[position]}
              selected={position === otpCode.length}
              onAutocomplete={handleAutocomplete}
            />
          ))}
        </section>

        {isVerifying && (
          <section className={clsx(classes.statusContainer, classes.progressContainer)}>
            <CircularProgress />
          </section>
        )}

        {isVerified && (
          <section className={clsx(classes.statusContainer, classes.verifiedContainer)}>
            <CheckCircleRoundedIcon className={classes.checkIcon} />
            Verified!
          </section>
        )}
      </section>

      {!hideResendEmail && !inTransientState && (
        <LinkButton className={classes.resendEmailButton} onClick={handleResendEmailVerification}>
          Send email again
        </LinkButton>
      )}

      <ErrorSnackbar error={error} setError={setError} />
    </section>
  )
}

VerificationCodeInput.propTypes = {
  className: PropTypes.string,
  onChangeOTP: PropTypes.func, // Callback function triggered when the OTP changes
  verificationState: PropTypes.shape({
    isVerified: PropTypes.bool, // Indicates if the OTP has been verified successfully
    isVerifying: PropTypes.bool // Indicates if the OTP is currently being verified
  }),
  hideResendEmail: PropTypes.bool // Flag to hide the "Resend Email" button
}

export default VerificationCodeInput
