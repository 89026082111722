import React from 'react'

import SvgIcon from '@mui/material/SvgIcon'

const VideoCamera = props => (
  <SvgIcon width="14" height="8" viewBox="0 0 14 8" fill="none" {...props}>
    <path d="M9.69226 7.53857H1.61522C0.722981 7.53857 -0.000183105 6.8154 -0.000183105 5.92316V0H8.07685C8.9691 0 9.69226 0.723164 9.69226 1.61541V7.53857Z" />
    <path d="M14 7.53857L10.7692 5.38469V2.15388L14 0V7.53857Z" />
  </SvgIcon>
)

export default VideoCamera
