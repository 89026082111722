/**
 * This module uses very simple read-only caching to minimize network requests for frequently-used values.
 * The caching can be very simple (i.e., no invalidation) because they change very rarely.
 */
import { getContactRoles, getDiagnoses, getDocumentTags, getHealthPlans, getRegionalCenters, getTypes } from './api'
import { TYPE_ROOT } from './arch/constants'
import { dirtyArrayWithOrder, emptyArray, equalArrayWithOrder } from './common'

const tagCache = {}
const typeCache = {}

const typesToCategories = types => {
  types.forEach(type => (type.segments = type.name.split('.')))

  const categories = {
    '': []
  }

  types
    .filter(type => type.segments[0] === TYPE_ROOT)
    .forEach(type => {
      const category = type.segments[1]
      if (!categories[category]) {
        categories[category] = []
      }

      categories[category].push({
        display: type.display,
        value: type.name
      })
    })

  return categories
}

const FILTER_ALL = 'all'

const getTypeFilterBase = () => ({
  [FILTER_ALL]: {
    label: 'All categories',
    types: []
  }
})

const filtersFromTypes = types => {
  const result = getTypeFilterBase()

  types.forEach(type => (type.segments = type.name.split('.')))
  types
    .filter(type => type.segments[0] === TYPE_ROOT)
    .forEach(type => {
      result.all.types.push(type.name)

      const category = type.segments[1]
      if (!result[category]) {
        // a.k.a. Post type in the UI
        result[category] = {
          label: category,
          types: []
        }
      }

      result[category].types.push(type.name)
    })

  return result
}

const cachePostTypes = async () => {
  const types = await getTypes()

  // The type list is used for cases where we need the full list of types.
  typeCache.list = types

  // The type lookup is used when we need the full type object, given its name.
  typeCache.lookup = {}
  types.forEach(type => (typeCache.lookup[type.name] = type))

  // The type categories are used when we need the types broken down by their hierarchical structure.
  typeCache.categories = typesToCategories(types)

  // The type filters are used when we need the types based on their category groupings.
  typeCache.filters = filtersFromTypes(types)
}

const getAndCacheLegacyTypesIfNeeded = async typeKey => {
  if (!typeCache[typeKey]) {
    await cachePostTypes()
  }

  return typeCache[typeKey]
}

const TAG_KEY_CATEGORIES = 'categories'
const TAG_KEY_DOCUMENT_TYPES = 'documentTypes'
const TAG_KEY_DIAGNOSES = 'diagnoses'
const TAG_KEY_HEALTH_PLANS = 'healthPlans'
const TAG_KEY_REGIONAL_CENTERS = 'regionalCenters' // Not officially a tag, but the caching model fits.
const TAG_KEY_PROVIDERS_AND_TEAM = 'providersAndTeam'
const TAG_KEY_FRIENDS_AND_FAMILY = 'friendsAndFamily'

const TAG_GETTERS = {
  [TAG_KEY_DOCUMENT_TYPES]: getDocumentTags,
  [TAG_KEY_DIAGNOSES]: getDiagnoses,
  [TAG_KEY_HEALTH_PLANS]: getHealthPlans,
  [TAG_KEY_REGIONAL_CENTERS]: getRegionalCenters,
  [TAG_KEY_PROVIDERS_AND_TEAM]: getContactRoles,
  [TAG_KEY_FRIENDS_AND_FAMILY]: getContactRoles
}

const getAndCacheTagsIfNeeded = async tagKey => {
  if (!tagCache[tagKey]) {
    const tagGetter = TAG_GETTERS[tagKey]
    if (tagGetter) {
      tagCache[tagKey] = await tagGetter(tagKey)
    }
  }

  return tagCache[tagKey]
}

const getTypeLookup = async () => getAndCacheLegacyTypesIfNeeded('lookup')
const getTypeCategories = async () => getAndCacheLegacyTypesIfNeeded('categories')
const getTypeFilters = async () => getAndCacheLegacyTypesIfNeeded('filters')

// Category tags are handled slightly differently because they depend on document type tags.
const getCategoryTags = async () => {
  const categoryTags = tagCache[TAG_KEY_CATEGORIES]
  if (categoryTags) {
    return categoryTags
  }

  const documentTypeTags = await getAndCacheTagsIfNeeded(TAG_KEY_DOCUMENT_TYPES)
  tagCache[TAG_KEY_CATEGORIES] = documentTypeTags
    .filter(documentTag => !documentTag.parentId)
    .sort((leftTag, rightTag) => {
      // We know these are top-level categories so we rely on the assumption that
      // their identifier arrays are all singletons.
      const leftId = leftTag.identifier[0]
      const rightId = rightTag.identifier[0]
      return leftId - rightId
    })

  return tagCache[TAG_KEY_CATEGORIES]
}

const getDocumentTypeTags = async () => getAndCacheTagsIfNeeded(TAG_KEY_DOCUMENT_TYPES)
const getDiagnosisTags = async () => getAndCacheTagsIfNeeded(TAG_KEY_DIAGNOSES)
const getHealthPlanTags = async () => getAndCacheTagsIfNeeded(TAG_KEY_HEALTH_PLANS)
const getRegionalCenterList = async () => getAndCacheTagsIfNeeded(TAG_KEY_REGIONAL_CENTERS)
const getProvidersAndTeamList = async () => getAndCacheTagsIfNeeded(TAG_KEY_PROVIDERS_AND_TEAM)
const getFriendsAndFamilyList = async () => getAndCacheTagsIfNeeded(TAG_KEY_FRIENDS_AND_FAMILY)

/**
 * Convenience function for checking if two tags are different.
 *
 * @param {array} leftIdentifier the first array-of-numbers to check
 * @param {array} rightIdentifier the second array-of-numbers to check
 * @returns whether the identifiers are different
 */
const differentTag = ({ identifier: leftIdentifier }, { identifier: rightIdentifier }) =>
  dirtyArrayWithOrder(leftIdentifier, rightIdentifier)

/**
 * Convenience function for determining the tag object for a particular identifier.
 *
 * @param {array} tagIdentifier the array of numeric IDs which identifies the tag
 * @param {array} tags the array of tags to search
 * @returns the tag object corresponding to the identifier; may be a placeholder if
 *          the identifier is not recognized
 */
const tagFor = (tagIdentifier, tags) => {
  // No tag value means no tag for sure.
  if (emptyArray(tagIdentifier) || emptyArray(tags)) {
    return
  }

  // If we don’t find a match, we synthesize a tag so that the user knows there was a value
  // but that it didn’t match a known tag.
  const tagMatch = tags.find(tag => equalArrayWithOrder(tag.identifier, tagIdentifier))

  // We give the synthesized tag a parentId because only category tags have no parentId and
  // those are very small in number…i.e., an unknown tag is most likely a non-category-tag.
  return tagMatch ?? { displayName: `unrecognized tag ${tagIdentifier}`, identifier: tagIdentifier, parentId: -1 }
}

/**
 * Convenience function for checking if one tag is the parent of another.
 *
 * @param {object} categoryTag the potential parent tag
 * @param {object} tag the potential child tag
 * @returns whether `categoryTag` is the parent of `tag`
 */
const tagIsParent = (categoryTag, tag) => categoryTag.identifier.includes(tag.parentId)

/**
 * The tag system of parents and composites makes the formulation of a key non-trivial.
 * This function captures that logic.
 *
 * @param {number} parentId the tag’s parent ID
 * @param {array} identifier the tag’s identifier, which is an array of numbers
 * @returns a string that will uniquely and universally identify the tag
 */
const tagKey = ({ parentId, identifier }) => JSON.stringify([...(parentId ? [parentId] : []), ...identifier])

export {
  cachePostTypes,
  FILTER_ALL,
  getTypeLookup,
  getTypeCategories,
  getTypeFilters,
  getCategoryTags,
  getDocumentTypeTags,
  getDiagnosisTags,
  getHealthPlanTags,
  getRegionalCenterList,
  differentTag,
  tagFor,
  tagIsParent,
  tagKey,
  getProvidersAndTeamList,
  getFriendsAndFamilyList
}
