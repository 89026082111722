import React from 'react'

import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'

import LinkButton from './LinkButton'
import BrandButton from './brand/BrandButton'

const useStyles = makeStyles(
  theme => ({
    paper: {},

    title: {
      alignItems: 'baseline',
      display: 'flex',
      justifyContent: 'space-between'
    },

    closeButton: {},

    closeIcon: {
      height: theme.spacing(3.5),
      width: theme.spacing(3.5)
    },

    titleText: {
      ...theme.typography.h3,
      marginRight: theme.spacing(1)
    },

    content: {},

    childrenContent: {
      // Zero vertical padding so container is “invisible” when there are no children.
      padding: theme.spacing(0, 3)
    },

    actions: {
      justifyContent: 'space-between',
      margin: theme.spacing(1, 2.75, 2.75, 1.75)
    },

    confirmButton: {
      ...theme.typography.button,
      color: theme.palette.error.dark,
      padding: theme.spacing(2.5, 0.5, 2),
      textDecoration: 'none',
      textTransform: 'none',

      '&:hover': {
        color: theme.palette.error.dark
      }
    },

    cancelButtonRoot: {
      padding: theme.spacing(2.5, 5, 2.25)
    },

    cancelButtonLabel: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.spacing(2)
    }
  }),
  {
    name: 'ConfirmationDialog'
  }
)

const TITLE_ID = 'confirmation-dialog-title'
const DESCRIPTION_ID = 'confirmation-dialog-description'

const EMPTY_PROPS_DEFAULT = {} // Use a predefined constant to ensure that the default is always the same value.

const ConfirmationDialog = props => {
  const {
    children,
    title,
    description,
    cancelLabel,
    confirmLabel,
    onCancel,
    onConfirm,
    classes: classesProp,
    confirmButtonProps = EMPTY_PROPS_DEFAULT,
    cancelButtonProps = EMPTY_PROPS_DEFAULT,
    ...rest
  } = props

  const classes = useStyles(props)
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={onCancel}
      aria-labelledby={TITLE_ID}
      aria-describedby={DESCRIPTION_ID}
      {...rest}
    >
      <DialogTitle id={TITLE_ID} className={classes.title}>
        <span className={classes.titleText}>{title}</span>

        <IconButton className={classes.closeButton} onClick={onCancel} edge="end" size="small">
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogTitle>

      {description && (
        <DialogContent className={classes.content}>
          <DialogContentText id={DESCRIPTION_ID} className={classes.descriptionText}>
            {description}
          </DialogContentText>
        </DialogContent>
      )}

      <DialogContent className={classes.childrenContent}>{children}</DialogContent>

      <DialogActions className={classes.actions}>
        <LinkButton className={classes.confirmButton} disabled={!onConfirm} onClick={onConfirm} {...confirmButtonProps}>
          {confirmLabel}
        </LinkButton>

        <BrandButton
          classes={{ root: classes.cancelButtonRoot, label: classes.cancelButtonLabel }}
          variant="contained"
          disabled={!onCancel}
          onClick={onCancel}
          {...cancelButtonProps}
        >
          {cancelLabel}
        </BrandButton>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  confirmLabel: PropTypes.node,
  cancelLabel: PropTypes.node,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  // …plus other Dialog props too.

  confirmButtonProps: PropTypes.object, // props that will be relayed to the confirm button, if any.
  cancelButtonProps: PropTypes.object // props that will be relayed to the cancel button, if any.
}

export default ConfirmationDialog
