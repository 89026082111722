const KEY_ARROW_UP = 'ArrowUp'
const KEY_ARROW_DOWN = 'ArrowDown'
const KEY_ARROW_LEFT = 'ArrowLeft'
const KEY_ARROW_RIGHT = 'ArrowRight'
const KEY_BACKSPACE = 'Backspace'
const KEY_DELETE = 'Delete'
const KEY_NEWLINE = 'Enter'
const KEY_SHIFT = 'Shift'
const KEY_TAB = 'Tab'

export {
  KEY_ARROW_UP,
  KEY_ARROW_DOWN,
  KEY_ARROW_LEFT,
  KEY_ARROW_RIGHT,
  KEY_BACKSPACE,
  KEY_DELETE,
  KEY_NEWLINE,
  KEY_SHIFT,
  KEY_TAB
}
