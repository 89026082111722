import { postChildGoal } from '../api'
import { listToMap } from '../common'

/**
 * Callback function that compares the length of the children property to sort an array of objects.
 */
const sortHierarchy = hierarchy => {
  hierarchy.sort((leftGoal, rightGoal) => {
    return rightGoal.children.length - leftGoal.children.length
  })

  hierarchy.forEach(item => {
    if (item.children.length) {
      sortHierarchy(item.children)
    }
  })

  return hierarchy
}

/**
 * Modifies the goals array in place by connecting parent goals to their children (within the same array).
 *
 * @param {array} goals
 * @param {object} goalMap object mapping goal IDs to goals
 *
 * @returns nothing—the goals array is modified in place
 */
const clusterGoals = (goals, goalMap) => {
  const goalList = goals.reduce((clustered, goal) => {
    const { parentage, id } = goal

    if (parentage?.length) {
      const hasParentGoal = parentage.some(parentId => goalMap.hasOwnProperty(parentId.toString()))

      if (hasParentGoal) {
        parentage.forEach(parentId => {
          const parent = goalMap[parentId]
          const isParentGoalInClustered = clustered.some(goalItem => goalItem.id === parentId)

          if (isParentGoalInClustered) {
            const parentGoal = clustered.find(goalItem => goalItem.id === parentId)
            parentGoal.children.push({ ...goal, parentId })
          } else if (parent && !clustered.some(goalItem => goalItem.id === id)) {
            parent.children.push({ ...goal, parentId })
            clustered.push(parent)
          }
        })
      } else if (!clustered.some(goalItem => goalItem.id === id)) {
        clustered.push(goal)
      }
    } else if (!clustered.some(goalItem => goalItem.id === id)) {
      clustered.push(goal)
    }

    return clustered
  }, [])

  return goalList
}

/**
 * Organizes a given list of goals into a hierarchy based on their parent-child references.
 *
 * @param {array} goals
 * @returns array of goals that have no parents, with children assigned and sorted by name
 */
const getGoalsTree = (goals, options) => {
  const { sort } = options ?? {}
  const processedGoals = goals.map(goal => ({ ...goal, children: [] }))
  const clusteredGoals = clusterGoals(processedGoals, listToMap(processedGoals))

  return sort ? sortHierarchy(clusteredGoals) : clusteredGoals
}

const ADD_GOAL_ID = 'addGoalId'

/**
 * Retrieves the goal ID upon successful login of user from public resource site
 * when the user has clicked on "Add to roadmap" button
 *
 * The value is stored in local storage upon clicking the add button.
 * Local storage is needed because the information “crosses” between the two distinct
 * apps (resources and the main one)
 *
 * @returns {string|null} the stored goal ID or null if not found
 */
const getAddGoalId = () => window.localStorage.getItem(ADD_GOAL_ID)

/**
 * Removes the goal ID from local storage.
 */
const removeAddGoalId = () => window.localStorage.removeItem(ADD_GOAL_ID)

/**
 * Convenience function for retrieving a goal template ID from local storage then attempting
 * to add that goal template to the given child.
 *
 * As a domain-level function, this function will throw on failure so the caller must supply
 * the typical try/catch block.
 *
 * @param {object} client - The client object containing child details.
 * @returns {string|null} The ID of the newly created goal or null if the operation failed.
 */
const addGoalFromExternalSource = async client => {
  const { id: childId, nickname, birthday, diagnosisTagIds, household: householdId } = client
  const addGoalId = getAddGoalId()

  const canAdd = nickname && birthday && diagnosisTagIds?.length > 0

  if (addGoalId && canAdd) {
    const { goalId } = await postChildGoal(childId, { goalTemplateId: Number(addGoalId), householdId })

    if (goalId) {
      removeAddGoalId()
      return goalId
    }
  }

  removeAddGoalId()

  // If we get here, there was either no goal ID to add or the add did not take place.
  return null
}

export { getGoalsTree, getAddGoalId, removeAddGoalId, addGoalFromExternalSource }
