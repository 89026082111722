import deepmerge from '@mui/utils/deepmerge'

import { THEME_PRIMARY_GREEN, THEME_COLOR_GROUP_NEUTRAL } from './app/support/theme'

// Dimensions are modeled as functions even if some are hardcoded right now in case we need to get

// theme-dependent in the future (like `bodyContentWidth`).
const userDialogWidth = theme => 448

// Standard maximum width for "body" content like info blocks, requests, and the like.
const bodyContentWidth = theme => 148

// Standard dimensions for various chat elements.
const chatWidth = theme => 45 // In spacing units… TODO unify the units for all of these sizes.
const chatEntryBarHeight = theme => 6.5 // Also in spacing units

const footerHeight = theme => 3 // Spacing units
const menubarGutter = theme => 0 // Spacing units between menu items and right side.

// Standard height for content region, useful for coordinating scrolls or fixed left/right-anchored sidebars.
// Values come from: overall height - header height - tabs height - footer height; will need manual adjustment
// if any of those heights change.
const contentViewportHeight = theme => `calc(100vh - 64px - 48px - ${theme.spacing(footerHeight(theme))}px)`

// Prevents massive URL strings in text fields from ruining the layout.
const detailContentWidth = theme => 676

const topLevelModuleStyles = theme => ({
  root: {
    margin: theme.spacing(2, 4),
    minHeight: '100vh',

    '& > *': {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: theme.spacing(bodyContentWidth(theme))
    }
  }
})

const DIALOG_PADDING = 4

const userDialogBaseStyles = theme => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: userDialogWidth(theme),
  padding: theme.spacing(0, DIALOG_PADDING)
})

const userDialogStyles = theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex'
  },

  dialogContainer: {
    flexGrow: 1,
    padding: theme.spacing(6, 0, 4),
    position: 'relative',

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(10, 0, 4)
    }
  },

  logo: {
    marginBottom: theme.spacing(4)
  },

  dialog: {
    ...userDialogBaseStyles(theme),

    borderStyle: 'none',
    boxShadow: 'none',
    marginBottom: theme.spacing(4),

    [theme.breakpoints.up('brandSmall')]: {
      marginBottom: theme.spacing(16)
    }
  },

  // Intended to be mixed with dialog.
  wide: {
    maxWidth: theme.spacing(71.5)
  },

  accent: {
    backgroundColor: THEME_PRIMARY_GREEN.main,
    borderRadius: theme.spacing(0, 0, 0, 2.5),
    display: 'none',
    position: 'relative',
    width: theme.spacing(53.625),

    [theme.breakpoints.up('md')]: {
      display: 'block',
      height: '100vh',
      position: 'sticky',
      top: 0
    }
  },

  footer: {
    bottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
    left: theme.spacing(7),
    position: 'absolute',
    right: theme.spacing(7)
  },

  hero: {
    bottom: theme.spacing(10),
    position: 'absolute',
    right: theme.spacing(4)
  },

  prompt: {
    margin: theme.spacing(7.5, 0, 5)
  },

  subprompt: {
    ...theme.typography.h2
  },

  instructions: {
    ...theme.typography.h6,
    fontWeight: theme.typography.fontWeightRegular,
    margin: theme.spacing(0, 0, 5)
  },

  buttonBar: {
    ...theme.typography.body1,
    alignItems: 'center',
    display: 'flex',
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'space-between'
  },

  button: {
    color: '#828282',
    fontSize: 10,
    lineHeight: '23px'
  },

  linkLikeButton: {
    alignItems: 'center',
    color: '#3838a1',
    display: 'flex',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 18 / 18,

    '&:visited': {
      color: '#3838a1'
    }
  },

  submit: {
    borderRadius: theme.spacing(256),
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 20 / 18,
    margin: theme.spacing(5, 0, 0),
    padding: theme.spacing(2.375, 6)
  },

  cancelRoot: {
    minWidth: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },

  dialogFooter: {
    ...userDialogBaseStyles(theme),

    marginBottom: theme.spacing(3.75),
    marginTop: theme.spacing(9.25)
  },

  copy: {
    ...theme.typography.body1
  },

  section: {
    marginTop: theme.spacing(3)
  },

  accentAction: {
    backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N20,
    height: '100%',
    width: theme.spacing(66.125)
  },

  wordmarkLogoContainer: {
    '&:before': {
      borderBottomLeftRadius: theme.spacing(0.5),
      borderTopLeftRadius: theme.spacing(0.5),
      width: theme.spacing(0.625)
    }
  },

  wordmarkLogo: {
    height: theme.spacing(4.5)
  },

  stars: {
    left: theme.spacing(-2.25),
    position: 'absolute',
    top: 0
  },

  text: {
    fontSize: theme.typography.pxToRem(16),
    marginBlock: theme.spacing(3)
  },

  quoteImage: {
    height: theme.spacing(5.5),
    left: theme.spacing(-2.25),
    top: theme.spacing(0.25),
    width: theme.spacing(7.375)
  },

  quoteColor: {
    color: THEME_PRIMARY_GREEN.main
  },

  submitButton: {
    margin: theme.spacing(2, 0)
  },

  loginButton: {
    padding: theme.spacing(2),
    width: theme.spacing(23.625)
  },

  buttonColor: {
    color: theme.palette.text.primary
  },

  buttonBarItem: {
    justifyContent: 'center'
  },

  bold: {
    fontWeight: theme.typography.fontWeightMedium
  },

  sidebarContent: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(26),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: theme.spacing(4),
    width: 'auto'
  },

  sidebarContentContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100%'
  },

  quotesContainer: {
    margin: theme.spacing(12.5),
    position: 'relative'
  },

  yellowLineyCircle: {
    bottom: 0,
    position: 'absolute',
    right: 0,
    zIndex: -1
  }
})

const loginJoinStyles = theme => ({
  root: {
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(3, 2)
    },

    [theme.breakpoints.between('sm', 'lg')]: {
      margin: theme.spacing(8, 9)
    },

    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(8, 15)
    },

    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },

    '& a:visited': {
      color: theme.palette.primary.dark
    },

    '& a:hover': {
      color: theme.palette.primary.light
    }
  },

  prompt: {
    marginBottom: '1rem',
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.25rem'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '2rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2.5rem'
    }
  },

  section: {
    marginTop: theme.spacing(1)
  },

  centered: {
    display: 'flex',
    '& > *': {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },

  buttonBar: {
    display: 'flex',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    '& > *': {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },

  button: {
    borderRadius: '10rem', // Automatically maxes out at a round border.
    color: theme.palette.common.white,
    [theme.breakpoints.only('xs')]: {
      fontSize: '1rem',
      paddingLeft: '3rem',
      paddingRight: '3rem'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '1.5rem',
      paddingLeft: '4.5rem',
      paddingRight: '4.5rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
      paddingLeft: '6rem',
      paddingRight: '6rem'
    }
  }
})

const linkStyle = theme => ({
  // We opt for a bottom border rather than text decoration due to its distance and thickness.
  borderBottomColor: theme.palette.green.main,
  borderBottomStyle: 'solid',
  borderBottomWidth: theme.spacing(0.25),
  color: theme.palette.text.primary,
  cursor: 'pointer',
  fontWeight: theme.typography.fontWeightMedium,
  textDecoration: 'none',
  transition: theme.transitions.create(['border-color', 'color']),

  '&:hover': {
    borderBottomColor: theme.palette.green.dark,
    textDecoration: 'none'
  }
})

const loadMoreStyle = theme => ({
  padding: theme.spacing(1.625, 3, 1.5)
})

const infoBlockExpandTransition = theme => ({
  duration: theme.transitions.duration.shortest
})

const COLOR_INPUT_ICON = '#b4c9cd'

/**
 * For use when trying to disable the underline of a Material UI Input component but `disableUnderline`
 * cannot be set for some reason.
 *
 * A little 😬 but unfortunately `disableUnderline` isn’t settable, such as with Material UI Picker
 * date/time components.
 */
const manualDisableMuiInputUnderline = theme => ({
  [[
    '& .MuiInput-underline:before',
    '.MuiInput-underline:after',
    '.MuiInput-underline:hover:not(.Mui-disabled):before',
    '.MuiInput-underline.MuiInput-focused:after'
  ].join(', ')]: {
    border: 'none'
  }
})

/**
 * Returns a function that creates an object containing the style properties and values needed
 * to have a “clamped” element based on number of lines.
 *
 * The return value is a function that accepts the current theme as an argument. For the
 * current implementation, `theme` actually isn’t used, but we’re including it for consistency
 * with other style helpers in this module plus the possibility that it will be used sometime
 * in the future.
 *
 * @param {number} maxLines the number of lines to clamp to
 * @returns function that generates the object
 */
const lineClampStyle = maxLines => theme => ({
  boxOrient: 'vertical',
  display: 'box',
  lineClamp: maxLines,
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

const DEFAULT_BACKDROP_INSET = 2.5

/**
 * Returns a CSS class object that adds a rotated square to the backdrop of an element.
 * Overrides or composition can be used to customize color, size, angle, etc.
 *
 * @param {object} theme the current Material UI theme
 * @returns style object for this visual
 */
const rotatedSquareBackdropStyle = (theme, overrides) =>
  deepmerge(
    {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',

      '&:before': {
        content: "''",
        backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N20,
        borderRadius: theme.spacing(0.75),
        bottom: theme.spacing(DEFAULT_BACKDROP_INSET),
        left: theme.spacing(DEFAULT_BACKDROP_INSET),
        position: 'absolute',
        right: theme.spacing(DEFAULT_BACKDROP_INSET),
        top: theme.spacing(DEFAULT_BACKDROP_INSET),
        transform: 'rotate(15deg)'
      }
    },
    overrides
  )

export {
  userDialogWidth,
  bodyContentWidth,
  chatWidth,
  chatEntryBarHeight,
  footerHeight,
  menubarGutter,
  contentViewportHeight,
  detailContentWidth,
  topLevelModuleStyles,
  userDialogStyles,
  loginJoinStyles,
  linkStyle,
  loadMoreStyle,
  infoBlockExpandTransition,
  COLOR_INPUT_ICON,
  manualDisableMuiInputUnderline,
  lineClampStyle,
  rotatedSquareBackdropStyle
}
