import React from 'react'

import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles(
  theme => ({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',

      // Units are set as literals because the fallback might render in an environment
      // that doesn’t have `theme`.
      padding: '15px 32px',
      rowGap: '16px',
      width: 'calc(100% - 32px)'
    },

    logo: {},
    message: {},
    progress: {}
  }),
  {
    name: 'LoadingFallback'
  }
)

const LoadingFallback = props => {
  const { message } = props

  const classes = useStyles(props)
  return (
    <article className={classes.root}>
      <img className={classes.logo} src="https://static.teamspecialx.com/images/logo.svg" alt="Undivided Logo" />
      {message && <span className={classes.message}>{message}</span>}
      <CircularProgress className={classes.progress} />
    </article>
  )
}

LoadingFallback.propTypes = {
  message: PropTypes.string
}

export default LoadingFallback
