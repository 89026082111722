/**
 * EmptyPlanningCategory displays information for an empty goal-planning category.
 */
import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  theme => ({
    root: {
      color: 'black' // No we really do mean black this time 😅
    },

    title: {
      ...theme.typography.body1
    }
  }),
  {
    name: 'EmptyPlanningCategory'
  }
)

const EmptyPlanningCategory = props => {
  const { className, title } = props

  const classes = useStyles(props)
  return (
    <article className={clsx(classes.root, className)}>
      <span className={classes.title}>{title}</span>
    </article>
  )
}

EmptyPlanningCategory.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.node, // For now, ignored. Included for “compatibility” with EmptyGoals.
  createGoal: PropTypes.func // For now, ignored. Included for “compatibility” with EmptyGoals.
}

export default EmptyPlanningCategory
