import React from 'react'

import SvgIcon from '@mui/material/SvgIcon'

const Map = props => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.19617 1.29142L6.04443 0V13.8508L9.57029 15.9663V2.11622L8.19773 1.29248L8.19617 1.29142ZM15.3603 1.23276C15.1687 1.09012 14.921 1.04655 14.6922 1.1152L10.6279 2.33454V16L15.1264 14.6505C15.446 14.5546 15.6649 14.2604 15.6649 13.9268V1.83891C15.6649 1.60011 15.5519 1.37536 15.3603 1.23276ZM0 14.1786V2.08994C0 1.75624 0.218906 1.46209 0.538449 1.36628L5.03694 0.0169373V13.683L0.972634 14.9023C0.901361 14.9236 0.828225 14.9341 0.755592 14.9341C0.595014 14.9341 0.436451 14.8829 0.304534 14.7847C0.112928 14.6422 0 14.4174 0 14.1786Z"
    />
  </SvgIcon>
)

export default Map
