import React, { useContext, useEffect } from 'react'
import { Navigate, useLocation, useMatch, useNavigate, useOutlet } from 'react-router-dom'

import {
  CAN_BE_NAVIGATOR,
  CAN_BE_ADMIN,
  CAN_BE_SUPERUSER,
  CAN_BE_CERTIFIED,
  CAN_PROVIDE_SERVICES
} from '../../arch/constants'

import { identifyMe } from '../../auth'
import { MessagesNavigationContext } from '../../chat/contexts'
import { CAPABILITY_CONFIRM_EMAIL, CAPABILITY_VIEW_PRIMARY_UI } from '../../arch/capabilityConstants'

import { LoginContext, NotificationsContext } from '../contexts'
import { useAppUser, useCurrentSite, useParentViewCapability } from '../hooks'

import ErrorBoundary from './ErrorBoundary'
import LoadingFallback from './LoadingFallback'
import NotFound from './NotFound'
import ThemedContainer from './ThemedContainer'

/**
 * The MainLayout component captures a range of common behavior that all main-level routes
 * (i.e., components that occupy the main area of the web app) can use. These include but are
 * not limited to:
 *
 * - An error boundary
 * - Recording/logging of analytics
 * - Eliminating a logged-in user if requested
 *
 * @param {object} props
 */
const MainLayout = props => {
  const { children, clearUser } = props

  const outlet = useOutlet()
  const { setAppUser } = useContext(LoginContext)

  useEffect(() => {
    identifyMe()
  }, [])

  useEffect(() => {
    // Check if the caller wants to ensure that there isn’t a user.
    if (clearUser) {
      setAppUser(null)
    }
  }, [clearUser, setAppUser])

  return <ErrorBoundary>{children || outlet}</ErrorBoundary>
}

/**
 * UserGate wraps its outlet around a check for a logged-in user, independent of role.
 *
 * @param {object} props
 */
const UserGate = props => {
  const appUser = useAppUser()
  const location = useLocation()
  const outlet = useOutlet()

  const { children } = props

  // If no user, we go to login.
  if (!appUser) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return children || outlet
}

/**
 * RoleGate wraps its outlet around a check for roles.
 *
 * @param {object} props
 */
const RoleGate = props => {
  const appUser = useAppUser()
  const location = useLocation()

  const outlet = useOutlet()

  const isOnboardingPath = useMatch('/onboarding')

  const { acceptableRoles, children } = props

  const { isUserCapable } = useParentViewCapability()

  // If no user, we go to login.
  if (!appUser) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  // Quick guard for unconfirmed email, if the logged-in user is a parent.
  if (appUser.isParent()) {
    if (!isUserCapable(CAPABILITY_VIEW_PRIMARY_UI) && !isOnboardingPath) {
      if (isUserCapable(CAPABILITY_CONFIRM_EMAIL)) {
        return <Navigate to="/confirm-email" />
      } else {
        // This should actually never happen, and is otherwise indicative of a deeper, more serious problem.
        // As such, we send the user to a bogus route which will look like a 404.
        return <Navigate to="/no-primary-ui-no-email" />
      }
    }
  }

  // If the user has sufficient roles, we render the outlet; otherwise we say 404
  // so as not to give away that a path might be valid for other roles.
  return appUser.role(acceptableRoles) ? children || outlet : <NotFound />
}

/**
 * ParentGate is a little more specialized than RoleGate because here we are factoring in whether
 * the current site specifies `withChildrenOnly`—this eliminates care team users.
 */
const ParentGate = props => {
  const appUser = useAppUser()
  const currentSite = useCurrentSite()
  const { withChildrenOnly } = currentSite

  const navigate = useNavigate()
  const { chatMessageRequest } = useContext(NotificationsContext)
  const { navigatorMessagesPath } = useContext(MessagesNavigationContext)

  useEffect(() => {
    if (chatMessageRequest) {
      navigate(navigatorMessagesPath())
    }
  }, [chatMessageRequest, navigate, navigatorMessagesPath])

  // Pre-emptive check for `withChildrenOnly`.
  const hasChildren = appUser?.household?.clients?.length > 0
  return withChildrenOnly && appUser && !hasChildren ? <NotFound /> : <RoleGate {...props} />
}

/**
 * Specialized gate meant for knowledge base articles.
 *
 * @param {object} props
 */
const ResourceGate = props => {
  const appUser = useAppUser()
  const outlet = useOutlet()

  const { children } = props

  const { location } = window
  const { pathname, search } = location

  if (!appUser) {
    // This is outside the app, so we assign directly to window.location.
    // Extract everything after `/resources/`
    const pathAfterResources = pathname.split('/resources/')[1]

    location.href = `/resources${pathAfterResources ? `/${pathAfterResources}` : ''}${search}`
    return <LoadingFallback message="Loading Undivided resources…" />
  }

  return children || outlet
}

/**
 * ThemeLayout wraps its outlet around a particular theme.
 */
const ThemeLayout = props => {
  const { theme, children } = props
  const outlet = useOutlet()

  return <ThemedContainer theme={theme}>{children || outlet}</ThemedContainer>
}

const AdminGate = props => <RoleGate {...props} acceptableRoles={CAN_BE_ADMIN} />
const NavigatorGate = props => <RoleGate {...props} acceptableRoles={CAN_BE_NAVIGATOR} />
const CertifiedMemberGate = props => <RoleGate {...props} acceptableRoles={CAN_BE_CERTIFIED} />
const ServicesGate = props => <RoleGate {...props} acceptableRoles={CAN_PROVIDE_SERVICES} />
const SuperuserGate = props => <RoleGate {...props} acceptableRoles={CAN_BE_SUPERUSER} />

const InternalUserGate = props => (
  <RoleGate {...props} acceptableRoles={[...CAN_PROVIDE_SERVICES, ...CAN_BE_ADMIN, ...CAN_BE_SUPERUSER]} />
)

export {
  ThemeLayout,
  MainLayout,
  UserGate,
  ParentGate,
  ResourceGate,
  AdminGate,
  NavigatorGate,
  CertifiedMemberGate,
  ServicesGate,
  SuperuserGate,
  InternalUserGate
}
