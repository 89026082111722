/**
 * This module contains domain-level functions involving users.
 */
import { patchEmailUnconfirmedPreferences, patchPreferences } from '../api'

const GOAL_EXPANDED = 'goalExpanded' // object keyed by goal ID with boolean stating if goal is expanded
const MESSAGE_CHECK = 'messageCheck' // object keyed by chat ID with value of last check date for that chat
const MESSAGE_OPEN = 'messageOpen' // boolean indicating if navigator chat is open
const PHASE = 'phase' // string indicating the user’s stated phase
const FOLLOWED_TOPICS = 'topicIFollowIds' // array of topic IDs that the user follows
const RESOURCE_BOOKMARKS = 'resourceBookmarks' // Array of resource IDs bookmarked by the user.
const GET_STARTED_DISMISSED = 'hideGetStarted' // boolean indicating if the Get Started widget has been dismissed

// boolean indicating if the user has closed the kickstart modal
const KICKSTART_ENDED_NOTIFICATION_MODAL_CLOSED = 'kickstartEndedNotificationModalClosed'

// boolean indicating if checklists are filtered by priority
const CHECKLIST_PRIORITY_FILTER_ON = 'checklistPriorityFilterOn'

// integer representing which In Progress tab index should be selected.
const IN_PROGRESS_TAB = 'inProgressTab'

// the FILTER preferences are strings, indicating the filter to set for the respective
// In Progress widget item.
const IN_PROGRESS_CHECKLIST_FILTER = 'inProgressChecklistFilter'
const IN_PROGRESS_GOAL_FILTER = 'inProgressGoalFilter'

// the LIMIT preferences are numeric, indicating the maximum number of items to display.
// -1 represents no limit (i.e., view all)
const IN_PROGRESS_CHECKLIST_LIMIT = 'inProgressChecklistLimit'
const IN_PROGRESS_GOAL_LIMIT = 'inProgressGoalLimit'

// string ID of the child whose roadmap should be displayed.
// Also used by the In Progress widget to select the child from the menu.
const ROADMAP_CHILD = 'roadmapChild'

// boolean indicating if the user has dismissed the empty goal prompt.
const EMPTY_GOAL_PROMPT_DISMISSED = 'emptyGoalPromptDismissed'

// boolean indicating if the user has chosen to hide About this Goal details.
const ABOUT_THIS_GOAL_LESS = 'aboutThisGoalLess'

// object storing the state of the user’s profile blocks.
const PROFILE_BLOCKS = 'profileBlocks'

// object storing the state of each received services profile block.
const RECEIVED_SERVICES = 'receivedServices'

const getPreference = (user, preference, defaultValue) =>
  user?.preferences?.hasOwnProperty(preference) ? user.preferences[preference] : defaultValue

const updateUserPreferences = async (user, preferences, patchFunction) => {
  // Make this change in place” so that the rest of the application sees the new preferences
  // (React components will need some kind of mechanism to invoke a re-render).
  const currentPreferences = user?.preferences ?? {}

  const newPreferences = {
    ...currentPreferences,
    ...(preferences ?? {})
  }

  user.preferences = newPreferences

  await patchFunction(user.username, newPreferences)
}

/**
 * Sets the given preferences for the given user.
 *
 * This interacts with the API and therefore might throw an exception; the caller is responsible for catching
 * that if it happens.
 *
 * This also mutates the given user with the new preference in addition to writing the new preference to the API.
 * The function makes no further assumptions about the given user so it is the responsibility of the caller to
 * handle (or ignore) the in-memory mutation.
 *
 * @param {object} user the user whose preferences are to be updated
 * @param {object} preferences an object containing the preferences to set { keys: values }
 */
const setPreferences = async (user, preferences) => await updateUserPreferences(user, preferences, patchPreferences)

/**
 * Sets the email preferences for users who have not confirmed their email.
 *
 * This interacts with the API and therefore might throw an exception; the caller is responsible for catching
 * that if it happens.
 *
 * @param {object} user the user whose email preferences are to be updated
 * @param {object} preferences an object containing the email preferences to set { keys: values }
 */
const setEmailUnconfirmedPreferences = async (user, preferences) =>
  await updateUserPreferences(user, preferences, patchEmailUnconfirmedPreferences)

/**
 * Sets the given preference for the given user.
 *
 * This is a convenience function for setPreferences, so all of the stipulations in the other function
 * also apply here.
 *
 * @param {object} user the user whose preferences are to be updated
 * @param {string} preference key of the preference to be set
 * @param {any} value value of the preference to be set
 */
const setPreference = async (user, preference, value) => await setPreferences(user, { [preference]: value })

/**
 * Sets the email preference for users who have not confirmed their email.
 *
 * This is a convenience function for setEmailUnconfirmedPreferences, so all of the stipulations in the other function
 * also apply here.
 *
 * @param {object} user the user whose email preference is to be updated
 * @param {string} preference key of the email preference to be set
 * @param {any} value value of the email preference to be set
 */
const setEmailUnconfirmedPreference = async (user, preference, value) =>
  await setEmailUnconfirmedPreferences(user, { [preference]: value })

/**
 * Sets the goal-expanded state for the given IDs to the given value. Since boolean arguments can be hard to
 * read, this function is private, and public versions have more explicit names.
 *
 * @param {object} user
 * @param {array} goalIds
 * @param {boolean} expanded
 */
const setGoalExpanded = (user, goalIds, expanded) => {
  const goalExpanded = getPreference(user, GOAL_EXPANDED, {})
  goalIds.forEach(goalId => (goalExpanded[goalId] = expanded))
  setPreference(user, GOAL_EXPANDED, goalExpanded)
}

const collapseGoals = (user, goalIds) => setGoalExpanded(user, goalIds, false)
const expandGoals = (user, goalIds) => setGoalExpanded(user, goalIds, true)

/**
 * State whether a goal should be in its expanded state. If no expanded state is persisted, return the
 * default value. (note how we cannot rely on the falsiness of the persisted value because if it is
 * false but persisted, that should override a default value of true)
 *
 * @param {object} user
 * @param {string} goalId
 * @param {boolean} defaultValue
 */
const isGoalExpanded = (user, goalId, defaultValue) => {
  const goalExpanded = getPreference(user, GOAL_EXPANDED, {})
  return goalExpanded.hasOwnProperty(goalId) ? goalExpanded[goalId] : defaultValue
}

/**
 * Resets the goal-expanded state of the given IDs. That way, the next time the goals are opened, they will
 * use whatever default expanded/collapsed state is given to them.
 *
 * @param {object} user
 * @param {array} goalIds
 */
const resetGoalExpanded = (user, goalIds) => {
  const goalExpanded = getPreference(user, GOAL_EXPANDED, {})
  goalIds.forEach(goalId => delete goalExpanded[goalId])
  setPreference(user, GOAL_EXPANDED, goalExpanded)
}

export {
  MESSAGE_CHECK,
  MESSAGE_OPEN,
  PHASE,
  FOLLOWED_TOPICS,
  RESOURCE_BOOKMARKS,
  KICKSTART_ENDED_NOTIFICATION_MODAL_CLOSED,
  GET_STARTED_DISMISSED,
  CHECKLIST_PRIORITY_FILTER_ON,
  IN_PROGRESS_TAB,
  IN_PROGRESS_CHECKLIST_FILTER,
  IN_PROGRESS_GOAL_FILTER,
  IN_PROGRESS_CHECKLIST_LIMIT,
  IN_PROGRESS_GOAL_LIMIT,
  ROADMAP_CHILD,
  EMPTY_GOAL_PROMPT_DISMISSED,
  ABOUT_THIS_GOAL_LESS,
  PROFILE_BLOCKS,
  RECEIVED_SERVICES,
  getPreference,
  setPreferences,
  setEmailUnconfirmedPreferences,
  setEmailUnconfirmedPreference,
  setPreference,
  collapseGoals,
  expandGoals,
  isGoalExpanded,
  resetGoalExpanded
}
