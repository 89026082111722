/**
 * This module defines miscellaneous functions relating to the overall App.
 *
 * A special group of helpers comprises functions that convey or manage certain conditions within the
 * application, such as whether a welcome dialog should appear. They are consolidated here so that
 * the business logic can be in one place and need only affect this module if that logic changes.
 */
import { CAN_BE_SUPERUSER, CAN_PROVIDE_SERVICES, OTP_LENGTH } from '../arch/constants'
import { FUNNEL_PAYMENT_COLLECT } from '../onboarding/constants'
import { TRIAL_KICKSTART, TRIAL_PLATFORM, ONBOARD_KICKSTART } from '../onboarding/trial/constants'

const patchState = (state = {}, stateModification = {}) => ({ ...state, ...stateModification })

const alterLocationState = (location, navigate, stateModification) => {
  const { pathname, state, search } = location

  navigate(`${pathname}${search}`, { replace: true, state: patchState(state, stateModification) })
}

/**
 * Determines whether or not a full-page transition element should be displayed.
 *
 * @param {object} location The React Router Location to check
 * @returns Whether the given location calls for a transition
 */
const shouldShowTransition = location => Boolean(location?.state?.transition)

const MOBILE_PATHS = ['/onboarding', '/resources', '/knowledge-base'] // Last one kept for backward compatibility.

/**
 * Determines whether or not the mobile CTA should be visible.
 */
const shouldShowMobileCta = location => {
  const { pathname } = location ?? {}
  return !MOBILE_PATHS.some(mobilePath => Boolean(pathname?.startsWith(mobilePath)))
}

// No dismiss for the mobile CTA because dismissing it is a one-time thing and is tracked as internal state.

/**
 * Determines whether or not a welcome dialog should be visible.
 */
const shouldShowWelcome = location => Boolean(location?.state?.welcome)

/**
 * Closes out the welcome dialog.
 */
const dismissWelcome = (location, navigate) => alterLocationState(location, navigate, { welcome: undefined })

/**
 * Determines whether or not a referral dialog should be visible.
 */
const shouldShowReferral = location => Boolean(location?.state?.referral)

/**
 * Displays the referral dialog.
 *
 * @param {object} location The useLocation value to use.
 * @param {function} navigate The navigate function to use.
 */
const showReferral = (location, navigate) => alterLocationState(location, navigate, { referral: true })

/**
 * Closes out the referral dialog.
 */
const dismissReferral = (location, navigate) => alterLocationState(location, navigate, { referral: undefined })

/**
 * Determines whether or not the user has responded to a kickstart trial CTA.
 */
const shouldKickstart = location => Boolean(location?.state?.kickstartRequest)

/**
 * Displays the kickstart dialog along with the welcome dialog.
 * This will also dismiss the upsell dialog, since that’s where we came from.
 */
const showKickstart = (location, navigate) =>
  alterLocationState(location, navigate, { kickstartRequest: true, upsellRequest: undefined })

/**
 * Closes out the kickstart dialog.
 */
const dismissKickstart = (location, navigate) =>
  alterLocationState(location, navigate, { kickstartRequest: undefined, trialOption: undefined })

/**
 * Shows the payment capture dialog alone and dismisses all other dialogs
 * This will also sets the trial option and upgrade dialog flag to open the dialogs in further flows.
 */
const showPaymentCaptureDialog = (location, navigate, trialOption) =>
  alterLocationState(location, navigate, {
    captureRequest: true,
    upsellRequest: undefined,
    kickstartRequest: undefined,
    trialOption: trialOption
  })

/**
 * Dismisses the payment capture dialog and shows the kickstart dialog
 * This will also sets the trial option to open the dialogs in further flows.
 */
const dismissPaymentCaptureAndShowKickstartDialog = (location, navigate, trialOption) =>
  alterLocationState(location, navigate, {
    captureRequest: undefined,
    kickstartRequest: true,
    trialOption: trialOption
  })

/**
 * Dismisses the payment capture dialog including all associated dialogs.
 */
const dismissPaymentAndTrialDialogs = (location, navigate) =>
  alterLocationState(location, navigate, {
    captureRequest: undefined,
    kickstartRequest: undefined,
    upsellRequest: undefined,
    trialOption: undefined
  })

const shouldPaymentCapture = location => Boolean(location?.state?.captureRequest)

const shouldOnlyShowWelcomeTrial = location =>
  Boolean(location?.state?.trialOption === TRIAL_PLATFORM && !location?.state?.captureRequest)

const shouldOnlyShowKickstart = location =>
  Boolean(
    location?.state?.kickstartRequest ||
      (location?.state?.trialOption === TRIAL_KICKSTART && !location?.state?.captureRequest)
  )

const shouldShowTrial = location => shouldUpsell(location) || shouldOnlyShowWelcomeTrial(location)

/**
 * Returns whether the given user might have tasks based on front-end-side activity.
 * Excluding superuser here is not a downgrade of capabilities—it indicates that
 * superusers exempt from tasks derived from front-end-side activity.
 *
 * @param {object} appUser the user we’re checking on
 * @returns whether the user should initiate the task-gathering routine
 */
const shouldGatherTasks = appUser => appUser.role(CAN_PROVIDE_SERVICES) && !appUser.role(CAN_BE_SUPERUSER)

/**
 * Determines whether or not the user has just changed tiers to trial.
 */
const shouldGreetNewTrial = location => {
  // TODO Disabled pending API support.
  return false
}

/**
 * Closes out the welcome-to-kickstart sequence.
 */
const dismissGreetNewTrial = (location, navigate) => {
  // TODO Disabled pending API support.
}

/**
 * Determines whether or not the CTA banner should be visible for the parent user.
 *
 * @param {object} appUser The user object to check
 * @returns {boolean} Whether the user is a parent
 */
const shouldShowCtaBanner = appUser => appUser.isParent()

/**
 * Determines whether or not the user has responded to an upsell CTA.
 */
const shouldUpsell = location => Boolean(location?.state?.upsellRequest)

/**
 * Displays the upsell dialog.
 */
const showUpsell = (location, navigate) => alterLocationState(location, navigate, { upsellRequest: true })

/**
 * Closes out the upsell dialog.
 */
const dismissUpsell = (location, navigate) => alterLocationState(location, navigate, { upsellRequest: undefined })

/**
 * This function sends the successful login status to the parent window of the iframe via postMessage.
 */
const notifyLoginStatusFromIframe = ({ successfulLogin, navigate }) => {
  // We only send the message if we are in an iframe—not useful to do otherwise.
  const inIframe = window.top !== window.self
  if (!inIframe) {
    return
  }

  window.parent.postMessage(
    {
      ...(successfulLogin === undefined ? {} : { successfulLogin }),
      ...(navigate === undefined ? {} : { navigate })
    },
    window.location.origin
  )
}

/**
 * Validates if the provided one-time password meets the required criteria.
 *
 * @param {string} code The one-time password to validate
 * @returns {boolean} Whether the code is valid
 */
const isValidOTP = code => code?.length === OTP_LENGTH && /^\d+$/.test(code)

/**
 * Handles an error promise, retrying if the promise was resolved.
 *
 * @param {Promise} errorPromise The promise to handle
 * @param {function} errorPromiseProvider A function that returns the promise to handle
 * @param {function} retryFunction The function to retry if the promise was resolved
 */
const invokeErrorPromise = async (errorPromise, errorPromiseProvider, retryFunction) => {
  if (!errorPromise && errorPromiseProvider) {
    errorPromise = errorPromiseProvider()
  }

  if (errorPromise) {
    try {
      await errorPromise
      errorPromise = null
      return retryFunction()
    } catch (error) {
      errorPromise = null
      // Handle rejection based on specific retry function if needed
      return null
    }
  }

  return null
}

/**
 * Custom hook that provides methods for managing API refresh events across the application.
 * This centralizes the event handling logic and provides a clean interface for components
 * to dispatch and listen for refresh events.
 */
const API_REFRESH_EVENT = 'apiRefresh'

const dispatchApiRefresh = () => {
  const event = new CustomEvent(API_REFRESH_EVENT)
  window.dispatchEvent(event)
}

const addApiRefreshListener = callback => {
  window.addEventListener(API_REFRESH_EVENT, callback)
}

const removeApiRefreshListener = callback => {
  window.removeEventListener(API_REFRESH_EVENT, callback)
}

const getResourceIdFromLink = link => {
  const match = link?.split('?')[0].match(/\/resources\/([^/]+-\d+|\d+)$/)
  return match ? match[1] : undefined
}

const paymentCollectFunnel = joinFunnel => joinFunnel === FUNNEL_PAYMENT_COLLECT

const kickstartFromOnboarding = location => Boolean(location?.state?.onboardTrialOption === ONBOARD_KICKSTART)

const updateTrialOptionForLinearTracking = (location, navigate, trialOption) =>
  alterLocationState(location, navigate, { onboardTrialOption: trialOption })

export {
  shouldGatherTasks,
  shouldGreetNewTrial,
  dismissGreetNewTrial,
  shouldKickstart,
  showKickstart,
  dismissKickstart,
  shouldShowCtaBanner,
  shouldShowTransition,
  shouldShowMobileCta,
  shouldShowReferral,
  showReferral,
  dismissReferral,
  shouldShowWelcome,
  dismissWelcome,
  shouldUpsell,
  showUpsell,
  dismissUpsell,
  notifyLoginStatusFromIframe,
  isValidOTP,
  invokeErrorPromise,
  dispatchApiRefresh,
  addApiRefreshListener,
  removeApiRefreshListener,
  getResourceIdFromLink,
  showPaymentCaptureDialog,
  dismissPaymentCaptureAndShowKickstartDialog,
  dismissPaymentAndTrialDialogs,
  shouldPaymentCapture,
  shouldOnlyShowWelcomeTrial,
  shouldOnlyShowKickstart,
  shouldShowTrial,
  paymentCollectFunnel,
  updateTrialOptionForLinearTracking,
  kickstartFromOnboarding
}
