/**
 * MembershipCall displays an onboarding step that prompts the user to schedule a call.
 */
import React, { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles'

import zaggyYellowLine from '../assets/zaggy-yellow-line.svg'

import { useAppUser, useCurrentHousehold, useParentViewCapability } from '../app/hooks'
import { THEME_COLOR_GROUP_NEUTRAL, THEME_COLOR_GROUP_YELLOW, THEME_PRIMARY_YELLOW } from '../app/support/theme'
import { CAPABILITY_SAVE, CAPABILITY_START_NAVIGATOR_TRIAL } from '../arch/capabilityConstants'
import { trackTrialAddedFromAccountCreation, trackTrialSkippedFromAccountCreation } from '../tracking/segment'
import { NAVIGATOR_TRIAL_STATUS_SCHEDULED, PLATFORM_TRIAL_STATUS_NOT_STARTED } from '../arch/membershipConstants'

import {
  CHECKPOINT_EMPTY,
  CHECKPOINT_SCHEDULE_CONFIRMATION,
  CHECKPOINT_VERIFY_EMAIL,
  SCHEDULE_CALL_CLIENT_QUOTES,
  SCHEDULE_CALL_CLIENT_NAME
} from './constants'
import { OnboardingStatus } from './contexts'
import { TRIAL_KICKSTART } from './trial/constants'
import { identifyJoinerFunnel } from './utilities'

import ErrorSnackbar from '../subcomponents/ErrorSnackbar'

import OnboardingContainer from './support/OnboardingContainer'
import ScheduleCall from './support/ScheduleCall'

const useStyles = makeStyles(
  theme => ({
    scheduleContainer: {
      display: 'flex'
    },

    hero: {
      bottom: theme.spacing(4)
    },

    callMain: {
      margin: 0
    },

    wide: {
      maxWidth: theme.spacing(90)
    },

    accent: {
      backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N10
    },

    sidebarImage: {
      bottom: '32%',
      position: 'absolute',
      right: 0
    },

    clientColor: {
      color: THEME_COLOR_GROUP_NEUTRAL.N500
    },

    quotesColor: {
      color: THEME_COLOR_GROUP_YELLOW.Y100
    }
  }),
  {
    name: 'MembershipCall'
  }
)

const MembershipCall = props => {
  const appUser = useAppUser()

  const { isParentCapable } = useParentViewCapability()

  const { setOnboarding } = useContext(OnboardingStatus)

  const household = useCurrentHousehold()
  const { referringObject, platformTrialStatus, navigatorTrialStatus, loading } = household ?? {}

  const currentFunnel = identifyJoinerFunnel(referringObject)

  const [saving, setSaving] = useState(false)
  const [error, setError] = useState()
  const [isPageLoaded, setIsPageLoaded] = useState(false)

  const persistAndProceed = ({ event, checkpoint, kickstartCallDetails }) => {
    event.preventDefault()

    setOnboarding({
      checkpoint: checkpoint ?? CHECKPOINT_SCHEDULE_CONFIRMATION,
      boostCall: kickstartCallDetails?.object
    })
  }

  const handleClose = event => {
    trackTrialSkippedFromAccountCreation(appUser, TRIAL_KICKSTART, currentFunnel)
    setSaving(true)

    try {
      const isFreeTierUser =
        isParentCapable(CAPABILITY_START_NAVIGATOR_TRIAL) && platformTrialStatus === PLATFORM_TRIAL_STATUS_NOT_STARTED
      const isEmailConfirmed = isParentCapable(CAPABILITY_SAVE)

      persistAndProceed({
        event,
        checkpoint: isFreeTierUser || isEmailConfirmed ? CHECKPOINT_EMPTY : CHECKPOINT_VERIFY_EMAIL
      })
    } finally {
      setSaving(false)
    }
  }

  const handleSchedule = (event, kickstartCallDetails) => {
    trackTrialAddedFromAccountCreation(appUser, TRIAL_KICKSTART, currentFunnel)
    persistAndProceed({ event, kickstartCallDetails })
  }

  useEffect(() => {
    if (!loading) {
      setIsPageLoaded(true)

      // If the page is loaded and the user has already scheduled a call,
      // we need to show the email confirmation page instead of the schedule page
      // This check ensures it runs only on the initial render
      if (!isPageLoaded && navigatorTrialStatus === NAVIGATOR_TRIAL_STATUS_SCHEDULED) {
        setOnboarding({ checkpoint: CHECKPOINT_VERIFY_EMAIL })
      }
    }
  }, [loading, navigatorTrialStatus, isPageLoaded, setOnboarding])

  const classes = useStyles(props)

  if (!appUser) {
    return <Navigate to="/login" />
  }

  return (
    <OnboardingContainer
      classes={{ img: classes.hero, accent: classes.accent, wide: classes.wide }}
      theme={THEME_PRIMARY_YELLOW.main}
      saving={saving}
      sidebarProps={{
        classes: { sidebarImage: classes.sidebarImage, client: classes.clientColor, quotesColor: classes.quotesColor },
        text: SCHEDULE_CALL_CLIENT_QUOTES,
        member: SCHEDULE_CALL_CLIENT_NAME,
        quoteVisible: true,
        vectorImage: zaggyYellowLine
      }}
      hideCheckpoints
    >
      <section className={classes.scheduleContainer}>
        <ScheduleCall classes={{ main: classes.callMain }} onSchedule={handleSchedule} onClose={handleClose} />
      </section>

      <ErrorSnackbar error={error} setError={setError} />
    </OnboardingContainer>
  )
}

export default MembershipCall
