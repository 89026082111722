import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import CircularProgress from '@mui/material/CircularProgress'
import Fade from '@mui/material/Fade'

import { MenubarContext } from '../../app/contexts'
import { useAppUser, useParentViewCapability } from '../../app/hooks'
import { THEME_COLOR_GROUP_NEUTRAL } from '../../app/support/theme'
import { CAPABILITY_HAVE_MEMBERSHIP, CAPABILITY_VIEW_PRIMARY_UI } from '../../arch/capabilityConstants'
import { CAN_BE_SUPERUSER } from '../../arch/constants'
import { userDialogStyles } from '../../commonStyles'

import { ONBOARDING_CONCLUSION } from '../constants'
import { OnboardingStatus } from '../contexts'

import ErrorSnackbar from '../../subcomponents/ErrorSnackbar'
import Lettermark from '../../subcomponents/brand/Lettermark'
import Wordmark from '../../subcomponents/brand/Wordmark'

import OnboardingHeader from './OnboardingHeader'
import OnboardingSidebarComponent from './OnboardingSidebarComponent'

const BREADCRUMB_LARGE = 1062
const BREADCRUMB_MEDIUM = 960
const BREADCRUMB_SMALL = 768
const BREADCRUMB_XSMALL = 618

export const buttonStyles = theme => ({
  buttonContainer: {
    display: 'flex'
  },

  filledButton: {
    padding: '12px 24px'
  },

  promptButton: {
    padding: theme.spacing(1, 2)
  },

  inlineProgress: {
    color: 'currentColor',
    marginLeft: theme.spacing(1)
  }
})

const useStyles = makeStyles(
  theme => ({
    ...userDialogStyles(theme),

    wordmarkLogoContainer: {
      '&:before': {
        borderBottomLeftRadius: theme.spacing(0.5),
        borderTopLeftRadius: theme.spacing(0.5),
        width: theme.spacing(0.625)
      }
    },

    wordmarkLogo: {
      height: theme.spacing(4.5)
    },

    lettermark: {
      position: 'absolute',
      left: 0
    },

    breadcrumbs: {
      minHeight: theme.spacing(10),

      [theme.breakpoints.between(BREADCRUMB_MEDIUM, BREADCRUMB_LARGE)]: {
        display: 'flex',
        marginLeft: theme.spacing(7)
      },

      [theme.breakpoints.between(BREADCRUMB_XSMALL, BREADCRUMB_SMALL)]: {
        display: 'flex',
        marginLeft: theme.spacing(7)
      },

      [theme.breakpoints.down(BREADCRUMB_XSMALL)]: {
        marginLeft: theme.spacing(11)
      }
    },

    titleContainer: {
      margin: theme.spacing(5, 0, 3),

      [theme.breakpoints.up('brandSmall')]: {
        margin: theme.spacing(5, 0, 4)
      }
    },

    reverse: {
      display: 'flex',
      flexDirection: 'column-reverse'
    },

    title: {
      margin: 0
    },

    subtitle: {
      margin: theme.spacing(1, 0, 0)
    },

    wide: {
      // Overrides the common style.
      maxWidth: theme.spacing(90),

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 7)
      }
    },

    nav: {
      display: 'flex',
      alignItems: 'center'
    },

    aside: {
      backgroundColor: ({ theme }) => theme,
      width: theme.spacing(41.25)
    },

    asideWithSidebar: {
      width: '33%'
    },

    sidebar: {
      backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N10,
      borderColor: THEME_COLOR_GROUP_NEUTRAL.N30,
      borderRadius: theme.spacing(1),
      borderStyle: 'solid',
      borderWidth: theme.spacing(0.125),
      margin: theme.spacing(21.375, 7),
      minWidth: theme.spacing(39.5),
      padding: theme.spacing(3)
    },

    img: {
      bottom: theme.spacing(10),
      position: 'absolute',
      right: theme.spacing(0),
      zIndex: -1 // To keep it beneath the sidebar.
    },

    imgWithSidebar: {
      bottom: theme.spacing(2.75),
      right: theme.spacing(20.5),
      width: theme.spacing(22.5)
    },

    progress: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
      width: '100%',

      '& > *': {
        margin: theme.spacing(1)
      }
    },

    copyright: {
      bottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'flex-end',
      left: theme.spacing(6),
      position: 'absolute',
      right: theme.spacing(6)
    },

    dialogSpacing: {
      [theme.breakpoints.up('brandSmall')]: {
        marginBottom: 0
      }
    }
  }),
  {
    name: 'OnboardingContainer'
  }
)

const OnboardingContainer = props => {
  const {
    children,
    className,
    image,
    title,
    subtitle,
    previous,
    reverseTitleAndSubtitle,
    sidebar,
    sidebarProps,
    joinState,
    theme,
    error,
    setError,
    saving,
    hideCheckpoints
  } = props

  const { setMenubarVisible } = useContext(MenubarContext)
  const { additionalProperties } = useContext(OnboardingStatus)
  const { showMenu } = additionalProperties

  const appUser = useAppUser()
  const navigate = useNavigate()

  const { isParentCapable } = useParentViewCapability()

  const canHaveMembership = isParentCapable(CAPABILITY_HAVE_MEMBERSHIP)
  const canGoHome = isParentCapable(CAPABILITY_VIEW_PRIMARY_UI)

  useEffect(() => {
    // This component typically hides the app menu unless specifically told to do so.
    if (showMenu) {
      return
    }

    setMenubarVisible(false)

    return () => {
      setMenubarVisible(true)
    }
  }, [setMenubarVisible, showMenu])

  useEffect(() => {
    // No onboarding check for superuser (for now while /join is gated).
    if (!appUser || appUser.role(CAN_BE_SUPERUSER)) {
      return
    }

    // TOOD The canGoHome check ensures that we avoid a redirect loop because going home will
    //      kick us out if we don’t have the primary UI capability. Due to recent changes in
    //      signup flows, it is currently unknown whether this change has other impacts beyond
    //      avoiding the loop. Further signup flow testing is needed.
    if (!canHaveMembership && canGoHome) {
      navigate(`/${ONBOARDING_CONCLUSION}`)
    }
  }, [appUser, navigate, canHaveMembership, canGoHome])

  const classes = useStyles(props)
  return (
    <section className={clsx(classes.root, className)}>
      <article className={classes.dialogContainer}>
        <section className={clsx(classes.dialog, classes.wide, classes.dialogSpacing)}>
          {hideCheckpoints ? (
            <Wordmark classes={{ logoContainer: classes.wordmarkLogoContainer, logo: classes.wordmarkLogo }} />
          ) : (
            <section className={classes.breadcrumbs}>
              <Lettermark className={classes.lettermark} />
              <OnboardingHeader theme={theme} previous={previous} joinState={joinState} />
            </section>
          )}

          <Fade in>
            <section>
              <section className={clsx(classes.titleContainer, reverseTitleAndSubtitle && classes.reverse)}>
                {title && <h1 className={classes.title}>{title}</h1>}
                {subtitle && <h3 className={classes.subtitle}>{subtitle}</h3>}
              </section>

              {saving ? (
                <section className={classes.progress}>
                  {/* Saving takes a while so we make this a mutually-exclusive display. */}
                  <CircularProgress />
                  <section>Saving your info...</section>
                </section>
              ) : (
                children
              )}
            </section>
          </Fade>
        </section>

        {error && setError && <ErrorSnackbar error={error} setError={setError} />}
      </article>

      {theme && (
        <aside className={clsx(classes.accent, classes.aside, sidebar && classes.asideWithSidebar)}>
          {sidebar}

          {image && (
            <img className={clsx(classes.img, sidebar && classes.imgWithSidebar)} src={image} alt="Mission graphic" />
          )}

          {sidebarProps && <OnboardingSidebarComponent {...sidebarProps} />}
        </aside>
      )}
    </section>
  )
}

OnboardingContainer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node, // The title to show.
  subtitle: PropTypes.node, // The subtitle/description to show.
  previous: PropTypes.string, // The checkpoint to set if the user moves back.
  reverseTitleAndSubtitle: PropTypes.bool, // Whether to swap title and subtitle order.
  sidebar: PropTypes.node, // If present, will be rendered directly within the aside.
  sidebarProps: PropTypes.object, // List of properties to pass to OnboardingSidebar.
  joinState: PropTypes.object, // Special-case value to send to '/join' if we are still signing up.
  theme: PropTypes.string, // Base color to use for certain elements.
  error: PropTypes.string, // Error message to display, if any.
  setError: PropTypes.func, // Function for updating the error value.
  saving: PropTypes.bool, // Whether the caller is currently saving values.
  hideCheckpoints: PropTypes.bool // Whether to show the checkpoint breadcrumbs.
}

export default OnboardingContainer
