import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import CircularProgress from '@mui/material/CircularProgress'

import CircleCheckFilled from '../../icons/CircleCheckFilled'

import { postOnboardingTrialMembership, postTrialMembership } from '../../api'
import { useAppUserRefresh, useCurrentHousehold, useAppUser } from '../../app/hooks'
import { THEME_COLOR_GROUP_GREEN } from '../../app/support/theme'
import {
  dispatchApiRefresh,
  showPaymentCaptureDialog,
  showKickstart,
  dismissUpsell,
  updateTrialOptionForLinearTracking
} from '../../app/utilities'
import { getHookPromiseErrorHandler } from '../../common'
import { trackTrialAddedFromInApp } from '../../tracking/segment'

import { identifyJoinerFunnel } from '../utilities'
import { FUNNEL_INTENT } from '../constants'

import { TRIAL_KICKSTART, TRIAL_OPTIONS, TRIAL_PLATFORM, VARIANT_IN_APP, VARIANT_ONBOARDING } from './constants'

import BrandButton from '../../subcomponents/brand/BrandButton'
import ErrorSnackbar from '../../subcomponents/ErrorSnackbar'
import LinkButton from '../../subcomponents/LinkButton'
import Ribbon from '../../subcomponents/Ribbon'

import HelpPrompt from '../support/HelpPrompt'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2)
    },

    title: {
      marginBlock: 0
    },

    subtitle: {},

    trialInfo: {
      display: 'flex',
      listStyle: 'none',
      margin: 0,
      padding: 0
    },

    infoItem: {
      '&:not(:first-child):before': {
        content: '"|"',
        padding: theme.spacing(0, 1)
      }
    },

    optionContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginBlock: theme.spacing(3)
    },

    options: {
      borderStyle: 'solid',
      borderWidth: theme.spacing(0.25),
      borderColor: theme.palette.border,
      borderRadius: theme.spacing(2),
      padding: theme.spacing(2, 3),
      cursor: 'pointer',
      position: 'relative',
      transition: 'all 0.3s ease',

      '&:hover': {
        borderColor: theme.palette.success.light,
        backgroundColor: THEME_COLOR_GROUP_GREEN.G5
      }
    },

    optionSelected: {
      backgroundColor: THEME_COLOR_GROUP_GREEN.G10,
      borderColor: theme.palette.success.main,
      paddingInline: theme.spacing(5.5),
      marginInline: theme.spacing(-2.5)
    },

    checkIcon: {
      position: 'absolute',
      top: theme.spacing(-1.75),
      right: theme.spacing(-1.75),
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: theme.palette.success.main,
      opacity: 0,
      transition: 'opacity 0.3s ease',

      '&.visible': {
        opacity: 1
      }
    },

    optionTitleContainer: {
      alignItems: 'center',
      display: 'flex',
      gap: theme.spacing(2),
      flexWrap: 'wrap-reverse'
    },

    optionTitle: {
      marginBlock: theme.spacing(0)
    },

    optionDescription: {
      whiteSpace: 'break-spaces'
    },

    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },

    startButton: {
      padding: theme.spacing(2, 3)
    },

    maybeLater: {
      textDecorationSkipInk: 'none',
      textUnderlinePosition: 'from-font'
    },

    support: {
      display: 'flex',
      justifyContent: 'flex-end'
    },

    ribbonRoot: {
      '&:after': {
        borderWidth: theme.spacing(1.375),
        height: theme.spacing(2.75),
        right: theme.spacing(-2.25),

        [theme.breakpoints.down('sm')]: {
          height: theme.spacing(2.5625),
          borderWidth: theme.spacing(1.25)
        }
      }
    },

    ribbonContent: {
      borderRadius: theme.spacing(0.25),
      fontSize: theme.typography.pxToRem(11),
      fontWeight: theme.typography.fontWeightMedium,
      paddingRight: theme.spacing(0.5),
      whiteSpace: 'nowrap'
    },

    progressContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(2)
    },

    progress: {
      color: theme.palette.text.primary
    }
  }),
  {
    name: 'TrialUpsell'
  }
)

const TrialOption = ({ option, isSelected, onClick, classes }) => (
  <section className={clsx(classes.options, isSelected && classes.optionSelected)} onClick={onClick}>
    <CircleCheckFilled className={clsx(classes.checkIcon, isSelected && 'visible')} />
    <section className={classes.optionContent}>
      <span className={classes.optionTitleContainer}>
        <h5 className={classes.optionTitle}>{option.title}</h5>

        {option.isPopular && (
          <Ribbon
            classes={{
              root: classes.ribbonRoot,
              content: classes.ribbonContent
            }}
            ribbonColor={THEME_COLOR_GROUP_GREEN.G100}
          >
            Most popular!
          </Ribbon>
        )}
      </span>
      <span className={classes.optionDescription}>{option.description}</span>
    </section>
  </section>
)

const popularOption = intentFunnel => (intentFunnel ? TRIAL_KICKSTART : TRIAL_PLATFORM)

const TrialUpsell = props => {
  const { variant = VARIANT_IN_APP, onConfirm, onCancel } = props
  const onboarding = variant === VARIANT_ONBOARDING

  const appUser = useAppUser()
  const location = useLocation()
  const navigate = useNavigate()

  const refreshAppUser = useAppUserRefresh()
  const { id: householdId, loading, referringObject, hasActivePaymentMethod } = useCurrentHousehold()

  const [selectedOption, setSelectedOption] = useState()
  const [startingTrial, setStartingTrial] = useState(false)
  const [isIntentFunnel, setIntentFunnel] = useState(false)

  const [error, setError] = useState()
  const handleApiError = useMemo(() => getHookPromiseErrorHandler(setError), [])

  const handleTrialOption = async () => {
    setStartingTrial(true)

    const processTrial = async trialAction => {
      await trialAction()
      await refreshAppUser()
      dispatchApiRefresh()
    }

    try {
      if (onboarding) {
        await processTrial(() => postOnboardingTrialMembership(householdId))
        updateTrialOptionForLinearTracking(location, navigate, `${VARIANT_ONBOARDING}${selectedOption}`)
      } else if (!hasActivePaymentMethod) {
        showPaymentCaptureDialog(location, navigate, selectedOption)
      } else {
        await processTrial(() => postTrialMembership(householdId))
        handlePostTrialActions()
      }
    } catch (error) {
      handleApiError(error)
    } finally {
      setStartingTrial(false)
    }

    if (onConfirm) {
      onConfirm(selectedOption)
    }
  }

  const handlePostTrialActions = () => {
    if (selectedOption === TRIAL_KICKSTART) {
      showKickstart(location, navigate)
    } else {
      trackTrialAddedFromInApp(appUser, TRIAL_PLATFORM)
      dismissUpsell(location, navigate)
    }
  }

  const handleOptionClick = option => setSelectedOption(option)

  useEffect(() => {
    if (!loading) {
      const funnel = identifyJoinerFunnel(referringObject)
      const intentFunnel = onboarding && funnel === FUNNEL_INTENT

      setIntentFunnel(intentFunnel)
      setSelectedOption(popularOption(intentFunnel))
    }
  }, [loading, onboarding, referringObject])

  const classes = useStyles(props)

  if (loading) {
    return (
      <section className={classes.progressContainer}>
        <CircularProgress className={classes.progress} />
      </section>
    )
  }

  return (
    <section className={classes.root}>
      <h1 className={classes.title}>How would you like to try Undivided?</h1>

      <span className={classes.subtitle}>
        Create a Roadmap for your child to discover unrealized benefits and supports.
      </span>

      <ul className={classes.trialInfo}>
        <li className={classes.infoItem}>Try free for 30 days</li>
        <li className={classes.infoItem}>Memberships start at $9/month</li>
        <li className={classes.infoItem}>Cancel anytime</li>
      </ul>

      <section className={classes.optionContainer}>
        {TRIAL_OPTIONS.map(({ value, title, description }) => (
          <TrialOption
            key={value}
            option={{ title, description, isPopular: popularOption(isIntentFunnel) === value }}
            isSelected={selectedOption === value}
            onClick={() => handleOptionClick(value)}
            classes={classes}
          />
        ))}
      </section>

      <section className={classes.buttonContainer}>
        <BrandButton
          variant="contained"
          className={classes.startButton}
          endIcon={startingTrial ? <CircularProgress className={classes.progress} size={16} /> : undefined}
          onClick={handleTrialOption}
          disabled={startingTrial}
        >
          Start trial
        </BrandButton>

        <LinkButton className={classes.maybeLater} onClick={onCancel}>
          {onboarding ? 'Explore first' : 'Later'}
        </LinkButton>
      </section>

      <section className={classes.support}>
        <HelpPrompt>Questions? Contact support</HelpPrompt>
      </section>

      <ErrorSnackbar error={error} setError={setError} />
    </section>
  )
}

TrialUpsell.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf([VARIANT_ONBOARDING, VARIANT_IN_APP]), // Variant to display (mainly impacts copy).
  onConfirm: PropTypes.func, // Function to call if the user accepts the upsell.
  onCancel: PropTypes.func // Function to call if the user declines the upsell.
}

export default TrialUpsell
