import React, { forwardRef } from 'react'

import makeStyles from '@mui/styles/makeStyles'

import { THEME_COLOR_GROUP_LINK } from '../app/support/theme'

import BrandButton from './brand/BrandButton'

const useStyles = makeStyles(
  theme => ({
    root: {
      borderColor: 'rgba(0, 0, 0, 0)',
      borderStyle: 'dashed',
      borderWidth: theme.spacing(0.125),
      color: THEME_COLOR_GROUP_LINK.main,
      minWidth: 'auto',
      transition: theme.transitions.create(['border-color', 'color'], {
        // Replicates Button except for transition properties.
        duration: theme.transitions.duration.short
      }),

      '&:visited': {
        // For LinkButtons that have actual links.
        color: THEME_COLOR_GROUP_LINK.main
      },

      '&:hover': {
        backgroundColor: 'initial',
        color: THEME_COLOR_GROUP_LINK.hover,
        textDecoration: 'underline'
      },

      '&:active': {
        color: THEME_COLOR_GROUP_LINK.active
      }
    },

    disabled: {
      color: theme.palette.text.disabled,

      '&:hover': {
        color: theme.palette.text.disabled
      }
    },

    startIcon: {},
    endIcon: {},

    focusVisible: {
      borderColor: THEME_COLOR_GROUP_LINK.main
    },

    iconSizeMedium: {},

    label: {
      ...theme.typography.button,
      textTransform: 'none'
    },

    text: {
      padding: theme.spacing(0, 0.25),
      textDecoration: 'underline'
    }
  }),
  {
    name: 'LinkButton'
  }
)

const LinkButton = forwardRef((props, ref) => {
  const { children, classes: classesProp, ...rest } = props
  const classes = useStyles(props)
  return (
    <BrandButton ref={ref} classes={classes} disableRipple {...rest}>
      {children}
    </BrandButton>
  )
})

export default LinkButton
