import React from 'react'
import { Link } from 'react-router-dom'

import { THEME_COLOR_GROUP_GREEN, THEME_COLOR_GROUP_YELLOW } from '../app/support/theme'
import { listToMap } from '../common'

import futureRoadmap from '../assets/future-roadmap.svg'

import welcomeRoadmapImage from '../assets/welcome-roadmap.svg'
import trackGoalProgressImage from '../assets/track-goal-progress.svg'
import readyToStartGoalImage from '../assets/ready-to-start-goal.svg'

import {
  GOAL_STATUSES,
  GOAL_STATUS_ACHIEVED,
  GOAL_STATUS_ACTIVE,
  GOAL_STATUS_DRAFT,
  GOAL_STATUS_FUTURE,
  GOAL_TYPE_ADD_ON_SERVICE,
  GOAL_TYPE_LEGACY,
  GOAL_TYPE_STANDARD,
  GOAL_TYPE_SUPER_GOAL,
  GOAL_TYPE_FOUNDATIONAL,
  GOAL_TYPE_ANNUAL,
  GOAL_TYPE_FIRST
} from '../constants'

import EmptyPlanningCategory from './support/EmptyPlanningCategory'

const GOAL_TYPE_SPECIFICATIONS = [
  { label: 'Add-on Service', value: GOAL_TYPE_ADD_ON_SERVICE, color: THEME_COLOR_GROUP_YELLOW.Y200, chatEnabled: true },
  { label: 'Annual', value: GOAL_TYPE_ANNUAL },
  { label: 'First', value: GOAL_TYPE_FIRST },
  { label: 'Foundational', value: GOAL_TYPE_FOUNDATIONAL },
  { label: 'Legacy', value: GOAL_TYPE_LEGACY, color: THEME_COLOR_GROUP_GREEN.G100, cardLabel: '', chatEnabled: true },
  {
    label: 'Standard',
    value: GOAL_TYPE_STANDARD,
    color: THEME_COLOR_GROUP_GREEN.G100,
    cardLabel: '',
    chatEnabled: false
  },
  { label: 'Super Goal', value: GOAL_TYPE_SUPER_GOAL }
]

const GOAL_TYPE_SPECIFICATIONS_MAP = listToMap(GOAL_TYPE_SPECIFICATIONS, 'value')

const createGoalCategory = ({ status, title, ...rest }) => {
  const key = status
  return {
    key,
    title: title || GOAL_STATUSES[key].label,
    styleSuffix: key,
    status,
    ...rest
  }
}

const LOCKED_GOAL_CATEGORIES = [
  createGoalCategory({
    status: GOAL_STATUS_FUTURE,
    expandGoalsInitially: false,
    lazyLoad: false,
    draft: false,
    emptyTitle: 'For later goals on the way…',
    emptyMessage: (
      <span>
        Future goals show here—your Navigator will help you set them up. Have a goal in mind? Add it as a{' '}
        <Link to="/vision">need or want</Link>.
      </span>
    )
  })
]

const GOAL_CATEGORIES = [
  createGoalCategory({
    status: GOAL_STATUS_ACTIVE,
    expandGoalsInitially: true,
    lazyLoad: false,
    draft: false,
    emptyTitle: 'Active goals on the way…',
    emptyMessage: (
      <span>
        Your Navigator will help you set up and activate your goals. Have a goal in mind? Add it as a{' '}
        <Link to="/vision">need or want</Link>.
      </span>
    )
  }),

  ...LOCKED_GOAL_CATEGORIES,

  createGoalCategory({
    status: GOAL_STATUS_ACHIEVED,
    expandGoalsInitially: false,
    lazyLoad: true,
    draft: false,
    emptyTitle: 'Achieved goals on the way…',
    emptyMessage: <span>When you complete a goal (and you will), you’ll see your success here.</span>
  }),

  createGoalCategory({
    status: GOAL_STATUS_DRAFT,
    expandGoalsInitially: false,
    lazyLoad: true,
    draft: true,
    emptyTitle: 'No draft Goals',
    navigatorOnly: true
  })
]

const PLANNING_CATEGORY_DRAFT = createGoalCategory({
  status: GOAL_STATUS_DRAFT,
  expandGoalsInitially: true,
  lazyLoad: false,
  draft: true,
  emptyTitle: 'User has no draft goals',
  EmptyComponent: EmptyPlanningCategory,
  navigatorOnly: true
})

const PLANNING_CATEGORY_ACTIVE = createGoalCategory({
  status: GOAL_STATUS_ACTIVE,
  title: 'Working on it',
  expandGoalsInitially: true,
  lazyLoad: false,
  draft: false,
  emptyTitle: 'You’re not working on any goals right now.',
  emptyMessage: (
    <span>
      Your Navigator will help you set up and activate your goals. Have an idea in mind? Add it as a{' '}
      <Link to="../checklist">task</Link>.
    </span>
  )
})

const PLANNING_CATEGORY_FUTURE = createGoalCategory({
  status: GOAL_STATUS_FUTURE,
  title: 'Future Roadmap',
  expandGoalsInitially: false,
  lazyLoad: false,
  draft: false,
  hideIfEmpty: true,
  backgroundImagePath: futureRoadmap,
  showToggleButton: true,
  infoIconTooltipContent: 'Goals that you will accomplish in the years to come',
  hideSubscript: true
})

const PLANNING_CATEGORY_ACHIEVED = createGoalCategory({
  status: GOAL_STATUS_ACHIEVED,
  title: 'Completed',
  expandGoalsInitially: false,
  lazyLoad: true,
  draft: false,
  hideIfEmpty: true,
  emptyTitle: 'User has no complete goals'
})

const ROADMAP_STEPS = [
  {
    title: 'Welcome to your Roadmap!',
    description:
      'A Roadmap is a suggested plan of goals to reach, over time. We’ll suggest ages for each goal, but they can be moved to fit your needs and meet you where you are.',
    image: welcomeRoadmapImage
  },
  {
    title: 'Track real progress in a goal workspace',
    description:
      'Each goal is equipped with a dedicated workspace. With a membership, you’ll have full access to step-by-step guidance, and actionable tasks.',
    image: trackGoalProgressImage
  },
  {
    title: 'Achieve your goals!',
    description:
      'Start on a goal, and access all the tools needed to achieve it. After you accomplish a goal, we’ll suggest new ones to reach new milestones.',
    image: readyToStartGoalImage
  }
]

const BASE_PLANNING_CATEGORIES = [PLANNING_CATEGORY_ACTIVE, PLANNING_CATEGORY_FUTURE, PLANNING_CATEGORY_ACHIEVED]

const ALL_PLANNING_CATEGORIES = [PLANNING_CATEGORY_DRAFT, ...BASE_PLANNING_CATEGORIES]

const UNCHECKED_DEFAULT_LIMIT = 500
const CHECKED_DEFAULT_LIMIT = 500

const CHECKLIST_HIGHLIGHT_PARAM = 'highlightId'

const CHECKLIST_ITEM_PRIORITY_HIGH = 'high'
const CHECKLIST_ITEM_PRIORITY_LOW = 'low'

const ALL_GOALS_LABEL = 'All goals'
const ALL_GOALS_VALUE = 'all-goals'

const ROADMAP_TEMPLATES_LABEL = 'Roadmap templates'
const ROADMAP_TEMPLATES_VALUE = 'roadmap-templates'

const GOAL_ACTION_DELETE = 'deleted'
const GOAL_ACTION_UPDATE = 'updateGoal'
const GOAL_ACTION_STATUS_UPDATE = 'updateGoalStatus'
const GOAL_ACTION_DRAFT = 'draft'

const GOAL_ACTION_SNACKBAR_MESSAGE = {
  [GOAL_ACTION_DRAFT]: 'Goal marked draft.',
  [GOAL_ACTION_UPDATE]: 'Goal is updated',
  [GOAL_ACTION_DELETE]: 'Goal is deleted'
}

const ROADMAP_MAX_DISPLAYABLE_AGE = 22

// This is the only roadmap category with a fixed key. All other categories
// are derived from ages.
const ROADMAP_CATEGORY_COMPLETED = 'completedGoal'
const ROADMAP_CATEGORY_BIRTH_LABEL = 'Birth'
const ROADMAP_CATEGORY_MAX_LABEL = `Over ${ROADMAP_MAX_DISPLAYABLE_AGE}`

const ADD_GOALS_LIST = [
  {
    label: ALL_GOALS_LABEL,
    value: ALL_GOALS_VALUE
  },
  {
    label: ROADMAP_TEMPLATES_LABEL,
    value: ROADMAP_TEMPLATES_VALUE
  }
]

const INTENT_GET_ROADMAP = 'getRoadmap'

const READ_ONLY_MESSAGE = 'This task is derived from a goal and cannot be edited.'

// Even though these constants pertain to goal subviews, they are defined here in order
// to avoid import/reference/chunk order issues due to the dependency chain of the
// modules that import them.
const GOAL_SUBVIEW_ABOUT = 'aboutTheGoal'
const GOAL_SUBVIEW_TASKS_NOTES_DOCS = 'tasksNotesDocuments'
const GOAL_SUBVIEW_DOCS_NOTES = 'documentsNotes'
const GOAL_SUBVIEW_DOCS = 'documents'
const GOAL_SUBVIEW_NOTES = 'notes'
const GOAL_SUBVIEW_TASKS = 'tasks'
const GOAL_SUBVIEW_TRIAL_CTA = 'trialCta'
const GOAL_SUBVIEW_START_TOOLBAR = 'startGoalToolbar'
const GOAL_SUBVIEW_ACTIVE_TOOLBAR = 'activeGoalToolbar'
const GOAL_SUBVIEW_RESTART_CTA = 'restartMembershipCta'
const GOAL_SUBVIEW_COMPLETE_GOAL_TOOLBAR = 'completeGoalToolbar'
const GOAL_SUBVIEW_STEPS = 'steps'
const GOAL_SUBVIEW_RESOURCES = 'resources'
const GOAL_SUBVIEW_COMPLETED_TOOLBAR = 'completedGoalToolbar'

export {
  GOAL_TYPE_SPECIFICATIONS,
  GOAL_TYPE_SPECIFICATIONS_MAP,
  GOAL_CATEGORIES,
  PLANNING_CATEGORY_ACTIVE,
  PLANNING_CATEGORY_FUTURE,
  PLANNING_CATEGORY_ACHIEVED,
  BASE_PLANNING_CATEGORIES,
  ALL_PLANNING_CATEGORIES,
  UNCHECKED_DEFAULT_LIMIT,
  CHECKED_DEFAULT_LIMIT,
  CHECKLIST_HIGHLIGHT_PARAM,
  CHECKLIST_ITEM_PRIORITY_HIGH,
  CHECKLIST_ITEM_PRIORITY_LOW,
  LOCKED_GOAL_CATEGORIES,
  ADD_GOALS_LIST,
  ALL_GOALS_VALUE,
  ROADMAP_TEMPLATES_VALUE,
  GOAL_ACTION_DELETE,
  GOAL_ACTION_UPDATE,
  GOAL_ACTION_STATUS_UPDATE,
  GOAL_ACTION_DRAFT,
  GOAL_ACTION_SNACKBAR_MESSAGE,
  GOAL_SUBVIEW_ABOUT,
  GOAL_SUBVIEW_TASKS_NOTES_DOCS,
  GOAL_SUBVIEW_DOCS_NOTES,
  GOAL_SUBVIEW_DOCS,
  GOAL_SUBVIEW_NOTES,
  GOAL_SUBVIEW_TASKS,
  GOAL_SUBVIEW_TRIAL_CTA,
  GOAL_SUBVIEW_START_TOOLBAR,
  GOAL_SUBVIEW_ACTIVE_TOOLBAR,
  GOAL_SUBVIEW_RESTART_CTA,
  GOAL_SUBVIEW_COMPLETE_GOAL_TOOLBAR,
  GOAL_SUBVIEW_COMPLETED_TOOLBAR,
  GOAL_SUBVIEW_STEPS,
  GOAL_SUBVIEW_RESOURCES,
  READ_ONLY_MESSAGE,
  ROADMAP_CATEGORY_COMPLETED,
  ROADMAP_CATEGORY_BIRTH_LABEL,
  ROADMAP_CATEGORY_MAX_LABEL,
  ROADMAP_MAX_DISPLAYABLE_AGE,
  ROADMAP_STEPS,
  INTENT_GET_ROADMAP
}
