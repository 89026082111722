import { useState, useEffect, useRef, useCallback, useMemo } from 'react'

import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'

import { getResourceFilters } from './api'
import { useAppUser } from './app/hooks'
import { getAllResourceTopics, nameComparator, TOPIC_FRONT_END_PROPERTIES } from './domain/topic'
import { getPreference, setPreference, PHASE } from './domain/user'
import { trackIdleTimeout } from './tracking/segment'
import { getTypeCategories, getDiagnosisTags, getCategoryTags, getDocumentTypeTags } from './typeUtilities'

import ConfirmationDialog from './subcomponents/ConfirmationDialog'
import UndoDeleteSnackbar from './subcomponents/UndoDeleteSnackbar'

/**
 * useContentFilters captures the retrieval of standard Undivided content filters (used for
 * knowledge base articles, guides, etc.).
 *
 * It starts out undefined then becomes an array upon loading, or else it will call the
 * optional `setError` function if something goes wrong (which should be very rare, but
 * can still happen).
 *
 * @param {function} setError (optional) error setter function in case something goes wrong
 * @returns object consisting of Undivided filter values
 */
const useContentFilters = setError => {
  // Start filters as undefined to show that they haven’t loaded yet.
  const [filters, setFilters] = useState()

  useEffect(() => {
    let active = true

    const retrieveFilters = async () => {
      try {
        const filters = await getResourceFilters()
        if (!active) {
          return
        }

        setFilters(filters)
      } catch (error) {
        if (setError) {
          setError(error)
        }
      }
    }

    retrieveFilters()
    return () => {
      active = false
    }
  }, [setError])

  return filters
}

const DOCUMENT_TYPE_NONE = { display: '', value: '' }

/**
 * useDocumentTypes provides coordinate state values for document category and type,
 * capturing the way the category influences the possible values for type.
 *
 * @param {string} category the currently-selected category
 * @param {function} setCategory setter for the category
 * @param {string} documentType the currently-selected document type
 * @param {function} setDocumentType the setter for that document type
 * @param {function} setError (optional) error setter function in case something goes wrong
 *
 * @returns object consisting of:
 *   categories: all known categories
 *   documentTypes: the document types for the current category
 *   handleCategoryChange: function (event) for updating the current category
 *   handleDocumentTypeChange: function (event) for updating the current document type
 *   typesLoading: a boolean indicating whether types are still loading
 */
const useDocumentTypes = ({ category, setCategory, documentType, setDocumentType, setError }) => {
  const [categories, setCategories] = useState({})
  const [typesLoading, setTypesLoading] = useState(false)

  const documentTypes = [DOCUMENT_TYPE_NONE, ...(category && categories[category] ? categories[category] : [])]

  const handleCategoryChange = event => {
    const newCategory = event.target.value
    const newDocumentType =
      newCategory && categories[newCategory] ? (categories[newCategory].includes(documentType) ? documentType : '') : ''

    setCategory(newCategory)
    setDocumentType(newDocumentType)
  }

  const handleDocumentTypeChange = event => setDocumentType(event.target.value)

  useEffect(() => {
    let active = true

    const retrieveTypeCategories = async () => {
      setTypesLoading(true)
      try {
        const categories = await getTypeCategories()
        if (!active) {
          return
        }

        setTypesLoading(false)
        setCategories(categories)
      } catch (error) {
        if (!active) {
          return
        }

        setTypesLoading(false)

        if (setError) {
          setError(error)
        }
      }
    }

    retrieveTypeCategories()
    return () => {
      active = false
    }
  }, [setError])

  return {
    categories,
    documentTypes,
    handleCategoryChange,
    handleDocumentTypeChange,
    typesLoading
  }
}

/**
 * useDocumentTags is a simple container for retrieving category and document type tags,
 * since they are accessed asynchronously and are used together most of the time.
 *
 * @param {function} setError (optional) error setter function in case something goes wrong
 * @returns object consisting of:
 *   categoryTags: all known category tags
 *   documentTypeTags: all known document type tags
 *   tagsLoading: a boolean indicating whether tags are still loading
 */
const useDocumentTags = ({ setError } = {}) => {
  const [categoryTags, setCategoryTags] = useState([])
  const [documentTypeTags, setDocumentTypeTags] = useState([])
  const [tagsLoading, setTagsLoading] = useState(false)

  useEffect(() => {
    let active = true

    const retrieveCategories = async () => {
      setTagsLoading(true)
      try {
        const categoryTags = await getCategoryTags()
        const documentTypeTags = await getDocumentTypeTags()
        if (!active) {
          return
        }

        setTagsLoading(false)
        setCategoryTags(categoryTags)
        setDocumentTypeTags(documentTypeTags)
      } catch (error) {
        if (!active) {
          return
        }

        setTagsLoading(false)

        if (setError) {
          setError(error)
        }
      }
    }

    retrieveCategories()
    return () => {
      active = false
    }
  }, [setError])

  return { categoryTags, documentTypeTags, tagsLoading }
}

const usePhase = appUser => {
  const [phase, setPhase] = useState(getPreference(appUser, PHASE, ''))

  // Cool function name…
  const handlePhaseChange = event => {
    setPhase(event.target.value)
    setPreference(appUser, PHASE, event.target.value)
  }

  return { phase, handlePhaseChange }
}

/**
 * useScrollToggle is a convenience hook that watches for scroll activity and sets whether
 * an element is past a certain scroll threshold. For this to work, the said “sticky” element
 * typically has position `sticky`, `fixed`, or `absolute` such that its position does not
 * change in relation to its container as it scrolls, because otherwise its `offsetTop`
 * property wouldn’t change.
 *
 * @param {nodeRef} stickyRef React ref to the element whose scroll offset is being watched
 * @param {nodeRef} containerRef React ref to the element within which `sticky` is scrolling
 * @param {function} toggle the function to call when scroll changes in relation to the threshold
 * @param {number} threshold the `offsetTop` value across which to toggle the given state
 */
const useScrollToggle = (stickyRef, containerRef, toggle, threshold) => {
  useEffect(() => {
    // Nothing to do if there’s nothing to listen to or no one to notify.
    if (!containerRef?.current || !toggle) {
      return
    }

    const container = containerRef.current

    // We do this with a direct event listener because the React update/effect cycle doesn’t quite
    // respond to this at the right moment.
    const scrollListener = () => {
      const stickyOffsetTop = stickyRef?.current?.offsetTop
      toggle(stickyOffsetTop > threshold)
    }

    container.addEventListener('scroll', scrollListener)

    return () => {
      container.removeEventListener('scroll', scrollListener)
    }
  }, [stickyRef, containerRef, toggle, threshold])
}

/**
 * useVisibilityToggleStyles is a helper hook for useVisibilityToggle that returns a styles
 * hook function for styling hidden and visible objects that are toggled by useVisibilityToggle.
 *
 * It is done as a hook so that usage parallels the `useStyles` convention established
 * by `makeStyles`.
 */
const useVisibilityToggleStyles = makeStyles(
  theme => ({
    hidden: {
      opacity: 0.0
    },

    default: {
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.complex
      })
    }
  }),
  {
    name: 'VisibilityToggle'
  }
)

/**
 * useVisibilityToggle is a convenience hook for capturing simple logic for hiding/showing
 * elements. The caller of the hook gets back coordinated `hide` and `show` functions plus
 * a `className` to use that controls hidden and shown states.
 *
 * @param {string} additionalHiddenClassName custom class name (if desired) to use when hidden
 * @returns an object with className to use on visibility-toggled elements, and hide/show functions
 */
const useVisibilityToggle = additionalHiddenClassName => {
  const [visible, setVisible] = useState(false)

  const classes = useVisibilityToggleStyles()
  const className = clsx(classes.default, !visible && classes.hidden, !visible && additionalHiddenClassName)

  return {
    className,
    hide: () => setVisible(false),
    show: () => setVisible(true)
  }
}

/**
 * Name says it all, I think?
 *
 * @param {string} noun
 * @returns The noun with an `s` appended to it
 */
const cheapPlural = noun => `${noun}s`

/**
 * Replaces the first character of a string with the given substitute.
 *
 * @param {string} string the base string
 * @param {string} sub the substitute for the first character
 * @returns the base string with the first character replaced by `sub`
 */
const replaceFirst = (string, sub) => `${sub}${string.slice(1)}`

/**
 * Sets the first character of the given string to uppercase.
 *
 * @param {string} string
 * @returns The string with the first character capitalized
 */
const capitalize = string => replaceFirst(string, string.charAt(0).toUpperCase())

/**
 * Sets the first character of the given string to lowercase.
 *
 * @param {string} string
 * @returns The string with the first character capitalized
 */
const decapitalize = string => replaceFirst(string, string.charAt(0).toLowerCase())

/**
 * Computes the singular/plural lowercase/capitalized variants of a given name.
 *
 * @param {string} itemSingular the singular-form name of the item
 * @param {string} itemPlural (optional) its plural form
 * @returns An object with the variants for the given item
 */
const itemBases = (itemSingular, itemPlural) => {
  const itemBase = decapitalize(itemSingular)
  const itemBasePlural = itemPlural ? itemPlural.toLowerCase() : cheapPlural(itemBase)
  const itemBaseCapitalized = capitalize(itemBase)
  const itemBasePluralCapitalized = capitalize(itemBasePlural)

  return {
    itemBase,
    itemBasePlural,
    itemBaseCapitalized,
    itemBasePluralCapitalized,
    itemsName: itemBasePlural,
    setItemsName: `set${itemBasePluralCapitalized}`
  }
}

/**
 * Takes a (presumably) camel-case string and breaks it up into words at the capital letters.
 *
 * @param {string} itemName the string to convert
 */
const camelCaseToMultiword = itemName => itemName.replaceAll(/[A-Z]/g, ' $&').toLowerCase()

/**
 * useCrudCache captures common logic for managing a cache of items that represent persisted data.
 * When managing such data, front end updates can appear faster if the cached list is modified in
 * memory, with persisted requests taking place asynchronously.
 *
 * For readability, the hook takes an “item name” and returns the result with functions based on
 * that name: e.g., `events`, `setEvents`, `eventCreated`, `eventUpdated`, `eventDeleted`. Atypical
 * plurals can be given as the second parameter; if omitted, the plural just appends an `s`.
 *
 * It is the responsibility of the hook’s caller to ensure that the items being manipulated remain
 * consistent with what is being persisted.
 *
 * All items are expected to have an `id` property.
 *
 * @param {string} itemSingular the singular-form name of the item being “CRUDed”
 * @param {object} options customization options (optional), consisting of:
 *   @param {string} itemPlural (optional) its plural form
 *   @param {function} sortComparator (optional) comparator for sorting the cache whenever it changes
 */
const useCrudCache = (itemSingular, { itemPlural, sortComparator } = {}) => {
  const [items, setItems] = useState([])
  const [deletedItem, setDeletedItem] = useState(null)

  const itemCreated = createdItem =>
    setItems(items => {
      const newItems = [...items, createdItem]
      return sortComparator ? newItems.sort(sortComparator) : newItems
    })

  const itemUpdated = updatedItem => {
    setItems(items => {
      const changedItemIndex = items.findIndex(item => item.id === updatedItem.id)
      if (changedItemIndex === -1) {
        // Not in the list, so no effect.
        return items
      } else {
        const updatedItems = [
          ...items.slice(0, changedItemIndex),
          // Allowing for PATCH-style partial objects.
          { ...items[changedItemIndex], ...updatedItem },
          ...items.slice(changedItemIndex + 1)
        ]

        return sortComparator ? updatedItems.sort(sortComparator) : updatedItems
      }
    })
  }

  const itemDeleted = deletedItem => {
    setItems(items => {
      const deletedItemIndex = items.findIndex(item => item.id === deletedItem.id)
      return deletedItemIndex === -1
        ? // Not in the list, so no effect.
          items
        : [...items.slice(0, deletedItemIndex), ...items.slice(deletedItemIndex + 1)]
    })

    // Make sure the deleted event is a copy so that it can be deleted later if undone.
    setDeletedItem({ ...deletedItem })
  }

  const names = itemBases(itemSingular, itemPlural)
  const { itemBase, itemBaseCapitalized, itemsName, setItemsName } = names

  return {
    names, // Mainly for use by other hooks.
    [itemsName]: items,
    [setItemsName]: setItems,
    [`deleted${itemBaseCapitalized}`]: deletedItem,
    [`setDeleted${itemBaseCapitalized}`]: setDeletedItem,
    [`${itemBase}Created`]: itemCreated,
    [`${itemBase}Updated`]: itemUpdated,
    [`${itemBase}Deleted`]: itemDeleted
  }
}

// The paging state is for UI updates. We use a local variable to avoid page-loading duplication.
// It’s outside hook scope to avoid dependency issues…now, will it be a problem if there are multiple
// paging hooks at play? We’ll have to see…
let pageLock = false

/**
 * The useLoadMore hook captures the logic needed for following a cursor and loading items from a paginated endpoint
 * on demand. All API specifics are captured in the pageLoader function that is given to the hook.
 *
 * @param {function} pageLoader accepts an optional cursor argment then returns an object with:
 *                   - itemPage, an array holding the next page of items
 *                   - cursorNext, the value of the cursor for the next page
 *
 *                   If pageLoader throws an error upon execution, it will be passed to `setError` if given
 *
 * @param {function} setError (optional) is a useState setter in the event of an error
 * @param {string} itemSingular (optional) same as useCrudCache
 * @param {object} options (optional) same as useCrudCache
 */
const useLoadMore = (pageLoader, setError, itemSingular = 'item', options = {}) => {
  const crudCache = useCrudCache(itemSingular, options)
  const { names } = crudCache
  const { setItemsName, itemBasePlural, itemBasePluralCapitalized } = names

  const setItems = crudCache[setItemsName]

  const [cursor, setCursor] = useState(null)
  const [complete, setComplete] = useState(false)
  const [paging, setPaging] = useState(false)

  const retrievePage = useCallback(
    async cursor => {
      try {
        return await pageLoader(cursor)
      } catch (error) {
        if (setError) {
          setError(error)
        }

        return null
      }
    },
    [pageLoader, setError]
  )

  const initializeItems = useCallback(
    async checkActive => {
      const page = await retrievePage()
      if (!checkActive() || !page) {
        return
      }

      const { itemsPage, cursorNext } = page
      setItems(itemsPage)
      setCursor(cursorNext)
      setComplete(!cursorNext)
      setPaging(false)
    },
    [setItems, retrievePage]
  )

  const nextPage = useCallback(() => {
    // Once the collection is complete, then don’t even try to make a request.
    if (complete) {
      return
    }

    pageLock = true
    setPaging(true)

    const loadNextPage = async () => {
      const page = await retrievePage(cursor)
      if (!page) {
        return
      }

      const { itemsPage, cursorNext } = page

      if (!complete) {
        setItems(items => [...(items || []), ...itemsPage])
        setCursor(cursorNext)
      }

      if (!cursorNext) {
        setComplete(true)
      }

      setPaging(false)
      pageLock = false
    }

    loadNextPage()
  }, [setItems, retrievePage, cursor, complete])

  const allPages = useCallback(() => {
    // Same here, a complete collection goes no further.
    if (complete) {
      return
    }

    pageLock = true
    setPaging(true)

    const loadAllPages = async () => {
      const accumulator = []
      let currentCursor = cursor

      do {
        const { itemsPage, cursorNext } = await retrievePage(currentCursor)
        accumulator.splice(accumulator.length, 0, ...itemsPage) // Mutate to avoid reallocations.
        currentCursor = cursorNext
      } while (currentCursor)

      // Wrap things up.
      setItems(items => [...(items ?? []), ...accumulator])
      setCursor(currentCursor)
      setComplete(true)

      setPaging(false)
      pageLock = false
    }

    loadAllPages()
  }, [setItems, retrievePage, cursor, complete])

  // Null until first load.
  useEffect(() => {
    setItems(null)
  }, [setItems])

  // Initialize things.
  useEffect(() => {
    let active = true

    setPaging(true)
    initializeItems(() => active)

    return () => {
      active = false
    }
  }, [setItems, initializeItems])

  const reset = useCallback(() => initializeItems(() => true), [initializeItems])

  // We expose the CRUD cache to permit in-memory changes that improve perceived responsiveness.
  return {
    ...crudCache,
    [`${itemBasePlural}Complete`]: complete,
    [`${itemBasePlural}Paging`]: paging,
    [`reset${itemBasePluralCapitalized}`]: reset,
    [`all${itemBasePluralCapitalized}Pages`]: allPages,
    [`next${itemBasePluralCapitalized}Page`]: nextPage
  }
}

const SCROLL_EVENT_KEY = 'scroll'

/**
 * The useInfiniteScroll hook “automates” the useLoadMore hook by invoking next-page behavior when the user scrolls
 * past a particular element, which should be assigned to the `lastItem` ref that is returned by the hook.
 *
 * @param {function} pageLoader is the same function that useLoadMore expects
 * @param {function} setError (optional) is the same function that useLoadMore expects
 * @param {string} itemSingular (optional) same as useCrudCache
 * @param {object} options (optional) same as useCrudCache
 */
const useInfiniteScroll = (pageLoader, setError, itemSingular = 'item', options = {}) => {
  const lastItem = useRef(null)

  const loadMore = useLoadMore(pageLoader, setError, itemSingular, options)
  const { names } = loadMore
  const { itemBaseCapitalized, itemBasePluralCapitalized } = names
  const nextPage = loadMore[`next${itemBasePluralCapitalized}Page`]

  useEffect(() => {
    let ticking = false

    const infiniteScrollListener = event => {
      if (!ticking) {
        ticking = true

        window.requestAnimationFrame(() => {
          const lastItemElement = lastItem.current
          const { pageTop, height } = window.visualViewport
          if (!pageLock && lastItemElement && pageTop + height > lastItemElement.offsetTop) {
            nextPage()
          }

          ticking = false
        })
      }
    }

    window.addEventListener(SCROLL_EVENT_KEY, infiniteScrollListener)

    return () => {
      window.removeEventListener(SCROLL_EVENT_KEY, infiniteScrollListener)
    }
  }, [nextPage])

  return { ...loadMore, [`last${itemBaseCapitalized}`]: lastItem }
}

/**
 * useCrudInteractions holds common logic/state management and components for the typical user interface
 * flow associated with creating, updating, and deleting objects. As with the other hooks, the hook takes
 * an “item name” and returns the result with properties and functions based on that name. Options and
 * behavior are similar to those other hooks.
 *
 * All items are expected to have an `id` property.
 *
 * @param {string} itemSingular (optional) same as useCrudCache
 * @param {object} options (optional) same as useCrudCache, plus:
 *   @param {function} new{itemName} function (no args) that creates a new (empty) instance of an item
 *   @param {function} restore{itemName} function (deleted item) that undoes the deletion of the given item
 *   @param {string|function} undoDeleteMessage
 *     string or function (deleted item) that prompts the user to undo deletion
 *
 * @param {object} itemCrudContext (optional) object holding lower-level state and functions
 *                                 ---if not supplied, useCrudCache() is used, specifically:
 *   @param {object} deleted{itemName} the most recently deleted item
 *   @param {function} setDeleted{itemName} setter for the most recently deleted item
 *   @param {function} {itemName}Created notifier that an item was created
 *   @param {function} {itemName}Updated notifier that an item was updated
 *   @param {function} {itemName}Deleted notifier that an item was deleted
 *
 * @returns an object containing assorted state, functions, and components for front-end CRUD interactions
 *   - properties starting with `set` are fairly standard low-level state-setters
 *   - properties starting with `handle` contain more logic, frequently can be used directly as event handlers
 */
const useCrudInteractions = (itemSingular = 'item', options = {}, itemCrudContext) => {
  const { itemPlural, undoDeleteMessage } = options
  const { itemBase, itemBaseCapitalized } = itemBases(itemSingular, itemPlural)
  const humanReadableItem = camelCaseToMultiword(itemBase)

  // Grab the options whose names depend on the item name.
  const newItem = options[`new${itemBaseCapitalized}`]
  const restoreItem = options[`restore${itemBaseCapitalized}`]

  // If we are given a CRUD context then this is “wasted,” but oh well nbd.
  const defaultCrudCache = useCrudCache(itemSingular, options)

  const crudContext = itemCrudContext || defaultCrudCache
  const deletedItem = crudContext[`deleted${itemBaseCapitalized}`]
  const setDeletedItem = crudContext[`setDeleted${itemBaseCapitalized}`]
  const itemCreated = crudContext[`${itemBase}Created`]
  const itemUpdated = crudContext[`${itemBase}Updated`]
  const itemDeleted = crudContext[`${itemBase}Deleted`]

  const [dirtyItemChange, setDirtyItemChange] = useState()
  const [itemIsDirty, setItemIsDirty] = useState(false)
  const [selectedItem, setSelectedItem] = useState()

  const handleHideItemDetail = event => setSelectedItem(null)
  const handleCloseUndoDelete = event => setDeletedItem(null)

  const handleDelete = deletedItem => {
    setSelectedItem(null)
    itemDeleted(deletedItem)
  }

  const dirtyItemChangeHandler = useCallback(
    itemToSelect => () => {
      setDirtyItemChange(null)
      setItemIsDirty(false)
      setSelectedItem(itemToSelect)
    },
    []
  )

  const handleRequestSelect = useCallback(
    itemToSelect => {
      const dirtyItemChange = dirtyItemChangeHandler(itemToSelect)
      if (itemIsDirty) {
        setDirtyItemChange(() => dirtyItemChange)
      } else {
        dirtyItemChange()
      }
    },
    [dirtyItemChangeHandler, itemIsDirty]
  )

  const handleRequestDeselect = customDeselect => {
    const dirtyItemChange = typeof customDeselect === 'function' ? customDeselect : dirtyItemChangeHandler(null)

    if (itemIsDirty) {
      setDirtyItemChange(() => dirtyItemChange)
    } else if (selectedItem) {
      // Deselect only if there is something already selected.
      dirtyItemChange()
    }
  }

  // If no new item function is given, we default to an empty object.
  const handleCreate = event => handleRequestSelect(newItem ? newItem() : {})

  const handleUndoDelete = async event => {
    const restoredItem = await restoreItem(deletedItem)
    itemCreated(restoredItem)
    handleCloseUndoDelete(event)
  }

  const handleUpdate = updatedItem => {
    // Small edge case here: if the updated item has the same ID as the selected one,
    // its properties may be different. Make sure to update the selected item accordingly
    // before passing the change on to the CRUD cache.
    const { id } = updatedItem ?? {}
    if (id && id === selectedItem?.id) {
      setSelectedItem({ ...updatedItem }) // item?.id was not falsy so item could not have been falsy.
    }

    itemUpdated(updatedItem)
  }

  const deselectionConfirmationDialog = (
    <ConfirmationDialog
      open={Boolean(selectedItem) && Boolean(dirtyItemChange)}
      title={`Are you sure you want to leave without saving changes to this ${humanReadableItem}?`}
      cancelLabel="Go Back to Editing"
      confirmLabel="Close"
      onCancel={() => setDirtyItemChange(null)}
      onConfirm={dirtyItemChange}
    />
  )

  const undoDeleteSnackbar = (
    <UndoDeleteSnackbar
      deletedItem={deletedItem}
      message={
        undoDeleteMessage
          ? typeof undoDeleteMessage === 'function'
            ? undoDeleteMessage(deletedItem)
            : undoDeleteMessage
          : `The ${humanReadableItem} has been deleted.`
      }
      onUndo={handleUndoDelete}
      onClose={handleCloseUndoDelete}
      open={deletedItem && typeof restoreItem === 'function'}
    />
  )

  return {
    ...crudContext,
    [`deleted${itemBaseCapitalized}`]: deletedItem,
    [`${itemBase}Created`]: itemCreated,
    [`${itemBase}Updated`]: itemUpdated,
    [`${itemBase}Deleted`]: itemDeleted,
    [`dirty${itemBaseCapitalized}Change`]: dirtyItemChange,
    [`setDirty${itemBaseCapitalized}Change`]: setDirtyItemChange,
    [`${itemBase}IsDirty`]: itemIsDirty,
    [`set${itemBaseCapitalized}IsDirty`]: setItemIsDirty,
    [`selected${itemBaseCapitalized}`]: selectedItem,
    [`setSelected${itemBaseCapitalized}`]: setSelectedItem,
    [`handleHide${itemBaseCapitalized}Detail`]: handleHideItemDetail,
    handleCloseUndoDelete,
    handleCreate,
    handleDelete,
    handleUpdate,
    handleRequestSelect,
    handleRequestDeselect,
    deselectionConfirmationDialog,
    undoDeleteSnackbar
  }
}

const ACTIVITY_EVENTS = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart']
const TIMEOUT_CHANNEL_NAME = 'undivided-ui-activity'
const TIMEOUT_STATE_ACTIVE = 'active'
const TIMEOUT_STATE_COUNTDOWN = 'countdown'
const TIMEOUT_STATE_INACTIVE = 'inactive'
const USE_CAPTURE = true // Quickie alias for readability.

// Keep track of idle timeouts internally to make sure we clear all of them when called for.
let idleTimeouts = []

const startIdleTimeout = (handler, delay) => {
  const timeout = setTimeout(handler, delay)
  idleTimeouts.push(timeout)
  return timeout
}

const clearIdleTimeout = id => {
  clearTimeout(id)
  idleTimeouts.forEach(timeoutId => clearTimeout(timeoutId))
  idleTimeouts = []
}

/**
 * The useTimeout hook captures the logic for an idle timeout.
 *
 * @param {number} timeoutInMilliseconds
 * @param {number} countdownInMilliseconds
 */
const useTimeout = (timeoutInMilliseconds, countdownInMilliseconds, timeoutCallback) => {
  const appUser = useAppUser()
  const timeoutBroadcastChannel = useMemo(() => new BroadcastChannel(TIMEOUT_CHANNEL_NAME), [])

  const [timeoutState, setTimeoutState] = useState(TIMEOUT_STATE_INACTIVE)
  const [countdownStart, setCountdownStart] = useState(null)
  const [, setIdleTimeout] = useState(null)
  const [, setWarnTimeout] = useState(null)

  const countdownTimeoutHandler = useCallback(() => {
    timeoutCallback()
  }, [timeoutCallback])

  const timeoutHandler = useCallback(() => {
    trackIdleTimeout(appUser)
    setTimeoutState(TIMEOUT_STATE_COUNTDOWN)
  }, [appUser])

  const resetIdleTimeout = useCallback(() => {
    setIdleTimeout(idleTimeout => {
      clearIdleTimeout(idleTimeout)
      return startIdleTimeout(timeoutHandler, timeoutInMilliseconds)
    })
  }, [timeoutHandler, timeoutInMilliseconds])

  const activityHandler = useCallback(
    event => {
      resetIdleTimeout()

      if (appUser) {
        const { username } = appUser
        timeoutBroadcastChannel.postMessage(username)
      }
    },
    [appUser, timeoutBroadcastChannel, resetIdleTimeout]
  )

  const messageHandler = useCallback(
    event => {
      if (appUser) {
        const { username } = appUser

        // We will only reset the timer if the received event matches the user in this instance.
        if (event.data === username) {
          resetIdleTimeout()

          // We might be counting down; if so, we reset that also.
          setTimeoutState(timeoutState => {
            return timeoutState === TIMEOUT_STATE_COUNTDOWN ? TIMEOUT_STATE_ACTIVE : timeoutState
          })
        }
      }
    },
    [appUser, resetIdleTimeout]
  )

  const terminateIdleTimer = useCallback(() => {
    setCountdownStart(null)

    setWarnTimeout(warnTimeout => {
      clearTimeout(warnTimeout)
      return null
    })

    setIdleTimeout(idleTimeout => {
      clearIdleTimeout(idleTimeout)
      return null
    })
  }, [])

  const initializeIdleTimer = useCallback(() => {
    terminateIdleTimer()
    activityHandler()
  }, [terminateIdleTimer, activityHandler])

  const activateIdleTimer = useCallback(() => {
    setTimeoutState(TIMEOUT_STATE_ACTIVE)
  }, [])

  useEffect(() => {
    if (appUser) {
      setTimeoutState(TIMEOUT_STATE_ACTIVE)
    } else {
      setTimeoutState(TIMEOUT_STATE_INACTIVE)
    }
  }, [appUser])

  useEffect(() => {
    timeoutBroadcastChannel.addEventListener('message', messageHandler)

    return () => {
      timeoutBroadcastChannel.removeEventListener('message', messageHandler)
    }
  }, [timeoutBroadcastChannel, messageHandler])

  useEffect(() => {
    if (timeoutState === TIMEOUT_STATE_ACTIVE) {
      ACTIVITY_EVENTS.forEach(event => document.addEventListener(event, activityHandler, USE_CAPTURE))
      initializeIdleTimer()
    } else {
      terminateIdleTimer()

      if (timeoutState === TIMEOUT_STATE_COUNTDOWN) {
        setCountdownStart(new Date())
        setWarnTimeout(warnTimeout => {
          clearTimeout(warnTimeout)
          return setTimeout(countdownTimeoutHandler, countdownInMilliseconds)
        })
      }
    }

    return () => {
      // Removing an unadded listener is harmless so we err on the side of that rather than miss
      // removing an already-added listener.
      ACTIVITY_EVENTS.forEach(event => document.removeEventListener(event, activityHandler, USE_CAPTURE))
    }
  }, [
    countdownInMilliseconds,
    timeoutState,
    activityHandler,
    countdownTimeoutHandler,
    initializeIdleTimer,
    terminateIdleTimer
  ])

  return {
    countdownStart,
    activateIdleTimer
  }
}

/**
 * Brute-force hook for retrieving all topics. Meant for use cases that require all available
 * topics in one call. May result in multiple network requests if the number of topics in the
 * system grows beyond the page limit.
 *
 * @param {function} setError (optional) error setter function in case something goes wrong
 * @returns {object} consisting of topics
 */
const useResourceTopics = setError => {
  // Start filters as undefined to show that they haven’t loaded yet.
  const [topics, setTopics] = useState()

  const topicsById = useMemo(
    () => (topics ? Object.fromEntries(topics.map(item => [item.id, item.name])) : undefined),
    [topics]
  )

  const concatenateTopics = useCallback(
    topicIds => (topicIds ?? []).map(topicId => topicsById?.[topicId]).join(', '),
    [topicsById]
  )

  useEffect(() => {
    let active = true

    const retrieveTopics = async () => {
      try {
        const topics = await getAllResourceTopics()
        if (!active) {
          return
        }

        setTopics(
          topics.sort(nameComparator).map(topic => ({
            ...topic,
            ...(TOPIC_FRONT_END_PROPERTIES[topic.id] || {})
          }))
        )
      } catch (error) {
        if (setError) {
          setError(error)
        }
      }
    }

    retrieveTopics()
    return () => {
      active = false
    }
  }, [setError])

  return { topics, topicsById, concatenateTopics }
}

/**
 * Common hook for fetching all available options for a diagnosis . Meant for use cases that require all available
 * diagnoses in one call. Any manipulation to the diagnoses options which includes mapping the diagnoses Tag IDs
 * or formatting with its display name is done here.
 *
 * @param {function} setError (optional) error setter function in case something goes wrong
 */
const useDiagnosisTags = setError => {
  const [diagnosisTags, setDiagonsisTags] = useState()

  const diagnosisOptions = useMemo(
    () =>
      (diagnosisTags ?? []).map(({ identifier, displayName, isNone }) => ({
        value: identifier[0],
        label: displayName,
        isNone
      })),
    [diagnosisTags]
  )

  const concatenateDiagnoses = useCallback(
    selectedTagIds =>
      (selectedTagIds ?? [])
        .map(value => diagnosisOptions.find(option => option.value === value)?.label)
        .filter(label => Boolean(label))
        .join(', '),

    [diagnosisOptions]
  )

  useEffect(() => {
    let active = true

    const retrieveDiagnosisTags = async () => {
      try {
        const fetchedDiagnosisTags = await getDiagnosisTags()
        if (!active) {
          return
        }

        setDiagonsisTags(fetchedDiagnosisTags)
      } catch (error) {
        if (setError) {
          setError(error)
        }
      }
    }

    retrieveDiagnosisTags()
    return () => {
      active = false
    }
  }, [setError])
  return { diagnosisOptions, concatenateDiagnoses }
}

export {
  useContentFilters,
  useDocumentTags,
  useDocumentTypes,
  usePhase,
  useScrollToggle,
  useVisibilityToggle,
  useCrudCache,
  useLoadMore,
  useInfiniteScroll,
  useCrudInteractions,
  useTimeout,
  useResourceTopics,
  useDiagnosisTags
}
