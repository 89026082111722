import React, { useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles'

import { useAppUser, useCurrentHousehold, useParentViewCapability, useActivePaymentMethod } from '../app/hooks'
import { THEME_COLOR_GROUP_GREEN, THEME_COLOR_GROUP_NEUTRAL, THEME_PRIMARY_YELLOW } from '../app/support/theme'

import { trackTrialAddedFromAccountCreation, trackTrialSkippedFromAccountCreation } from '../tracking/segment'
import { notifyLoginStatusFromIframe, paymentCollectFunnel, kickstartFromOnboarding } from '../app/utilities'
import { PLATFORM_TRIAL_STATUS_NOT_STARTED } from '../arch/membershipConstants'
import { CAPABILITY_CONFIRM_EMAIL } from '../arch/capabilityConstants'

import { CHECKPOINT_KICKSTART, CHECKPOINT_VERIFY_EMAIL, CHECKPOINT_PAYMENT_CC } from './constants'
import { OnboardingStatus } from './contexts'
import { TRIAL_KICKSTART, TRIAL_PLATFORM, VARIANT_ONBOARDING } from './trial/constants'
import { identifyJoinerFunnel } from './utilities'

import sun from '../assets/trial-upsell-sidebar-sun.svg'

import OnboardingContainer from './support/OnboardingContainer'
import TrialUpsell from './trial/TrialUpsell'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4)
    },

    titleContainer: {
      margin: theme.spacing(4, 0, 2)
    },

    accent: {
      backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N10
    },

    content: {
      alignItems: 'center',
      display: 'flex',
      gap: theme.spacing(7)
    },

    benefitList: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      listStyle: 'none',
      margin: 0,
      padding: 0
    },

    benefitItem: {
      ...theme.typography.body1,

      display: 'flex',
      gap: theme.spacing(1)
    },

    benefitIcon: {
      backgroundColor: THEME_COLOR_GROUP_GREEN.G100,
      borderRadius: theme.spacing(0.5),
      color: THEME_COLOR_GROUP_GREEN.G900,
      height: theme.spacing(3),
      margin: theme.spacing(-0.125, 0, 0),
      width: theme.spacing(3)
    },

    benefitText: {},

    actions: {
      alignItems: 'stretch',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      justifyContent: 'space-between',
      padding: theme.spacing(0, 4),

      [theme.breakpoints.up('brandSmall')]: {
        alignItems: 'baseline',
        flexDirection: 'row',
        gap: theme.spacing(3),
        padding: 0
      }
    },

    actionButtonRoot: {
      padding: theme.spacing(1.5, 3)
    },

    confirmButton: {},

    doThisLaterButton: {
      padding: theme.spacing(1.25, 3)
    },

    description: {
      ...theme.typography.body1
    },

    finePrint: {
      ...theme.typography.body1,

      alignItems: 'center',
      color: theme.palette.text.secondary,
      columnGap: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      rowGap: 0,
      justifyContent: 'center',
      listStyle: 'none',
      margin: theme.spacing(2, 0, 0),
      padding: 0,

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    },

    finePrintItem: {},

    finePrintDivider: {
      display: 'none',

      [theme.breakpoints.up('sm')]: {
        display: 'list-item'
      }
    },

    helpPrompt: {
      alignSelf: 'flex-end',
      margin: theme.spacing(-1, 0, 0) // Shrink the gap for this one.
    },

    progress: {
      color: theme.palette.common.white,
      margin: theme.spacing(-0.25, 0, 0)
    },

    [theme.breakpoints.down('md')]: {
      description: {
        width: '100%'
      }
    },

    [theme.breakpoints.down('brandSmall')]: {
      content: {
        flexDirection: 'column'
      },

      actionButtonRoot: {
        flex: 1,
        minWidth: theme.spacing(32)
      }
    }
  }),
  {
    name: 'TrialOnboarding'
  }
)

const useTrialUpsellSidebarStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      height: '100%',
      padding: theme.spacing(25, 8.125, 0),
      position: 'relative'
    },

    title: {
      margin: 0,
      textAlign: 'center',
      zIndex: 1 // To avoid falling beneath the image.
    },

    amount: {
      alignItems: 'center',
      display: 'flex',
      fontSize: theme.typography.pxToRem(56),
      fontWeight: theme.typography.fontWeightMedium,
      justifyContent: 'center'
    },

    leftSun: {},
    rightSun: {
      transform: 'rotateZ(190deg)'
    }
  }),
  {
    name: 'TrialUpsellSidebar'
  }
)

const TrialUpsellSidebar = props => {
  const classes = useTrialUpsellSidebarStyles()
  return (
    <section className={classes.root}>
      <h2 className={classes.title}>
        Undivided unlocks an average of
        <span className={classes.amount}>
          <img className={classes.leftSun} src={sun} alt="Sun" />
          $63,000
          <img className={classes.rightSun} src={sun} alt="Sun" />
        </span>
        per family in annual benefits and support.
      </h2>
    </section>
  )
}

const TrialOnboarding = props => {
  const { setOnboarding, additionalProperties } = useContext(OnboardingStatus)
  const { onClose, onConfirm } = additionalProperties

  const appUser = useAppUser()

  const location = useLocation()

  const household = useCurrentHousehold()
  const { referringObject, platformTrialStatus, loading, joinFunnel } = household
  const isPaymentCollect = paymentCollectFunnel(joinFunnel)

  const hasActivePaymentMethod = useActivePaymentMethod()
  const currentFunnel = identifyJoinerFunnel(referringObject)

  const isOnboardFromKickstart = kickstartFromOnboarding(location)

  const { isParentCapable } = useParentViewCapability()
  const unConfirmedEmail = isParentCapable(CAPABILITY_CONFIRM_EMAIL)

  const trialHasStarted = platformTrialStatus !== PLATFORM_TRIAL_STATUS_NOT_STARTED

  const navigate = useNavigate()

  const handleClose = event => {
    notifyLoginStatusFromIframe({ successfulLogin: true })
    trackTrialSkippedFromAccountCreation(appUser, TRIAL_PLATFORM, currentFunnel)

    // If TrialUpsell finds an onClose function in additionalProperties, it will invoke that upon
    // ending. Otherwise, it navigates to Home.
    if (onClose) {
      onClose()
    } else {
      navigate('/home', { replace: true })
    }
  }

  const handleConfirm = trialOption => {
    setOnboarding({
      checkpoint: isPaymentCollect
        ? CHECKPOINT_PAYMENT_CC
        : trialOption === TRIAL_KICKSTART
        ? CHECKPOINT_KICKSTART
        : CHECKPOINT_VERIFY_EMAIL
    })
    trackTrialAddedFromAccountCreation(appUser, TRIAL_PLATFORM, currentFunnel)

    if (onConfirm) {
      onConfirm()
    }
  }

  useEffect(() => {
    if (loading) {
      return
    }

    let checkpoint = null
    if (trialHasStarted) {
      if (unConfirmedEmail) {
        // Email unconfirmation has the priority.
        checkpoint = CHECKPOINT_VERIFY_EMAIL
      } else if (isPaymentCollect) {
        // Check whether the payment method is active, if not then move them to the CC form.
        if (!hasActivePaymentMethod) {
          checkpoint = CHECKPOINT_PAYMENT_CC
        } else if (isOnboardFromKickstart) {
          // This is a special case where the user, after entering their payment method and confirming their email,
          // is redirected back to the onboarding page for Kickstart scheduling once the external Stripe redirect is complete.
          checkpoint = CHECKPOINT_KICKSTART
        }
      }
    }

    if (checkpoint) {
      setOnboarding({ checkpoint })
    }
  }, [
    loading,
    trialHasStarted,
    unConfirmedEmail,
    isPaymentCollect,
    hasActivePaymentMethod,
    isOnboardFromKickstart,
    setOnboarding
  ])

  const classes = useStyles(props)
  return (
    <OnboardingContainer
      classes={{ titleContainer: classes.titleContainer, accent: classes.accent }}
      theme={THEME_PRIMARY_YELLOW.main}
      sidebar={<TrialUpsellSidebar />}
      hideCheckpoints
    >
      <TrialUpsell variant={VARIANT_ONBOARDING} onConfirm={handleConfirm} onCancel={handleClose} />
    </OnboardingContainer>
  )
}

export default TrialOnboarding
