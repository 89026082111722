import React from 'react'

import PropTypes from 'prop-types'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import CssBaseline from '@mui/material/CssBaseline'

const ThemedContainer = props => {
  const { theme, children } = props
  return theme ? (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  ) : (
    children
  )
}

ThemedContainer.propTypes = {
  theme: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}

export default ThemedContainer
