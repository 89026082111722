import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import LogRocket from 'logrocket'

import 'react-datepicker/dist/react-datepicker.css'
import './react-datepicker-undivided.css'
import './index.css'

import { apiHost } from './auth'
import App from './App'
import * as serviceWorker from './serviceWorker'

const logRocketAppId = `${process.env.REACT_APP_LOGROCKET_APP_ID}`
if (logRocketAppId !== 'disabled') {
  LogRocket.init(`${process.env.REACT_APP_LOGROCKET_APP_ID}/undivided-apps`, {
    mergeIframes: true,
    childDomains: ['*'],
    release: process.env.REACT_APP_VERSION
  })
}

const startup = async () => {
  await apiHost(process.env.REACT_APP_API)

  const container = document.getElementById('root')
  const root = createRoot(container)

  root.render(
    <BrowserRouter basename="/app">
      <App />
    </BrowserRouter>
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
}

startup()
