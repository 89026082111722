import { patchOnboarding } from '../api'
import { handleErrorWithStateHooks } from '../common'

import {
  ONBOARDING_CONCLUSION,
  CHECKPOINT_EMPTY,
  CHECKPOINT_TRIAL_UPSELL,
  CHECKPOINT_CHILD_INFO,
  CHECKPOINT_VERIFY_EMAIL,
  CHECKPOINT_KICKSTART,
  CHECKPOINT_SCHEDULE_CONFIRMATION,
  CHECKPOINT_PAYMENT_CC
} from '../onboarding/constants'

import { getAddGoalId } from './goal'

/**
 * This function captures the business logic that determines whether the app is in a situation
 * where it is adding a goal from the resources application.
 *
 * @returns whether the app appears to be in the middle of adding a goal from resources
 */
const isAddingGoalFromResource = () => {
  const addGoalId = getAddGoalId()

  return Boolean(addGoalId)
}

/**
 * Helper function for determining the onboarding sequence to use, based on the given arguments.
 */
const onboardingSequenceFor = (canBecomeMember, preferredNavigatorId, isNavigatorTrialInvited, isPaymentCollect) => {
  const baselineSequence =
    (isNavigatorTrialInvited || preferredNavigatorId) && canBecomeMember
      ? [CHECKPOINT_KICKSTART, CHECKPOINT_SCHEDULE_CONFIRMATION, CHECKPOINT_VERIFY_EMAIL]
      : isPaymentCollect && canBecomeMember
      ? [CHECKPOINT_VERIFY_EMAIL, CHECKPOINT_TRIAL_UPSELL, CHECKPOINT_PAYMENT_CC]
      : canBecomeMember
      ? [CHECKPOINT_TRIAL_UPSELL, CHECKPOINT_VERIFY_EMAIL]
      : []

  return isAddingGoalFromResource() ? [CHECKPOINT_VERIFY_EMAIL, CHECKPOINT_CHILD_INFO] : baselineSequence
}

/**
 * Convenience function for determining the checkpoint after a given one, in the given sequence.
 *
 * @param {string} checkpoint The checkpoint to move from
 * @param {array} sequence The current sequence of checkpoints
 * @returns the next checkpoint
 */
const checkpointAfter = (checkpoint, sequence) => sequence[sequence.indexOf(checkpoint) + 1] ?? CHECKPOINT_EMPTY

/**
 * This is a thin wrapper on the `patchOnboarding` API request that integrates some error handling.
 *
 * @param {object} patch The onboarding patch payload
 * @param {function} setError State setter in case there is an error
 * @param {array} errorSetters Array of [setter, value] pairs to set in case there is an error
 * @returns
 */
const updateOnboardingObject = async (patch, setError, errorSetters) => {
  try {
    return await patchOnboarding(patch)
  } catch (error) {
    handleErrorWithStateHooks(error, setError, errorSetters)
    return false
  }
}

/**
 * Performs required front-end operations upon conclusion of onboarding.
 *
 * @param {function} navigate
 * @param {function} setAppUser
 */
const concludeOnboarding = async (navigate, refreshAppUser) => {
  // At this stage, the user preferences may have changed on the back end because of onboarding closeout.
  // Thus, we have to manually reload the user then update that user in memory. We rely on the caller to
  // give us the right `history` object and `refreshAppUser` function to do so.
  await refreshAppUser()
  navigate(`/${ONBOARDING_CONCLUSION}`)
}

export { isAddingGoalFromResource, onboardingSequenceFor, checkpointAfter, concludeOnboarding, updateOnboardingObject }
