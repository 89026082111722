import React, { useEffect, useState, useMemo } from 'react'

import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import LinearProgress from '@mui/material/LinearProgress'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import { useAppUser } from '../../app/hooks'
import { getHookPromiseErrorHandler } from '../../common'

import BrandButton from '../../subcomponents/brand/BrandButton'

const useStyles = makeStyles(
  theme => ({
    // Default Calendly embed sizes don’t quite fit with our design, so we make some
    // adjustments at this level. Note that this is pretty fragile and will need
    // updates whenever the overall design (Undivided or Calendly) changes.
    root: {
      [theme.breakpoints.up('md')]: {
        overflow: 'hidden'
      }
    },

    calendly: {
      height: theme.spacing(100.5),
      margin: 0,

      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(-20, -25),
        transform: 'scale(0.625)'
      },

      [theme.breakpoints.down('md')]: {
        height: theme.spacing(138.125)
      }
    },

    error: {
      backgroundColor: 'none',
      borderRadius: theme.spacing(0.5),
      margin: theme.spacing(0, 3),
      padding: theme.spacing(2, 4, 3, 2)
    },

    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(2, 0, 3)
    }
  }),
  {
    name: 'CalendlyEmbed'
  }
)

const CSS_CALENDLY = 'https://assets.calendly.com/assets/external/widget.css'
const INTERVAL_CALENDLY = 100

const hideCookieBanner = boostUrl => {
  const url = new URL(boostUrl)
  url.searchParams.append('hide_gdpr_banner', 1)
  return url.toString()
}

const CalendlyEmbed = props => {
  const appUser = useAppUser()

  const { onError } = props

  const [calendlyApi, setCalendlyApi] = useState()
  const [calendlyCheckInterval, setCalendlyCheckInterval] = useState()

  const [error, setError] = useState()
  const errorHandler = useMemo(() => getHookPromiseErrorHandler(setError), [])

  useEffect(() => {
    // Because the Calendly JavaScript is loaded on the fly, there may be a race condition
    // where this component is rendered before that library is ready. So we need to watch
    // for it.
    const calendlyApiCheck = () => setCalendlyApi(window.Calendly)
    setCalendlyCheckInterval(window.setInterval(calendlyApiCheck, INTERVAL_CALENDLY))
  }, [])

  useEffect(() => {
    if (calendlyApi) {
      window.clearInterval(calendlyCheckInterval)
      // Fall through to check on the user.
    } else {
      // Not ready yet.
      return
    }

    if (calendlyApi && appUser) {
      try {
        calendlyApi.initInlineWidget({
          url: hideCookieBanner(appUser?.household.navigator.kickstartCalendarUrl),
          parentElement: document.getElementById('calendly'),
          prefill: {
            name: appUser?.name,
            email: appUser?.email
          }
        })
      } catch (error) {
        errorHandler(error)
      }
    }

    return () => {
      const calendlyElement = document.getElementById('calendly')

      while (calendlyElement && calendlyElement.firstChild) {
        calendlyElement.removeChild(calendlyElement.firstChild)
      }
    }
  }, [calendlyApi, calendlyCheckInterval, appUser, errorHandler])

  const classes = useStyles(props)
  return (
    <section className={classes.root}>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link href={CSS_CALENDLY} rel="stylesheet" />

      {calendlyApi ? (
        error ? (
          <Alert className={classes.error} severity="info">
            <AlertTitle>Unable to schedule your call</AlertTitle>

            <p>
              We’re sorry but it appears we are unable to schedule you at this time. Please contact Undivided for
              assistance with scheduling this call at a later time.
            </p>

            <p>If you have reason to believe that this might be a temporary issue, you may try to reload this page.</p>

            <section className={classes.buttonContainer}>
              <BrandButton onClick={() => window.location.reload()}>Try Again</BrandButton>

              {onError && (
                <BrandButton variant="contained" onClick={onError}>
                  Skip Scheduling
                </BrandButton>
              )}
            </section>

            <Accordion className={classes.errorDetailContainer}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Click here for technical details</AccordionSummary>
              <AccordionDetails>
                <section>
                  <section>Error details are as follows:</section>

                  <ul>
                    <li>{error}</li>
                  </ul>
                </section>
              </AccordionDetails>
            </Accordion>
          </Alert>
        ) : (
          <section id="calendly" className={classes.calendly} />
        )
      ) : (
        <LinearProgress />
      )}
    </section>
  )
}

CalendlyEmbed.propTypes = {
  onError: PropTypes.func
}

export default CalendlyEmbed
