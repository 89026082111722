/**
 * This module lists constant class names meant for analytics/tracking packages such as Segment.
 * Pretty much everything is an export so we use the `export const` syntax here for brevity.
 */
export const CLASS_NAVIGATOR_MESSENGER = 'navigator-messenger'
export const CLASS_MESSAGE_SEND = 'sendMessage'
export const CLASS_MESSAGE_ATTACH = 'attachToMessage'

export const CLASS_NEW_EVENT = 'add-new-event'
export const CLASS_EVENT_ADD = 'eventAdded'
export const CLASS_EVENT_JOIN_ICON = 'joinMeetingIcon'
export const CLASS_EVENT_JOIN_LINK = 'joinMeetingLink'

export const CLASS_FAMILY_OBJECTIVE = 'objective'
export const CLASS_SAVE_FAMILY_OBJECTIVE = 'save-objective'
export const CLASS_DELETE_FAMILY_OBJECTIVE = 'delete-objective'

export const CLASS_PHASE = 'phase'
export const CLASS_GOAL = 'ap-plan-goal'
export const CLASS_GOAL_CARD = 'goalCard'
export const CLASS_GOAL_REORDER = 'reorderGoal'
export const CLASS_GOAL_NOTES = 'goalNotes'
export const CLASS_ADD_CUSTOM_TASK = 'addCustomTask'

export const CLASS_NEW_DOCUMENT = 'binder-add-new'
export const CLASS_BINDER_SEARCH = 'search-binder'
export const CLASS_BINDER_LIST = 'navigate-binder'
export const CLASS_BINDER_PREVIEW = 'binder-preview'

export const CLASS_HOME_TAB = 'home-tab'
export const CLASS_ACTION_PLAN_TAB = 'action-plan-tab'
export const CLASS_EVENTS_TAB = 'events-tab'
export const CLASS_MESSAGES_TAB = 'messages-tab'
export const CLASS_BINDER_TAB = 'binder-tab'

export const CLASS_PROFILE_EDIT_BUTTON = 'editProfileItem'
export const CLASS_PROFILE_FINISHED_BUTTON = 'saveProfileItem'
export const CLASS_PROFILE_VISIBILITY_SWITCH = 'hideProfileBlock'
export const CLASS_CONTACT_ADD = 'contactAdded'

export const CLASS_ASK_FOR_HELP = 'ask-for-help-button'

export const CLASS_UPLOAD_DOC_SECTION = 'uploadDocSection'
export const CLASS_UPLOAD_DOC_BUTTON = 'uploadDocButton'
export const CLASS_DOCUMENT_VISIBILITY = 'documentVisibility'
export const CLASS_UPLOAD_DOC_DATE = 'issuedDate'
export const CLASS_DOC_PREVIEW = 'docPreview'
export const CLASS_UPLOADED_DOC_TITLE = 'uploadedDocTitle'
export const CLASS_ADD_NEW_DOC = 'addNewDoc'
export const CLASS_NO_DOC_TO_PREVIEW = 'noDocToPreview'
export const CLASS_PREVIEW_DOC = 'previewDoc'
export const CLASS_PREVIEW_UPLOADED_DOC_BUTTON = 'previewUploadedDocButton'
export const CLASS_DOWNLOAD_UPLOADED_DOC_BUTTON = 'downloadUploadedDocButton'
export const CLASS_LINK_DOC_TO_BUTTON = 'linkDocToButton'
export const CLASS_COLLAPSE_TOGGLE_DOC_BUTTON = 'collapseToggleDocButton'
export const CLASS_EDIT_DOC_BUTTON = 'editDocButton'
export const CLASS_DELETE_DOC_BUTTON = 'deleteDocButton'
export const CLASS_SAVE_DOC_BUTTON = 'documentSave'

export const CLASS_UNTAGGED_FILTER = 'untaggedFilter'
export const CLASS_ADD_TAG = 'addTag'
export const CLASS_TAGS_FIELD = 'documentTags'
export const CLASS_CHILD_SELECTOR = 'childSelector'
export const CLASS_FILE_SELECTOR = 'fileSelector'
export const CLASS_FILE_SELECTOR_MERGE = 'fileSelectorMerge'
export const CLASS_OPEN_NEW_TAB = 'openNewTab'
export const CLASS_LINK_TO_GOAL = 'linkToGoal'

export const CLASS_CHECKLIST_ITEM = 'checklistItem'
export const CLASS_CHECKLIST_CHECKED = 'checklistChecked'

export const CLASS_RESOURCE_BOOKMARK = 'bookmarkResource'
export const CLASS_RESOURCE_UNBOOKMARK = 'unbookmarkResource'
export const CLASS_RESOURCE_COPY_LINK = 'copyResourceLink'
