/**
 * Panel is a “combo” component that combines an error boundary with a suspense container,
 * facilitating lazy loading and component rendering error interception.
 */
import React, { Suspense } from 'react'

import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import ErrorBoundary from './ErrorBoundary'
import LoadingFallback from './LoadingFallback'

const useStyles = makeStyles(
  theme => ({
    root: {
      minHeight: '100vh'
    }
  }),
  {
    name: 'Panel'
  }
)

const Panel = props => {
  const { children, fallback, emptyFallback, fullHeight } = props

  const classes = useStyles(props)
  const defaultFallback = <LoadingFallback classes={fullHeight ? { root: classes.root } : undefined} />
  return (
    <Suspense fallback={fallback ?? (emptyFallback ? undefined : defaultFallback)}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Suspense>
  )
}

Panel.propTypes = {
  fallback: PropTypes.node, // Component to display while in suspense, if something different is preferred. Or…
  emptyFallback: PropTypes.bool, // Indicates that no fallback is to be shown (overrides fallback).
  fullHeight: PropTypes.bool // Indicates that the default fallback should be full-height.
}

export default Panel
