const VARIANT_ONBOARDING = 'onboarding'
const VARIANT_IN_APP = 'in-app'

const TRIAL_KICKSTART = 'Kickstart'
const TRIAL_PLATFORM = 'Platform'

const ONBOARD_KICKSTART = `${VARIANT_ONBOARDING}${TRIAL_KICKSTART}`
const ONBOARD_PLATFORM = `${VARIANT_ONBOARDING}${TRIAL_PLATFORM}`

const TRIAL_OPTIONS = [
  {
    title: 'Platform Trial',

    description: [
      'Get step-by-step guidance and progress tracking to accomplish your goals.',
      'Plus, expert-led office hours, unlimited resources, and Andy AI assistance.'
    ].join(' '),

    value: TRIAL_PLATFORM
  },

  {
    title: 'Platform Trial + Kickstart with a Navigator',

    description: [
      'Start your trial by meeting 1:1 with a Navigator to customize your child’s Roadmap,',
      'introduce you to tools, and address your unique needs.'
    ].join(' '),

    value: TRIAL_KICKSTART
  }
]

export {
  VARIANT_ONBOARDING,
  VARIANT_IN_APP,
  TRIAL_KICKSTART,
  TRIAL_PLATFORM,
  TRIAL_OPTIONS,
  ONBOARD_KICKSTART,
  ONBOARD_PLATFORM
}
