// The App component is pretty much its full set of routes, with a single context provided to hold
// the current matched route.
import { useEffect, useMemo } from 'react'
import { matchRoutes, useLocation, useRoutes } from 'react-router-dom'

import { SiteContext } from './app/contexts'
import { ALL_ROUTES, site } from './navigation/map'
import { trackPage } from './tracking/segment'
import { user } from './auth'

const closestSite = location => {
  const matches = matchRoutes(ALL_ROUTES, location)

  const matchedPath = matches
    .map(match => match.route.path)
    .filter(path => path && path !== '/')
    .join('/')

  return site(matchedPath)
}

const App = props => {
  const location = useLocation()
  const currentSite = useMemo(() => closestSite(location), [location])

  const { pathname } = location

  const { title, trackTitle } = currentSite ?? {}

  // Perform some app-wide activities in response to site changes.
  useEffect(() => {
    // No user context at this level, so we need to call the user function directly.
    const appUser = user()
    const { household, signupKey, status } = appUser ?? {}
    const { id: householdId, qualifiedForKickstart } = household ?? {}

    if (trackTitle || title) {
      const pageTitle = trackTitle || title
      const pageDetails = {
        household: householdId,
        qualified: qualifiedForKickstart ? qualifiedForKickstart === 'qualified' : undefined,
        signupKey,
        status
      }
      trackPage(pageTitle, pageDetails)
    }

    if (title) {
      document.title = title
      return () => {
        document.title = 'Undivided'
      }
    }
  }, [pathname, title, trackTitle])

  const allRoutes = useRoutes(ALL_ROUTES)
  return <SiteContext.Provider value={currentSite}>{allRoutes}</SiteContext.Provider>
}

export default App
