import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import { ThemeProvider } from '@mui/material/styles'

import isValid from 'date-fns/isValid'

import IconButton from '@mui/material/IconButton'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { PickersCalendarHeader } from '@mui/x-date-pickers/PickersCalendarHeader'

import { accountThemeExtender } from '../app/support/theme'
import { INPUT_DATE_FORMAT } from '../constants'

import CalendarIcon from '../icons/Calendar'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%'
    },

    button: {
      color: theme.palette.text.primary,
      margin: theme.spacing(-0.25, 0, 0),
      padding: theme.spacing(1)
    },

    icon: {
      height: theme.spacing(1.5),
      width: theme.spacing(1.5)
    },

    labelContainer: {
      '& .MuiPickersFadeTransitionGroup-root': {
        maxHeight: theme.spacing(3) // temporary fix for Calendar header label jumping
      }
    }
  }),
  {
    name: 'DateField'
  }
)

const DateField = props => {
  const {
    className,
    classes: classesProp,
    format,
    placeholder,
    value,
    onChange,
    inputVariant,
    InputLabelProps,
    InputProps,
    Icon,
    minDate,
    maxDate,
    InputAdornmentProps,
    ...passThrough
  } = props

  const checkAndPropagate = date => {
    if (onChange && (!date || isValid(date))) {
      onChange(date)
    }
  }

  const classes = useStyles(props)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={accountThemeExtender}>
        <DesktopDatePicker
          className={clsx(classes.root, className)}
          format={format ?? INPUT_DATE_FORMAT}
          value={value ?? null}
          minDate={minDate}
          maxDate={maxDate}
          onChange={checkAndPropagate}
          slots={{
            // For some reason, slots have to be explicitly stated, even if they are still the default,
            // in order for the slotProps to be passed in correctly.
            calendarHeader: PickersCalendarHeader,
            openPickerButton: IconButton,
            openPickerIcon: Icon ?? CalendarIcon
          }}
          slotProps={{
            calendarHeader: { classes: { labelContainer: classes.labelContainer } },
            inputAdornment: { ...InputAdornmentProps },
            openPickerButton: { className: classes.button },
            openPickerIcon: { className: classes.icon },
            textField: { variant: inputVariant ?? 'standard', InputProps, InputLabelProps }
          }}
          {...passThrough}
        />
      </ThemeProvider>
    </LocalizationProvider>
  )
}

DateField.propTypes = {
  value: PropTypes.instanceOf(Date)
  // Other propTypes are the same as the internal date picker’s prop types.
}

export default DateField
