// These constants implement the specification in:
// https://docs.google.com/spreadsheets/d/124v5579b27F4WkkPLoRxJ2UibK4IwRS0krmHZaDtpW8/edit#gid=0
import { INTENT_GET_ROADMAP } from '../goal/constants'

import ChildInfo from './ChildInfo'
import MembershipCall from './MembershipCall'
import TrialOnboarding from './TrialOnboarding'
import EmailConfirmationReminder from './EmailConfirmationReminder'
import SetupIntentOnboarding from './SetupIntentOnboarding'

import ScheduleConfirmationCard from './support/ScheduleConfirmationCard'
import {
  CREATE_ROADMAP_FUNNEL_REFERRING_OBJECTS,
  RESOURCE_FUNNEL_REFERRING_OBJECTS,
  ANDY_FUNNEL_REFERRING_OBJECTS
} from '../arch/referringObjectConstants'

const JOIN_PATH = 'join'
const JOIN_PATH_REFER = 'join-refer'
const JOIN_PATH_KICKSTART = 'join-kickstart'
const JOIN_PATH_MEDI_CAL_WAIVER = 'join-medi-cal-waiver'
const JOIN_PATH_MEDI_CAL_SECONDARY = 'join-medi-cal-secondary'
const JOIN_PATH_REGIONAL_CENTER = 'join-apply-regional-center'
const JOIN_PATH_IHSS = 'join-obtain-ihss'
const JOIN_PATH_IEP = 'join-annual-iep'
const INVITE_PATH_SKIP_CALL = 'invite-skip-call'

const PROVIDER_PATH_REFER = 'provider-refer'

const VERIFY_EMAIL_QUOTES =
  'We joined Undivided after years of struggling on our own. It was like a light shone on us, and we could see the path ahead.”'

const SCHEDULE_CALL_CLIENT_QUOTES = 'The first meeting felt like a breath of fresh air.”'

const SCHEDULE_CALL_CLIENT_NAME = 'Tselote T.'

const THANK_YOU_QUOTES =
  'Thank you so much for\nyour support! The value\nof [Undivided] is beyond\nwords-I don’t feel so\nalone in all of this now\nthat I have a team of\npeople to bounce ideas\noff of (and vent to!)'

const SCHEDULED_CALL_QUOTES =
  'Working with my\nNavigator has entirely changed my perspective. I\n went from feeling like a\nvictim to feeling like a\nchampion.'

const JOIN_GET_STARTED_CONTENT = [
  '“I’ve repeatedly found that patients whose families work with Undivided',
  'don’t face the same challenges accessing or affording care for their children.”'
].join(' ')

// Each string in the array indicates a separate paragraph.
const JOIN_IEP_COPY = [
  [
    'Set up your account with Undivided and begin your free kickstart',
    'to get IEP help and access to education advocacy.'
  ].join(' '),

  [
    'During your kickstart, you’ll be matched with a Navigator to help you identify your goals,',
    'and we will add a step-by-step guide to preparing for an IEP meeting to your account',
    'so that you can get started right away.'
  ].join(' ')
]

const HELP_LABEL = 'Contact support'
const HELP_CALL_LINK = 'mailto:support@undivided.io?subject=I%20need%20help%20creating%20an%20account'

const JOIN_SITES_ALL = [
  {
    path: JOIN_PATH,
    funnelId: 1,
    gated: false
  },

  {
    path: JOIN_PATH_KICKSTART,
    funnelId: 2,
    gated: true
  },

  {
    path: JOIN_PATH_MEDI_CAL_WAIVER,
    funnelId: 3,
    gated: false
  },

  {
    path: JOIN_PATH_MEDI_CAL_SECONDARY,
    funnelId: 4,
    gated: false
  },

  {
    path: JOIN_PATH_REGIONAL_CENTER,
    funnelId: 5,
    gated: false
  },

  {
    path: JOIN_PATH_IHSS,
    funnelId: 6,
    gated: false
  },

  {
    path: JOIN_PATH_IEP,
    copy: JOIN_IEP_COPY,
    funnelId: 7,
    gated: false
  },

  {
    path: JOIN_PATH_REFER,
    funnelId: 8,
    gated: false
  },

  {
    path: INVITE_PATH_SKIP_CALL,
    funnelId: 9,
    gated: false
  }
]

const PROVIDER_SITES_ALL = [
  {
    path: PROVIDER_PATH_REFER,
    funnelId: 10,
    gated: false
  }
]

const ONBOARDING_FUNNELS = {
  1: { kickstart: false, zipCode: false },
  2: { kickstart: true, zipCode: true },
  3: { kickstart: true, zipCode: true },
  4: { kickstart: true, zipCode: true },
  5: { kickstart: true, zipCode: true },
  6: { kickstart: true, zipCode: true },
  7: { kickstart: true, zipCode: true },
  9: { kickstart: false, zipCode: false } // This is the invite-but-skip-scheduling funnel.
}

const sitePath = joinSite => joinSite.path

const JOIN_PATHS_GATED = JOIN_SITES_ALL.filter(joinSite => joinSite.gated).map(sitePath)
const JOIN_PATHS_PUBLIC = JOIN_SITES_ALL.filter(joinSite => !joinSite.gated).map(sitePath)

const PROVIDER_PATHS_GATED = PROVIDER_SITES_ALL.filter(providerSite => providerSite.gated).map(sitePath)
const PROVIDER_PATHS_PUBLIC = PROVIDER_SITES_ALL.filter(providerSite => !providerSite.gated).map(sitePath)

const JOIN_SITE_MAP = Object.fromEntries(JOIN_SITES_ALL.map(joinSite => [`/${joinSite.path}`, joinSite]))
const PROVIDER_SITE_MAP = Object.fromEntries(
  PROVIDER_SITES_ALL.map(providerSite => [`/${providerSite.path}`, providerSite])
)

const ONBOARDING_LANDING = 'onboarding'
const ONBOARDING_CONCLUSION = 'home'

const CHECKPOINT_WELCOME = 'welcome'
const CHECKPOINT_TOPICS = 'topics'
const CHECKPOINT_CHILD = 'child'
const CHECKPOINT_LEAD_SOURCE = 'lead-source'
const CHECKPOINT_BENEFITS = 'public-benefits'
const CHECKPOINT_KICKSTART = 'kickstart'
const CHECKPOINT_QUALIFICATION = 'qualification'
const CHECKPOINT_QUALIFIED = 'qualified'
const CHECKPOINT_EMPTY = ''
const NO_CHECKPOINT = 'noCheckPoint'
const CHECKPOINT_TRIAL_UPSELL = 'trial-upsell'
const CHECKPOINT_CHILD_INFO = 'child-info'
const CHECKPOINT_VERIFY_EMAIL = 'verify-email'
const CHECKPOINT_SCHEDULE_CONFIRMATION = 'scheduleConfirmation'
const CHECKPOINT_PAYMENT_CC = 'payment-cc'

const QUALIFIED = 'qualified'
const NOT_QUALIFIED = 'notQualified'
const BYPASS = 'bypass'

const DESTINATION_HOME = 'home'
const DESTINATION_SCHEDULE = 'schedule'
const DESTINATION_CONFIRMATION = 'confirmCode'

const CHILD_TYPE_OPTIONS = [
  { label: 'Parent of a child 18 or younger with disabilities.', value: 'minor' },
  { label: 'Parent with an adult child living with disabilities.', value: 'adult-child' },
  { label: 'Adult living with one or more disability.', value: 'adult-living-with' },
  { label: 'Provider or work with families in need.', value: 'provider' }
]

const OPTION_NO_CHILD_TYPE = '__no-child-type__'

const ONBOARDING_MAP = {
  [CHECKPOINT_VERIFY_EMAIL]: EmailConfirmationReminder,
  [CHECKPOINT_CHILD_INFO]: ChildInfo,
  [CHECKPOINT_TRIAL_UPSELL]: TrialOnboarding,
  [CHECKPOINT_KICKSTART]: MembershipCall,
  [CHECKPOINT_SCHEDULE_CONFIRMATION]: ScheduleConfirmationCard,
  [CHECKPOINT_PAYMENT_CC]: SetupIntentOnboarding
}

const ROADMAP_WALKTHROUGH_LINK = `/roadmaps?intent=${INTENT_GET_ROADMAP}`

const FUNNEL_INTENT = 'Intent'
const FUNNEL_RESOURCES = 'Resources'
const FUNNEL_CREATE_ROADMAP = 'Create Roadmap'
const FUNNEL_ANDY = 'Andy AI'
const FUNNEL_PAYMENT_COLLECT = 'Payment Collect'

const FUNNEL_MAPPING = {
  [FUNNEL_RESOURCES]: RESOURCE_FUNNEL_REFERRING_OBJECTS,
  [FUNNEL_CREATE_ROADMAP]: CREATE_ROADMAP_FUNNEL_REFERRING_OBJECTS,
  [FUNNEL_INTENT]: [],
  [FUNNEL_ANDY]: ANDY_FUNNEL_REFERRING_OBJECTS
}

export {
  JOIN_SITES_ALL,
  PROVIDER_SITES_ALL,
  JOIN_PATH,
  JOIN_PATH_KICKSTART,
  JOIN_PATH_MEDI_CAL_WAIVER,
  JOIN_PATHS_GATED,
  PROVIDER_PATHS_GATED,
  JOIN_PATH_MEDI_CAL_SECONDARY,
  JOIN_PATH_REGIONAL_CENTER,
  JOIN_PATH_IHSS,
  JOIN_PATH_IEP,
  JOIN_PATHS_PUBLIC,
  PROVIDER_PATHS_PUBLIC,
  JOIN_GET_STARTED_CONTENT,
  JOIN_SITE_MAP,
  PROVIDER_SITE_MAP,
  ONBOARDING_FUNNELS,
  ONBOARDING_LANDING,
  ONBOARDING_CONCLUSION,
  ONBOARDING_MAP,
  CHECKPOINT_WELCOME,
  CHECKPOINT_TOPICS,
  CHECKPOINT_CHILD,
  CHECKPOINT_LEAD_SOURCE,
  CHECKPOINT_BENEFITS,
  CHECKPOINT_KICKSTART,
  CHECKPOINT_QUALIFICATION,
  CHECKPOINT_QUALIFIED,
  CHECKPOINT_SCHEDULE_CONFIRMATION,
  CHECKPOINT_PAYMENT_CC,
  NO_CHECKPOINT,
  THANK_YOU_QUOTES,
  SCHEDULED_CALL_QUOTES,
  SCHEDULE_CALL_CLIENT_QUOTES,
  SCHEDULE_CALL_CLIENT_NAME,
  HELP_LABEL,
  HELP_CALL_LINK,
  CHECKPOINT_EMPTY,
  CHILD_TYPE_OPTIONS,
  OPTION_NO_CHILD_TYPE,
  QUALIFIED,
  NOT_QUALIFIED,
  BYPASS,
  DESTINATION_HOME,
  DESTINATION_SCHEDULE,
  DESTINATION_CONFIRMATION,
  CHECKPOINT_TRIAL_UPSELL,
  CHECKPOINT_CHILD_INFO,
  CHECKPOINT_VERIFY_EMAIL,
  VERIFY_EMAIL_QUOTES,
  ROADMAP_WALKTHROUGH_LINK,
  FUNNEL_INTENT,
  FUNNEL_CREATE_ROADMAP,
  FUNNEL_RESOURCES,
  FUNNEL_PAYMENT_COLLECT,
  FUNNEL_MAPPING
}
