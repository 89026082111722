/**
 * ChunkLoadError is a component that is shown when a ChunkLoadError is encountered.
 * It provides specific messaging to explain what happened with buttons to initiate
 * a reload.
 */
import React from 'react'

import makeStyles from '@mui/styles/makeStyles'

import InfoIcon from '@mui/icons-material/Info'

import { reloadApp } from '../reload'

import BrandButton from '../../subcomponents/brand/BrandButton'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(4)
    },

    message: {
      maxWidth: theme.spacing(64)
    },

    buttons: {
      margin: theme.spacing(4, 0)
    }
  }),
  {
    name: 'ChunkLoadError'
  }
)

const ChunkLoadError = props => {
  const classes = useStyles(props)
  return (
    <article className={classes.root}>
      <section className={classes.message}>
        <section>
          <InfoIcon color="info" fontSize="large" titleAccess="Need to reload" />
        </section>

        <p>
          It looks like your web browser is out of sync with the latest version of our app. Please reload the page to
          sync up, or click on the button below.
        </p>

        <p>
          You might encounter this message more than once—just keep reloading until it goes away. Web browsers can be
          finicky like that, unfortunately 😅
        </p>

        <p className={classes.buttons}>
          <BrandButton variant="contained" onClick={reloadApp}>
            Reload
          </BrandButton>
        </p>
      </section>
    </article>
  )
}

export default ChunkLoadError
