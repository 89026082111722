import { createContext } from 'react'

/**
 * Provides functions for determining paths to messaging views.
 * Defaults in this context are applicable to the parent portal.
 *
 * Other environments (e.g., services portal, parent view) should provide their
 * alternative values, if needed.
 *
 * A provider can substitute falsy values for these functions; the
 * expectation is that falsy properties mean that links should not
 * be rendered.
 */
const MessagesNavigationContext = createContext({
  // The function that returns the path to message bookmarks.
  bookmarksPath: () => '/messages/bookmarks',

  // The function that returns the path to message search results.
  matchesPath: () => '/messages/matches',

  // The function that returns the path to navigator messages.
  navigatorMessagesPath: () => '/messages/navigator',

  // The function that returns the path to goal messages, given the goal’s ID.
  goalMessagesPath: goalId => `/messages/goals/${goalId}`
})

const ChatSearchContext = createContext({
  // Whether search should be visible.
  showSearch: false,

  // The function to set whether search should be visible.
  setShowSearch: () => {},

  // The current search query.
  query: '',

  // The function to set the search query.
  setQuery: () => {},

  // Indicates whether the search results are loading.
  searching: false,

  // Indicates whether search result pagination is taking place. This is distinct from an actual search
  // in case different UI feedback is desired.
  paging: false,

  // The current search results.
  results: [],

  // Indicates whether all available results have been retrieved.
  complete: true,

  // Function that requests more search results for the current search.
  loadMore: () => {}
})

export { MessagesNavigationContext, ChatSearchContext }
