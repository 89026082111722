import React from 'react'
import { useLocation } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles'

import Paper from '@mui/material/Paper'

import logo from '../../assets/logo-cobrand.svg'
import { userDialogStyles } from '../../commonStyles'

import { useAppUser } from '../hooks'

const useStyles = makeStyles(userDialogStyles, { name: 'NotFound' })

const NotFound = props => {
  const appUser = useAppUser()
  const location = useLocation()
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <div className={classes.dialogContainer}>
        <Paper className={classes.dialog}>
          <section className={classes.logo}>
            <img src={logo} alt="Undivided Logo" />
          </section>

          <h1 className={classes.prompt}>Sorry, but we don’t know about {location.pathname}.</h1>

          <p>
            {appUser
              ? 'Our sidebar menu lists everything that you can visit. Please try one of those links.'
              : 'Please try a different link. If you’re a member, you can try to log in first.'}
          </p>
        </Paper>
      </div>
    </div>
  )
}

export default NotFound
