import React, { forwardRef } from 'react'

import SvgIcon from '@mui/material/SvgIcon'

const ClientAccounts = forwardRef((props, ref) => (
  <SvgIcon width="15" height="18" viewBox="0 0 15 18" {...props} ref={ref}>
    <path d="M10.0331 8.4731C9.28353 8.98054 8.40255 9.25154 7.50129 9.25196C6.2904 9.25027 5.12965 8.76155 4.27381 7.89307C3.41789 7.0245 2.9369 5.8471 2.93642 4.61933C2.93772 3.70465 3.20644 2.8109 3.70862 2.05102C4.21081 1.29115 4.92391 0.69926 5.7578 0.350169C6.59169 0.00107763 7.50895 -0.0895479 8.39363 0.0897462C9.27831 0.26904 10.0907 0.710206 10.7281 1.35749C11.3656 2.00476 11.7995 2.8291 11.9749 3.72631C12.1504 4.62352 12.0595 5.55333 11.7139 6.39822C11.3683 7.24311 10.7834 7.96515 10.0331 8.4731Z" />
    <path d="M7.50129 9.25196C7.50118 9.25196 7.5014 9.25196 7.50129 9.25196V9.25196Z" />
    <path d="M14.8187 15.1894L14.8324 15.2203C15.4034 16.5294 14.444 18 13.0322 18H1.9692C0.556856 18 -0.403202 16.5306 0.167224 15.2221L0.179158 15.1955H0.194671C0.475597 14.5705 0.832819 13.9838 1.25795 13.449C2.07713 12.395 3.15164 11.574 4.3768 11.0662C5.36666 10.6496 6.42869 10.438 7.50042 10.4437C8.57214 10.438 9.63416 10.6497 10.624 11.0662C11.8489 11.5742 12.9232 12.3951 13.7423 13.449C14.1662 13.982 14.5226 14.5667 14.8032 15.1894H14.8187Z" />
  </SvgIcon>
))

export default ClientAccounts
