import React from 'react'

import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import Snackbar from '@mui/material/Snackbar'

import CloseIcon from '@mui/icons-material/Close'

import BrandIconButton from './brand/BrandIconButton'

const useStyles = makeStyles(
  theme => ({
    message: {
      maxWidth: theme.spacing(64),
      '& a': {
        color: theme.palette.primary.main
      }
    },

    errorMessage: {
      fontStyle: 'italic',
      margin: theme.spacing(1)
    }
  }),
  {
    name: 'ErrorSnackbar'
  }
)

const DEFAULT_ANCHOR_ORIGIN = {
  horizontal: 'left',
  vertical: 'bottom'
}

const LOCKED_ERROR = 'Verify your email to get full access.'
const DEFAULT_GENERAL = 'An unexpected error has occurred.'
const DEFAULT_WHAT_TO_DO = [
  'There is a possibility that this error is transient. Please wait a few moments then try again.',
  'If the error persists, please contact Undivided technical support.'
].join('\n')

const ErrorSnackbar = props => {
  const { className, error, setError } = props
  const clearError = () => setError(null)

  const anchorOrigin = props.anchorOrigin || DEFAULT_ANCHOR_ORIGIN
  const general = props.general || DEFAULT_GENERAL
  const whatToDo = props.whatToDo || DEFAULT_WHAT_TO_DO

  const classes = useStyles(props)
  return (
    <Snackbar
      className={className}
      anchorOrigin={anchorOrigin}
      open={Boolean(error) && error !== LOCKED_ERROR}
      onClose={clearError}
      ContentProps={{ classes: { message: classes.message } }}
      message={[
        <div key="general">{general} The specific message given by the error is:</div>,

        <div key="specific" className={classes.errorMessage}>
          {error}
        </div>,

        <div key="what-to-do">{whatToDo}</div>
      ]}
      action={
        <BrandIconButton aria-label="Close" color="inherit" onClick={clearError}>
          <CloseIcon />
        </BrandIconButton>
      }
    />
  )
}

ErrorSnackbar.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string, // Not required because it may be null or empty.
  setError: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.object,
  general: PropTypes.any, // any allows for component or markup insertion.
  whatToDo: PropTypes.any
}

export default ErrorSnackbar
