const RESOURCE_MAIN_NAV = 'Main Nav'
const RESOURCE_SIDEBAR_CTA = 'Sidebar CTA'
const RESOURCE_FOOTER_CTA = 'Footer CTA'
const RESOURCE_LOCKED_RESOURCE = 'Locked Resource'
const RESOURCE_ADD_GOAL_CALLOUT = 'Add Goal Callout'
const RESOURCE_START_ROADMAP = 'Start Roadmap'
const RESOURCE_GOAL_HEADER_CTA = 'Goal Header CTA'
const RESOURCE_GOAL_KICKSTART_CTA = 'Goal Kickstart CTA'
const RESOURCE_GOAL_ROADMAP_CTA = 'Goal Roadmap CTA'
const RESOURCE_GOAL_LISTING_SIDEBAR_CTA = 'Goal Listing Sidebar CTA'
const RESOURCE_ARTICLES_SIDEBAR_CTA = 'Articles Sidebar CTA'
const RESOURCE_PARENT_QUESTIONS_SIDEBAR_CTA = 'Parent Questions Sidebar CTA'
const RESOURCE_EVENT_RECAPS_SIDEBAR_CTA = 'Event Recaps Sidebar CTA'
const RESOURCE_VIDEOS_SIDEBAR_CTA = 'Videos Sidebar CTA'
const RESOURCE_DECODERS_SIDEBAR_CTA = 'Decoders Sidebar CTA'

const ANDY_RESULTS = 'Andy Results'
const ANDY_SEARCH_BAR = 'AI Search Bar'
const ANDY_SEARCH_HOME = 'AI Search Home'

const RESOURCE_FUNNEL_REFERRING_OBJECTS = [
  RESOURCE_FOOTER_CTA,
  RESOURCE_MAIN_NAV,
  RESOURCE_SIDEBAR_CTA,
  RESOURCE_LOCKED_RESOURCE,
  RESOURCE_START_ROADMAP,
  RESOURCE_GOAL_HEADER_CTA,
  RESOURCE_GOAL_KICKSTART_CTA,
  RESOURCE_GOAL_ROADMAP_CTA,
  RESOURCE_GOAL_LISTING_SIDEBAR_CTA,
  RESOURCE_ARTICLES_SIDEBAR_CTA,
  RESOURCE_PARENT_QUESTIONS_SIDEBAR_CTA,
  RESOURCE_EVENT_RECAPS_SIDEBAR_CTA,
  RESOURCE_VIDEOS_SIDEBAR_CTA,
  RESOURCE_DECODERS_SIDEBAR_CTA
]

const ANDY_FUNNEL_REFERRING_OBJECTS = [ANDY_RESULTS, ANDY_SEARCH_BAR, ANDY_SEARCH_HOME]

const CREATE_ROADMAP_FUNNEL_REFERRING_OBJECTS = [RESOURCE_ADD_GOAL_CALLOUT]

export { RESOURCE_FUNNEL_REFERRING_OBJECTS, CREATE_ROADMAP_FUNNEL_REFERRING_OBJECTS, ANDY_FUNNEL_REFERRING_OBJECTS }
