import React, { forwardRef } from 'react'

import SvgIcon from '@mui/material/SvgIcon'

const CircleCheckFilled = forwardRef((props, ref) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} ref={ref}>
    <circle cx="16" cy="16" r="15.5" />
    <g transform="translate(0, -4)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7525 16.4705L13.7374 25.9722C13.6579 26.0477 13.5451 26.0942 13.4174 26.0942C13.2898 26.0942 13.1769 26.0476 13.0974 25.9722L8.24734 21.3613C7.91747 21.0482 7.91747 20.5527 8.24734 20.2396L8.85714 19.6608C9.19946 19.3359 9.76045 19.3359 10.1028 19.6608L13.4255 22.8145L21.8971 14.77C22.2394 14.4451 22.8004 14.4451 23.1427 14.77L23.7525 15.3488C24.0824 15.6619 24.0823 16.1574 23.7525 16.4705Z"
        fill="white"
      />
    </g>
  </SvgIcon>
))

export default CircleCheckFilled
