import { HTTP_BAD_REQUEST, HTTP_OK, HTTP_NOT_FOUND, METHOD_POST } from './arch/constants'
import { get, query, patch, post, put, del, directHead, authorizedFetch, postSearch } from './auth'
import { noContentCheck, statusCheck, emitNativeError } from './common'
import model from './model'

const getFeed = householdId => get(`home${householdId ? `/${householdId}` : ''}`)

const getHousehold = id => get(`households/${id}`).then(householdContainer => householdContainer.household)
const patchHousehold = (id, payload) =>
  patch(`households/${id}`, payload).then(householdContainer => householdContainer.household)

const getGoalHouseholds = params => query('me/goalhouseholds', params)
const getNavigatedHouseholds = params => query('me/navigatedhouseholds', params)

const getClients = () => get('clients').then(clientsContainer => clientsContainer.clients)
const getClient = id => get(`clients/${id}`).then(clientContainer => clientContainer.client)
const getClientTeam = id => get(`clients/${id}/team`).then(teamContainer => teamContainer.users)

const getPostById = id => get(`posts/${id}`).then(postContainer => postContainer.post)

const getHouseholdRatings = householdId => get(`households/${householdId}/ratings`)
const postHouseholdRating = (householdId, rating) => post(`households/${householdId}/ratings`, rating)

const downloadPostArchive = postId => {
  const params = new URLSearchParams()
  params.set('disposition', 'attachment')
  return query(`posts/${postId}/archive`, params)
}

// Unique behavior alert: A missing post document is actually quite normal, so we "accept" HTTP_BAD_REQUEST statuses.
const requestPostDocument = (postId, params) => {
  const route = `posts/${postId}/document`
  return authorizedFetch(params ? `${route}?${params}` : route)
    .then(statusCheck([HTTP_OK, HTTP_BAD_REQUEST]), emitNativeError)
    .then(response => response.json())
}

const downloadPostDocument = postId => {
  const params = new URLSearchParams()
  params.set('disposition', 'attachment')
  return requestPostDocument(postId, params)
}

const getPostDocument = requestPostDocument

const getPostGoals = postId => get(`posts/${postId}/goals`).then(goalsContainer => goalsContainer.goals)
const putPostGoals = (postId, goalIds) => put(`posts/${postId}/goals`, { goalIds })

const getPostSources = (householdId, childId, queryTerm) => {
  const params = new URLSearchParams()
  params.set('query', queryTerm)

  // If we have been given a household ID, we stop there so that the search isn’t too strictly scoped
  // (i.e., terms only showing up if used with the given child). We use child ID only if we don’t have
  // a household ID.
  if (householdId) {
    params.set('householdId', householdId)
  } else if (childId) {
    params.set('childId', childId)
  }

  return query(`post_sources`, params).then(sourcesContainer => sourcesContainer.sources)
}

const getPostWhitelist = postId => get(`posts/${postId}/whitelist`).then(whitelistContainer => whitelistContainer.users)

const queryAllPosts = params => query('posts', params)
const queryCollections = (collectionName, params) => query(`collections/${collectionName}`, params)

// This variant on queryCollections is primarily for backward compatibility with older app components.
const queryPosts = (childId, params) => {
  const finalParams = params ?? new URLSearchParams()
  finalParams.set('childId', childId)

  // Original queryPosts does return the container so that the cursor rides along.
  return queryCollections('documents', params)
}

const getProfileBlockCollection = childId => {
  const params = new URLSearchParams()
  params.set('childId', childId)

  // Backward compatibility: current calls to getProfileBlockCollection don’t do pagination.
  params.set('limit', 1000)
  return queryCollections('profile', params)
}

const getMe = () => get('me').then(userContainer => userContainer.user)
const patchMe = user => patch('me', user).then(userContainer => userContainer.user)

const patchProducts = (householdId, productId, payload) =>
  patch(`households/${householdId}/products/${productId}`, payload)

const postTrialMembership = householdId => post(`households/${householdId}/memberships`, {})

const postOnboardingTrialMembership = householdId => post(`onboarding/households/${householdId}/memberships`, {})

const getOnboarding = () =>
  get('onboarding')
    .then(onboardingContainer => onboardingContainer.onboarding)
    .catch(error => {
      if (error.response?.status === HTTP_NOT_FOUND) {
        // Special case—a 404 on onboarding is a no-op: it is pretty much equivalent to “not onboarding.”
        // In this case, we return an empty object to the caller.
        return {}
      } else {
        throw error
      }
    })

const patchOnboarding = onboarding =>
  patch('onboarding', onboarding).then(onboardingContainer => onboardingContainer.onboarding)

const postSchedulingRelay = event => post('kickstarts', event)

const patchPreferences = (username, preferences) =>
  patch('userpreferences', { username, preferences }).then(userContainer => userContainer.user)

const patchEmailUnconfirmedPreferences = (username, preferences) =>
  patch('emailunconfirmed/userpreferences', { username, preferences }).then(userContainer => userContainer.user)

const getTypes = () => get('types').then(typesContainer => typesContainer.types)

const postClient = clientPayload => post('clients', clientPayload).then(clientContainer => clientContainer.client)
const patchClient = client => patch(`clients/${client.id}`, client).then(clientContainer => clientContainer.client)

const patchClientWithAuditDetails = (clientId, clientPayload) =>
  patch(`clients/${clientId}?showGoalChanges=true`, clientPayload)

const putClientTeam = client =>
  put(`clients/${client.id}/team`, client.payload).then(teamContainer => teamContainer.users)

const getContact = id => get(`contacts/${id}`).then(contactContainer => contactContainer.contact)
const postContact = params => post('contacts', params).then(container => container.contact)
const patchContact = contact => patch(`contacts/${contact.id}`, contact).then(container => container.contact)
const queryContacts = params => query('contacts', params)
const deleteContact = contact => del(`contacts/${contact.id}`)

const getContactRoles = category => {
  const params = new URLSearchParams()
  params.set('category', category)

  return query('contact-roles', params).then(container => container.roles)
}

const postPost = postPayload => post(`posts`, postPayload).then(postContainer => postContainer.post)
const patchPost = post => patch(`posts/${post.id}`, post).then(postContainer => postContainer.post)
const deletePost = post => del(`posts/${post.id}`)

const postToWhitelist = (postId, userId) => post(`posts/${postId}/whitelist`, { user_id: userId })
const deleteFromWhitelist = (postId, userId) => del(`posts/${postId}/whitelist/${userId}`)

const getAsset = (assetId, variant) => get(`assets/${assetId}/${variant}`)
const getResourceAsset = assetId => get(`resourceassets?ids=${assetId}`)

const headDirectAsset = url => directHead(url)

const uploadAsset = (url, formData) =>
  fetch(url, {
    method: METHOD_POST,
    body: formData
  }).then(noContentCheck, emitNativeError)

const downloadAsset = (assetId, variant) => {
  const params = new URLSearchParams()
  params.set('disposition', 'attachment')
  return query(`assets/${assetId}/${variant}`, params)
}

const postAsset = ({ householdId, childId, file }) =>
  post('assets', {
    householdId,
    childId,
    mimeType: file.type,
    fileName: file.name
  }).then(assetContainer => {
    const assetForm = new FormData()
    assetForm.append('key', assetContainer.url.fields.key)
    Object.keys(assetContainer.url.fields)
      .filter(key => key !== 'key')
      .forEach(key => assetForm.append(key, assetContainer.url.fields[key]))

    assetForm.append('Content-Type', file.type)
    assetForm.append('file', file)
    return uploadAsset(assetContainer.url.url, assetForm).then(() => assetContainer.id)
  })

const postResourceAsset = (file, options) =>
  post(`resourceassets`, {
    ...options,
    mimeType: file.type
  }).then(assetContainer => {
    const assetForm = new FormData()
    assetForm.append('key', assetContainer.url.fields.key)
    Object.keys(assetContainer.url.fields)
      .filter(key => key !== 'key')
      .forEach(key => assetForm.append(key, assetContainer.url.fields[key]))

    assetForm.append('Content-Type', file.type)
    assetForm.append('file', file)
    return uploadAsset(assetContainer.url.url, assetForm).then(() => assetContainer.id)
  })

const deleteAsset = assetId => del(`assets/${assetId}`)

const queryTemplates = params => query('infoblocktemplates', params)

const getTemplate = templateId =>
  get(`infoblocktemplates/${templateId}`).then(templateContainer => templateContainer.template)

const postTemplate = templatePayload =>
  post('infoblocktemplates', templatePayload).then(templateContainer => templateContainer.template)

const patchTemplate = template =>
  patch(`infoblocktemplates/${template.id}`, template).then(templateContainer => templateContainer.template)

const deleteTemplate = templateId => del(`infoblocktemplates/${templateId}`)

const queryUsers = params => query('users', params)
const queryInternalUsers = params => query('internalusers', params)
const getUser = userId => get(`users/${userId}`).then(userContainer => model.user(userContainer.user))

const getNavigator = navigatorId => get(`navigators/${navigatorId}`)

const queryChatCandidates = params => query('chat/candidates', params)
const getChatMembers = channelId => get(`chat/channels/${channelId}/members`)
const patchChatMembers = (channelId, payload) => patch(`chat/channels/${channelId}/members`, payload)

const postInvitation = (clientId, name, nickname, familyName, email, type = 'TEAM') =>
  post('invitations', { client: clientId, name, nickname, familyName, email, type })

const postInvitationByNavigator = (userId, clientId, name, nickname, familyName, email, type = 'TEAM') =>
  post(`household/${userId}/invitations`, { client: clientId, name, nickname, familyName, email, type })

const getInvitations = clientId =>
  get(`clients/${clientId}/invitations`).then(invitationsContainer => invitationsContainer.invitations)

const postIndirectSignup = indirectSignupPayload => post('indirectsignups', indirectSignupPayload)
const postSignup = signupPayload => post('signups', signupPayload)

const getResource = id => get(`resources/${id}`).then(container => container.resource)
const getResources = ids => get(`resources?ids=${encodeURIComponent(ids)}`).then(container => container.resources)
const deleteResource = id => del(`resources/${id}`)
const patchResource = resource => patch(`resources/${resource.id}`, resource).then(container => container.resource)
const postResource = resource => post('resources', resource).then(container => container.resource)
const postResourceSearch = params => postSearch('resourcesearch', params)
const getResourceFilters = () => get('resourcefilters').then(filters => filters.filters)

const queryTopics = params => query('resourcetopics', params)

const getFamilyObjectives = (householdId, params) => {
  const paramsToUse = params || new URLSearchParams()
  paramsToUse.set('household', householdId)
  return query('familyobjectives', params)
}

const familyObjectiveExtractor = familyObjectiveContainer => familyObjectiveContainer.familyObjective

const getFamilyObjective = familyObjectiveId =>
  get(`familyobjectives/${familyObjectiveId}`).then(familyObjectiveExtractor)

const postFamilyObjective = familyObjective => post('familyobjectives', familyObjective).then(familyObjectiveExtractor)

const patchFamilyObjective = familyObjective =>
  patch(`familyobjectives/${familyObjective.id}`, familyObjective).then(familyObjectiveExtractor)

const deleteFamilyObjective = familyObjectiveId => del(`familyobjectives/${familyObjectiveId}`)

const queryEvents = params => query('events', params)
const getEvent = id => get(`events/${id}`).then(container => container.event)
const postEvent = event => post('events', event).then(container => container.event)
const patchEvent = event => patch(`events/${event.id}`, event).then(container => container.event)
const deleteEvent = eventId => del(`events/${eventId}`)

const queryGoals = params => query('goals', params)
const getGoal = id => get(`goals/${id}`).then(container => container.goal)
const postGoal = goal => post('goals', goal).then(container => container.goal)
const patchGoal = goal => patch(`goals/${goal.id}`, goal).then(container => container.goal)
const deleteGoal = goalId => del(`goals/${goalId}`)

const getRoadmaps = () => get('roadmaps').then(container => container.roadmaps)

const postRoadmapGoals = ({ householdId, roadmapId, childId }) =>
  post(`households/${householdId}/roadmaps`, { roadmapId, childId }).then(container => container.insertedGoals)

const postRecommendedRoadmapGoals = clientId => post(`clients/${clientId}/recommended-goals`)

const queryGuides = params => query('guides', params)
const postGuide = guide => post('guides', guide).then(container => container.guide)
const patchGuide = guide => patch(`guides/${guide.id}`, guide).then(container => container.guide)
const patchStep = step => patch(`steps/${step.id}`, step).then(container => container.step)

// Note: Atypical signature for `getGuide`. To support guide variants, an optional `householdId` argument
//       determines whether variant substitution should take place. Household is used because it is the
//       household that drives variant selection.
const getGuide = (id, householdId) =>
  (() => {
    if (householdId) {
      const params = new URLSearchParams()
      params.set('householdId', householdId)
      return query(`guides/${id}`, params)
    } else {
      return get(`guides/${id}`)
    }
  })().then(container => container.guide)

const deleteGuide = guideId => del(`guides/${guideId}`).then(container => container.guide)

const postGuideVariant = (guide, variantSourceId) =>
  post(`guides/${variantSourceId}/variants`, guide).then(container => container.variant)

// Of note: these endpoint containers return both the variant and the parent variant count,
// so unlike other calls we return the entire response and don’t extract just the variant.
const patchGuideVariant = guideVariant => patch(`guidevariants/${guideVariant.id}`, guideVariant)
const deleteGuideVariant = guideVariantId => del(`guidevariants/${guideVariantId}`)

// The get guide variant container returns both as well, but the function “merges” them so that
// guide parent properties that the variant doesn’t have appear to be part of the variant,
// _except_ for the `variants` property which should exclusively be in the parent.
const getGuideVariant = guideVariantId =>
  get(`guidevariants/${guideVariantId}`).then(container => {
    const { guide, variant } = container
    const { variants, ...guideParent } = guide
    return { ...guideParent, ...variant }
  })

const queryVariants = (variantSourceId, params) => query(`guides/${variantSourceId}/variants`, params)

const queryHouseholdChecklist = (id, params) => query(`households/${id}/checklist`, params)
const queryGoalChecklist = (id, params) => query(`goals/${id}/checklist`, params)
const patchHouseholdChecklist = (id, payload) => patch(`households/${id}/checklist`, payload)

const checklistDelete = item => {
  const { id, householdId } = item
  return patchHouseholdChecklist(householdId, { delete: { id } })
}

const checklistAddFromSource = ({ householdId, goalId, guideId, stepId, key }) =>
  patchHouseholdChecklist(householdId, { addFromSource: { goalId, guideId, stepId, key } })

const checklistUpdate = ({ householdId, id, changes }) =>
  patchHouseholdChecklist(householdId, { update: { id, changes } })

const checklistAdd = ({ householdId, item, before }) => patchHouseholdChecklist(householdId, { add: { item, before } })
const checklistCheck = ({ householdId, id, checked }) => checklistUpdate({ householdId, id, changes: { checked } })
const checklistMove = ({ householdId, id, before }) => patchHouseholdChecklist(householdId, { move: { id, before } })
const checklistPriority = ({ householdId, id, priority }) => checklistUpdate({ householdId, id, changes: { priority } })

const postNewSignupRequest = signupKey => postSearch(`signups/${signupKey}/refresh`)

const queryTasks = params => query(`tasks`, params)
const getTask = id => get(`tasks/${id}`).then(container => container.task)
const patchTask = task => patch(`tasks/${task.id}`, task).then(container => container.task)
const postTask = task => post('tasks', task).then(container => container.task)
const deleteTask = taskId => del(`tasks/${taskId}`)

const queryNavigatedHouseholdDetails = params =>
  query('me/navigated_household_details', params).then(container => container.navigated_household_details)
const queryGoalHouseholdDetails = params =>
  query('me/goal_household_details', params).then(container => container.goal_household_details)
const querySharedHouseholdDetails = params =>
  query('me/shared_household_details', params).then(container => container.shared_household_details)

const queryMetrics = (path, params) => query(`metrics/${path}`, params)

const queryEngagements = (path, params) =>
  queryMetrics(`engagement/${path}`, params).then(container => container.engagements)

const queryAtRiskHouseholdDetails = params => queryEngagements('atRisk', params)
const queryLowAppUsageHouseholdDetails = params => queryEngagements('appUsage', params)
const queryLowMessengerEngagementHouseholdDetails = params => queryEngagements('messenger', params)
const queryLowBinderEngagementHouseholdDetails = params => queryEngagements('binder', params)
const queryLowPlanEngagementHouseholdDetails = params => queryEngagements('plan', params)
const queryRatingsChangeHouseholdDetails = params => queryEngagements('ratingsChange', params)

const getBulkHouseholdRatings = params =>
  query('households/ratings', params).then(container => container.household_ratings)

const postBulkHouseholdRatings = params => post('households/ratings', params)
const postGoalBankDetail = goalBank => post('goaltemplates', goalBank).then(container => container.goalTemplate)
const getGoalBankDetail = (id, params) => get(`goaltemplates/${id}`, params).then(container => container.goalTemplate)

const patchGoalBankDetail = (id, goalBank) =>
  patch(`goaltemplates/${id}`, goalBank).then(container => container.goalTemplate)

const patchHouseholdGoalsReorder = (householdId, params) =>
  patch(`household/${householdId}/goals`, params).then(container => container.goals)

const patchClientGoalsReorder = (clientId, params) =>
  patch(`clients/${clientId}/goals`, params).then(container => container.goals)

const queryGoalBankDetails = params =>
  postSearch('goaltemplates/search', params).then(container => container.goalTemplates)

const queryGoalBankAutocompleteDetails = params =>
  postSearch('goaltemplates/autocomplete', params).then(container => container.goalTemplates)

const queryGoalDetailsForClient = (clientId, params) =>
  query(`goaltemplates/client/${clientId}`, params).then(container => container.goalTemplates)

const paymentSessionDetails = sessionId => get(`payment/confirm/${sessionId}`)
const paymentCustomer = () => get(`me/membership-details`)
const paymentInitiate = payload => postSearch('payment/initiate', payload).then(container => container.targetUrl)

const processSetupIntent = () => postSearch(`stripe/customer/setup-intent`)
const createCustomerSession = () => postSearch('stripe/customer/session')
const patchCustomerDefaultPaymentMethod = payload => patch('stripe/customer/payment-methods', payload)

const cancelAutoSubscription = () => postSearch('households/auto-subscription')

const postEmailConfirmation = key => postSearch(`confirmations/onboarding/${key}`)
const postEmailConfirmationResendRequest = email => postSearch('resend-otp', email)
const postVerifyOTP = otp => postSearch('verify-otp', otp)

const getReminders = () => get('reminders')

const queryPublicResources = payload => postSearch('resources/search/public', payload)

const postMessagesBookmark = messageId => post(`chat/messages/${messageId}/bookmarkers`)
const deleteMessagesBookmark = messageId => del(`chat/messages/${messageId}/bookmarkers`)
const getChatChannels = () => get(`me/chat-channels`)

const queryTags = ({ context }) => {
  const params = new URLSearchParams()

  if (context) {
    params.set('context', context)
  }

  return query('tags', params).then(container => container.tags)
}

const getDiagnoses = () => queryTags({ context: 'diagnoses' })
const getDocumentTags = () => queryTags({ context: 'documents' })

const queryTypeTags = ({ q, under, topOnly } = {}) => {
  const params = new URLSearchParams()

  if (q) {
    params.set('q', q)
  }

  if (under) {
    params.set('under', under)
  }

  if (topOnly) {
    params.set('topOnly', topOnly)
  }

  return query('doctypes', params).then(container => container.doctypes)
}

const getTypeTagCategories = () => queryTypeTags({ under: 'root' })
const getTypeTags = () => queryTypeTags()

const getHealthPlans = () => get('health-plans').then(container => container.healthPlans)
const getRegionalCenters = () => get('regionalcenters').then(container => container.regionalCenters)
const getChildGoals = childId => get(`clients/${childId}/roadmap`).then(container => container)
const postChildGoal = (childId, params) => post(`clients/${childId}/roadmap`, params)

const performAuditRequest = (endpoint, childId, options = {}) => {
  const { payload, verbose, all } = options

  const params = new URLSearchParams()
  params.set('childId', childId)

  if (verbose) {
    params.set('verbose', true)
  }

  if (all) {
    params.set('all', all)
  }

  return payload ? postSearch(`${endpoint}?${params}`, payload) : query(endpoint, params)
}

const performVerboseAuditRequest = (endpoint, childId, options = {}) =>
  performAuditRequest(endpoint, childId, { ...options, verbose: true })

const getAuditStart = (auditId, childId) => performAuditRequest(`audits/${auditId}/start`, childId)
const getAuditUndo = (auditId, childId) => performAuditRequest(`audits/${auditId}/undo`, childId)
const getAuditRestart = (auditId, childId) => performAuditRequest(`audits/${auditId}/undo`, childId, { all: true })
const getAvailableAudits = childId => get(`clients/${childId}/audits/available`).then(container => container.audits)

const getAllAudits = childId => {
  const params = new URLSearchParams()
  params.set('childId', childId)

  return query('/audits', params).then(container => container.audits)
}

const getVerboseAuditStart = (auditId, childId) => performVerboseAuditRequest(`audits/${auditId}/start`, childId)
const getVerboseAuditUndo = (auditId, childId) => performVerboseAuditRequest(`audits/${auditId}/undo`, childId)

const getVerboseAuditRestart = (auditId, childId) =>
  performVerboseAuditRequest(`audits/${auditId}/undo`, childId, { all: true })

const getSuggestedGoals = (childId, auditId) => {
  const params = new URLSearchParams()
  if (auditId) {
    params.set('auditId', auditId)
  }

  return query(`clients/${childId}/goals/suggestions`, params)
}

const patchSuggestedGoals = (childId, selections) => patch(`clients/${childId}/goals/suggestions`, selections)

const postAuditAnswer = (auditId, childId, answers) =>
  performAuditRequest(`audits/${auditId}/answer`, childId, { payload: answers })

const postVerboseAuditAnswer = (auditId, childId, answers) =>
  performVerboseAuditRequest(`audits/${auditId}/answer`, childId, { payload: answers })

const getReceivedServices = childId => {
  const params = new URLSearchParams()
  params.set('childId', childId)

  return query('received-services', params).then(container => container.services)
}

const getReceivedService = id => get(`received-services/${id}`).then(container => container.service)
const postReceivedService = serviceInstance => post('received-services', serviceInstance)
const patchReceivedService = serviceInstance => patch(`received-services/${serviceInstance.id}`, serviceInstance)
const deleteReceivedService = serviceInstanceId => del(`received-services/${serviceInstanceId}`)

const queryRoadmapTemplates = params => query('roadmaptemplates', params)

const queryRoadmapGoalTemplates = (roadmapId, params) =>
  query(`roadmaptemplates/${roadmapId}/goaltemplates`, params).then(container => container.roadmap)

const postRoadmapGoalTemplates = (clientId, templates) =>
  post(`clients/${clientId}/goalTemplates`, templates).then(container => container.added)

const getHouseholdBookmarks = householdId =>
  get(`household/${householdId}/bookmarks`).then(container => container.resourceBookmarkIds)

const removeHouseholdBookmarkPreference = async (householdId, payload) =>
  patch(`household/${householdId}/bookmarks/remove`, payload)

const getSchools = childId => get(`clients/${childId}/schools`).then(container => container.schools)
const patchSchools = (postId, payload) =>
  patch(`clients/schools/${postId}`, payload).then(container => container.schools)

export {
  getFeed,
  getHousehold,
  patchHousehold,
  getBulkHouseholdRatings,
  postBulkHouseholdRatings,
  getGoalHouseholds,
  getNavigatedHouseholds,
  getClients,
  getClient,
  getClientTeam,
  putClientTeam,
  getContact,
  postContact,
  patchContact,
  queryContacts,
  deleteContact,
  getInvitations,
  getPostById,
  getHouseholdRatings,
  postHouseholdRating,
  downloadPostArchive,
  downloadPostDocument,
  getPostDocument,
  getPostGoals,
  putPostGoals,
  getPostSources,
  getPostWhitelist,
  getProfileBlockCollection,
  getMe,
  patchMe,
  patchProducts,
  postTrialMembership,
  getOnboarding,
  patchOnboarding,
  postSchedulingRelay,
  patchPreferences,
  getTypes,
  postClient,
  patchClient,
  patchClientWithAuditDetails,
  postPost,
  patchPost,
  deletePost,
  postToWhitelist,
  deleteFromWhitelist,
  queryCollections,
  queryPosts,
  queryAllPosts,
  headDirectAsset,
  getAsset,
  getResourceAsset,
  downloadAsset,
  postAsset,
  postResourceAsset,
  deleteAsset,
  queryTemplates,
  getTemplate,
  postTemplate,
  patchTemplate,
  deleteTemplate,
  queryUsers,
  queryInternalUsers,
  getUser,
  getNavigator,
  queryChatCandidates,
  getChatMembers,
  patchChatMembers,
  postSignup,
  postIndirectSignup,
  postInvitation,
  postInvitationByNavigator,
  getResource,
  getResources,
  deleteResource,
  postResource,
  postResourceSearch,
  patchResource,
  getResourceFilters,
  queryTopics,
  getFamilyObjectives,
  getFamilyObjective,
  postFamilyObjective,
  patchFamilyObjective,
  deleteFamilyObjective,
  queryEvents,
  getEvent,
  postEvent,
  patchEvent,
  deleteEvent,
  queryGoals,
  getGoal,
  postGoal,
  patchGoal,
  deleteGoal,
  getRoadmaps,
  postRoadmapGoals,
  queryHouseholdChecklist,
  queryGoalChecklist,
  queryGuides,
  postGuide,
  patchGuide,
  patchStep,
  checklistAdd,
  checklistAddFromSource,
  checklistCheck,
  checklistDelete,
  checklistMove,
  checklistPriority,
  checklistUpdate,
  getGuide,
  deleteGuide,
  postGuideVariant,
  patchGuideVariant,
  deleteGuideVariant,
  queryVariants,
  getGuideVariant,
  postNewSignupRequest,
  queryTags,
  getDiagnoses,
  getDocumentTags,
  queryTypeTags,
  getTypeTagCategories,
  getTypeTags,
  queryTasks,
  getTask,
  patchTask,
  postTask,
  deleteTask,
  queryNavigatedHouseholdDetails,
  queryGoalHouseholdDetails,
  querySharedHouseholdDetails,
  queryMetrics,
  queryAtRiskHouseholdDetails,
  queryLowAppUsageHouseholdDetails,
  queryLowMessengerEngagementHouseholdDetails,
  queryLowBinderEngagementHouseholdDetails,
  queryLowPlanEngagementHouseholdDetails,
  queryRatingsChangeHouseholdDetails,
  queryGoalBankDetails,
  getGoalBankDetail,
  postGoalBankDetail,
  patchGoalBankDetail,
  patchHouseholdGoalsReorder,
  queryGoalBankAutocompleteDetails,
  queryGoalDetailsForClient,
  paymentSessionDetails,
  paymentCustomer,
  paymentInitiate,
  patchCustomerDefaultPaymentMethod,
  createCustomerSession,
  processSetupIntent,
  cancelAutoSubscription,
  postEmailConfirmation,
  postEmailConfirmationResendRequest,
  getReminders,
  queryPublicResources,
  postMessagesBookmark,
  deleteMessagesBookmark,
  getChatChannels,
  getHealthPlans,
  getRegionalCenters,
  getChildGoals,
  postChildGoal,
  getAuditStart,
  getAuditUndo,
  getAuditRestart,
  getAvailableAudits,
  getAllAudits,
  getVerboseAuditStart,
  getVerboseAuditUndo,
  getVerboseAuditRestart,
  getSuggestedGoals,
  patchSuggestedGoals,
  postAuditAnswer,
  postVerboseAuditAnswer,
  getReceivedServices,
  getReceivedService,
  postReceivedService,
  patchReceivedService,
  deleteReceivedService,
  queryRoadmapTemplates,
  queryRoadmapGoalTemplates,
  postRoadmapGoalTemplates,
  patchEmailUnconfirmedPreferences,
  patchClientGoalsReorder,
  postVerifyOTP,
  postRecommendedRoadmapGoals,
  getHouseholdBookmarks,
  removeHouseholdBookmarkPreference,
  getContactRoles,
  postOnboardingTrialMembership,
  getSchools,
  patchSchools
}
