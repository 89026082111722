import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import makeStyles from '@mui/styles/makeStyles'

import { THEME_COLOR_GROUP_YELLOW } from '../../app/support/theme'

import UndividedQuotes from '../../subcomponents/UndividedQuotes'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden'
    },

    mobileImage: {
      height: '100%',
      left: 0,
      marginInline: 'auto',
      maxHeight: theme.spacing(67.178),
      maxWidth: theme.spacing(40.125),
      position: 'absolute',
      right: 0,
      width: '100%'
    },

    sidebarImage: {
      flexBasis: 0,
      flexGrow: 3,
      marginTop: theme.spacing(5),
      position: 'relative',
      maxHeight: theme.spacing(68.125)
    },

    vectorImage: {},

    quotesColor: {},

    sidebarContentContainer: {
      padding: theme.spacing(0, 3.75, 10, 3.75),
      position: 'relative'
    },

    textCenter: {
      margin: 'auto'
    },

    sidebarContent: {
      fontFamily: 'Architects Daughter',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 30 / 20,
      width: theme.spacing(33.875)
    },

    client: {
      alignItems: 'center',
      color: THEME_COLOR_GROUP_YELLOW.Y700,
      display: 'flex',
      gap: theme.spacing(1),
      lineHeight: 22 / 16,
      fontWeight: theme.typography.fontWeightMedium
    }
  }),
  {
    name: 'OnboardingSidebarComponent'
  }
)

const OnboardingSidebarComponent = props => {
  const { className, text, vectorImage, mobileImage, member, quoteVisible } = props

  const classes = useStyles(props)
  return (
    <section className={clsx(classes.root, className)}>
      {(vectorImage || mobileImage) && (
        <section className={classes.sidebarImage}>
          {vectorImage && <img src={vectorImage} className={classes.vectorImage} alt="Vector" />}
          {mobileImage && <img src={mobileImage} className={classes.mobileImage} alt="Mobile" />}
        </section>
      )}
      <section
        className={
          mobileImage ? classes.sidebarContentContainer : clsx(classes.sidebarContentContainer, classes.textCenter)
        }
      >
        <UndividedQuotes
          classes={{ quotesColor: classes.quotesColor }}
          text={text}
          member={member}
          quoteVisible={quoteVisible}
        />
      </section>
    </section>
  )
}

OnboardingSidebarComponent.propTypes = {
  text: PropTypes.string, // content in side component
  vectorImage: PropTypes.string, // vector image should be placed in the component
  mobileImage: PropTypes.string, // image should be placed in the component
  quoteVisible: PropTypes.bool, // to show quote image or not
  member: PropTypes.string //name of the member
}

export default OnboardingSidebarComponent
