/**
 * The constants listed here are specific to user membership and being in the _arch_ module
 * these constants also have meaning on the server side.
 */
const NAVIGATOR_TRIAL_STATUS_NOT_SCHEDULED = 'notScheduled'
const NAVIGATOR_TRIAL_STATUS_SCHEDULED = 'scheduled'
const NAVIGATOR_TRIAL_STATUS_COMPLETED = 'completed'
const NAVIGATOR_TRIAL_STATUS_EXPIRED = 'expired'
const NAVIGATOR_TRIAL_STATUS_CONVERTED = 'converted'
const NAVIGATOR_TRIAL_STATUS_STARTED = 'started'
const NAVIGATOR_TRIAL_STATUS_INVITED = 'invited'

const PLATFORM_TRIAL_STATUS_NOT_STARTED = 'notStarted'
const PLATFORM_TRIAL_STATUS_STARTED = 'started'
const PLATFORM_TRIAL_STATUS_EXPIRED = 'expired'
const PLATFORM_TRIAL_STATUS_CHURNED = 'churned'
const PLATFORM_TRIAL_STATUS_CONVERTED = 'converted'

const POST_TRIAL_INTRUSION = [
  NAVIGATOR_TRIAL_STATUS_COMPLETED,
  NAVIGATOR_TRIAL_STATUS_EXPIRED,
  NAVIGATOR_TRIAL_STATUS_CONVERTED
]

export {
  NAVIGATOR_TRIAL_STATUS_NOT_SCHEDULED,
  NAVIGATOR_TRIAL_STATUS_SCHEDULED,
  NAVIGATOR_TRIAL_STATUS_COMPLETED,
  NAVIGATOR_TRIAL_STATUS_EXPIRED,
  NAVIGATOR_TRIAL_STATUS_CONVERTED,
  NAVIGATOR_TRIAL_STATUS_STARTED,
  NAVIGATOR_TRIAL_STATUS_INVITED,
  POST_TRIAL_INTRUSION,
  PLATFORM_TRIAL_STATUS_NOT_STARTED,
  PLATFORM_TRIAL_STATUS_STARTED,
  PLATFORM_TRIAL_STATUS_EXPIRED,
  PLATFORM_TRIAL_STATUS_CHURNED,
  PLATFORM_TRIAL_STATUS_CONVERTED
}
