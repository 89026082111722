import React from 'react'

import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'

import CloseIcon from '@mui/icons-material/Close'

import BrandIconButton from './brand/BrandIconButton'

const useStyles = makeStyles(
  theme => ({
    action: {
      display: 'flex'
    },

    close: {
      padding: theme.spacing(0.5)
    },

    contentRoot: {
      maxWidth: theme.spacing(64)
    }
  }),
  {
    name: 'UndoDeleteSnackbar'
  }
)

const AUTO_HIDE_DURATION = 5000

const UndoDeleteSnackbar = props => {
  const { deletedItem, message, onUndo, onClose, ...rest } = props

  const classes = useStyles(props)
  return (
    <Snackbar
      onClose={onClose}
      open={Boolean(deletedItem)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={AUTO_HIDE_DURATION}
      ContentProps={{ classes: { root: classes.contentRoot } }}
      message={message}
      action={
        <section className={classes.action}>
          <Button size="small" onClick={onUndo}>
            Undo Delete
          </Button>

          <BrandIconButton color="inherit" className={classes.close} onClick={onClose}>
            <CloseIcon />
          </BrandIconButton>
        </section>
      }
      {...rest}
    />
  )
}

UndoDeleteSnackbar.propTypes = {
  deletedItem: PropTypes.object,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onUndo: PropTypes.func,
  onClose: PropTypes.func
  // …and Snackbar’s other properties, for more customization.
}

export default UndoDeleteSnackbar
