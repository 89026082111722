/**
 * Task constants are separated out because only the service portal will be using them
 * (as of this writing). Putting them in a separate file will allow for chunking.
 */
const TASK_STATUS_DONE = 'done'
const TASK_STATUS_NOT_DONE = 'notDone'

const TASK_TYPE_CUSTOM = 'customTask'
const TASK_TYPE_NEW_MESSAGES = 'newChatMessages'
const TASK_TYPE_ACCOUNT_CHANGES = 'accountChanges'

const TASK_TYPE_STEPS_COMPLETED = 'stepCompleted'
const TASK_TYPE_GUIDE_COMPLETED = 'guideCompleted'
const TASK_TYPE_DOCUMENT_ADDED = 'documentAdded'
const TASK_TYPE_DOCUMENT_UPDATED = 'documentUpdated'
const TASK_TYPE_PROFILE_BLOCK_UPDATED = 'profileBlockUpdated'
const TASK_TYPE_CHILD_ADDED = 'childAdded'
const TASK_TYPE_CHILD_INFO_UPDATED = 'childInfoUpdated'
const TASK_TYPE_PARENT_UPDATED = 'parentUpdated'
const TASK_TYPE_NEED_ADDED = 'needAdded'
const TASK_TYPE_NEED_UPDATED = 'needUpdated'
const TASK_TYPE_VISION_UPDATED = 'visionUpdated'
const TASK_TYPE_DOCUMENT_ATTACHED = 'documentAttached'
const TASK_TYPE_PARENT_TASK_CREATED = 'parentTaskCreated'
const TASK_TYPE_PARENT_TASK_COMPLETED = 'parentTaskCompleted'
const TASK_TYPE_TRIAL_HAS_STARTED = 'trialHasStarted'
const TASK_TYPE_START_KICKSTART_REMINDER = 'startKickstartReminder'

const MAX_LENGTH_TASK_TITLE = 256
const MAX_LENGTH_TASK_DESCRIPTION = 3000

export {
  TASK_STATUS_DONE,
  TASK_STATUS_NOT_DONE,
  TASK_TYPE_CUSTOM,
  TASK_TYPE_NEW_MESSAGES,
  TASK_TYPE_ACCOUNT_CHANGES,
  MAX_LENGTH_TASK_TITLE,
  MAX_LENGTH_TASK_DESCRIPTION,
  TASK_TYPE_STEPS_COMPLETED,
  TASK_TYPE_GUIDE_COMPLETED,
  TASK_TYPE_DOCUMENT_ADDED,
  TASK_TYPE_DOCUMENT_UPDATED,
  TASK_TYPE_PROFILE_BLOCK_UPDATED,
  TASK_TYPE_CHILD_ADDED,
  TASK_TYPE_CHILD_INFO_UPDATED,
  TASK_TYPE_PARENT_UPDATED,
  TASK_TYPE_NEED_ADDED,
  TASK_TYPE_NEED_UPDATED,
  TASK_TYPE_VISION_UPDATED,
  TASK_TYPE_DOCUMENT_ATTACHED,
  TASK_TYPE_PARENT_TASK_CREATED,
  TASK_TYPE_PARENT_TASK_COMPLETED,
  TASK_TYPE_TRIAL_HAS_STARTED,
  TASK_TYPE_START_KICKSTART_REMINDER
}
