import React, { useState, useContext, useEffect } from 'react'

import { THEME_PRIMARY_NEUTRAL } from '../app/support/theme'
import { useActivePaymentMethod } from '../app/hooks'

import { CHECKPOINT_EMPTY, CHECKPOINT_PAYMENT_CC } from './constants'
import { OnboardingStatus } from './contexts'

import SetupPaymentIntent from '../user/SetupIntent'

import OnboardingContainer from './support/OnboardingContainer'

const TRACK_LOCATION = 'Onboarding'

const SetupIntentOnboarding = props => {
  const { setOnboarding } = useContext(OnboardingStatus)
  const [error, setError] = useState()

  const hasActivePaymentMethod = useActivePaymentMethod()

  useEffect(() => {
    if (!hasActivePaymentMethod) {
      setOnboarding({ checkpoint: CHECKPOINT_PAYMENT_CC })
    } else {
      setOnboarding({ checkpoint: CHECKPOINT_EMPTY })
    }
  }, [hasActivePaymentMethod, setOnboarding])

  return (
    <OnboardingContainer theme={THEME_PRIMARY_NEUTRAL.background} error={error} setError={setError} hideCheckpoints>
      <SetupPaymentIntent location={TRACK_LOCATION} />
    </OnboardingContainer>
  )
}

export default SetupIntentOnboarding
