import React, { forwardRef } from 'react'

import PropTypes from 'prop-types'

import Button from '@mui/material/Button'

const BrandButton = forwardRef((props, ref) => {
  const { children, ...rest } = props

  return (
    <Button ref={ref} color="inherit" {...rest}>
      {children}
    </Button>
  )
})

BrandButton.propTypes = {
  children: PropTypes.node
}

export default BrandButton
