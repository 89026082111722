import React, { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles'

import CircularProgress from '@mui/material/CircularProgress'

import { postRecommendedRoadmapGoals } from '../api'
import { useAppUser, useChildUpdater } from '../app/hooks'
import { THEME_PRIMARY_NEUTRAL } from '../app/support/theme'
import { getHookPromiseErrorHandler } from '../common'
import { checkpointAfter } from '../domain/onboarding'
import { getAddGoalId } from '../domain/goal'

import { OnboardingStatus } from './contexts'
import { ROADMAP_WALKTHROUGH_LINK } from './constants'

import ChildRoadmap from '../portal/parent/support/ChildRoadmap'
import BrandButton from '../subcomponents/brand/BrandButton'
import ConfirmationDialog from '../subcomponents/ConfirmationDialog'

import OnboardingContainer from './support/OnboardingContainer'
import HelpPrompt from './support/HelpPrompt'

const useStyles = makeStyles(
  theme => ({
    actionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(5)
    },

    nextButton: {
      padding: theme.spacing(1.5, 3)
    },

    helpPrompt: {
      float: 'inline-end',
      margin: theme.spacing(2, 0, 0)
    },

    skipButton: {
      padding: theme.spacing(1.5, 3)
    },

    resendEmailButton: {},
    saveProgress: {}
  }),
  {
    name: 'ChildInfo'
  }
)

const ChildInfo = props => {
  const appUser = useAppUser()
  const { household } = appUser
  const { clients } = household
  const childId = clients[0].id

  const navigate = useNavigate()

  const { onboarding, setOnboarding, sequence } = useContext(OnboardingStatus)
  const { checkpoint } = onboarding ?? {}
  const nextCheckpoint = checkpointAfter(checkpoint, sequence)

  const [creatingRoadmap, setCreatingRoadmap] = useState(false)
  const [error, setError] = useState()
  const [confirmingSkip, setConfirmingSkip] = useState(false)

  const handleApiError = useMemo(() => getHookPromiseErrorHandler(setError), [])

  const {
    nickname,
    birthday,
    diagnosisTagIds,
    saveInProgress: childSaveInProgress,
    setNickname,
    setBirthday,
    setDiagnosisTagIds,
    canSave,
    updateClient
  } = useChildUpdater({ childId, handleApiError })

  const saveInProgress = childSaveInProgress || creatingRoadmap

  const handleConfirm = async event => {
    event.preventDefault()

    const onUpdateSuccess = async () => {
      setCreatingRoadmap(true)
      const goalId = getAddGoalId()

      try {
        if (!goalId) {
          await postRecommendedRoadmapGoals(childId)
        }
      } catch (error) {
        // For now, we mask any errors that occur during this sequence and doing the same operations
        // as if no error occurred.
        // handleApiError(error)
      } finally {
        const nextRoute = goalId ? '/roadmaps' : ROADMAP_WALKTHROUGH_LINK

        setOnboarding({ checkpoint: nextCheckpoint })
        navigate(nextRoute)
        setCreatingRoadmap(false)
      }
    }

    await updateClient(onUpdateSuccess)
  }

  const handleSkip = () => setConfirmingSkip(true)

  const handleSkipClose = () => setConfirmingSkip(false)

  const handleSkipConfirmation = () => navigate('/roadmaps')

  const classes = useStyles(props)
  return (
    <OnboardingContainer theme={THEME_PRIMARY_NEUTRAL.background} error={error} setError={setError} hideCheckpoints>
      <ChildRoadmap
        nickname={nickname}
        birthday={birthday}
        diagnosisTagIds={diagnosisTagIds}
        setNickname={setNickname}
        setBirthday={setBirthday}
        setDiagnosisTagIds={setDiagnosisTagIds}
      />

      <section className={classes.actionContainer}>
        <BrandButton
          className={classes.nextButton}
          variant="contained"
          disabled={!canSave || saveInProgress}
          endIcon={saveInProgress ? <CircularProgress className={classes.saveProgress} size={16} /> : undefined}
          onClick={handleConfirm}
        >
          Next
        </BrandButton>

        <BrandButton variant="outlined" className={classes.skipButton} onClick={handleSkip}>
          Skip
        </BrandButton>
      </section>

      <HelpPrompt className={classes.helpPrompt} />

      <ConfirmationDialog
        open={confirmingSkip}
        title="Are you sure you want to skip creating a Roadmap for your child? "
        description="You have to create a Roadmap before you can add individual goals."
        cancelLabel="Back to Roadmap"
        confirmLabel="Skip"
        onCancel={handleSkipClose}
        onConfirm={handleSkipConfirmation}
      />
    </OnboardingContainer>
  )
}

export default ChildInfo
