import React from 'react'

import SvgIcon from '@mui/material/SvgIcon'

const Quotes = props => {
  return (
    <SvgIcon width="36" height="27" viewBox="0 0 36 27" {...props}>
      <path d="M15.9459 20.5021C15.9459 22.1314 14.0836 24.4882 12.8032 25.6556C11.5229 26.8231 9.19506 27 7.56555 27C6.86719 27 6.05244 26.8506 5.12129 26.5519C4.26775 26.2531 3.453 25.805 2.67704 25.2075C1.90109 24.61 1.24153 23.9004 0.698357 23.0788C0.232786 22.1826 0 21.1369 0 19.9419C0 18.5975 0.0775944 17.2905 0.232783 16.0207C0.465571 14.6763 0.892347 13.2573 1.51311 11.7635C2.13387 10.2697 3.02622 8.62655 4.19015 6.83402C5.35408 5.04149 6.90599 2.98755 8.84587 0.672197C10.0874 0.224066 11.1737 0 12.1049 0C13.1136 0 13.812 0.3361 14.2 1.0083C14.1224 1.38174 13.9672 1.82987 13.7344 2.35269C13.5792 2.80083 13.3076 3.39834 12.9196 4.14523L11.0573 7.61826C10.2038 9.63485 9.66063 11.5394 9.42784 13.332C10.0486 13.4066 10.747 13.5934 11.5229 13.8921C11.7465 13.9704 11.9806 14.0307 12.2232 14.0932C12.9069 14.2694 13.6584 14.463 14.4327 15.1245C15.482 16.0207 15.9459 18.8728 15.9459 20.5021ZM35.9996 20.5021C35.9996 22.1314 34.1373 24.4882 32.857 25.6556C31.5766 26.8231 29.2488 27 27.6193 27C26.9209 27 26.1062 26.8506 25.175 26.5519C24.3215 26.2531 23.5067 25.805 22.7308 25.2075C21.9548 24.61 21.2952 23.9004 20.7521 23.0788C20.2865 22.1826 20.0537 21.1369 20.0537 19.9419C20.0537 18.5975 20.1313 17.2905 20.2865 16.0207C20.5193 14.6763 20.9461 13.2573 21.5668 11.7635C22.1876 10.2697 23.0799 8.62655 24.2439 6.83402C25.4078 5.04149 26.9597 2.98755 28.8996 0.672197C30.1411 0.224066 31.2275 0 32.1586 0C33.1673 0 33.8657 0.3361 34.2537 1.0083C34.1761 1.38174 34.0209 1.82987 33.7881 2.35269C33.6329 2.80083 33.3613 3.39834 32.9734 4.14523L31.1111 7.61826C30.2575 9.63485 29.7143 11.5394 29.4816 13.332C30.1023 13.4066 30.8007 13.5934 31.5766 13.8921C31.8002 13.9704 32.0343 14.0307 32.2769 14.0932C32.9606 14.2694 33.7121 14.463 34.4865 15.1245C35.5357 16.0207 35.9996 18.8728 35.9996 20.5021Z" />
    </SvgIcon>
  )
}

export default Quotes
