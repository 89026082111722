/**
 * This module defines the hooks that are available to any component within the web app.
 */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import preval from 'preval.macro'

import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'

import { getClient, getHousehold, getMe, patchClient, postEmailConfirmationResendRequest, postVerifyOTP } from '../api'
import { trackEmailConfirmation, trackInvalidOTPSubmitted } from '../tracking/segment'

import model from '../model'

import {
  ClientContext,
  HouseholdContext,
  LoginContext,
  ParentContext,
  RemindersContext,
  SiteContext,
  UserContext,
  SnackbarStackContext
} from './contexts'
import { openVerifyOtpDialogCallback } from '../auth'
import { CAPABILITY_CONFIRM_EMAIL } from '../arch/capabilityConstants'
import { useMatch } from 'react-router-dom'

// These may be redundant…custom hooks are used in case logic behind context might be involved but as time goes on,
// this may turn out to be unnecessary…
const useAppUser = () => useContext(UserContext)
const useCurrentClient = () => useContext(ClientContext)
const useCurrentParent = () => useContext(ParentContext)
const useCurrentHousehold = () => useContext(HouseholdContext)
const useCurrentSite = () => useContext(SiteContext)
const useCurrentReminders = () => useContext(RemindersContext)

// This would be a helper except that it relies on LoginContext, which vanilla functions can’t get to.
const useAppUserRefresh = () => {
  const { setAppUser } = useContext(LoginContext)

  const refreshUser = useCallback(async () => {
    const updatedUser = await getMe()

    if (updatedUser) {
      setAppUser(model.user(updatedUser))
    }
  }, [setAppUser])

  return refreshUser
}

const useAppHouseholdRefresh = householdId => {
  const { setAppHousehold } = useContext(LoginContext)

  const refreshHousehold = useCallback(async () => {
    const updatedHousehold = await getHousehold(householdId)

    if (updatedHousehold) {
      setAppHousehold(model.household(updatedHousehold))
    }
  }, [setAppHousehold, householdId])

  return refreshHousehold
}

const useAppClientRefresh = clientId => {
  const { setAppClient } = useContext(LoginContext)

  const refreshClient = useCallback(async () => {
    const updatedClient = await getClient(clientId)

    if (updatedClient) {
      setAppClient(updatedClient)
    }
  }, [setAppClient, clientId])

  return refreshClient
}

/**
 * This hook captures the logic for determining which children to display in a given context.
 */
const useCurrentChildren = () => {
  const appUser = useAppUser()
  const { sharedClients } = appUser ?? {}

  const household = useCurrentHousehold()
  const { clients } = household ?? {}

  // Care team members see their shared clients; everyone else sees the household’s children.
  return (appUser.isCareTeamMember() ? sharedClients : clients) ?? []
}

const useVersion = () => {
  const app = process.env.REACT_APP_VERSION
  const build = preval`module.exports = Date.now().toString(16)`
  const copyright = 'Undivided Copyright © 2025'

  return {
    app,
    build,
    copyright,
    full: `${copyright} v${app}`
  }
}

/**
 * Custom hook for managing OTP (One-Time Password) verification process.
 * Handles state management and API interactions for email verification.
 */
const DEFAULT_VERIFICATION_STATE = { otpCode: '', isVerified: null, isVerifying: false }

const useOTPVerification = triggerUseEffect => {
  const refreshAppUser = useAppUserRefresh()

  const [verificationState, setVerificationState] = useState(DEFAULT_VERIFICATION_STATE)

  const { otpCode } = verificationState ?? {}

  const updateVerificationState = updates => {
    setVerificationState(prevState => ({ ...prevState, ...updates }))
  }

  const updateOTPCode = code => updateVerificationState({ otpCode: code })

  const verifyOTP = async ({ location, joinFunnel, lockedError }) => {
    updateVerificationState({ isVerifying: true })
    try {
      await postVerifyOTP({ otp: otpCode })
      await refreshAppUser()

      updateVerificationState({ isVerified: true })
      trackEmailConfirmation(location, joinFunnel)
      if (lockedError && typeof lockedError.resolve === 'function') {
        lockedError.resolve()
      }
    } catch (error) {
      updateVerificationState({ isVerified: false })
      trackInvalidOTPSubmitted(location)
    } finally {
      updateVerificationState({ isVerifying: false })
    }
  }

  useEffect(() => setVerificationState(DEFAULT_VERIFICATION_STATE), [triggerUseEffect])

  return { verifyOTP, updateOTPCode, verificationState }
}

const useParentViewCapability = () => {
  const appUser = useAppUser()
  const { household: userHousehold } = appUser ?? {}

  const household = useCurrentHousehold()
  const { inParentView } = useCurrentSite() ?? {}

  const isParent = appUser?.isParent()

  // Small optimization: the current household might still be loading so we use the user’s household
  // (which will be fully loaded for sure) **if** the user’s household is the same as the current one.
  // Otherwise, we’ll have to wait (e.g., such as for parent view).
  const householdForMenu = userHousehold?.id === household?.id ? userHousehold : household
  const { capabilities: householdCapabilities } = householdForMenu ?? {}

  const shouldCheckHouseholdCapabilities = isParent || inParentView

  const checkCapabilities = capabilities => {
    const checkCapability = capability => householdCapabilities?.includes(capability)

    return Array.isArray(capabilities) ? capabilities.every(checkCapability) : checkCapability(capabilities)
  }

  const isUserCapable = capabilities => (shouldCheckHouseholdCapabilities ? checkCapabilities(capabilities) : true)

  const isParentCapable = capabilities => (shouldCheckHouseholdCapabilities ? checkCapabilities(capabilities) : false)

  return { isUserCapable, isParentCapable }
}

const useCurrentOrFirstChild = () => {
  const currentClient = useCurrentClient()
  const currentChildren = useCurrentChildren()

  const [child, setChild] = useState(null)

  useEffect(() => {
    const selectedChild = currentClient && !currentClient.loading ? currentClient : currentChildren?.[0]

    if (selectedChild) {
      setChild(selectedChild)
    }
  }, [currentClient, currentChildren])

  return child
}

/**
 * This hook encapsulates the logic for handling errors such as HTTP_LOCKED and HTTP_AUTHORIZATION within the app.
 */

const useTransientErrorState = ({ appUser, dialogSetter = () => {}, initialErrorState = {} }) => {
  const [error, setError] = useState(null)

  const errorPromise = useCallback(() => {
    if (!appUser) {
      return null
    }

    return new Promise((resolve, reject) => {
      if (typeof dialogSetter === 'function') {
        dialogSetter(true)
      }

      setError({
        ...initialErrorState,
        resolve,
        reject
      })
    })
  }, [appUser, dialogSetter, initialErrorState])

  useEffect(() => {
    if (!appUser) {
      setError(currentError => {
        if (currentError) {
          currentError.reject()
        }
        return null
      })
    }
  }, [appUser])

  return {
    error,
    setError,
    errorPromise
  }
}

const useCopyToClipboard = type => {
  const snackContext = useContext(SnackbarStackContext)

  const copyClickHandler = async text => {
    await window.navigator.clipboard.writeText(text)

    snackContext.openSnackbar({
      message: { title: `${type} copied to clipboard!` },
      id: text
    })
  }

  return { copyClickHandler }
}

const useChildUpdater = ({ childId, handleApiError }) => {
  const household = useCurrentHousehold()
  const { id: householdId } = household ?? {}

  const refreshAppClient = useAppClientRefresh(childId)
  const refreshAppHousehold = useAppHouseholdRefresh(householdId)

  const [nickname, setNickname] = useState('')
  const [birthday, setBirthday] = useState(null)
  const [diagnosisTagIds, setDiagnosisTagIds] = useState([])

  const [loadInProgress, setLoadInProgress] = useState(false)
  const [saveInProgress, setSaveInProgress] = useState(false)

  const canSave = useMemo(
    () => Boolean(nickname && birthday && diagnosisTagIds?.length),
    [birthday, diagnosisTagIds, nickname]
  )

  const updateClient = async onUpdateSuccess => {
    setSaveInProgress(true)
    try {
      const updatedClient = await patchClient({
        nickname,
        birthday: format(birthday, 'yyyy-MM-dd'),
        diagnosesTagIds: diagnosisTagIds,
        id: childId
      })

      if (updatedClient) {
        await Promise.all([refreshAppHousehold(), refreshAppClient()])

        if (onUpdateSuccess) {
          onUpdateSuccess()
        }
      }
    } catch (error) {
      handleApiError(error)
    } finally {
      setSaveInProgress(false)
    }
  }

  useEffect(() => {
    if (!childId) {
      return
    }

    let active = true

    const retrieveClient = async () => {
      try {
        setLoadInProgress(true)
        const child = await getClient(childId)

        if (active) {
          const { nickname, birthday, diagnosesTagIds } = child

          setNickname(nickname || '')
          const birthdayValue = parseISO(birthday)
          setBirthday(isValid(birthdayValue) ? birthdayValue : null)
          setDiagnosisTagIds(diagnosesTagIds ?? [])

          setLoadInProgress(false)
        }
      } catch (error) {
        handleApiError(error)
        setLoadInProgress(false)
      }
    }

    retrieveClient()
    return () => {
      active = false
    }
  }, [childId, handleApiError])

  return {
    nickname,
    birthday,
    diagnosisTagIds,
    setNickname,
    setBirthday,
    setDiagnosisTagIds,
    loadInProgress,
    saveInProgress,
    updateClient,
    canSave
  }
}

const useVerificationEmailResend = ({ handleApiError }) => {
  const { email, otpGeneratedAt: timestamp } = useAppUser() ?? {}
  const snackContext = useContext(SnackbarStackContext)
  const [otpGeneratedAt, setOtpGeneratedAt] = useState(timestamp)

  const handleResendEmailVerification = async () => {
    try {
      const { generatedAt } = await postEmailConfirmationResendRequest({ email })
      setOtpGeneratedAt(generatedAt)
      snackContext.openSnackbar({
        message: { title: 'Code sent successfully' },
        id: email
      })
    } catch (error) {
      handleApiError(error)
    }
  }

  return { handleResendEmailVerification, otpGeneratedAt }
}

const useResendOTP = () => {
  const resendEmailConfirmation = useCallback(async () => {
    await postEmailConfirmationResendRequest()
  }, [])
  return resendEmailConfirmation
}

const useEmailConfirmationHandler = () => {
  const { isParentCapable } = useParentViewCapability()
  const isOnboardingPath = useMatch('/onboarding')

  const executeOnEmailConfirmed = callback => {
    if (isParentCapable(CAPABILITY_CONFIRM_EMAIL) && !isOnboardingPath) {
      openVerifyOtpDialogCallback()
    } else {
      callback()
    }
  }

  return { executeOnEmailConfirmed }
}

const useActivePaymentMethod = () => {
  const appUser = useAppUser()
  const { household } = appUser ?? {}
  const { hasActivePaymentMethod } = household ?? {}
  return hasActivePaymentMethod
}

export {
  useAppUser,
  useAppUserRefresh,
  useAppHouseholdRefresh,
  useAppClientRefresh,
  useCurrentChildren,
  useCurrentClient,
  useCurrentParent,
  useCurrentHousehold,
  useCurrentSite,
  useCurrentReminders,
  useVersion,
  useOTPVerification,
  useParentViewCapability,
  useCurrentOrFirstChild,
  useTransientErrorState,
  useCopyToClipboard,
  useChildUpdater,
  useVerificationEmailResend,
  useResendOTP,
  useEmailConfirmationHandler,
  useActivePaymentMethod
}
