import React, { useEffect, useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import toDate from 'date-fns-tz/toDate'

import Paper from '@mui/material/Paper'

import CalendarIcon from '../../icons/Calendar'
import VideoCameraIcon from '../../icons/VideoCamera'

import { getDateRangeString } from '../../events/utilities'
import { useAppUser } from '../../app/hooks'
import { THEME_COLOR_GROUP_YELLOW } from '../../app/support/theme'

const ICON_SIZE = 1.75

const useStyles = makeStyles(
  theme => ({
    paper: {
      borderColor: theme.palette.border,
      borderRadius: theme.spacing(1),
      borderStyle: 'solid',
      borderTop: 'none',
      borderWidth: theme.spacing(0.125),
      boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.05)',
      margin: 0,
      transition: theme.transitions.create(['border-color', 'box-shadow'])
    },

    rect: {
      backgroundColor: theme.palette.success.main,
      borderRadius: theme.spacing(1, 1, 0, 0),
      height: theme.spacing(0.5),
      width: '100%'
    },

    greenBar: {
      position: 'absolute',
      top: -1
    },

    content: {
      display: 'flex',
      gap: theme.spacing(2),
      padding: theme.spacing(2)
    },

    navigatorProfile: {
      aspectRatio: '1 / 1.2',
      borderRadius: theme.spacing(0.75),
      objectFit: 'cover',
      width: theme.spacing(13)
    },

    eventInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1)
    },

    profileContainer: {},

    titleContainer: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap-reverse',
      gap: theme.spacing(1.25),
      justifyContent: 'space-between'
    },

    title: {
      margin: 0
    },

    desktopViewTitle: {},

    mobileViewTitle: {},

    iconContainer: {
      alignItems: 'center',
      backgroundColor: theme.palette.border,
      borderRadius: theme.spacing(0.25),
      display: 'flex',
      flexShrink: 0,
      height: theme.spacing(3),
      justifyContent: 'center',
      width: theme.spacing(3)
    },

    icon: {
      color: theme.palette.text.primary,
      height: theme.spacing(ICON_SIZE),
      margin: theme.spacing(0, 0.25),
      width: theme.spacing(ICON_SIZE)
    },

    detailRow: {
      alignItems: 'flex-start',
      display: 'flex',
      gap: theme.spacing(1)
    },

    eventDetail: {
      ...theme.typography.body1,

      color: theme.palette.text.secondary,
      margin: theme.spacing(0, 0.5)
    },

    pictureBox: {},

    scheduleBadge: {
      backgroundColor: THEME_COLOR_GROUP_YELLOW.Y30,
      borderRadius: theme.spacing(3.75),
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(1, 2, 0.75)
    },

    [theme.breakpoints.down('sm')]: {
      content: {
        flexDirection: 'column',
        gap: theme.spacing(1)
      },

      desktopViewTitle: {
        display: 'none'
      },

      profileContainer: {
        alignItems: 'center',
        display: 'flex',
        gap: theme.spacing(2)
      },

      navigatorProfile: {
        aspectRatio: 1,
        width: '100%'
      },

      pictureBox: {
        width: theme.spacing(10)
      }
    },

    [theme.breakpoints.up('sm')]: {
      mobileViewTitle: {
        display: 'none'
      }
    }
  }),
  {
    name: 'Complete'
  }
)

const CardTitle = ({ title, name, className }) => <h6 className={className}>{title || `Welcome Call with ${name}`}</h6>

const EventCard = props => {
  const { onboardingDetails } = props

  const appUser = useAppUser()
  const { name, profilePicture } = appUser.household.navigator

  const { boostCall } = onboardingDetails ?? {}
  const { name: title, startTime, endTime } = boostCall ?? {}

  const [timeString, setTimeString] = useState()

  useEffect(() => {
    setTimeString(
      getDateRangeString({
        startTime: toDate(startTime),
        endTime: toDate(endTime)
      })
    )
  }, [startTime, endTime])

  const classes = useStyles(props)
  return (
    <Paper className={classes.paper}>
      <section className={classes.rect}>
        <section className={classes.greenBar} />
      </section>

      <section className={classes.content}>
        <section className={classes.profileContainer}>
          <span className={classes.pictureBox}>
            <img className={classes.navigatorProfile} src={profilePicture} alt={name} />
          </span>

          <CardTitle className={clsx(classes.title, classes.mobileViewTitle)} title={title} name={name} />
        </section>

        <section className={classes.eventInfo}>
          <section className={classes.titleContainer}>
            <CardTitle className={clsx(classes.title, classes.desktopViewTitle)} title={title} name={name} />
            <span className={classes.scheduleBadge}>Scheduled and added to your events!</span>
          </section>

          <section className={classes.detailRow}>
            <span className={classes.iconContainer}>
              <CalendarIcon className={classes.icon} />
            </span>

            {timeString && <p className={classes.eventDetail}>{timeString}</p>}
          </section>

          <section className={classes.detailRow}>
            <span className={classes.iconContainer}>
              <VideoCameraIcon className={classes.icon} />
            </span>

            <p className={classes.eventDetail}>
              You’ll be meeting on a video call. The Zoom link will be included in the event invitation, sent to your
              email.
            </p>
          </section>
        </section>
      </section>
    </Paper>
  )
}

export default EventCard
