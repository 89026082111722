/**
 * This component is an implementation of the Undivided lettermark logo, but as a component so that it
 * has some added functionality like a version tooltip and shortcut link to the web app root.
 */
import React from 'react'
import { Link } from 'react-router-dom'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import Tooltip from '@mui/material/Tooltip'

import { THEME_PRIMARY_GREEN } from '../../app/support/theme'
import { useVersion } from '../../app/hooks'

import logo from '../../assets/logo.svg'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex'
    },

    link: {},
    logo: {},

    logoContainer: {
      display: 'flex',

      '&:before': {
        content: "''",
        backgroundColor: THEME_PRIMARY_GREEN.main,
        display: 'inline-block',
        height: '100%',
        width: theme.spacing(2.25)
      }
    }
  }),
  { name: 'Lettermark' }
)

const Lettermark = props => {
  const { className } = props

  const version = useVersion()
  const classes = useStyles(props)
  return (
    <Link className={classes.link} to="/">
      <article className={clsx(classes.root, className)}>
        <Tooltip arrow title={version.full}>
          <section className={classes.logoContainer}>
            <img className={classes.logo} src={logo} alt="Undivided lettermark logo" />
          </section>
        </Tooltip>
      </article>
    </Link>
  )
}

Lettermark.propTypes = {
  className: PropTypes.string
}

export default Lettermark
