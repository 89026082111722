/**
 * FoundationalAuditFields is a lower-level building block for foundational audits,
 * handling just the layout and styling of foundational audit data. State is provided
 * by the caller.
 */
import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

import isValid from 'date-fns/isValid'

import TextField from '@mui/material/TextField'

import US_STATE_LIST from '../../constants/statesUsa'
import { age } from '../../profile/lib/BasicInformationHelpers'

import DateField from '../../subcomponents/DateField'
import DiagnosisField from '../../subcomponents/DiagnosisField'

const useStyles = makeStyles(
  theme => ({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      justifyContent: 'center',
      transition: theme.transitions.create('opacity'),
      width: '100%'
    },

    twoColumn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    },

    field: {
      margin: 0
    },

    ageField: {},
    diagnosisField: {},
    nicknameField: {},
    birthdayField: {},
    stateField: {},

    input: {},
    ageInput: {},
    diagnosisInput: {},
    nicknameInput: {},
    birthdayInput: {},

    dateNotchedOutline: {},
    selectDisplay: {}
  }),
  {
    name: 'FoundationalAuditFields'
  }
)

const FoundationalAuditFields = props => {
  const {
    className,
    disabled,
    nickname,
    setNickname,
    birthday,
    setBirthday,
    diagnosisTagIds,
    setDiagnosisTagIds,
    state,
    setState,
    showAge
  } = props

  const handleNicknameChange = event => setNickname(event.target.value)
  const handleBirthdayChange = newBirthday => setBirthday(newBirthday)
  const handleDiagnosisTagIdsChange = event => setDiagnosisTagIds(event.target.value)
  const handleStateChange = event => setState(event.target.value)

  const classes = useStyles(props)

  const ageField = (
    <TextField
      className={clsx(classes.field, classes.ageField)}
      InputProps={{ className: clsx(classes.input, classes.ageInput) }}
      label="Age"
      fullWidth
      variant="filled"
      value={isValid(birthday) ? age(birthday) : ''}
      disabled // This is just static field to display computed age based on "Date of birth".
    />
  )

  const diagnosisField = (
    <DiagnosisField
      classes={{
        root: clsx(classes.field, classes.diagnosisField),
        select: clsx(classes.input, classes.diagnosisInput),
        selectDisplay: classes.selectDisplay
      }}
      value={diagnosisTagIds}
      onChange={handleDiagnosisTagIdsChange}
      name="diagnosisTagIds"
      label="Diagnosis"
      disabled={disabled}
    />
  )

  return (
    <section className={clsx(classes.root, className)}>
      <section className={classes.twoColumn}>
        {setNickname && (
          <TextField
            className={clsx(classes.field, classes.nicknameField)}
            autoComplete="off"
            InputProps={{ className: clsx(classes.input, classes.nicknameInput) }}
            fullWidth
            variant="filled"
            name="nickname"
            label="Child’s preferred name"
            value={nickname}
            onChange={handleNicknameChange}
            disabled={disabled}
          />
        )}

        {showAge && !setState && setDiagnosisTagIds && diagnosisField}

        {setState && (
          <TextField
            className={clsx(classes.field, classes.stateField)}
            name="location"
            label="State"
            select
            fullWidth
            margin="normal"
            variant="filled"
            SelectProps={{ native: true }}
            value={state}
            onChange={handleStateChange}
            disabled={disabled}
          >
            <option value="" />

            {US_STATE_LIST.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </TextField>
        )}
      </section>

      <section className={classes.twoColumn}>
        {setBirthday && (
          <DateField
            classes={{ root: clsx(classes.field, classes.birthdayField) }}
            name="birthday"
            label="Date of birth"
            margin="normal"
            onChange={handleBirthdayChange}
            value={birthday}
            inputVariant="filled"
            maxDate={new Date()}
            InputProps={{
              classes: {
                root: clsx(classes.input, classes.birthdayInput),
                notchedOutline: classes.dateNotchedOutline
              }
            }}
            disabled={disabled}
          />
        )}

        {/* If age is to be displayed, it is shown next to the birthday with the diagnosis field
            being full-width below; otherwise the diagnosis field goes here. */}
        {showAge ? ageField : setDiagnosisTagIds && diagnosisField}
      </section>

      {showAge && setState && setDiagnosisTagIds && diagnosisField}
    </section>
  )
}

FoundationalAuditFields.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool, // Indicates whether fields should be disabled.

  nickname: PropTypes.string, // Child's preferred name
  birthday: PropTypes.instanceOf(Date), // Child's date of birth
  diagnosisTagIds: PropTypes.arrayOf(PropTypes.number), // List of diagnosis tag IDs
  state: PropTypes.string, // Household’s location

  // If a setter is not given, the field will not be shown.
  setNickname: PropTypes.func, // State setter function for child’s preferred name
  setBirthday: PropTypes.func, // State setter function for child’s date of birth
  setDiagnosisTagIds: PropTypes.func, // State setter function for list of diagnosis tag IDs
  setState: PropTypes.func, // State setter function for household’s location

  showAge: PropTypes.bool // Indicates whether to show the child’s calculated age
}

export default FoundationalAuditFields
