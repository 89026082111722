/**
 * Some client model/object logic is called for before interacting directly with client API routes.
 * This module captures those additional operations.
 */
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import isValid from 'date-fns/isValid'

import { getUser, getClient, patchClient, postAsset, getAsset, headDirectAsset } from '../api'
import { CAN_BE_NAVIGATOR } from '../arch/constants'
import { invalidateAsset } from '../assetUtilities'
import { user } from '../auth'
import { defaultName } from '../common'
import { ASSET_SIZE_1X, DATE_FORMAT_ISO } from '../constants'

const getBirthdayDate = birthday => {
  const parsedBirthday = parse(birthday, DATE_FORMAT_ISO, new Date())

  // Invalid values (for whatever reason) get wiped out.
  if (isValid(parsedBirthday)) {
    return parsedBirthday
  } else {
    return null
  }
}

const clientForUi = client => {
  const result = { ...client }

  // Client birthdays need to be turned into date objects.
  if (result.birthday) {
    const birthdayDate = getBirthdayDate(result.birthday)

    if (birthdayDate) {
      result.birthday = birthdayDate
    } else {
      delete result.birthday
    }
  }

  // TODO Still some lack of clarity re: child name at invitation vs. child first/family name here,
  // so for now, copy `name` into `nickname` if `nickname` is blank.
  result.nickname = result.nickname || result.name

  return result
}

const clientPayloadForServer = client => {
  const result = { ...client }

  // Blank names aren’t prohibited, but we do trim either way.
  result.nickname = (client.nickname || '').trim()
  result.familyName = (client.familyName || '').trim()
  result.name = defaultName(result.nickname, result.familyName) || ''

  result.streetAddress = (client.streetAddress || '').trim()
  result.city = (client.city || '').trim()
  result.state = (client.state || '').trim()
  result.zip = (client.zip || '').trim()

  // Special handling for birthday: the API will reject the empty string and 502 on null so we don't include it in
  // the patch payload if it is either.
  const birthday = result.birthday
  if (!birthday) {
    delete result.birthday

    // No birthday, no problem.
    return result
  } else {
    if (isValid(birthday)) {
      // An updated client will be a Date object so we need to convert it into a string before patching.
      result.birthday = format(result.birthday, DATE_FORMAT_ISO)
      return result
    } else {
      // An invalid birthday most likely means that the user hasn’t finished editing it; in this case,
      // we don’t touch the payload and we notify the caller by returning a null payload.
      return null
    }
  }
}

const getParent = async client => {
  const currentUser = user()
  if (!client || !currentUser) {
    return null
  }

  if (currentUser.isParentOf(client.id)) {
    return currentUser
  }

  if (currentUser.role(CAN_BE_NAVIGATOR)) {
    // Navigators _are_ allowed to look up other users, so we defer any thrown errors to the caller because if that
    // happens, then something legitimately went wrong.
    return await getUser(client.createdBy)
  } else {
    return null
  }
}

/**
 * Although the current user object has a `household.clients` array that should be sufficient most of the time,
 * this array holds just client stubs and don’t have all of the clients’ properties. This helper function loads
 * the client data in full, and also loads their _latest_ persisted values, in case those values were modified
 * very recently.
 *
 * @param {object} user
 */
const getChildren = async user => {
  const clients = await Promise.all((user.household?.clients || []).map(clientStub => getClient(clientStub.id)))
  return clients.map(clientForUi)
}

const saveClient = async (client, setError) => {
  const clientPayload = clientPayloadForServer(client)
  if (!clientPayload) {
    // A null payload means that the given client object isn’t ready for persisting.
    return null
  }

  try {
    return await patchClient(clientPayload)
  } catch (error) {
    if (setError) {
      setError(error)
    } else {
      throw error
    }
  }

  return clientPayload
}

const ASSET_RETRY_DELAY = 2500

const saveAndUpdateProfilePicture = async (client, setClient) => {
  const { avatarFile, ...clientWithoutAvatarFile } = client
  if (!avatarFile) {
    return false
  }

  setClient({
    ...clientWithoutAvatarFile,
    profilePictureInProgress: true
  })

  const assetId = await postAsset({ childId: client.id, file: avatarFile })
  const priorAssetId = clientWithoutAvatarFile.profilePicture
  clientWithoutAvatarFile.profilePicture = assetId
  const saveResult = await saveClient(clientWithoutAvatarFile)

  // QoL loop: wait for the asset thumbnail to be ready before proceeding.
  const { headUrl } = await getAsset(assetId, ASSET_SIZE_1X)

  let available = false
  while (!available) {
    try {
      await headDirectAsset(headUrl)
      available = true
    } catch (error) {
      // No-op; we’ll just try again after a slight delay.
      // (thank you https://stackoverflow.com/a/39914235)
      await new Promise(r => setTimeout(r, ASSET_RETRY_DELAY))
    }
  }

  setClient({
    ...clientWithoutAvatarFile,
    profilePictureInProgress: false
  })

  if (priorAssetId) {
    invalidateAsset(priorAssetId)
  }

  return saveResult
}

const saveAndUpdateClient = async (updatedClient, setClient, setError) => {
  // Additional special handling: an `avatarFile` property means that the user has picked a new profile picture.
  // That one is a slightly different sequence.
  if (updatedClient.avatarFile) {
    return await saveAndUpdateProfilePicture(updatedClient, setClient)
  } else {
    const savedPayload = await saveClient(updatedClient, setError)
    if (savedPayload) {
      setClient(updatedClient)
    }

    return savedPayload
  }
}

export { clientForUi, clientPayloadForServer, getParent, getChildren, saveAndUpdateClient, getBirthdayDate }
