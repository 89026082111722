/**
 * This module holds functionality relating to “assets” (secure S3-hosted files), particularly caching
 * for frequently-used ones.
 */
import { ALL_ASSET_SIZES } from './constants'

// This is it, the core data structure, one subcache per size.
const assetCache = {
  ...Object.fromEntries(ALL_ASSET_SIZES.map(size => [size, {}]))
}

const cacheAsset = (assetId, assetSize, asset) => {
  const sizeCache = assetCache[assetSize]

  // Attempts to cache to an invalid size will behave like the asset is never cached.
  if (sizeCache) {
    // Passing a falsy asset deletes the cache entry for that asset.
    if (asset) {
      sizeCache[assetId] = asset
    } else {
      delete sizeCache[assetId]
    }
  }
}

const getCachedAsset = (assetId, assetSize) => assetCache[assetSize]?.[assetId]
const invalidateAsset = assetId => ALL_ASSET_SIZES.forEach(size => cacheAsset(assetId, size, null))

export { getCachedAsset, cacheAsset, invalidateAsset }
