import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import makeStyles from '@mui/styles/makeStyles'

import Quotes from '../icons/Quotes'

import { THEME_COLOR_GROUP_YELLOW } from '../app/support/theme'

const useStyles = makeStyles(
  theme => ({
    quoteImage: {
      color: THEME_COLOR_GROUP_YELLOW.Y700,
      left: theme.spacing(2),
      position: 'absolute',
      top: theme.spacing(1.125),
      width: theme.spacing(4.5),
      zIndex: -1
    },

    quotesColor: {},

    sidebarContent: {
      fontFamily: 'Architects Daughter',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 30 / 20,
      width: theme.spacing(33.875)
    },

    client: {
      alignItems: 'center',
      color: theme.palette.text.secondary,
      display: 'flex',
      gap: theme.spacing(1),
      lineHeight: 22 / 16,
      fontWeight: theme.typography.fontWeightMedium
    }
  }),
  {
    name: 'UndividedQuotes'
  }
)

const UndividedQuotes = props => {
  const { className, text, member, quoteVisible } = props

  const classes = useStyles(props)

  return (
    <section className={clsx(className)}>
      {quoteVisible && <Quotes classes={{ root: classes.quotesColor }} className={classes.quoteImage} />}
      <p className={classes.sidebarContent}>{text}</p>
      <span className={classes.client}>— {member}</span>
    </section>
  )
}

export default UndividedQuotes

UndividedQuotes.propTypes = {
  className: PropTypes.string, // Classes to apply to the root element.
  member: PropTypes.string, // This is the name of the person who said the quote.
  quoteVisible: PropTypes.bool, // This is a boolean value that determines whether the quote is visible.
  text: PropTypes.string // This is the text of the quote.
}
