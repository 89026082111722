/**
 * Theme values taken from https://www.figma.com/file/uUSJIZrruwRFNLVF3YmMHw/Web-App?node-id=1637%3A7220
 */
import { createTheme } from '@mui/material/styles'
import deepmerge from '@mui/utils/deepmerge'

const THEME_FONT_FAMILY = ['Value', 'Avenir', 'Helvetica', 'Georgia', 'Arial', 'sans-serif'].join(', ')

// These font sizes are in pixels, to be converted to rem via pxToRem.
const THEME_DESKTOP_FONT_SIZE_H1 = 42
const THEME_DESKTOP_FONT_SIZE_H2 = 32
const THEME_DESKTOP_FONT_SIZE_H3 = 28
const THEME_DESKTOP_FONT_SIZE_H4 = 24
const THEME_DESKTOP_FONT_SIZE_H5 = 22
const THEME_DESKTOP_FONT_SIZE_H6 = 20
const THEME_DESKTOP_FONT_SIZE_BODY1 = 16
const THEME_DESKTOP_FONT_SIZE_BODY2 = 14

const THEME_MOBILE_FONT_SIZE_H1 = 32
const THEME_MOBILE_FONT_SIZE_H2 = 28
const THEME_MOBILE_FONT_SIZE_H3 = 24
const THEME_MOBILE_FONT_SIZE_H4 = 22
const THEME_MOBILE_FONT_SIZE_H5 = 20
const THEME_MOBILE_FONT_SIZE_H6 = 18
const THEME_MOBILE_FONT_SIZE_BODY1 = 16
const THEME_MOBILE_FONT_SIZE_BODY2 = 14

const THEME_DESKTOP_LINE_HEIGHT_H1 = 46
const THEME_DESKTOP_LINE_HEIGHT_H2 = 36
const THEME_DESKTOP_LINE_HEIGHT_H3 = 32
const THEME_DESKTOP_LINE_HEIGHT_H4 = 28
const THEME_DESKTOP_LINE_HEIGHT_H5 = 26
const THEME_DESKTOP_LINE_HEIGHT_H6 = 24
const THEME_DESKTOP_LINE_HEIGHT_BODY1 = 24
const THEME_DESKTOP_LINE_HEIGHT_BODY2 = 24

const THEME_MOBILE_LINE_HEIGHT_H1 = 36
const THEME_MOBILE_LINE_HEIGHT_H2 = 32
const THEME_MOBILE_LINE_HEIGHT_H3 = 28
const THEME_MOBILE_LINE_HEIGHT_H4 = 24
const THEME_MOBILE_LINE_HEIGHT_H5 = 24
const THEME_MOBILE_LINE_HEIGHT_H6 = 22
const THEME_MOBILE_LINE_HEIGHT_BODY1 = 22
const THEME_MOBILE_LINE_HEIGHT_BODY2 = 22

const THEME_FONT_SIZE_BUTTON = THEME_DESKTOP_FONT_SIZE_BODY1
const THEME_FONT_SIZE_BUTTON1 = THEME_DESKTOP_FONT_SIZE_BODY1
const THEME_FONT_SIZE_BUTTON2 = THEME_DESKTOP_FONT_SIZE_BODY2
const THEME_FONT_SIZE_CAPTION = 12
const THEME_FONT_SIZE_INPUT = THEME_DESKTOP_FONT_SIZE_BODY1
const THEME_FONT_SIZE_LABEL = THEME_DESKTOP_FONT_SIZE_BODY1
const THEME_FONT_SIZE_LABEL1 = THEME_DESKTOP_FONT_SIZE_BODY1
const THEME_FONT_SIZE_LABEL2 = THEME_DESKTOP_FONT_SIZE_BODY2
const THEME_FONT_SIZE_TOOLTIP = 10

const THEME_LINE_HEIGHT_INPUT = 20
const THEME_LINE_HEIGHT_LABEL = 20

const THEME_COLOR_GROUP_NEUTRAL = {
  N10: '#fafafc',
  N20: '#f6f7fb',
  N25: '#ebebeb',
  N30: '#edeff4',
  N40: '#e2e6f0',
  N45: '#e6e6e6',
  N47: '#d5d5d5',
  N50: '#d6dbe7',
  N60: '#c2ccda',
  N70: '#b3bdcc',
  N80: '#a6afbf',
  N90: '#97a0af',
  N100: '#8993a4',
  N200: '#7a869a',
  N300: '#6b778c',
  N400: '#5e6c84',
  N500: '#505f79',
  N600: '#42526e',
  N700: '#344563',
  N800: '#253858',
  N900: '#172b4d',
  N1000: '#091e42',
  N2000: '#051023' // _Our_ black 😅
}

const THEME_COLOR_GROUP_BLUE = {
  B10: '#f0fbff',
  B20: '#e0f6fe',
  B30: '#d1f2fe',
  B40: '#c1edfe',
  B50: '#b2e9fe',
  B60: '#a3e4fd',
  B70: '#93e0fd',
  B80: '#84dbfd',
  B100: '#65d2fc',
  B200: '#42c8fb',
  B300: '#20BEFB',
  B400: '#05B0F2',
  B600: '#037ead',
  B700: '#03658b',
  B800: '#024c68',
  B900: '#013245'
}

const THEME_COLOR_GROUP_GREEN = {
  G10: '#f8fdf2',
  G20: '#f1fbe4',
  G30: '#EAFAD7',
  G40: '#e3f8ca',
  G50: '#ddf6bd',
  G60: '#d6f4af',
  G70: '#cff2a2',
  G80: '#c8f195',
  G100: '#baed7a',
  G200: '#88dd1e',
  G300: '#99e43b',
  G500: '#75BE1A',
  G600: '#619E15',
  G700: '#4e7e11',
  G800: '#3A5F0D',
  G900: '#273f09',
  G1000: '#132004'
}

const THEME_COLOR_GROUP_ORANGE = {
  O10: '#fff7e8',
  O20: '#fef0d1',
  O40: '#fee1a3',
  O50: '#fed98c',
  O60: '#fdd175',
  O70: '#fdca5e',
  O80: '#fdc247',
  O100: '#fcb319',
  O200: '#f6a803',
  O300: '#db9503',
  O400: '#bf8302',
  O700: '#6d4b01',
  O800: '#523801',
  O900: '#372501'
}

const THEME_COLOR_GROUP_PURPLE = {
  P10: '#f3f3ff',
  P20: '#e7e7ff',
  P40: '#cfcfff',
  P50: '#c3c3ff',
  P60: '#b7b7ff',
  P70: '#ababff',
  P80: '#9f9fff',
  P100: '#8787ff',
  P200: '#6d6de8',
  P300: '#4d4dc9',
  P400: '#3838a1',
  P600: '#1e1e7d',
  P700: '#00006f',
  P800: '#01015c'
}

const THEME_COLOR_GROUP_RED = {
  R10: '#feeded',
  R20: '#fddcdc',
  R40: '#fbb9b9',
  R50: '#faa7a7',
  R60: '#f99595',
  R70: '#f88484',
  R80: '#f77272',
  R100: '#f54f4f',
  R200: '#f33030',
  R300: '#f21212',
  R600: '#990909',
  R700: '#7b0707',
  R900: '#3d0303'
}

const THEME_COLOR_GROUP_YELLOW = {
  Y10: '#fffce8',
  Y20: '#fefad1',
  Y30: '#fef7ba',
  Y40: '#fef5a3',
  Y50: '#fef28c',
  Y60: '#fdef75',
  Y70: '#fded5e',
  Y100: '#fce519',
  Y200: '#f6dd03',
  Y300: '#dbc503',
  Y400: '#bfac02',
  Y600: '#897B02',
  Y700: '#6d6201',
  Y900: '#373101'
}

// The `background`, `border`, `selection`, and `text` color properties are custom to Undivided.
const THEME_PRIMARY_BLUE = {
  background: THEME_COLOR_GROUP_BLUE.B10,
  border: THEME_COLOR_GROUP_BLUE.B60,
  main: THEME_COLOR_GROUP_BLUE.B100,
  dark: THEME_COLOR_GROUP_BLUE.B600,
  light: THEME_COLOR_GROUP_BLUE.B40,
  selection: THEME_COLOR_GROUP_BLUE.B300,
  text: THEME_COLOR_GROUP_BLUE.B900
}

const THEME_SECONDARY_BLUE = {
  background: THEME_COLOR_GROUP_BLUE.B10,
  border: THEME_COLOR_GROUP_BLUE.B60,
  main: THEME_COLOR_GROUP_BLUE.B70,
  dark: THEME_COLOR_GROUP_BLUE.B900,
  light: THEME_COLOR_GROUP_BLUE.B40,
  selection: THEME_COLOR_GROUP_BLUE.B300,
  text: THEME_COLOR_GROUP_BLUE.B600
}

const THEME_PRIMARY_GREEN = {
  background: THEME_COLOR_GROUP_GREEN.G10,
  border: THEME_COLOR_GROUP_GREEN.G60,
  main: THEME_COLOR_GROUP_GREEN.G100,
  dark: THEME_COLOR_GROUP_GREEN.G700,
  light: THEME_COLOR_GROUP_GREEN.G40,
  selection: THEME_COLOR_GROUP_GREEN.G300,
  text: THEME_COLOR_GROUP_GREEN.G900
}

const THEME_SECONDARY_GREEN = {
  background: THEME_COLOR_GROUP_GREEN.G10,
  border: THEME_COLOR_GROUP_GREEN.G60,
  main: THEME_COLOR_GROUP_GREEN.G70,
  dark: THEME_COLOR_GROUP_GREEN.G900,
  light: THEME_COLOR_GROUP_GREEN.G100,
  selection: THEME_COLOR_GROUP_GREEN.G300,
  text: THEME_COLOR_GROUP_GREEN.G700
}

const THEME_PRIMARY_ORANGE = {
  background: THEME_COLOR_GROUP_ORANGE.O10,
  border: THEME_COLOR_GROUP_ORANGE.O60,
  main: THEME_COLOR_GROUP_ORANGE.O100,
  dark: THEME_COLOR_GROUP_ORANGE.O400,
  light: THEME_COLOR_GROUP_ORANGE.O40,
  selection: THEME_COLOR_GROUP_ORANGE.O300,
  text: THEME_COLOR_GROUP_ORANGE.O800
}

const THEME_SECONDARY_ORANGE = {
  background: THEME_COLOR_GROUP_ORANGE.O10,
  border: THEME_COLOR_GROUP_ORANGE.O60,
  main: THEME_COLOR_GROUP_ORANGE.O70,
  dark: THEME_COLOR_GROUP_ORANGE.O800,
  light: THEME_COLOR_GROUP_ORANGE.O40,
  selection: THEME_COLOR_GROUP_ORANGE.O300,
  text: THEME_COLOR_GROUP_ORANGE.O400
}

const THEME_PRIMARY_PURPLE = {
  background: THEME_COLOR_GROUP_PURPLE.P10,
  border: THEME_COLOR_GROUP_PURPLE.P60,
  main: THEME_COLOR_GROUP_PURPLE.P100,
  dark: THEME_COLOR_GROUP_PURPLE.P400,
  light: THEME_COLOR_GROUP_PURPLE.P40,
  selection: THEME_COLOR_GROUP_PURPLE.P300,
  text: THEME_COLOR_GROUP_PURPLE.P800
}

const THEME_SECONDARY_PURPLE = {
  background: THEME_COLOR_GROUP_PURPLE.P10,
  border: THEME_COLOR_GROUP_PURPLE.P60,
  main: THEME_COLOR_GROUP_PURPLE.P70,
  dark: THEME_COLOR_GROUP_PURPLE.P800,
  light: THEME_COLOR_GROUP_PURPLE.P40,
  selection: THEME_COLOR_GROUP_PURPLE.P300,
  text: THEME_COLOR_GROUP_PURPLE.P400
}

const THEME_PRIMARY_RED = {
  background: THEME_COLOR_GROUP_RED.R10,
  border: THEME_COLOR_GROUP_RED.R60,
  main: THEME_COLOR_GROUP_RED.R100,
  dark: THEME_COLOR_GROUP_RED.R600,
  light: THEME_COLOR_GROUP_RED.R40,
  selection: THEME_COLOR_GROUP_RED.R300,
  text: THEME_COLOR_GROUP_RED.R900
}

const THEME_SECONDARY_RED = {
  background: THEME_COLOR_GROUP_RED.R10,
  border: THEME_COLOR_GROUP_RED.R60,
  main: THEME_COLOR_GROUP_RED.R70,
  dark: THEME_COLOR_GROUP_RED.R900,
  light: THEME_COLOR_GROUP_RED.R40,
  selection: THEME_COLOR_GROUP_RED.R300,
  text: THEME_COLOR_GROUP_RED.R600
}

const THEME_PRIMARY_YELLOW = {
  background: THEME_COLOR_GROUP_YELLOW.Y10,
  border: THEME_COLOR_GROUP_YELLOW.Y60,
  main: THEME_COLOR_GROUP_YELLOW.Y100,
  dark: THEME_COLOR_GROUP_YELLOW.Y300,
  light: THEME_COLOR_GROUP_YELLOW.Y40,
  selection: THEME_COLOR_GROUP_YELLOW.Y300,
  text: THEME_COLOR_GROUP_YELLOW.Y900
}

const THEME_SECONDARY_YELLOW = {
  background: THEME_COLOR_GROUP_YELLOW.Y10,
  border: THEME_COLOR_GROUP_YELLOW.Y60,
  main: THEME_COLOR_GROUP_YELLOW.Y70,
  dark: THEME_COLOR_GROUP_YELLOW.Y900,
  light: THEME_COLOR_GROUP_YELLOW.Y200,
  selection: THEME_COLOR_GROUP_YELLOW.Y300,
  text: THEME_COLOR_GROUP_YELLOW.Y300
}

const THEME_PRIMARY_NEUTRAL = {
  background: THEME_COLOR_GROUP_NEUTRAL.N10,
  border: THEME_COLOR_GROUP_NEUTRAL.N60,
  main: THEME_COLOR_GROUP_NEUTRAL.N1000,
  dark: THEME_COLOR_GROUP_NEUTRAL.N2000,
  light: THEME_COLOR_GROUP_NEUTRAL.N40,
  selection: THEME_COLOR_GROUP_NEUTRAL.N300,
  text: 'white'
}

const THEME_SECONDARY_NEUTRAL = {
  background: THEME_COLOR_GROUP_NEUTRAL.N10,
  border: THEME_COLOR_GROUP_NEUTRAL.N60,
  main: THEME_COLOR_GROUP_NEUTRAL.N70,
  dark: THEME_COLOR_GROUP_NEUTRAL.N900,
  light: THEME_COLOR_GROUP_NEUTRAL.N200,
  selection: THEME_COLOR_GROUP_NEUTRAL.N300,
  text: THEME_COLOR_GROUP_NEUTRAL.N2000
}

// Pending final color choices from Tanya.
const THEME_PRIMARY_DEFAULT = THEME_PRIMARY_PURPLE

const THEME_SECONDARY_DEFAULT = {
  ...THEME_SECONDARY_PURPLE,
  main: '#e8eeed' // very light aqua green (turquoise)
}

const THEME_COLOR_GROUP_LINK = {
  main: THEME_COLOR_GROUP_NEUTRAL.N1000,
  hover: THEME_COLOR_GROUP_GREEN.G800,
  active: THEME_COLOR_GROUP_NEUTRAL.N400,
  visited: THEME_COLOR_GROUP_NEUTRAL.N1000
}

/**
 * See export default block comment at the bottom to see how BASELINE_THEME relates to the final
 * top-level theme for the application.
 */
const BASELINE_THEME = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      brandSmaller: 480, // Custom breakpoint for Undivided.
      brandSmall: 501, // Custom breakpoint for Undivided.
      sm: 600,
      brandMedium: 700, // Custom breakpoint for Undivided.
      md: 960, // For compatibility with MUI 4 breakpoints; MUI 5 defaults to 900.
      lg: 1280, // For compatibility with MUI 4 breakpoints; MUI 5 defaults to 1200.
      xl: 1536
    }
  },

  mixins: {
    toolbar: {
      minHeight: 12,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 12
      },
      '@media (min-width:600px)': {
        minHeight: 12
      }
    }
  },

  palette: {
    common: {
      black: THEME_COLOR_GROUP_NEUTRAL.N2000
    },

    background: {
      default: '#fff' // Can be removed in Material UI 5.
    },

    border: THEME_COLOR_GROUP_NEUTRAL.N30,
    borderLight: THEME_COLOR_GROUP_NEUTRAL.N20,
    borderDark: THEME_COLOR_GROUP_NEUTRAL.N60,

    primary: THEME_PRIMARY_DEFAULT,
    secondary: THEME_SECONDARY_DEFAULT,
    link: THEME_COLOR_GROUP_LINK, // Custom palette entry.

    // Undivided-exclusive color groups.
    blue: THEME_PRIMARY_BLUE,
    green: THEME_PRIMARY_GREEN,
    orange: THEME_PRIMARY_ORANGE,
    purple: THEME_PRIMARY_PURPLE,
    red: THEME_PRIMARY_RED,
    yellow: THEME_PRIMARY_YELLOW,
    neutral: THEME_PRIMARY_NEUTRAL,

    error: {
      main: THEME_COLOR_GROUP_RED.R100,
      dark: THEME_COLOR_GROUP_RED.R600,
      light: THEME_COLOR_GROUP_RED.R40
    },

    warning: {
      main: THEME_COLOR_GROUP_PURPLE.P100,
      dark: THEME_COLOR_GROUP_PURPLE.P400,
      light: THEME_COLOR_GROUP_PURPLE.P40
    },

    info: {
      main: THEME_COLOR_GROUP_BLUE.B100,
      dark: THEME_COLOR_GROUP_BLUE.B600,
      light: THEME_COLOR_GROUP_BLUE.B40
    },

    success: {
      main: THEME_COLOR_GROUP_GREEN.G100,
      dark: THEME_COLOR_GROUP_GREEN.G700,
      light: THEME_COLOR_GROUP_GREEN.G40
    },

    text: {
      primary: THEME_COLOR_GROUP_NEUTRAL.N1000,
      secondary: THEME_COLOR_GROUP_NEUTRAL.N500,
      tertiary: THEME_COLOR_GROUP_NEUTRAL.N100, // Custom text color.
      disabled: THEME_COLOR_GROUP_NEUTRAL.N70,
      hint: THEME_COLOR_GROUP_NEUTRAL.N100
    }
  },

  typography: {
    fontFamily: THEME_FONT_FAMILY
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          color: THEME_COLOR_GROUP_LINK.main,
          textDecoration: 'none',

          '&:visited:not(.MuiButtonBase-root)': {
            color: THEME_COLOR_GROUP_LINK.visited
          },

          '&:hover:not(.MuiButtonBase-root)': {
            color: THEME_COLOR_GROUP_LINK.hover
          },

          '&:hover': {
            textDecoration: 'underline'
          },

          '&:active:not(.MuiButtonBase-root)': {
            color: THEME_COLOR_GROUP_LINK.active,
            textDecoration: 'underline'
          }
        }
      }
    },

    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          // AvatarGroup uses direction row-reverse while maintaining the same avatar order 🤷🏽‍♂️
          // Because of the reversal, we need to also reverse the justification to preserve
          // the appearance of having the same order.
          justifyContent: 'flex-end',

          '& .MuiAvatar-root': {
            boxSizing: 'border-box' // MUI 5 changed this to 'content-box'
          }
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },

        contained: {
          boxShadow: 'none',

          '&.MuiButton-colorInherit': {
            backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N1000,

            '&:hover': {
              backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N600
            }
          },

          '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)'
          }
        },

        outlined: {
          borderStyle: 'solid',
          boxSizing: 'border-box',

          // In the event that this is a link styled like a button…
          '&:visited': {
            color: 'inherit'
          }
        }
      }
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N10,
          borderColor: THEME_COLOR_GROUP_NEUTRAL.N20,
          borderStyle: 'solid',

          '&:hover': {
            backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N10,
            borderColor: THEME_PRIMARY_PURPLE.light,

            '&.MuiFilledInput-root': {
              '&:before': {
                borderBottom: 'none'
              },

              '&:after': {
                borderBottom: 'none'
              }
            }
          },

          '&.Mui-focused': {
            backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N10,
            borderColor: THEME_PRIMARY_PURPLE.light
          },

          '&.Mui-disabled': {
            backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N30
          },

          // The branded style doesn’t do underlines.
          '&.MuiFilledInput-root': {
            '&:before': {
              borderBottom: 'none'
            },

            '&:after': {
              borderBottom: 'none'
            }
          }
        }
      }
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: THEME_COLOR_GROUP_NEUTRAL.N500
          }
        }
      }
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N25,

            '&:hover': {
              backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N25
            }
          }
        }
      }
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: THEME_COLOR_GROUP_NEUTRAL.N500
          }
        },

        input: {
          height: '1.1876em', // Reset (19px), match the native input line-height and MUIv4.
          // …except for MUI NativeSelects; see below.

          '&.MuiSelect-select': {
            minHeight: '1.1876em' // Reset (19px), match the native input line-height and MUIv4.
          }
        }
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        filled: {
          // Really just overriding tx but the whole string is needed.
          transform: 'translate(17px, 17px) scale(1)',

          '&.Mui-focused': {
            color: THEME_COLOR_GROUP_NEUTRAL.N500
          },

          '&.MuiInputLabel-shrink': {
            transform: 'translate(17px, 9px)'
          }
        },

        shrink: {
          lineHeight: 18 / THEME_FONT_SIZE_LABEL2
        }
      }
    },

    MuiNativeSelect: {
      styleOverrides: {
        select: {
          '&.MuiInputBase-input': {
            height: 'auto' // As noted in the MuiInputBase override above.
          }
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            borderColor: THEME_PRIMARY_PURPLE.light
          },

          '&.Mui-focused': {
            borderColor: THEME_PRIMARY_PURPLE.light
          }
        }
      }
    },

    MuiPickersDay: {
      styleOverrides: {
        daySelected: {
          color: 'white'
        }
      }
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: THEME_COLOR_GROUP_NEUTRAL.N2000,
          boxShadow: '4px 4px 24px 0px rgba(0, 0, 0, 0.28)',
          lineHeight: 16 / THEME_FONT_SIZE_CAPTION
        },

        arrow: {
          color: THEME_COLOR_GROUP_NEUTRAL.N2000
        }
      }
    }
  }
})

/**
 * Helpers for common theme overrides (so far). May fluctuate based on the range of overrides that
 * emerges over time.
 */
const standardColorOverrides = (colorGroup, secondaryColorGroup) => ({
  palette: {
    primary: colorGroup,
    secondary: secondaryColorGroup || {}
  }
})

const colorOverrideTheme = (outerTheme, colorGroup, secondaryColorGroup) =>
  deepmerge(outerTheme, standardColorOverrides(colorGroup, secondaryColorGroup))

const accountThemeExtender = outerTheme =>
  colorOverrideTheme(outerTheme, THEME_PRIMARY_NEUTRAL, THEME_SECONDARY_NEUTRAL)

const chatThemeExtender = outerTheme => colorOverrideTheme(outerTheme, THEME_PRIMARY_YELLOW, THEME_SECONDARY_YELLOW)
const communityThemeExtender = outerTheme => colorOverrideTheme(outerTheme, THEME_PRIMARY_RED, THEME_SECONDARY_RED)
const exploreThemeExtender = outerTheme => colorOverrideTheme(outerTheme, THEME_PRIMARY_ORANGE, THEME_SECONDARY_ORANGE)
const planThemeExtender = outerTheme => colorOverrideTheme(outerTheme, THEME_PRIMARY_GREEN, THEME_SECONDARY_GREEN)
const visionThemeExtender = outerTheme => colorOverrideTheme(outerTheme, THEME_PRIMARY_PURPLE, THEME_SECONDARY_PURPLE)

const homeThemeExtender = outerTheme =>
  colorOverrideTheme(
    outerTheme,
    {
      // In the absence of additional information, we define this theme as an “override” of the primary purple scheme.
      ...THEME_PRIMARY_PURPLE,
      main: THEME_COLOR_GROUP_PURPLE.P60
    },
    THEME_SECONDARY_PURPLE
  )

const clientAccountsThemeExtender = outerTheme =>
  colorOverrideTheme(outerTheme, THEME_PRIMARY_BLUE, THEME_SECONDARY_BLUE)

const profileBinderThemeExtender = outerTheme =>
  colorOverrideTheme(outerTheme, THEME_PRIMARY_BLUE, THEME_SECONDARY_BLUE)

export {
  THEME_PRIMARY_BLUE,
  THEME_PRIMARY_GREEN,
  THEME_PRIMARY_ORANGE,
  THEME_PRIMARY_PURPLE,
  THEME_PRIMARY_YELLOW,
  THEME_PRIMARY_RED,
  THEME_PRIMARY_NEUTRAL,
  THEME_SECONDARY_BLUE,
  THEME_SECONDARY_GREEN,
  THEME_SECONDARY_ORANGE,
  THEME_SECONDARY_PURPLE,
  THEME_SECONDARY_YELLOW,
  THEME_SECONDARY_RED,
  THEME_SECONDARY_NEUTRAL,
  THEME_COLOR_GROUP_BLUE,
  THEME_COLOR_GROUP_GREEN,
  THEME_COLOR_GROUP_ORANGE,
  THEME_COLOR_GROUP_PURPLE,
  THEME_COLOR_GROUP_YELLOW,
  THEME_COLOR_GROUP_RED,
  THEME_COLOR_GROUP_NEUTRAL,
  THEME_COLOR_GROUP_LINK,
  accountThemeExtender,
  chatThemeExtender,
  communityThemeExtender,
  exploreThemeExtender,
  homeThemeExtender,
  planThemeExtender,
  profileBinderThemeExtender,
  clientAccountsThemeExtender,
  visionThemeExtender
}

/**
 * We use an inline function to create the default theme because some values rely on helper functions
 * and constants of the Material UI default theme itself, so we need a theme to begin with and as the
 * top-level theme, this does not an “outer theme” to go by. So BASELINE_THEME is a theme object based
 * on values that can be set _without_ those helpers, and this function, which creates the actual theme
 * to use, merges additional properties that rely on those them.
 */
export default (() => {
  // Use the baseline theme to inject values based on the default Material UI theme’s helper functions.
  const theme = BASELINE_THEME
  const createTransition = theme.transitions.create
  const { spacing, typography } = theme
  const { fontWeightBold, fontWeightMedium, fontWeightRegular, pxToRem } = typography

  // Leverage how standard h# and Material Typography variants are the same.
  //
  // Line heights are expressed as line-height-in-pixels / font-size-in-pixels because the theme code
  // requires line heights to be pure numbers (i.e., no units).
  const typographyH = {
    h1: {
      fontSize: pxToRem(THEME_MOBILE_FONT_SIZE_H1),
      fontWeight: fontWeightMedium,
      lineHeight: THEME_MOBILE_LINE_HEIGHT_H1 / THEME_MOBILE_FONT_SIZE_H1,

      [theme.breakpoints.up('sm')]: {
        fontSize: pxToRem(THEME_DESKTOP_FONT_SIZE_H1),
        lineHeight: THEME_DESKTOP_LINE_HEIGHT_H1 / THEME_DESKTOP_FONT_SIZE_H1
      }
    },

    h2: {
      fontSize: pxToRem(THEME_MOBILE_FONT_SIZE_H2),
      fontWeight: fontWeightMedium,
      lineHeight: THEME_MOBILE_LINE_HEIGHT_H2 / THEME_MOBILE_FONT_SIZE_H2,

      [theme.breakpoints.up('sm')]: {
        fontSize: pxToRem(THEME_DESKTOP_FONT_SIZE_H2),
        lineHeight: THEME_DESKTOP_LINE_HEIGHT_H2 / THEME_DESKTOP_FONT_SIZE_H2
      }
    },

    h3: {
      fontSize: pxToRem(THEME_MOBILE_FONT_SIZE_H3),
      fontWeight: fontWeightMedium,
      lineHeight: THEME_MOBILE_LINE_HEIGHT_H3 / THEME_MOBILE_FONT_SIZE_H3,

      [theme.breakpoints.up('sm')]: {
        fontSize: pxToRem(THEME_DESKTOP_FONT_SIZE_H3),
        lineHeight: THEME_DESKTOP_LINE_HEIGHT_H3 / THEME_DESKTOP_FONT_SIZE_H3
      }
    },

    h4: {
      fontSize: pxToRem(THEME_MOBILE_FONT_SIZE_H4),
      fontWeight: fontWeightMedium,
      lineHeight: THEME_MOBILE_LINE_HEIGHT_H4 / THEME_MOBILE_FONT_SIZE_H4,

      [theme.breakpoints.up('sm')]: {
        fontSize: pxToRem(THEME_DESKTOP_FONT_SIZE_H4),
        lineHeight: THEME_DESKTOP_LINE_HEIGHT_H4 / THEME_DESKTOP_FONT_SIZE_H4
      }
    },

    h5: {
      fontSize: pxToRem(THEME_MOBILE_FONT_SIZE_H5),
      fontWeight: fontWeightMedium,
      lineHeight: THEME_MOBILE_LINE_HEIGHT_H5 / THEME_MOBILE_FONT_SIZE_H5,

      [theme.breakpoints.up('sm')]: {
        fontSize: pxToRem(THEME_DESKTOP_FONT_SIZE_H5),
        lineHeight: THEME_DESKTOP_LINE_HEIGHT_H5 / THEME_DESKTOP_FONT_SIZE_H5
      }
    },

    h6: {
      fontSize: pxToRem(THEME_MOBILE_FONT_SIZE_H6),
      fontWeight: fontWeightMedium,
      lineHeight: THEME_MOBILE_LINE_HEIGHT_H6 / THEME_MOBILE_FONT_SIZE_H6,

      [theme.breakpoints.up('sm')]: {
        fontSize: pxToRem(THEME_DESKTOP_FONT_SIZE_H6),
        lineHeight: THEME_DESKTOP_LINE_HEIGHT_H6 / THEME_DESKTOP_FONT_SIZE_H6
      }
    }
  }

  const typographyInput = {
    fontFamily: THEME_FONT_FAMILY,
    fontSize: pxToRem(THEME_FONT_SIZE_INPUT),
    fontWeight: fontWeightRegular,
    lineHeight: THEME_LINE_HEIGHT_INPUT / THEME_FONT_SIZE_INPUT
  }

  const typographyLabel = {
    fontSize: pxToRem(THEME_FONT_SIZE_LABEL),
    fontWeight: fontWeightMedium,
    lineHeight: THEME_LINE_HEIGHT_LABEL / THEME_FONT_SIZE_LABEL
  }

  // We don’t use responsiveFontSizes because the Undivided design only has two font-size breakpoints,
  // and they are set directly.
  return deepmerge(theme, {
    typography: {
      ...typographyH,

      subtitle1: { fontFamily: 'Helvetica, Avenir, Georgia, Arial, sans-serif' },

      body1: {
        fontSize: pxToRem(THEME_MOBILE_FONT_SIZE_BODY1),
        fontWeight: fontWeightRegular,
        lineHeight: THEME_MOBILE_LINE_HEIGHT_BODY1 / THEME_MOBILE_FONT_SIZE_BODY1,

        [theme.breakpoints.up('sm')]: {
          fontSize: pxToRem(THEME_DESKTOP_FONT_SIZE_BODY1),
          lineHeight: THEME_DESKTOP_LINE_HEIGHT_BODY1 / THEME_DESKTOP_FONT_SIZE_BODY1
        }
      },

      body2: {
        fontSize: pxToRem(THEME_MOBILE_FONT_SIZE_BODY2),
        fontWeight: fontWeightRegular,
        lineHeight: THEME_MOBILE_LINE_HEIGHT_BODY2 / THEME_MOBILE_FONT_SIZE_BODY2,

        [theme.breakpoints.up('sm')]: {
          fontSize: pxToRem(THEME_DESKTOP_FONT_SIZE_BODY2),
          lineHeight: THEME_DESKTOP_LINE_HEIGHT_BODY2 / THEME_DESKTOP_FONT_SIZE_BODY2
        }
      },

      caption: {
        fontSize: pxToRem(THEME_FONT_SIZE_CAPTION),
        fontWeight: fontWeightBold,
        lineHeight: 16 / THEME_FONT_SIZE_CAPTION
      },

      button: {
        fontSize: pxToRem(THEME_FONT_SIZE_BUTTON),
        fontWeight: fontWeightMedium,
        lineHeight: 16 / THEME_FONT_SIZE_BUTTON
      },

      // Brand-specific.
      button1: {
        fontSize: pxToRem(THEME_FONT_SIZE_BUTTON1),
        fontWeight: fontWeightMedium,
        lineHeight: 16 / THEME_FONT_SIZE_BUTTON1
      },

      button2: {
        fontSize: pxToRem(THEME_FONT_SIZE_BUTTON2),
        fontWeight: fontWeightMedium,
        lineHeight: 14 / THEME_FONT_SIZE_BUTTON2
      },

      // Brand-specific, variants for Material UI input and label components.
      input: typographyInput,
      label: typographyLabel,

      label1: {
        fontSize: pxToRem(THEME_FONT_SIZE_LABEL1),
        fontWeight: fontWeightMedium,
        lineHeight: 20 / THEME_FONT_SIZE_LABEL1
      },

      label2: {
        fontSize: pxToRem(THEME_FONT_SIZE_LABEL2),
        fontWeight: fontWeightMedium,
        lineHeight: 18 / THEME_FONT_SIZE_LABEL2
      }
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          a: {
            fontWeight: theme.typography.fontWeightMedium,
            transition: createTransition('color')
          },

          ul: {
            paddingInlineStart: theme.spacing(2.5)
          },

          body: {
            fontSize: pxToRem(THEME_DESKTOP_FONT_SIZE_BODY2),
            lineHeight: 22 / THEME_DESKTOP_FONT_SIZE_BODY2
          },

          ...typographyH,
          input: typographyInput,
          label: typographyLabel
        }
      },

      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiInput-root .MuiInput-input': {
              padding: theme.spacing(0.75, 0.5, 0.75, 0)
            }
          }
        }
      },

      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: theme.spacing(12.5),
            padding: theme.spacing(1, 2, 0.75)
          },

          contained: {
            color: theme.palette.common.white,
            padding: theme.spacing(1, 2, 0.875), // Needs explicit override due to original style.

            // In the event that this is a link styled like a button…
            '&:visited': {
              color: theme.palette.common.white
            },

            '&:hover': {
              color: theme.palette.common.white
            }
          },

          outlined: {
            padding: theme.spacing(0.75, 2, 0.625),

            '&.MuiButton-outlinedInherit': {
              borderWidth: theme.spacing(0.25)
            },

            '&.Mui-disabled': {
              borderWidth: theme.spacing(0.25)
            }
          },

          text: {
            padding: theme.spacing(1, 2, 0.75) // Needs explicit override due to original style.
          }
        }
      },

      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: theme.spacing(1),
            borderWidth: theme.spacing(0.125),
            borderTopLeftRadius: theme.spacing(1), // These need to be specifically reiterated
            borderTopRightRadius: theme.spacing(1) // because the original sets them explicitly.
          },

          input: {
            padding: theme.spacing(3.375, 2, 1),

            '&:-webkit-autofill': {
              borderRadius: theme.spacing(1)
            },

            '&.MuiNativeSelect-select': {
              WebkitTextFillColor: 'inherit'
            },

            '&.MuiInputBase-input': {
              WebkitTextFillColor: 'inherit'
            }
          },

          multiline: {
            padding: theme.spacing(3, 2, 0.75),

            '& .MuiInputBase-input': {
              padding: 0
            }
          }
        }
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: pxToRem(THEME_DESKTOP_FONT_SIZE_BODY2),
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: 22 / THEME_DESKTOP_FONT_SIZE_BODY2
          }
        }
      },

      MuiFormLabel: {
        styleOverrides: {
          root: typographyLabel
        }
      },

      MuiIconButton: {
        styleOverrides: {
          sizeSmall: {
            padding: theme.spacing(0.375)
          }
        }
      },

      MuiInputBase: {
        styleOverrides: {
          root: typographyInput
        }
      },

      MuiInputLabel: {
        styleOverrides: {
          shrink: {
            fontSize: pxToRem(THEME_FONT_SIZE_LABEL2),
            fontWeight: fontWeightMedium,

            // shrink stays shrunk.
            [theme.breakpoints.up('sm')]: {
              fontSize: pxToRem(THEME_FONT_SIZE_LABEL2)
            }
          }
        }
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: spacing(1),
            fontSize: pxToRem(THEME_FONT_SIZE_TOOLTIP),
            padding: spacing(0.75, 1)
          }
        }
      }
    }
  })
})()
