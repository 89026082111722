/**
 * This module is meant to be the single source of truth in determining what goes where. It states the range of
 * paths that are recognized by the web app and contains all of their metadata, such as the main component,
 * path parameters, level of access, and other characteristics.
 *
 * For some paths, information pertaining to the user interface may also be supplied, such as the end-user
 * label for the path, relevant icons, etc. This fully centralizes _map.js_ as the one-stop-shop for all
 * information pertaining to all recognized paths within the web app.
 *
 * The implementation and usage might not be perfect at this writing, but the goal is eventually to be able to
 * shape/revise the structure of the application primarily by changing the contents of this module.
 */
import { Fragment, lazy } from 'react'
import { Outlet } from 'react-router-dom'

import PropTypes from 'prop-types'

import HelpIcon from '@mui/icons-material/HelpOutlineRounded'
import HomeIcon from '@mui/icons-material/Home'

import {
  UserGate,
  ParentGate,
  NavigatorGate,
  AdminGate,
  SuperuserGate,
  CertifiedMemberGate,
  ResourceGate,
  MainLayout,
  ThemeLayout,
  ServicesGate,
  InternalUserGate
} from '../app/support/gates'

import {
  accountThemeExtender,
  chatThemeExtender,
  communityThemeExtender,
  exploreThemeExtender,
  planThemeExtender,
  clientAccountsThemeExtender,
  profileBinderThemeExtender,
  visionThemeExtender,
  homeThemeExtender
} from '../app/support/theme'

import {
  CAPABILITY_PLAN,
  CAPABILITY_VIEW_BINDER,
  CAPABILITY_VIEW_GOAL,
  CAPABILITY_VIEW_MESSAGES
} from '../arch/capabilityConstants'

import { TASK_STATUS_DONE, TASK_STATUS_NOT_DONE } from '../arch/taskConstants'
import { RESERVED_ID_NEW } from '../guide/constants'

import {
  JOIN_PATHS_GATED,
  JOIN_PATHS_PUBLIC,
  ONBOARDING_LANDING,
  PROVIDER_PATHS_GATED,
  PROVIDER_PATHS_PUBLIC
} from '../onboarding/constants'

import {
  CLASS_HOME_TAB,
  CLASS_ACTION_PLAN_TAB,
  CLASS_EVENTS_TAB,
  CLASS_BINDER_TAB,
  CLASS_GOAL,
  CLASS_MESSAGES_TAB
} from '../tracking/constants'

import ClientAccountsIcon from '../icons/ClientAccounts'
import CommunityIcon from '../icons/Community'
import GearIcon from '../icons/Gear'
import MapIcon from '../icons/Map'
import MessagesIcon from '../icons/Messages'
import TasksIcon from '../icons/Tasks'

import Panel from '../app/support/Panel'

const Root = lazy(() => import('../app/Root'))
const NotFound = lazy(() => import('../app/support/NotFound'))

const Account = lazy(() => import('../portal/Account'))
const Binder = lazy(() => import('../portal/parent/Binder'))
const BinderIndexOutlet = lazy(() => import('../portal/parent/support/BinderIndexOutlet'))
const BinderChildOutlet = lazy(() => import('../portal/parent/support/BinderChildOutlet'))
const Documents = lazy(() => import('../portal/parent/Documents'))
const DocumentsIndexOutlet = lazy(() => import('../portal/parent/support/DocumentsIndexOutlet'))
const DocumentsPostOutlet = lazy(() => import('../portal/parent/support/DocumentsPostOutlet'))
const Events = lazy(() => import('../portal/parent/Events'))
const EventDetailRedirect = lazy(() => import('../portal/parent/support/EventDetailRedirect'))
const EventDetailOutlet = lazy(() => import('../portal/parent/support/EventDetailOutlet'))
const EventGroupRedirect = lazy(() => import('../portal/parent/support/EventGroupRedirect'))
const EventGroupOutlet = lazy(() => import('../portal/parent/support/EventGroupOutlet'))
const Feed = lazy(() => import('../portal/parent/Feed'))
const GiftRedirect = lazy(() => import('../portal/GiftRedirect'))
const Goal = lazy(() => import('../portal/parent/Goal'))
const GuideOutlet = lazy(() => import('../guide/GuideOutlet'))
const Home = lazy(() => import('../Home'))
const HouseholdRedirect = lazy(() => import('../HouseholdRedirect'))
const Invite = lazy(() => import('../Invite'))
const Join = lazy(() => import('../onboarding/Join'))
const ProviderReferral = lazy(() => import('../onboarding/ProviderReferral'))
const Login = lazy(() => import('../Login'))
const Logout = lazy(() => import('../app/Logout'))
const Messages = lazy(() => import('../portal/parent/Messages'))
const MessageBookmarks = lazy(() => import('../portal/parent/MessageBookmarks'))
const MessageSearchMatchesOutlet = lazy(() => import('../portal/parent/support/MessageSearchMatchesOutlet'))
const MessagesRedirect = lazy(() => import('../portal/parent/support/MessagesRedirect'))
const GoalTeamChatOutlet = lazy(() => import('../portal/parent/support/GoalTeamChatOutlet'))
const HouseholdMessagesOutlet = lazy(() => import('../portal/parent/support/HouseholdMessagesOutlet'))
const MobileRedirect = lazy(() => import('../portal/MobileRedirect'))
const InvitationConfirmation = lazy(() => import('../InvitationConfirmation'))
const Plan = lazy(() => import('../portal/parent/Plan'))
const PlanRedirect = lazy(() => import('../portal/parent/support/PlanRedirect'))
const Roadmap = lazy(() => import('../goal/Roadmap'))

const PlanningChecklist = lazy(() => import('../goal/PlanningChecklist'))
const ParentTasks = lazy(() => import('../portal/parent/Tasks'))
const ResetConfirmation = lazy(() => import('../ResetConfirmation'))
const ResetPassword = lazy(() => import('../ResetPassword'))
const ResourceOutlet = lazy(() => import('../knowledge/ResourceOutlet'))
const PostDocumentView = lazy(() => import('../binder/PostDocumentView'))
const PublicResources = lazy(() => import('../knowledge/PublicResources'))

const Dashboard = lazy(() => import('../portal/services/Dashboard'))
const RecentMessages = lazy(() => import('../portal/services/RecentMessages'))
const Tasks = lazy(() => import('../portal/services/Tasks'))
const TasksLanding = lazy(() => import('../task/TasksLanding'))
const TaskDetail = lazy(() => import('../task/TaskDetail'))
const ClientAccountList = lazy(() => import('../portal/services/client-account-list/ClientAccountList'))
const ClientAccountDetails = lazy(() => import('../portal/services/ClientAccountDetails'))
const TabRedirect = lazy(() => import('../portal/services/household/TabRedirect'))
const OverviewTab = lazy(() => import('../portal/services/household/OverviewTab'))
const MessagesTab = lazy(() => import('../portal/services/household/MessagesTab'))
const GoalsTab = lazy(() => import('../portal/services/household/GoalsTab'))
const VisionTab = lazy(() => import('../portal/services/household/VisionTab'))
const BinderTab = lazy(() => import('../portal/services/household/BinderTab'))
const DocumentsTab = lazy(() => import('../portal/services/household/DocumentsTab'))
const AccountActivity = lazy(() => import('../portal/services/support/AccountActivity'))
const GoalOutlet = lazy(() => import('../portal/services/support/GoalOutlet'))
const PlanningOutlet = lazy(() => import('../portal/services/support/PlanningOutlet'))
const ParentView = lazy(() => import('../portal/services/ParentView'))
const Tools = lazy(() => import('../portal/services/Tools'))
const GoalBankGuideAndResource = lazy(() => import('../portal/services/goal-bank/admin/GoalBankGuideAndResource'))
const GoalBankDrawerOutlet = lazy(() => import('../portal/services/goal-bank/GoalBankDrawerOutlet'))
const GoalBankRedirect = lazy(() => import('../portal/services/goal-bank/GoalBankRedirect'))
const AllGoals = lazy(() => import('../portal/services/planning/AllGoals'))
const SuggestedGoalsRedirect = lazy(() => import('../portal/services/planning/SuggestedGoalsRedirect'))
const RoadmapTemplates = lazy(() => import('../portal/services/planning/RoadmapTemplates'))

const GuideManager = lazy(() => import('../guide/admin/GuideManager'))
const GoalRedirect = lazy(() => import('../goal/GoalRedirect'))
const TemplateManager = lazy(() => import('../profile/admin/TemplateManager'))
const HouseholdSetup = lazy(() => import('../setup/HouseholdSetup'))
const AccountCreation = lazy(() => import('../onboarding/AccountCreation'))
const EmailConfirmation = lazy(() => import('../onboarding/EmailConfirmation'))
const EmailConfirmationReminder = lazy(() => import('../onboarding/EmailConfirmationReminder'))
const InvitationExpired = lazy(() => import('../onboarding/ExpiredMagicLink'))
const NewInviteSent = lazy(() => import('../onboarding/NewInviteSent'))
const OnboardingLanding = lazy(() => import('../onboarding/Onboarding'))
const CopilotConfirmation = lazy(() => import('../setup/CopilotConfirmation'))
const InfoBlockMigrationTool = lazy(() => import('../profile/admin/InfoBlockMigrationTool'))
const KnowledgeBaseArticlePreview = lazy(() => import('../knowledge/KnowledgeBaseArticlePreview'))
const ProxyToken = lazy(() => import('../user/admin/ProxyToken'))
const ResourceBulkUpdateTool = lazy(() => import('../knowledge/admin/ResourceBulkUpdateTool'))
const ResourceEditor = lazy(() => import('../knowledge/admin/resource-editor/ResourceEditor'))
const ResourceAdmin = lazy(() => import('../knowledge/admin/ResourceAdmin'))
const TopicsManager = lazy(() => import('../knowledge/admin/TopicsManager'))
const DecoderEditor = lazy(() => import('../knowledge/admin/resource-editor/DecoderEditor'))
const TrialRedirect = lazy(() => import('../onboarding/trial/TrialRedirect'))

const GoalBank = lazy(() => import('../portal/services/goal-bank/GoalBank'))
const GoalBankAdminDetail = lazy(() => import('../portal/services/goal-bank/admin/GoalBankAdminDetail'))
const GoalBankGeneralView = lazy(() => import('../portal/services/goal-bank/GoalBankGeneralView'))
const GoalBankHierarchyView = lazy(() => import('../portal/services/goal-bank/hierarchy-view/GoalBankHierarchyView'))
const GoalBankArchiveView = lazy(() => import('../portal/services/goal-bank/GoalBankArchiveView'))

const ResourceOrGuideListing = lazy(() =>
  import('../portal/services/goal-bank/goal-template-attachment/GoalTemplateAttachmentsListing')
)

const SuggestedGoalsDialog = lazy(() => import('../portal/services/planning/SuggestedGoalsDialog'))
const CustomGoalDetail = lazy(() => import('../goal/admin/CustomGoalDetail'))
const SuccessOrFailedPage = lazy(() => import('../user/PaymentStatus'))
const PaymentIntentStatus = lazy(() => import('../user/PaymentIntentStatus'))

const MessagesCtaTooltip = lazy(() => import('../cta/MessagesCtaTooltip'))
const BinderCtaTooltip = lazy(() => import('../cta/BinderCtaTooltip'))

/**
 * The top-level site map resembles a React Router routes hierarchy but with add information and meaning
 * based on the specific needs of the app. `path` and `children` are the same, but the final route
 * `element` can be determined in different ways. They all end up as `element` but because they have
 * differing roles, we have chosen to give them different names to be clear about their role in the
 * route hierarchy. In the end, they are all applications of the “layout route” pattern that is
 * recommended by React Router:
 *
 *   https://reactrouter.com/docs/en/v6/getting-started/concepts#layout-routes
 *
 * - `gate` (elementType) makes a layout route that controls access to its children—i.e., it navigates
 *    elsewhere if the conditions of the gate (typically roles) are not met
 *
 * - `theme` (func) makes a layout route that revises the theme of its children
 *
 * - `main` (bool) wraps its children in the standard top-level layout of the app
 *
 * - `component` (elementType) is any other element in pre-React Router v6 style: i.e., the _component_
 *    to instantiate and not the instantiation itself (the way `element` expects it). It is less flexible
 *    (i.e., can’t specify props) but looks a little cleaner because there no angle brackets nor slashes
 *    are necessary. Time will tell whether it is advantageous to keep this implementation
 *
 * - `element` (node) is a direct pass-through to the final Route `element`
 *
 * Some site specifications may also have the following properties:
 *
 * - `title` (string): If links to this route may appear in a menu, this would be their display name.
 *    This is also used to name the browser tab/window when the user is at this route. Finally, `title`
 *    is used for page tracking if `trackTitle` is not present (see next)
 *
 * - `trackTitle` (string): The name to use when reporting a page event; for use if the track label must
 *    be different from `title`
 *
 * - `capability` (string): A “hint” (because it is currently not consistently enforced) as to what
 *    capability must be present in the current household in order for the site to be available
 *
 * - `className` (string): A class to attach to the instantiated element as a predictable tag
 * - `preIcon` (elementType): If links to this route appear in a menu, use this is an icon before the title
 * - `postIcon` (elementType): If links to this route appear in a menu, use this is an icon after the title
 * - `clearUser` (bool): Indicates a route where we expressly don’t want a current user
 *
 * - `chatAvailable` (bool): Indicates whether navigator chat should be visible at this route (assuming
 *    all other rules regarding chat have been met)
 *
 * - `disabled` (bool): If links to this route appear in a menu, render it as disabled (not really used
 *    in the navigation map, but individual menu items might be appended with this for dynamic behavior)
 *
 * - `inParentView` (bool): Indicates whether a route is part of “parent view,” which is a specific mode
 *    that lets a navigator or other internal users see the same user interface that a parent would
 *
 * - `owner` (string): If this route should highlight something in the menu and its path doesn’t start
 *    with that menu item’s path, this can still identify which menu item it “belongs” to for proper
 *    selection highlighting
 *
 * - `forMembersOnly` (elementType): If present, the site should only be enabled for a user that has a
 *    paid membership; non-members will be shown this component as a tooltip CTA
 *
 * - `withChildrenOnly` (bool): Indicates whether a site should only be visible to a user that has a
 *    household with children
 *
 */
const SITE_SHAPE = PropTypes.shape({
  // Same properties as Route…
  caseSensitive: PropTypes.bool,
  children: PropTypes.array, // of SITE_SHAPE—can’t recurse because this isn’t a real type system.
  element: PropTypes.node,
  index: PropTypes.bool,
  path: PropTypes.string,

  // …plus a few more (see above for explanations).
  gate: PropTypes.elementType,
  theme: PropTypes.func,
  main: PropTypes.bool,
  component: PropTypes.elementType,
  title: PropTypes.string,
  className: PropTypes.string,
  preIcon: PropTypes.elementType,
  postIcon: PropTypes.elementType,
  clearUser: PropTypes.bool,
  chatAvailable: PropTypes.bool,
  disabled: PropTypes.bool,
  inParentView: PropTypes.bool,
  owner: PropTypes.string,
  forMembersOnly: PropTypes.elementType,
  withChildrenOnly: PropTypes.bool
})

/**
 * Before we get into the main site map, we define some route patterns that get repeated in certain contexts.
 */
const binderRoutes = (binderComponent, documentsComponent) => [
  {
    path: 'binder',
    capability: CAPABILITY_VIEW_BINDER,
    main: true,
    component: binderComponent,
    className: CLASS_BINDER_TAB,
    forMembersOnly: BinderCtaTooltip,
    title: 'Profile',

    children: [
      { index: true, component: BinderIndexOutlet, title: 'Binder' },
      { path: ':clientId', component: BinderChildOutlet, title: 'Binder' }
    ]
  },

  {
    path: 'documents',
    capability: CAPABILITY_VIEW_BINDER,
    main: true,
    component: documentsComponent,
    title: 'Documents',

    children: [
      { index: true, component: DocumentsIndexOutlet, title: 'Documents' },
      {
        path: ':postId',
        component: DocumentsPostOutlet,
        title: 'Documents',
        children: [{ path: 'edit', component: () => null /* Handled by the parent component internally. */ }]
      }
    ]
  }
]

const exploreRoutes = () => [
  ...['resources', 'knowledge-base'].map(prefix => ({
    path: prefix,
    main: true,
    chatAvailable: true,
    component: PublicResources,
    title: 'Resources',
    // No need for preIcon here because Resources is a collapsible menu;
    // the CollapsibleMenu instance sets the icon.

    // TODO Possibly refactor for nesting sometime?
    children: [
      'search',
      'andy-results',
      ':articleId',
      ':articleId/:articleTitleSlug',
      'print/:articleId',
      'print/:articleId/:contentIndex',
      'article/:articleId',
      'article/:articleId/:articleTitleSlug',
      'goals/:goalId'
    ].map(path => ({ path, component: PublicResources }))
  })),

  {
    path: 'resources/my-bookmarks',
    title: 'Bookmarks',
    main: true,
    chatAvailable: true,
    component: PublicResources
  }
]

const parentViewRoutes = () => [
  {
    path: 'feed',
    theme: homeThemeExtender,
    className: CLASS_HOME_TAB,
    chatAvailable: true,
    component: Feed,
    withChildrenOnly: true,
    title: 'Home',
    trackTitle: 'Parent Home',
    preIcon: CommunityIcon
  },

  {
    path: 'checklist',
    theme: homeThemeExtender,
    component: ParentTasks,
    withChildrenOnly: true,
    title: 'Tasks',
    owner: 'feed',
    trackTitle: 'Parent Tasks'
  },

  {
    path: 'events',
    theme: homeThemeExtender,
    component: Events,
    title: 'Events',
    owner: 'feed',
    className: CLASS_EVENTS_TAB,

    children: [
      { index: true, component: EventGroupRedirect },

      {
        path: ':eventGroup', // 'upcoming' or 'past' as of now.
        component: EventGroupOutlet,
        children: [
          { index: true, component: EventDetailRedirect },
          { path: ':eventId', component: EventDetailOutlet }
        ]
      }
    ]
  },

  {
    theme: planThemeExtender,
    children: [
      {
        path: 'roadmaps',
        capability: CAPABILITY_PLAN,
        className: CLASS_ACTION_PLAN_TAB,
        chatAvailable: true,
        component: Plan,
        withChildrenOnly: true,
        title: 'Roadmap',
        preIcon: MapIcon,

        children: [
          { index: true, component: PlanRedirect },
          { path: ':clientId', component: Roadmap }
        ]
      },

      {
        path: 'roadmaps/:clientId/goals/:goalId',
        capability: CAPABILITY_VIEW_GOAL,
        chatAvailable: true,
        component: Goal,
        title: 'Goal',
        owner: 'roadmaps',
        className: CLASS_GOAL,

        children: [
          { path: 'edit', component: () => null /* Special case handling, see GoalDetail.js */ },

          {
            path: 'guides/:guideId',
            chatAvailable: true,
            component: GuideOutlet,
            owner: 'roadmaps'
          },

          {
            path: 'resources/:resourceId',
            chatAvailable: true,
            component: ResourceOutlet,
            owner: 'roadmaps'
          }
        ]
      }
    ]
  },

  {
    path: 'messages',
    capability: CAPABILITY_VIEW_MESSAGES,
    theme: chatThemeExtender,
    className: CLASS_MESSAGES_TAB,
    chatAvailable: true,
    component: Messages,
    forMembersOnly: MessagesCtaTooltip,
    withChildrenOnly: true,
    title: 'Messages',
    preIcon: MessagesIcon,

    children: [
      { index: true, component: MessagesRedirect },
      { path: 'matches', component: MessageSearchMatchesOutlet },
      { path: 'navigator', component: HouseholdMessagesOutlet },

      {
        path: 'goals',
        children: [
          { index: true, component: MessagesRedirect },
          { path: ':goalChannel', component: GoalTeamChatOutlet }
        ]
      }
    ]
  },

  {
    path: 'messages/bookmarks',
    theme: chatThemeExtender,
    component: MessageBookmarks,
    title: 'Message Bookmarks',
    owner: 'messages'
  },

  {
    theme: profileBinderThemeExtender,
    children: binderRoutes(Binder, Documents)
  },

  {
    path: 'gift-undivided',
    theme: planThemeExtender,
    component: GiftRedirect,
    title: 'Gift'
  },

  {
    path: 'trial-upgrade',
    theme: planThemeExtender,
    component: TrialRedirect,
    title: 'Trial'
  },

  {
    path: 'help',
    title: 'Help Center', // No component because the menu item handles everything.
    preIcon: HelpIcon
  }
]

const GUIDE_AND_RESOURCE_DRAWER = [
  { index: true, component: GoalBankDrawerOutlet },
  {
    path: 'goals/:goalBankId',
    component: GoalBankDrawerOutlet,
    children: [
      { path: 'guides/:guideId', component: GoalBankGuideAndResource },
      { path: 'resources/:resourceId', component: GoalBankGuideAndResource }
    ]
  }
]

const SITE_MAP = [
  {
    path: '/',
    component: Root,
    children: [
      { path: 'login', main: true, component: Login, title: 'Login' },
      { path: 'logout', main: true, component: Logout, title: 'Logout' },
      { path: 'confirm-email', main: true, component: EmailConfirmationReminder, title: 'Confirm Email' },
      { index: true, main: true, component: Home, title: 'Landing' },
      { path: 'mobile', main: true, component: MobileRedirect },
      { path: 'password-reset', main: true, clearUser: true, component: ResetPassword, title: 'Password Recovery' },

      // Public join paths as of this writing.
      ...JOIN_PATHS_PUBLIC.map(path => ({ path, main: true, clearUser: true, component: Join, title: 'Join' })),
      ...PROVIDER_PATHS_PUBLIC.map(path => ({
        path,
        main: true,
        clearUser: true,
        component: ProviderReferral,
        title: 'provider'
      })),

      // In principle, confirmation pages should clear the user before proceeding, but we’ll
      // handle that within the component because they may also auto-login a user and doing
      // the user-clear at the boundary level will interfere with that.
      {
        path: 'confirmations/resets/:resetKey',
        main: true,
        component: ResetConfirmation,
        title: 'Reset Password'
      },

      ...['confirmations/signups/:signupKey', 'magic-link/:signupKey'].map(path => ({
        path,
        main: true,
        component: AccountCreation,
        title: 'Create Account'
      })),

      {
        path: 'confirmation/email/:confirmationKey',
        main: true,
        component: EmailConfirmation,
        title: 'Email Confirmation'
      },
      {
        path: 'confirmations/copilots/:copilotKey',
        main: true,
        component: CopilotConfirmation,
        title: 'Accept Invite'
      },
      {
        path: 'confirmations/invitations/:invitationKey',
        main: true,
        component: InvitationConfirmation,
        title: 'Accept Invite'
      },
      {
        path: 'confirmations/signups/expired/:signupKey',
        main: true,
        component: InvitationExpired,
        title: 'Confirmation Expired'
      },
      {
        path: 'confirmations/signups/new-link',
        main: true,
        component: NewInviteSent,
        title: 'Request Confirmation Email'
      },
      { path: '*', main: true, component: NotFound },

      {
        gate: UserGate,
        main: true,
        children: [
          {
            path: 'home',
            theme: communityThemeExtender,
            component: Home,
            title: 'Landing',
            preIcon: HomeIcon,
            className: CLASS_HOME_TAB
          },

          {
            path: 'documents/:postId/view',
            component: PostDocumentView,
            title: 'Document'
          },

          {
            path: 'chat',
            title: 'Messages',
            children: [
              { index: true, component: HouseholdRedirect },
              { path: ':householdId/messages/:messageId', component: HouseholdRedirect, title: 'Navigator Chat' },
              {
                path: ':householdId/goals/:goalId/comments/:commentId',
                component: GoalRedirect,
                title: 'Goal Chat'
              }
            ]
          },

          { path: ':clientId/goals/:goalId', component: GoalRedirect, title: 'Goal Workspace' },
          { path: ':clientId/goals/:goalId/comments/:commentId', component: GoalRedirect, title: 'Goal Chat' },

          {
            theme: accountThemeExtender,
            children: ['account', 'me'].flatMap(path =>
              ['', '/*'].map(suffix => ({
                path: `${path}${suffix}`,
                component: Account,
                title: 'Account',
                preIcon: GearIcon
              }))
            )
          },

          {
            theme: exploreThemeExtender,
            children: ['resources', 'knowledge-base'].map(prefix => ({
              path: `${prefix}/preview/:articleId`,
              chatAvailable: true,
              component: KnowledgeBaseArticlePreview
            }))
          }
        ]
      },

      {
        gate: ResourceGate,
        main: true,
        children: [{ theme: exploreThemeExtender, children: exploreRoutes() }]
      },

      {
        gate: ParentGate,
        main: true,
        children: [
          { path: ONBOARDING_LANDING, component: OnboardingLanding, title: 'Onboarding' },
          ...parentViewRoutes(),
          ...exploreRoutes(),

          // TODO temporary while new Account Settings design is still in progress.
          { path: 'setup/copilots', component: HouseholdSetup, title: 'Add Co-Pilot' }
        ]
      },

      {
        gate: InternalUserGate,
        main: true,
        children: [
          {
            path: 'tasks',
            theme: chatThemeExtender,
            element: <Tasks status={TASK_STATUS_NOT_DONE} />,
            title: 'Tasks',
            preIcon: TasksIcon,
            children: [
              { index: true, component: TasksLanding },
              { path: ':taskId', component: TaskDetail }
            ]
          },

          {
            path: 'tasks/archive',
            theme: chatThemeExtender,
            element: <Tasks status={TASK_STATUS_DONE} />,
            children: [
              { index: true, component: TasksLanding },
              { path: ':taskId', component: TaskDetail }
            ]
          },

          {
            path: 'tools',
            theme: exploreThemeExtender,
            component: Tools,
            title: 'Tools'
            // No need for preIcon here because Tools is a collapsible menu;
            // the CollapsibleMenu instance sets the icon.
          },

          {
            path: 'resources/my-bookmarks',
            title: 'Bookmarks',
            main: true,
            chatAvailable: true,
            component: PublicResources
          },

          {
            path: 'goal-bank',
            title: 'Goal Bank',
            component: GoalBank,
            theme: exploreThemeExtender,
            children: [
              { index: true, component: GoalBankRedirect },

              {
                path: 'standard-view',
                component: GoalBankGeneralView,
                children: GUIDE_AND_RESOURCE_DRAWER
              },

              {
                path: 'hierarchy-view',
                component: GoalBankHierarchyView,
                children: GUIDE_AND_RESOURCE_DRAWER
              },

              { path: 'archive-view', component: GoalBankArchiveView, children: GUIDE_AND_RESOURCE_DRAWER },
              { path: 'new', component: GoalBankAdminDetail },

              {
                path: ':goalBankId',
                component: GoalBankAdminDetail,
                children: [
                  { path: 'add-guide', theme: accountThemeExtender, component: ResourceOrGuideListing },
                  { path: 'add-resource', theme: accountThemeExtender, component: ResourceOrGuideListing }
                ]
              }
            ]
          },

          {
            path: 'guide-admin',
            component: GuideManager,
            title: 'Guides',
            children: [
              { index: true, component: GoalBankDrawerOutlet },
              { path: ':guideId', title: 'Guide Admin', component: GoalBankDrawerOutlet },
              {
                // When the “new” path is followed by an ID, we interpret that as creating a guide from that source.
                path: `${RESERVED_ID_NEW}/:variantSourceId`,
                component: GoalBankDrawerOutlet,
                title: 'Guide Admin'
              },
              { path: ':guideId/variants/:guideVariantId', component: GoalBankDrawerOutlet, title: 'Guide Admin' },
              { path: ':guideId/guides/:guidePreviewId', component: GoalBankDrawerOutlet },
              { path: 'guides/:guidePreviewId', component: GoalBankDrawerOutlet }
            ]
          }
        ]
      },

      {
        gate: ServicesGate,
        main: true,
        children: [
          {
            path: 'client-accounts',
            theme: clientAccountsThemeExtender,
            title: 'Accounts',
            preIcon: ClientAccountsIcon,
            children: [
              { index: true, component: ClientAccountList },

              {
                path: ':householdId',
                component: ClientAccountDetails,

                children: [
                  { index: true, component: TabRedirect },
                  { path: 'overview', component: OverviewTab },
                  { path: 'messages', component: MessagesTab },
                  { path: 'vision', theme: visionThemeExtender, component: VisionTab },

                  {
                    path: 'roadmaps/:clientId',
                    theme: planThemeExtender,
                    component: GoalsTab,
                    children: [
                      { index: true, component: PlanningOutlet },

                      {
                        path: 'add-goals',
                        theme: exploreThemeExtender,
                        component: SuggestedGoalsDialog,
                        children: [
                          { index: true, component: SuggestedGoalsRedirect },
                          {
                            path: 'all-goals',
                            component: AllGoals,
                            children: GUIDE_AND_RESOURCE_DRAWER
                          },
                          {
                            path: 'roadmap-templates',
                            component: RoadmapTemplates
                          },
                          {
                            path: 'new',
                            component: CustomGoalDetail,
                            children: [
                              { path: 'guides/:guideId', component: GuideOutlet },
                              { path: 'resources/:resourceId', theme: exploreThemeExtender, component: ResourceOutlet }
                            ]
                          }
                        ]
                      },

                      ...['goals/:goalId', 'goals/:goalId/edit'].map(path => ({
                        path,
                        component: GoalOutlet,
                        children: [
                          { path: 'guides/:guideId', theme: planThemeExtender, component: GuideOutlet },
                          { path: 'resources/:resourceId', theme: exploreThemeExtender, component: ResourceOutlet }
                        ]
                      }))
                    ]
                  },

                  ...binderRoutes(BinderTab, DocumentsTab),

                  { path: 'tasks', component: PlanningChecklist },
                  { path: 'activity', component: AccountActivity }
                ]
              }
            ]
          },

          {
            path: 'parent-view/:householdId',
            component: ParentView,
            inParentView: true,
            children: [...parentViewRoutes(), { theme: exploreThemeExtender, children: exploreRoutes() }]
          }
        ]
      },

      {
        gate: NavigatorGate,
        main: true,
        children: [
          {
            path: 'dashboard',
            theme: communityThemeExtender,
            className: CLASS_HOME_TAB,
            component: Dashboard,
            title: 'Home',
            preIcon: HomeIcon
          },

          { path: 'invite', component: Invite, title: 'Invite' },
          { path: 'tasks', theme: chatThemeExtender, component: Tasks, title: 'Tasks', preIcon: TasksIcon }
        ]
      },

      {
        gate: CertifiedMemberGate,
        main: true,
        children: [{ path: 'client-services-portal', theme: communityThemeExtender, component: RecentMessages }]
      },

      {
        gate: AdminGate,
        main: true,

        // TODO Possibly refactor for nesting sometime?
        children: [
          { path: 'templates', component: TemplateManager, title: 'PB editor' },
          { path: 'templates/:templateId', component: TemplateManager },
          { path: 'resource-admin', component: ResourceAdmin, title: 'Resource admin' },
          { path: 'resource-admin/:resourceId', component: ResourceEditor, title: 'Resource admin' },
          { path: 'decoders/:resourceId', component: DecoderEditor },
          { path: 'topics', component: TopicsManager, title: 'Topics admin' }
        ]
      },

      {
        gate: SuperuserGate,
        main: true,
        children: [
          // Some join routes are not ready for prime time.
          // TODO When they go to prime time, make sure to add `clearUser: true`
          ...JOIN_PATHS_GATED.map(path => ({ path, main: true, component: Join, title: 'Signup' })),
          ...PROVIDER_PATHS_GATED.map(path => ({
            path,
            main: true,
            component: ProviderReferral,
            title: 'Provider Referral Signup'
          })),

          { path: 'proxy-token', component: ProxyToken, title: 'Proxy token' },
          { path: 'resource-bulk-update', component: ResourceBulkUpdateTool, title: 'Resource migration' },
          { path: 'resource-bulk-update/resources/:resourceId', component: ResourceBulkUpdateTool },
          { path: 'resource-bulk-update/resources/:resourceId/:cursor', component: ResourceBulkUpdateTool },
          { path: 'info-block/migration', component: InfoBlockMigrationTool, title: 'PB migration' },
          { path: 'info-block/migration/:templateId', component: InfoBlockMigrationTool }
        ]
      },

      {
        path: '/post-payment',
        component: SuccessOrFailedPage,
        title: 'Payment Gateway'
      },

      {
        path: '/pre-payment',
        component: PaymentIntentStatus,
        title: 'Payment Gateway'
      }
    ]
  }
]

/**
 * SITE_MAP is the source of truth and directly determines the app’s Routes, but sometimes site information
 * needs to be looked up based on pathname. Thus, we derive SITE_INDEX from SITE_MAP which traverses the
 * tree to build an object keyed by full pathname.
 */
const createIndexEntries = site => {
  const { children, path, ...rest } = site
  return [
    site,
    ...(children || []).flatMap(createIndexEntries).map(childEntry => ({
      ...rest,
      ...childEntry,
      path: `${path && path !== '/' ? `${path}/` : ''}${childEntry.path ?? ''}`
    }))
  ]
}

const createIndex = siteMap =>
  Object.fromEntries(
    siteMap
      .flatMap(createIndexEntries)
      // No falsy paths nor full wildcards for the index.
      .filter(indexEntry => indexEntry.path && indexEntry.path !== '*')
      .map(indexEntry => [indexEntry.path, indexEntry])
  )

const SITE_INDEX = createIndex(SITE_MAP)

const reverseLexical = (left, right) => (left < right ? 1 : left > right ? -1 : 0)
const paths = Object.keys(SITE_INDEX)

const ALL_PATHS = paths.sort(reverseLexical)
const PREVIEW_PATHS = paths.filter(path => path.includes('/preview/'))
const SETUP_PATHS = paths.filter(path => path.startsWith('setup'))
const ONBOARDING_PATHS = paths.filter(path => path.startsWith('onboarding'))
const EMAIL_CONFIRMATION_PATH = paths.filter(path => path.startsWith('confirm-email'))
const KICKSTART_PREP_PATH = paths.filter(path => path.startsWith('kickstart-prep'))
const OPEN_PATHS = paths.filter(path => !SITE_INDEX[path].gate).sort(reverseLexical)
const USER_PATHS = paths.filter(path => SITE_INDEX[path].gate).sort(reverseLexical)
const PARENT_PATHS = USER_PATHS.filter(path => [ParentGate, ParentGate].includes(SITE_INDEX[path].gate))
const FULLSCREEN_PATHS = paths.filter(path => path.endsWith('/view'))
const MENULESS_PATHS = [...OPEN_PATHS, ...ONBOARDING_PATHS, ...FULLSCREEN_PATHS]

const CHAT_PATHS = paths
  .filter(
    path =>
      (SITE_INDEX[path].chatAvailable || PARENT_PATHS.includes(path)) &&
      !PREVIEW_PATHS.includes(path) &&
      !SETUP_PATHS.includes(path) &&
      !ONBOARDING_PATHS.includes(path) &&
      !EMAIL_CONFIRMATION_PATH.includes(path) &&
      !KICKSTART_PREP_PATH.includes(path)
  )
  .sort(reverseLexical)

const site = path => SITE_INDEX[path]

const siteNodeToRoute = siteNode => {
  const { path, index, element, gate, theme, main, component: Component, clearUser, children } = siteNode

  const Gate = gate ?? Fragment
  const Theme = theme ? props => <ThemeLayout theme={theme} {...props} /> : Fragment
  const Layout = main ? props => <MainLayout clearUser={clearUser} {...props} /> : Fragment
  const content = Component ? <Component /> : element ?? <Outlet />

  return {
    path,
    index,
    element: (
      <Panel fullHeight={Component === Root /* Special case for top-level load. */}>
        <Gate>
          <Theme>
            <Layout>{content}</Layout>
          </Theme>
        </Gate>
      </Panel>
    ),
    children: children?.map(siteNodeToRoute)
  }
}

const ALL_ROUTES = SITE_MAP.map(siteNodeToRoute)

export { site, SITE_SHAPE, ALL_ROUTES, ALL_PATHS, SETUP_PATHS, CHAT_PATHS, MENULESS_PATHS, USER_PATHS }
